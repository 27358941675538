<template>
  <f7-page login-screen>
    <navbarlist :list="'private-exercises-categories'"></navbarlist>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{ 'base.new_category' | translate}}</h4>
          </f7-block>
          <f7-list form no-hairlines-md @submit.prevent="createCategory">
            <f7-list-input
              :label="'base.category' | translate"
              type="text"
              :placeholder="'base.category' | translate"
              :value="category.title"
              @input="category.title = $event.target.value"
              clear-button
              required
              validate
              :error-message="'base.required' | translate"
            >
            </f7-list-input>
            <f7-list-item :title="'base.color_category' | translate" class="custom-select">
              <select name="color" v-model="category.color">
                <option :value="null">{{ 'global.lack' | translate}}</option>
                <option v-for="(color, index) in paleteColorsDot" :key="index" :value="index" :selected="index == category.color">
                    {{color.label}}
                </option>
              </select>
            </f7-list-item>
            <f7-block class="text-center">
              <f7-button v-if="!loaderCategory" type="submit" raised>
                {{ 'base.add_category' | translate}}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import privateExercisesServices from "@/services/privateExercisesServices.js"
import Navbarlist from "@/components/Navbar/Navbarlist";
import Loader from "@/components/Loader";
import CONSTANTS from "@/utils/constants.js"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  components: {
    Navbarlist,
    Loader,
  },
  data: () => {
    return {
      loader: false,
      loaderCategory: false,
      paleteColorsDot: CONSTANTS.COLORS_DOT,
      category: {
        title: '',
        color: null,
      },
    };
  },
  mounted() {
    this.fetchDataUser();
  },
  methods: {
    async createCategory() {
      try {
        this.loaderCategory = true;

        const responseCreateCategory = await privateExercisesServices.createCategory(this.category);
        if (responseCreateCategory.data.id) {
          this.$f7router.navigate(`/private-exercises-categories/`)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with adding categories to private outlines. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderCategory = false;
    },
  }
};
</script>