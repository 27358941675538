import Api from './index'
import store from '@/store'

export default {
  async createDue(data) {
    return Api().post('dues/', data, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listDues(group, pagination) {
    let page = '';
    if (pagination) {
      const url = new URL(pagination);
      page= url.search
    }
    return Api().get(`dues/?group=${group}&${page}`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async itemDue(id) {
    return Api().get(`dues/${id}/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async due(data) {
    return Api().put(`dues/${data.id}/`, data, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async deleteDue(id) {
    return Api().delete(`dues/${id}/`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async duesStats(data) {
    return Api().get(`dues/stats/?group=${data.group}&date_start=${data.date_start}&date_end=${data.date_end}`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
}