<template>
  <f7-page login-screen>
    <navbar></navbar>
    <f7-block style="margin-top: -50px; margin-bottom: -75px;">
      <lottie
        :options="defaultOptionsCancel"
        :height="300"
        :width="300"
        v-on:animCreated="handleAnimation"
      />
    </f7-block>
    <f7-block class="text-align-center">
      <h4>{{ 'payment.failed_page' | translate}} :(</h4>
      <span>{{ 'payment.failed_page_description' | translate}}</span>
    </f7-block>
    <f7-block class="text-center">
      <f7-button href="/payments/" raised>{{ 'payment.retry_payment' | translate }}</f7-button>
    </f7-block>

  </f7-page>
</template>
<script>
import * as animationDataCancel from "@/assets/lottie/cancel.json";
import Lottie from "vue-lottie";
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    lottie: Lottie,
    Navbar,
  },
  data: () => {
    return {
      defaultOptionsCancel: {
        animationData: animationDataCancel.default,
        loop: false
      }
    };
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    }
  }
};
</script>