import { mapGetters } from 'vuex'
import authServices from "@/services/AuthServices"
import Vue from 'vue';

export default {
  computed: {
    ...mapGetters({
      isAuthenticated: 'appkonspektypilkanozna/isAuthenticated',
      myToken: 'appkonspektypilkanozna/myToken',
      getActualBook: 'appkonspektypilkanozna/getActualBook',
      getLang: 'appkonspektypilkanozna/getLang',
      getGlobalId: 'appkonspektypilkanozna/getGlobalId',
      getRole: 'appkonspektypilkanozna/getRole',
      getActiveGroup: 'appkonspektypilkanozna/getActiveGroup',
      getActiveFilteredBookId: 'appkonspektypilkanozna/getActiveFilteredBookId',
    }),
    host() {
      if (process.env.NODE_ENV == 'development') {
        return 'http://localhost:8100'
      }
      if (process.env.NODE_ENV == 'production') {
        return 'https://api.konspektypilkanozna.pl'
      }
    },
    hostApp() {
      if (process.env.NODE_ENV == 'development') {
        return 'http://localhost:8080'
      }
      if (process.env.NODE_ENV == 'production') {
        return 'https://app.soccer-sessions.com'
      }
    },
    pkStripe() {
      if (process.env.NODE_ENV == 'development') {
        return 'pk_test_51JIYgVAcVpyxdvXPHYgQ8bctZmpSApmaQUl3KYmuiqs7coVwzh52y9zv19w8UXFhpdgczHguIsimmFizDCS6Xw0100Zp8CcGML'
      }
      if (process.env.NODE_ENV == 'production') {
        return 'pk_live_51JIYgVAcVpyxdvXPlXP3Bct0iO0qzRiF8tlDv715Ld1GL1YeP2RnsiWCcnu8FunzTuXFi7FGwqTq4DLOMFOQzAnB00BJXSZMQg'
      }
    }
  },
  methods: {
    async checkVerified(token) {
      try {
        const responseVerify = await authServices.tokenVerify(token);
        if (responseVerify.status == 200) {
          this.$store.dispatch("appkonspektypilkanozna/setVerified", true);
        } else {
          this.$store.dispatch("appkonspektypilkanozna/setToken", null);
          this.$store.dispatch("appkonspektypilkanozna/setVerified", false);
          this.$store.dispatch("appkonspektypilkanozna/setRole", null);
          this.$store.dispatch("appkonspektypilkanozna/setGlobalId", null);
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeLang() {
      if (this.getLang == 'pl') {
        this.$store.dispatch("appkonspektypilkanozna/setLang", 'en');
        Vue.i18n.set('en');
        this.$f7.toast.create({
          text: 'Application language changed to English',
          position: 'bottom',
          closeTimeout: 3000,
        }).open();
        if (this.$f7.views.main.router.currentRoute.params.idBook !== undefined) {
          this.$f7.views.main.router.navigate(this.$f7.views.main.router.currentRoute.path, {
            reloadCurrent: true,
            ignoreCache: true,
        })
        
        }
      } else if (this.getLang == 'en') {
        this.$store.dispatch("appkonspektypilkanozna/setLang", 'pl');
        Vue.i18n.set('pl');
        this.$f7.toast.create({
          text: 'Application language changed to Polish',
          position: 'bottom',
          closeTimeout: 3000,
        }).open();
        if (this.$f7.views.main.router.currentRoute.params.idBook !== undefined) {
          this.$f7.views.main.router.navigate(this.$f7.views.main.router.currentRoute.path, {
            reloadCurrent: true,
            ignoreCache: true,
        })
        }
      }
    },
    changeGroup(val) {
      this.$store.dispatch("appkonspektypilkanozna/setGroup", val);
    }
  }
}