<template>
  <f7-page login-screen class="no-print">
    <navbarbook></navbarbook>
    <loader v-if="loader"></loader>

    <div v-else>
      <f7-block v-if="actualPage !== undefined">
        <f7-block class="text-center text-uppercase ">
          <h4>{{actualPage[0].title}}
          </h4>
        </f7-block>
        <f7-block>
          <div v-for="row in actualPage[0].content" :key="row.id">
            <div v-if="row.includes('png') || row.includes('jpg') || row.includes('mp4')">
              <p v-if="row.includes('png') || row.includes('jpg')">
                <img class="w-100" :src="`/static/${row}`">
              </p>
              <p v-if="row.includes('mp4')">
                <video id="video-player" width="100%" controls loop muted>
                  <source :src="`/static/${row}`" type="video/mp4">
                </video>
              </p>
            </div>
            <div v-else>
              <p class="text-justify" v-html="row"></p>
            </div>
          </div>
        </f7-block>
        <f7-row>
          <f7-col width="50">
            <f7-button v-if="indexPage !== 0" style="margin: 0 12px;" @click="$f7router.navigate(`${previosPage}`);" raised>{{ 'books.previous' | translate }}</f7-button>
          </f7-col>
          <f7-col width="50">
            <f7-button v-if="indexPage !== contentBook.length - 1" style="margin: 0 12px;" @click="$f7router.navigate(`${nextPage}`);" raised>{{ 'books.next' | translate }}</f7-button>
          </f7-col>
        </f7-row>
        <div v-if="bookPanel.demo">
          <div v-if="bookPanel.shop !== '' && getLang === 'pl'">
            <f7-link style="margin: 0 12px;" :href="bookPanel.shop" external target="_blank">
              <p class="text-center font-weight-bold" style="color: #d62029">{{ 'global.more_buy_single' | translate }}.</p>
            </f7-link>
            <p class="text-uppercase text-center mt-0 mb-0">{{ 'global.or' | translate }}</p>
          </div>
          <f7-link style="margin: 0 12px;" href="/payments/">
            <p class="text-center font-weight-bold" style="color: #0fa300">{{ 'global.more_buy_subscriptions' | translate }}.</p>
          </f7-link>
        </div>
        
      </f7-block>
    </div>
  </f7-page>
</template>

<script>
import Navbarbook from "@/components/Navbar/Navbarbook.vue";
import Loader from "@/components/Loader";
import mixinBook from "@/mixins/mixinBook"

export default {
  mixins: [mixinBook],
  components: {
    Navbarbook,
    Loader
  },
  props: {
    idBook: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      loader: false,
      contentBook: [],
      bookPanel: {}
    };
  },
  mounted() {
    this.$store.dispatch("appkonspektypilkanozna/setActualBook", this.idBook);
    if (this.isAuthenticated) {
      this.fetchDataBook()
    } else {
      this.fetchDataBookDemo()
    }
    if (this.$f7route.query.lang !== undefined) {
        const lang = this.$f7route.query.lang
        if (this.getLang !== lang) {
          this.changeLang()
        }
    }
  },
  
};
</script>