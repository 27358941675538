export default {
  namespaced: true,
  state: {
    role: null,
    globalId: null,
    token: null,
    verified: false,
    actualBook: '',
    lang: 'pl',
    config: {
      coach: true,
      subject: true,
      microcycle: true,
      info: true,
      accessories: true,
      areas: true,
      objective: true,
      coaching: true,
      notes: true,

      introduction1: true,
      introduction2: true,
      main1: true,
      main2: true,
      final: true,
      new_pzpn: false,
    },
    configExercise: {
      subject: true,
      objective: true,
      coaching: true,
      info: true,
      part: true,
    },
    activeGroup: '',
    activeFilteredBookId: null
  },
  mutations: { 
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_VERIFIED(state, value) {
      state.verified = value
    },
    SET_ACTUAL_BOOK(state, book) {
      state.actualBook = book
    },
    SET_LANG(state, payload) {
      state.lang = payload;
    },
    SET_ROLE(state, role) {
      state.role = role
    },
    SET_GLOBAL_ID(state, id) {
      state.globalId = id
    },
    SET_CONFIG(state, config) {
      state.config = config
    },
    SET_CONFIG_EXERCISE(state, configExercise) {
      state.configExercise = configExercise
    },
    SET_GROUP(state, group) {
      state.activeGroup = group
    },
    SET_FILTERED_BOOK_ID(state, bookId) {
      state.activeFilteredBookId = bookId
    }
   },
  actions: {
    setRole({ commit }, role) {
      commit('SET_ROLE', role);
    },
    setGlobalId({ commit }, id) {
      commit('SET_GLOBAL_ID', id);
    },
    setToken({ commit }, token) {
      commit('SET_TOKEN', token);
    },
    setVerified({ commit }, value) {
      commit('SET_VERIFIED', value)
    },
    setActualBook({ commit }, book) {
      commit('SET_ACTUAL_BOOK', book)
    },
    setLang({ commit }, payload) {
      commit('SET_LANG', payload);
    },
    setConfig({ commit }, payload) {
      commit('SET_CONFIG', payload);
    },
    setConfigExersice({ commit }, payload) {
      commit('SET_CONFIG_EXERCISE', payload);
    },
    setGroup({ commit }, payload) {
      commit('SET_GROUP', payload);
    },
    setFilteredBookId({ commit }, payload) {
      commit('SET_FILTERED_BOOK_ID', payload);
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token;
    },
    myToken(state) {
      return state.token == null ? '' : state.token;
    },
    isVerified(state) {
      return state.verified
    },
    getActualBook(state) {
      return state.actualBook
    },
    getGlobalId(state) {
      return state.globalId
    },
    getRole(state) {
      return state.role
    },
    getLang: state => state.lang,
    getActiveGroup: state => state.activeGroup,
    getActiveFilteredBookId: state => state.activeFilteredBookId,
  }
}