import groupsServices from "@/services/GroupsServices.js"

export default {
    data: () => {
        return {
          players: {},
        };
      },
  methods: {
    async fetchPlayers(group, page) {
        try {
          this.loader = true;
  
          const responseDataPlayers = await groupsServices.listPlayers(group, page);
          this.players = responseDataPlayers.data
        } catch (error) {
          this.$f7.toast
            .create({
              text: "Something went wrong with the player list. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
        this.loader = false;
      },
  }
}