<template>
    <div class="wrapperBox">
        <f7-block>
          <div class="sectorMain">

          <div class="d-flex w-100 box-row" v-if="configExercise.subject" :class="configExercise.objective || configExercise.coaching || configExercise.info ? 'no-border-bottom' : ''">
            <div class="box-col box-col-medium text-center border-right">
              <p class="text-uppercase">{{ 'outline.subject' | translate }}</p>
              <span class="print">{{exercise.subject}}</span><input type="text" class="no-print" maxlength="256" v-model="exercise.subject" :placeholder="'outline.subject' | translate">
             
            </div>
          </div>
          <div v-if="configExercise.objective">
            <div class="d-flex w-100 box-row" :class="configExercise.coaching || configExercise.info ? 'no-border-bottom' : ''">
              <div class="box-col box-col-medium text-center border-right">
                <p class="text-uppercase">{{ 'outline.objective' | translate }}</p>
                <span class="print">{{exercise.objective}}</span><input type="text" class="no-print" maxlength="512" v-model="exercise.objective" :placeholder="'outline.objective' | translate">
              </div>
            </div>
          </div>
          <div v-if="configExercise.coaching">
            <div class="d-flex w-100 box-row" :class="configExercise.info ? 'no-border-bottom' : ''">
              <div class="box-col box-col-medium text-center border-right">
                <p class="text-uppercase">{{ 'outline.coachingPoints' | translate }}</p>
                <span class="print">{{exercise.coaching}}</span><input type="text" class="no-print" maxlength="512" v-model="exercise.coaching" :placeholder="'outline.coachingPoints' | translate">
              </div>
            </div>
          </div>
          <div v-if="configExercise.info">
            <div class="d-flex w-100 box-row">
              <div class="box-col box-col-2 text-center border-right">
                <p class="text-uppercase">{{ 'outline.ageCategory' | translate }}</p>

                <span class="print">{{ageCategories[exercise.age_category] | translate}}</span>
                <select class="no-print" name="age_category" v-model="exercise.age_category">
                  <option v-for="(ageCategory, index) in ageCategories" :key="index" :value="index" :selected="index == exercise.age_category">
                      {{ageCategory | translate}}
                  </option>
                </select>
              </div>
              <div class="no-print text-center box-col box-col-2 border-left border-right">
                <p class="text-uppercase">{{ 'outline.category' | translate }}</p>

                <select name="category" v-model="exercise.category">
                  <option value="">{{ 'outline.noCategories' | translate }}</option>
                  <option v-for="category in categories" :key="category.id" :value="category.id" :selected="category.id == exercise.category">
                      {{category.title}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          </div>

       
      </f7-block>
    </div>            
</template>

<script>
import CONSTANTS from "@/utils/constants.js"
import privateExercisesServices from "@/services/PrivateExercisesServices.js"

export default {
  name: 'Infoexercise',
  props: {
    configExercise: {
      type: Object,
      required: true,
    },
    exercise: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      ageCategories: CONSTANTS.AGE_CATEGORIES,
      categories: [],
    }
  },
mounted() {
    if (this.isAuthenticated) {
      this.fetchCategories();
    }
  },
  methods: {
    async fetchCategories() {
        try {
          this.loader = true;
  
          const responseDataCategories = await privateExercisesServices.listAllCategories();
          this.categories = responseDataCategories.data
        } catch (error) {
          this.$f7.toast
            .create({
              text: "Something went wrong with the list of private outline categories. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
      },
  }
}
</script>