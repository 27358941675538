import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import appkonspektypilkanozna from './app'
import vuexI18n from 'vuex-i18n';

import translationsPl from '../locale/pl';
import translationsEn from '../locale/en';
Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    appkonspektypilkanozna
  }
})

Vue.use(vuexI18n.plugin, store);
Vue.i18n.add('pl', translationsPl);
Vue.i18n.add('en', translationsEn);

export default store