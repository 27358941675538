<template>
  <f7-page login-screen>
    <navbar></navbar>
    <loader v-if="loader"></loader>
  <div v-else>
    <f7-block class="text-center text-uppercase ">
        <h4>{{ 'wallet.wallet_page' | translate}}</h4>
      </f7-block>
      <f7-block>
        
        <p class="mb-0 mt-0 font-weight-bold">{{ 'wallet.balance' | translate}}: {{wallet.amount == null ? '0': wallet.amount}} {{currency[account.currency]}}</p>
        <p>
          <f7-progressbar :progress="wallet.amount >= 300 ? 100 : wallet.amount * 100 / 300"></f7-progressbar>
        </p>
      </f7-block>
        <f7-list form @submit.prevent="onWithdraw">
        <f7-list-input
          :label="'wallet.withdraw' | translate"
          :placeholder="`Min. 300 ${currency[account.currency]}`"
          :value="withdraw"
          @input="withdraw = $event.target.value"
          required
          validate
          autocomplete="off"
          pattern="[0-9]*"
          :error-message="'global.required' | translate"
        ></f7-list-input>
        <f7-block class="text-center">
          <f7-button v-if="!loaderWithdraw" type="submit" raised>{{ 'wallet.withdraw' | translate}}</f7-button>
          <f7-button v-else raised>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>
      <f7-block>
      <div class="data-table">
          <p class="mb-0 font-weight-bold">{{ 'wallet.total_amount_paid_out' | translate}}: {{partnerPayments.amount == null ? '0': partnerPayments.amount}} {{currency[account.currency]}}</p>
          <p class="mb-0 mt-0">{{ 'wallet.count_payments' | translate}}: {{partnerPayments.list.length}}</p>
          <table v-if="partnerPayments.list.length > 0">
            <thead>
              <tr>
                <th class="label-cell pl-0">{{ 'wallet.data_payment' | translate}}</th>
                <th class="label-cell pl-0">{{ 'wallet.withdraw' | translate}}</th>
                <th class="label-cell pl-0">{{ 'wallet.status' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in partnerPayments.list" :key="item.id">

                <th class="label-cell pl-0">
                  <span v-if="item.date_payment">
                      {{ item.date_payment | formatDate }}
                    </span>
                    <span v-else>
                      -
                    </span>
                </th>
                <th class="label-cell pl-0">{{item.withdraw}} {{currency[account.currency]}}</th>
                <th class="label-cell pl-0">
                  <div>
                      <f7-link v-if="item.status == 0" :tooltip="'wallet.status_1' | translate">
                        <img height="13px" src="/static/icons/hourglass.svg" alt />
                      </f7-link>
                      <f7-link v-if="item.status == 1" :tooltip="'wallet.status_2' | translate">
                        <img height="13px" src="/static/icons/wrench.svg" alt />
                      </f7-link>
                      <f7-link v-if="item.status == 2" :tooltip="'wallet.status_3' | translate">
                        <img height="13px" src="/static/icons/stopwatch-2.svg" alt />
                      </f7-link>
                      <f7-link v-if="item.status == 3" :tooltip="'wallet.status_4' | translate">
                        <img height="13px" src="/static/icons/success.svg" alt />
                      </f7-link>
                  </div>
                </th>
              </tr>
            </tbody>
          </table>
          <div v-else class="text-center mt-2">
            <img height="70px" src="/static/icons/no-data.svg" alt />
            <p class="mt-0">{{ 'wallet.no_data' | translate}}</p>
          </div>
        </div>

        <div class="data-table">
          <p class="mb-0 font-weight-bold">{{ 'wallet.history' | translate}}</p>
          <p class="mb-0 mt-0">{{ 'wallet.count' | translate}}: {{wallet.list.length}}</p>
          <table v-if="wallet.list.length > 0">
            <thead>
              <tr>
                <th class="label-cell pl-0">{{ 'wallet.amount' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in wallet.list" :key="item.id">
                <th class="label-cell pl-0">
                  
                  <f7-link :tooltip="item.notes ? `${item.notes}` : ''" :style="{ color: item.amount > 0 ? 'green' : 'red' }">
                    {{item.amount}} {{currency[account.currency]}}
                  </f7-link>
                </th>
              </tr>
            </tbody>
          </table>
          <div v-else class="text-center mt-2">
            <img height="70px" src="/static/icons/no-data.svg" alt />
            <p class="mt-0">{{ 'wallet.no_data' | translate}}</p>
          </div>
        </div>
    </f7-block>
  </div>

  </f7-page>
</template>

<script>
import CONSTANTS from "@/utils/constants.js"
import Navbar from "@/components/Navbar/Navbar.vue";
import Loader from "@/components/Loader";
import partnerServices from "@/services/PartnerServices.js"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  components: {
    Navbar,
    Loader
  },
  data: () => {
        return {
          loader: false,
          loaderWithdraw: false,
          currency: CONSTANTS.CURRENCY,
          account: {
            currency: '',
          },
          withdraw: null,
          wallet: {
            amount: 0,
            list: []
          },
          partnerPayments: {
            amount: 0,
            list: []
          },
        };
      },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchDataUser().then(() => {
        this.fetchWallet()
        this.fetchPartnerPayments()
      })
    }
  },
  methods: {
    async fetchWallet() {
        try {
          this.loader = true;
  
          const responseDataWallet = await partnerServices.listWallet();
          this.wallet = responseDataWallet.data
        } catch (error) {
          this.$f7.toast
            .create({
              text: "Something went wrong with the wallet list. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
        this.loader = false;
      },
      async fetchPartnerPayments() {
        try {
          this.loader = true;
  
          const responseDataPartnerPayments = await partnerServices.listPartnerPayments();
          this.partnerPayments = responseDataPartnerPayments.data
        } catch (error) {
          this.$f7.toast
            .create({
              text: "Something went wrong with the payslip. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
        this.loader = false;
      },
      async onWithdraw() {
      if (this.withdraw < 300) {
        this.$f7.toast
          .create({
            text: `The minimum withdrawal amount is 300 ${this.currency[this.account.currency]}!`,
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        return false;
      }

      if (this.wallet.amount == null || this.wallet.amount < 300) {
        this.$f7.toast
          .create({
            text: "Payout balance too low!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        return false;
      }

      this.loaderWithdraw = true;
      const data = {
        "withdraw": this.withdraw
      }
      try {
        const responseWidthdraw = await partnerServices.createPartnerPayments(data);
        if ('access' in responseWidthdraw.data && !responseWidthdraw.data.access) {
          if (responseWidthdraw.data.type == 'amount') {
            this.$f7.toast
              .create({
                text: "There are not enough funds to withdraw or the minimum withdrawal amount is 300! Please try again or contact your administrator.",
                            position: "bottom",
            
                closeTimeout: 3000
              })
              .open();
          }
        } else {
          if (responseWidthdraw.data.id) {
            this.withdraw = 0
            this.fetchPartnerPayments()
            this.fetchWallet()
          }
          this.$f7.toast
          .create({
            text:
              "The payment has been ordered",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text:
              "Something went wrong with your payment. Please try again or contact your administrator.",
                        position: "bottom",
          
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderWithdraw = false;
    },
  }
};
</script>