<template>
  <f7-page login-screen>
    <navbarcalendarcrm></navbarcalendarcrm>

    <loader v-if="loader"></loader>

    <div v-else>

        <f7-block class="text-uppercase">
          <h4>{{ 'club.planning_trainings' | translate}} <f7-link class="p-1" :tooltip="'popover.planning_trainings' | translate"><img height="11px" src="/static/icons/help.svg" alt /></f7-link></h4>
          <div class="d-flex justify-content-start align-items-center">
            <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{ 'club.group' | translate }}: </h4>
            <div class="custom-select">
                  <select name="group" v-model="actualGroup" @change="changeGroup(actualGroup)">
                      <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == actualGroup">
                          {{group.name}}
                      </option>
                  </select>
              </div>
          </div>
          <f7-button v-if="!groups.length > 0" href="/groups/" raised>{{ 'club.need_create_group' | translate }}!</f7-button>
          <div class="countAll">
            <span>
              <p>{{ 'club.count' | translate}}</p>
              <template v-if="planningTrainings.count">
                {{planningTrainings.count}}
              </template>
              <template v-else>
                0
              </template>
            </span>
          </div>
        </f7-block>
        <div class="listCards" v-if="planningTrainings.count > 0">
          <f7-block>
            <f7-row>
              <f7-col v-for="planning in planningTrainings.results" :key="planning.id" width="100" class="position-relative">
                <div class="delete" @click.stop="showDialogDeletePlanningTrainings(planning)">
                  <img height="20px" src="/static/icons/trash.svg" alt />
                </div>
                <a :href="`/planning-trainings/${planning.id}/`">
                <f7-card class="custom-card">
                  <div>
                    <p>{{planning.mesocycle}}</p>
                    <p class="info" v-if="planning.group && groups.length > 0">{{ 'club.group' | translate }}: {{groups.find(obj => obj.id == planning.group).name}}</p>
                    <p class="info" v-if="planning.mesocycle_date_start">{{planning.mesocycle_date_start}} - {{planning.mesocycle_date_end}}</p>
                    <f7-link v-if="planning.group && groups.length > 0" :tooltip="`Group color: ${paleteColorsDot[groups.find(obj => obj.id == planning.group).color].label}`" class="color" :style="{background : paleteColorsDot[groups.find(obj => obj.id == planning.group).color].value}"></f7-link>
                  </div>
                </f7-card>
                </a>
              </f7-col>
            </f7-row>
          </f7-block>
        </div>
          <div v-else class="text-center text-uppercase">
            <img height="100px" src="/static/icons/pencil.svg" alt />
            <h4>{{ 'club.empty_planning_trainings' | translate }}!</h4>
        </div>
        <f7-block>
          <div class="d-flex justify-content-between aling-items-center custom-pagination-view">
            <div class="pagination-item">
              <div v-if="planningTrainings.previous" @click="fetchPlanningTrainings(actualGroup,planningTrainings.previous)">
                <img class="cursor-pointer" height="30px" src="/static/icons/previous.svg" alt />
              </div>
            </div>
            <div class="pagination-item">
              <a :href="`/create-planning-training/?group=${actualGroup}`">
                <img class="cursor-pointer" height="30px" src="/static/icons/plus.svg" alt="">
              </a>
            </div>
            <div class="pagination-item">
              <div v-if="planningTrainings.next" @click="fetchPlanningTrainings(actualGroup, planningTrainings.next)">
                <img class="cursor-pointer" height="30px" src="/static/icons/next.svg" alt />
              </div>
            </div>
          </div>
        </f7-block>
    </div>

    <div class="custom-fab">
        <div class="box box-zoom cursor-pointer" @click="goToCreate()">
            <f7-link :tooltip="'global.add_new' | translate" >
              <img height="20px" src="/static/icons/plus-add.svg" alt="">
            </f7-link>
        </div>
      </div>

  </f7-page>
</template>

<script>
import Navbarcalendarcrm from "@/components/Navbar/Navbarcalendarcrm.vue";
import Loader from "@/components/Loader";
import trainingsServices from "@/services/TrainingsServices.js"
import mixinGroups from "@/mixins/mixinGroups"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinGroups, mixinUser],
  components: {
    Navbarcalendarcrm,
    Loader
  },
  data: () => {
    return {
      loader: false,
      actualGroup: '',
      planningTrainings: {},
    };
  },
  watch: {
    'actualGroup': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchPlanningTrainings(this.actualGroup)
      }
    },
  },
  mounted() {
    this.fetchDataUser().then(() => {
        this.fetchGroups().then(() => {
          if (this.getRole == 'coach') {
            this.groups = this.profileGroups;
          }
          if (this.groups.length > 0) {
              if (this.$f7route.query.group !== undefined) {
                this.actualGroup = this.$f7route.query.group
              } else {
                const checkGroup = obj => obj.id === this.getActiveGroup;
                if (this.getActiveGroup !== '' && this.groups.some(checkGroup)) {
                  this.actualGroup = this.getActiveGroup
                } else {
                  this.actualGroup = this.groups[0].id
                }
              }
            }
        })
    })
  },
  methods: {
    goToCreate() {
      this.$f7router.navigate(`/create-planning-training/?group=${this.actualGroup}`)
    },
    async fetchPlanningTrainings(group, page) {
      try {
        this.loader = true;

        const responseDataPlanningTrainings = await trainingsServices.listPlanningTrainings(group, page);
        this.planningTrainings = responseDataPlanningTrainings.data
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the group list. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
    showDialogDeletePlanningTrainings(planning) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the training plan ${planning.mesocycle}? The change will be irreversible!`, '', () => {
          this.deletePlanningTrainings(planning.id)
        });
    },
    async deletePlanningTrainings(id) {
      try {
        const responseDeletePlanningTraining = await trainingsServices.deletePlanningTraining(id);
        this.fetchPlanningTrainings(this.actualGroup);
        this.$f7.toast
          .create({
            text: "The training plan has been deleted!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with deleting the training plan. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  }
};
</script>