<template>
  <f7-block class="wrapperPanel">
    <div class="no-print">

      <p class="no-print font-weight-bold">
        <span class="text-uppercase">{{ 'outline.showInfoOutline' | translate }}</span>
        </p>
        <div class="d-flex">
          <input type="checkbox" id="subject" class="no-print" v-model="config.subject">
          <label class="no-print" for="subject">
              <span class="text-uppercase">{{ 'outline.subject' | translate }}</span>

          </label>
        </div>
        <div class="d-flex">
          <input type="checkbox" id="coach" class="no-print" v-model="config.coach">
          <label class="no-print" for="coach">
              <span class="text-uppercase">{{ 'outline.coach' | translate }}</span>
          </label>
        </div>
        <div class="d-flex">
          <input type="checkbox" id="microcycle" class="no-print" v-model="config.microcycle">
          <label class="no-print" for="microcycle">
              <span class="text-uppercase">{{ 'outline.microcycle' | translate }}</span>
          </label>
        </div>
        <div class="d-flex">
          <input type="checkbox" id="objective" class="no-print" v-model="config.objective">
          <label class="no-print" for="objective">
              <span class="text-uppercase">{{ 'outline.objective' | translate }}</span>
          </label>
        </div>
        <div class="d-flex">
          <input type="checkbox" id="coaching" class="no-print" v-model="config.coaching">
          <label class="no-print" for="coaching">
              <span class="text-uppercase">{{ 'outline.coachingPoints' | translate }}</span>
          </label>
        </div>
        <div class="d-flex">
          <input type="checkbox" id="areas" class="no-print" v-model="config.areas">
          <label class="no-print" for="areas">
              <span class="text-uppercase">{{ 'outline.areas' | translate }}</span>
          </label>
        </div>
        <div class="d-flex">
          <input type="checkbox" id="info" class="no-print" v-model="config.info">
          <label class="no-print" for="info">
              <span class="text-uppercase">{{ 'outline.addittionalInformations' | translate }}</span>
          </label>
        </div>
        <div class="d-flex">
          <input type="checkbox" id="accessories" class="no-print" v-model="config.accessories">
          <label class="no-print" for="accessories">
              <span class="text-uppercase">{{ 'outline.trainingAccessories' | translate }}</span>
          </label>
        </div>
        <div class="d-flex">
          <input type="checkbox" id="notes" class="no-print" v-model="config.notes">
          <label class="no-print" for="notes">
              <span class="text-uppercase">{{ 'outline.notes' | translate }}</span>
          </label>
        </div>
    </div>
    <div class="no-print">
        <p class="no-print font-weight-bold">
            <span class="text-uppercase">{{ 'outline.showPartOutline' | translate }}</span>
            </p>
            <div class="d-flex">
              <input type="checkbox" id="part_introduction_I" class="no-print" v-model="config.introduction1">
              <label class="no-print" for="part_introduction_I">
                  <span class="text-uppercase">{{ 'outline.introduction1Name' | translate }}</span>
              </label>
            </div>
            
            <div class="d-flex">
              <input type="checkbox" id="part_introduction_II" class="no-print" v-model="config.introduction2">
              <label class="no-print" for="part_introduction_II">
                  <span class="text-uppercase">{{ 'outline.introduction2Name' | translate }}</span>
              </label>
            </div>
            <div class="d-flex">
              <input type="checkbox" id="part_main_I"  class="no-print" v-model="config.main1">
              <label class="no-print" for="part_main_I">
                  <span class="text-uppercase">{{ 'outline.main1Name' | translate }}</span>
              </label>
            </div>
            <div class="d-flex">
              <input type="checkbox" id="part_main_II" class="no-print" v-model="config.main2">
              <label class="no-print" for="part_main_II">
                  <span class="text-uppercase">{{ 'outline.main2Name' | translate }}</span>
              </label>
            </div>
            <div class="d-flex">
              <input type="checkbox" id="part_final" class="no-print" v-model="config.final">
              <label class="no-print" for="part_final">
                  <span class="text-uppercase">{{ 'outline.finalName' | translate }}</span>
              </label>
            </div>
    </div>
    <div class="no-print">
      <div style="display: inline-flex">
            <label class="toggle toggle-int color-black">
            <input type="checkbox" v-model="config.new_pzpn"/>
            <span class="toggle-icon"></span>
          </label>
          <p class="ml-2">{{ 'club.new_pzpn' | translate }}</p>
        </div>
    </div>
  </f7-block>
</template>

<script>
export default {
  name: 'Togglepaneloutline',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  watch: {
    config: {
      handler(val) {
        this.$store.dispatch("appkonspektypilkanozna/setConfig", val);
      },
      deep: true
    }
  },
}
</script>