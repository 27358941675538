<template>
  <f7-page login-screen>
    <navbarcalendarcrm></navbarcalendarcrm>

    <loader v-if="loader"></loader>

    <div v-else>

        <f7-block class="text-uppercase">
          <h4>{{ 'club.trainings' | translate}} <f7-link class="p-1" :tooltip="'popover.trainings' | translate"><img height="11px" src="/static/icons/help.svg" alt /></f7-link></h4>
          <div class="d-flex justify-content-start align-items-center">
            <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{'club.group' | translate}}: </h4>
            <div class="custom-select">
                  <select name="group" v-model="actualGroup" @change="changeGroup(actualGroup)">
                      <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == actualGroup">
                          {{group.name}}
                      </option>
                  </select>
              </div>
          </div>
          <f7-button v-if="!groups.length > 0" href="/groups/" raised>{{'club.need_create_group' | translate}}!</f7-button>
          <div class="countAll">
            <span>
              <p>{{ 'club.count' | translate}}</p>
              <template v-if="trainings.count">
                {{trainings.count}}
              </template>
              <template v-else>
                0
              </template>
            </span>
          </div>
        </f7-block>
        <div class="listCards" v-if="trainings.count > 0">
          <f7-block>
            <f7-row>
              <f7-col v-for="training in trainings.results" :key="training.id" width="100" class="position-relative">
                <div class="delete" @click.stop="showDialogDeleteTrainings(training)">
                  <img height="20px" src="/static/icons/trash.svg" alt />
                </div>
                <a :href="`/trainings/${training.id}/`">
                <f7-card class="custom-card">
                  <div>
                    <p>{{training.subject}}</p>
                    <p class="info" v-if="training.group && groups.length > 0">Grupa: {{groups.find(obj => obj.id == training.group).name}}</p>
                    <p class="info">{{training.training_date}}</p>
                    <p class="info" v-if="training.training_hours_start && training.training_hours_end" v-text="getTime(training)"></p>
                    <p class="info" v-if="training.playing_field && playingFields.length > 0">{{'club.pitch' | translate}}: {{playingFields.find(obj => obj.id == training.playing_field).name}}</p>
                    <f7-link v-if="training.group && groups.length > 0" :tooltip="`Group color: ${paleteColorsDot[groups.find(obj => obj.id == training.group).color].label}`" class="color" :style="{background : paleteColorsDot[groups.find(obj => obj.id == training.group).color].value}"></f7-link>
                  </div>
                </f7-card>
                </a>
              </f7-col>
            </f7-row>
          </f7-block>
        </div>
        <div v-else class="text-center text-uppercase">
            <img height="100px" src="/static/icons/training.svg" alt />
            <h4>{{'club.empty_trainings_group' | translate}}!</h4>
        </div>
        <f7-block>
          <div class="d-flex justify-content-between aling-items-center custom-pagination-view">
            <div class="pagination-item">
              <div v-if="trainings.previous" @click="fetchTrainings(actualGroup, trainings.previous)">
                <img class="cursor-pointer" height="30px" src="/static/icons/previous.svg" alt />
              </div>
            </div>
            <div class="pagination-item">
              <a :href="`/create-training/?group=${actualGroup}`">
                <img class="cursor-pointer" height="30px" src="/static/icons/plus.svg" alt="">
              </a>
            </div>
            <div class="pagination-item">
              <div v-if="trainings.next" @click="fetchTrainings(actualGroup, trainings.next)">
                <img class="cursor-pointer" height="30px" src="/static/icons/next.svg" alt />
              </div>
            </div>
          </div>
        </f7-block>
    </div>

    <div class="custom-fab">
        <div class="box box-zoom cursor-pointer" @click="goToCreate()">
            <f7-link :tooltip="'global.add_new' | translate" >
              <img height="20px" src="/static/icons/plus-add.svg" alt="">
            </f7-link>
        </div>
      </div>

  </f7-page>
</template>

<script>
import Navbarcalendarcrm from "@/components/Navbar/Navbarcalendarcrm.vue";
import Loader from "@/components/Loader";
import trainingsServices from "@/services/TrainingsServices.js"
import mixinGroups from "@/mixins/mixinGroups"
import mixinPlayingFields from "@/mixins/mixinPlayingFields"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinGroups, mixinUser, mixinPlayingFields],
  components: {
    Navbarcalendarcrm,
    Loader
  },
  data: () => {
    return {
      loader: false,
      actualGroup: '',
      trainings: {},
    };
  },
  watch: {
    'actualGroup': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchTrainings(this.actualGroup)
      }
    },
  },
  mounted() {
    this.fetchDataUser().then(() => {
        this.fetchPlayingFields().then(() => {
          this.fetchGroups().then(() => {
            if (this.getRole == 'coach') {
              this.groups = this.profileGroups;
            }
            if (this.groups.length > 0) {
              if (this.$f7route.query.group !== undefined) {
                this.actualGroup = this.$f7route.query.group
              } else {
                const checkGroup = obj => obj.id === this.getActiveGroup;
                if (this.getActiveGroup !== '' && this.groups.some(checkGroup)) {
                  this.actualGroup = this.getActiveGroup
                } else {
                  this.actualGroup = this.groups[0].id
                }
              }
            }
          })
        })
    })
  },
  methods: {
    goToCreate() {
      this.$f7router.navigate(`/create-training/?group=${this.actualGroup}`)
    },
    getTime(training) {
      if (training.training_hours_start && training.training_hours_end) {
        const hours_start = training.training_hours_start.split(/:/)
        const hours_end = training.training_hours_end.split(/:/)
        return `${hours_start[0]}:${hours_start[1]} - ${hours_end[0]}:${hours_end[1]}`
      } else {
        return ''
      }
    },
    async fetchTrainings(group, page) {
      try {
        this.loader = true;

        const responseDataTrainings = await trainingsServices.listTrainings(group, page);
        this.trainings = responseDataTrainings.data
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the workout list. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
    showDialogDeleteTrainings(training) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the workout ${training.subject}? The change will be irreversible!`, '', () => {
          this.deleteTrainings(training.id)
        });
    },
    async deleteTrainings(id) {
      try {
        const responseDeleteTraining = await trainingsServices.deleteTraining(id);
        this.fetchTrainings(this.actualGroup);
        this.$f7.toast
          .create({
            text: "Workout has been removed!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with deleting the workout. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  }
};
</script>