<template>
  <f7-page login-screen>
    <navbarlist :list="'groups'"></navbarlist>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{  'club.group' | translate}}
              <img class="cursor-pointer" v-if="getRole == 'owner'" @click="showDialogDeleteGroup(group)" height="14px" src="/static/icons/trash.svg" alt />
            </h4>
          </f7-block>
          <f7-list form no-hairlines-md @submit.prevent="updateGroup">
            <f7-list-input
              :label="'club.name' | translate"
              type="text"
              :placeholder="'club.name' | translate"
              :value="group.name"
              @input="group.name = $event.target.value"
              required
              validate
              :error-message="'global.required' | translate"
              clear-button
            >
            </f7-list-input>
            <f7-list-item :title="'club.color_group' | translate" class="custom-select">
              <select name="color" v-model="group.color">
                <option v-for="(color, index) in paleteColorsDot" :key="index" :value="index" :selected="index == group.color">
                    {{color.label}}
                </option>
              </select>
            </f7-list-item>
            <f7-list-input
              :label="'club.premium_amount' | translate"
              type="number"
              :placeholder="'club.premium_amount' | translate"
              :value="group.due_price"
              @input="group.due_price = $event.target.value"
              required
              validate
              :error-message="'global.required' | translate"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.description' | translate"
              type="textarea"
              :placeholder="'club.description' | translate"
              :value="group.description"
              @input="group.description = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-block class="text-center" v-if="getRole == 'owner'" >
              <f7-button v-if="!loaderGroup" type="submit" raised>
                {{ 'club.save' | translate }}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import Navbarlist from "@/components/Navbar/Navbarlist.vue";
import Loader from "@/components/Loader";
import groupsServices from "@/services/GroupsServices.js"
import CONSTANTS from "@/utils/constants.js"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  components: {
    Navbarlist,
    Loader
  },
  props: {
    idGroup: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      loader: false,
      loaderGroup: false,
      paleteColorsDot: CONSTANTS.COLORS_DOT,
      group: {
        name: '',
        color: '',
        description: '',
      },
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchDataUser().then(() => {
          this.fetchGroup(this.idGroup)
      })
    }
  },
  methods: {
    async fetchGroup(id) {
      try {
        this.loader = true;

        const responseDataGroup = await groupsServices.itemGroup(id);
        this.group = responseDataGroup.data
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with the group. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    showDialogDeleteGroup(group) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the group ${group.name}? By deleting a group, you will also delete all workouts, training plans, attendance lists associated with this group. The change will be irreversible!`, '', () => {
          this.deleteGroup(group.id)
        });
    },
    async deleteGroup(id) {
      try {
        const responseDeleteGroup = await groupsServices.deleteGroup(id);
        this.$f7router.navigate(`/groups/`)
        this.$f7.toast
          .create({
            text: "The group has been deleted!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with group deletion. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
    async updateGroup() {
      try {
        this.loaderGroup = true;

        const responseUpdateGroup = await groupsServices.group(this.group);
        if ('access' in responseUpdateGroup.data && !responseUpdateGroup.data.access) {
            this.$f7.toast
            .create({
              text: "Access to the module has expired!",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        } else {
          this.$f7router.navigate(`/groups/`)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the outline creation. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderGroup = false;
    },
  }
};
</script>