import Api from './index'
import store from '@/store'

export default {
  async listWallet() {
    return Api().get('wallet/', {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listPartnerPayments() {
    return Api().get('partnerpayments/', {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async createPartnerPayments(data) {
    return Api().post('partnerpayments/', data, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
}

