<template>
  <f7-page :login-screen="!previewMode" :class="{ 'previewMode ': previewMode }" class="no-print pageCreateSession">
    <navbarbook></navbarbook>
    <loader v-if="loader"></loader>

    <div v-else>
      <div v-if="actualSession !== undefined">
      <f7-block v-if="!previewMode" style="margin-top: 0px">

        <f7-toolbar tabbar labels bottom style="border-radius: 0px 0px 10px 10px">
          <f7-link :tab-link="`#sessionTemat${id}`" tab-link-active>{{ 'outline.subject' | translate }}</f7-link>
          <f7-link v-if="actualSession[0].partIntroductory !== undefined" :tab-link="`#czescWstepna${id}`"><span>{{ 'outline.part' | translate }}</span> <span>{{  'outline.introduction' | translate }}</span></f7-link>
          <f7-link v-if="actualSession[0].partIntroductoryI !== undefined" :tab-link="`#czescWstepna1${id}`"><span>{{ 'outline.part' | translate }}</span> <span>{{  'outline.introduction1' | translate }}</span></f7-link>
          <f7-link v-if="actualSession[0].partIntroductoryII !== undefined" :tab-link="`#czescWstepna2${id}`"><span>{{ 'outline.part' | translate }}</span> <span>{{  'outline.introduction2' | translate }}</span></f7-link>
          <f7-link v-if="actualSession[0].partMainI !== undefined" :tab-link="`#czescGlowna1${id}`"><span>{{ 'outline.part' | translate }}</span> <span>{{  'outline.main1' | translate }}</span></f7-link>
          <f7-link v-if="actualSession[0].partMainII !== undefined" :tab-link="`#czescGlowna2${id}`"><span>{{ 'outline.part' | translate }}</span> <span>{{  'outline.main2' | translate }}</span></f7-link>
          <f7-link v-if="actualSession[0].partFinal !== undefined" :tab-link="`#czescKoncowa${id}`"><span>{{ 'outline.part' | translate }}</span> <span>{{  'outline.final' | translate }}</span></f7-link>
        </f7-toolbar>
        <f7-tabs>

          <f7-tab :id="`sessionTemat${id}`" class="page-content" tab-active style="padding-top: 10px; padding-bottom: 0px">
            <f7-card class="sessionCardDetail">
                <f7-card-header>
                  <div class="left">
                      {{actualSession[0].title}}
                  </div>
                </f7-card-header>
            </f7-card>
            <div>
              <f7-block class="subject">
                <strong class="text-uppercase">{{  'outline.subject' | translate }}: </strong>{{actualSession[0].summary.subject}}
              </f7-block>
              <f7-block v-if="actualSession[0].summary.goal !== undefined" class="goal">
                <strong class="text-uppercase">{{  'outline.objective' | translate }}: </strong>{{actualSession[0].summary.goal}}
              </f7-block>
              <f7-block v-if="actualSession[0].summary.coachPoint !== undefined" class="goal">
                <strong class="text-uppercase">{{  'outline.coachingPoints' | translate }}: </strong>{{actualSession[0].summary.coachPoint}}
              </f7-block>
              <f7-block v-if="actualSession[0].summary.qualityNotes" class="qualityNotes">
                <strong class="text-uppercase">{{  'outline.qualityNotes' | translate }}: </strong>
                <p v-for="note in actualSession[0].summary.qualityNotes" :key="note.id" v-html="note"></p>
              </f7-block>
            </div>
          </f7-tab>
          <f7-tab v-if="actualSession[0].partIntroductory !== undefined" :id="`czescWstepna${id}`" class="page-content" style="padding-top: 0px; padding-bottom: 0px">
          <div>
            <div class="part" v-for="part in actualSession[0].partIntroductory" :key="part.id">
              <f7-block class="image">
                <div v-for="image in part.image" :key="image.id">
                  <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${image}`">
                </div>
              </f7-block>
              <f7-block class="font-weight-bold">
                <p class="text-justify">{{part.name}}</p>
              </f7-block>
              <f7-block>
                <p class="text-justify" v-for="paragraph in part.content" :key="paragraph.id">{{paragraph}}</p>
              </f7-block>  
              <f7-block v-if="part.hasOwnProperty('imageMod')" class="image">
                <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                  <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${imageMod}`">
                </div>
              </f7-block>   
              <f7-block v-if="part.hasOwnProperty('mod')" class="mod">
                <p><strong>{{ 'outline.modifications' | translate }}:</strong></p>
                <p class="text-justify" v-for="paragraph in part.mod" :key="paragraph.id">{{paragraph}}</p>
              </f7-block>
            </div>
          </div>
          </f7-tab>
          <f7-tab v-if="actualSession[0].partIntroductoryI !== undefined" :id="`czescWstepna1${id}`" class="page-content" style="padding-top: 0px; padding-bottom: 0px">
          <div>
            <div class="part" v-for="part in actualSession[0].partIntroductoryI" :key="part.id">
              <f7-block class="image">
                <div v-for="image in part.image" :key="image.id">
                  <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${image}`">
                </div>
              </f7-block>
              <f7-block class="font-weight-bold">
                <p class="text-justify">{{part.name}}</p>
              </f7-block>
              <f7-block>
                <p class="text-justify" v-for="paragraph in part.content" :key="paragraph.id">{{paragraph}}</p>
              </f7-block>  
              <f7-block v-if="part.hasOwnProperty('imageMod')" class="image">
                <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                  <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${imageMod}`">
                </div>
              </f7-block>   
              <f7-block v-if="part.hasOwnProperty('mod')" class="mod">
                <p><strong>{{ 'outline.modifications' | translate }}:</strong></p>
                <p class="text-justify" v-for="paragraph in part.mod" :key="paragraph.id">{{paragraph}}</p>
              </f7-block>
            </div>
          </div>
          </f7-tab>
          <f7-tab v-if="actualSession[0].partIntroductoryII !== undefined" :id="`czescWstepna2${id}`" class="page-content" style="padding-top: 0px; padding-bottom: 0px">
          <div>
            <div class="part" v-for="part in actualSession[0].partIntroductoryII" :key="part.id">
              <f7-block class="image">
                <div v-for="image in part.image" :key="image.id">
                  <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${image}`">
                </div>
              </f7-block>
              <f7-block class="font-weight-bold">
                <p class="text-justify">{{part.name}}</p>
              </f7-block>
              <f7-block>
                <p class="text-justify" v-for="paragraph in part.content" :key="paragraph.id">{{paragraph}}</p>
              </f7-block>  
              <f7-block v-if="part.hasOwnProperty('imageMod')" class="image">
                <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                  <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${imageMod}`">
                </div>
              </f7-block>   
              <f7-block v-if="part.hasOwnProperty('mod')" class="mod">
                <p><strong>{{ 'outline.modifications' | translate }}:</strong></p>
                <p class="text-justify" v-for="paragraph in part.mod" :key="paragraph.id">{{paragraph}}</p>
              </f7-block>
            </div>
          </div>
          </f7-tab>
          <f7-tab v-if="actualSession[0].partMainI !== undefined" :id="`czescGlowna1${id}`" class="page-content" style="padding-top: 0px; padding-bottom: 0px">
          <div>
            <div class="part" v-for="part in actualSession[0].partMainI" :key="part.id">
              <f7-block class="image">
                <div v-for="image in part.image" :key="image.id">
                  <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${image}`">
                </div>
              </f7-block>
              <f7-block class="font-weight-bold">
                <p class="text-justify">{{part.name}}</p>
              </f7-block>
              <f7-block>
                <p class="text-justify" v-for="paragraph in part.content" :key="paragraph.id">{{paragraph}}</p>
              </f7-block> 
              <f7-block v-if="part.hasOwnProperty('imageMod')" class="image">
                <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                  <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${imageMod}`">
                </div>
              </f7-block>
              <f7-block v-if="part.hasOwnProperty('mod')" class="mod">
                <p><strong>{{ 'outline.modifications' | translate }}:</strong></p>
                <p class="text-justify" v-for="paragraph in part.mod" :key="paragraph.id">{{paragraph}}</p>
              </f7-block>
            </div>
          </div>
          </f7-tab>
          <f7-tab v-if="actualSession[0].partMainII !== undefined" :id="`czescGlowna2${id}`" class="page-content" style="padding-top: 0px; padding-bottom: 0px">
            <div>
              <div class="part" v-for="part in actualSession[0].partMainII" :key="part.id">
                <f7-block class="image">
                  <div v-for="image in part.image" :key="image.id">
                    <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${image}`">
                  </div>
                </f7-block>
                <f7-block class="font-weight-bold">
                  <p class="text-justify">{{part.name}}</p>
                </f7-block>
                <f7-block>
                  <p class="text-justify" v-for="paragraph in part.content" :key="paragraph.id">{{paragraph}}</p>
                </f7-block>
                <f7-block v-if="part.hasOwnProperty('imageMod')" class="image">
                  <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                    <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${imageMod}`">
                  </div>
                </f7-block>
                <f7-block v-if="part.hasOwnProperty('mod')" class="mod">
                  <p><strong>{{ 'outline.modifications' | translate }}:</strong></p>
                  <p class="text-justify" v-for="paragraph in part.mod" :key="paragraph.id">{{paragraph}}</p>
                </f7-block>
              </div>
            </div>
          </f7-tab>
          <f7-tab v-if="actualSession[0].partFinal !== undefined" :id="`czescKoncowa${id}`" class="page-content" style="padding-top: 0px; padding-bottom: 0px">
            <div>
              <div class="part" v-for="part in actualSession[0].partFinal" :key="part.id">
                <f7-block class="image">
                  <div v-for="image in part.image" :key="image.id">
                    <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${image}`">
                  </div>
                </f7-block>
                <f7-block class="font-weight-bold">
                  <p class="text-justify">{{part.name}}</p>
                </f7-block>
                <f7-block>
                  <p class="text-justify" v-for="paragraph in part.content" :key="paragraph.id">{{paragraph}}</p>
                </f7-block>
                <f7-block v-if="part.hasOwnProperty('imageMod')" class="image">
                  <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                    <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${imageMod}`">
                  </div>
                </f7-block>
                <f7-block v-if="part.hasOwnProperty('mod')" class="mod">
                  <p><strong>{{ 'outline.modifications' | translate }}:</strong></p>
                  <p class="text-justify" v-for="paragraph in part.mod" :key="paragraph.id">{{paragraph}}</p>
                </f7-block>
              </div>
            </div>
          </f7-tab>
        </f7-tabs>
       
      </f7-block>

      <main v-else id="outlineViewport">
          <div class="wrapperBox">
            <f7-block>
              <div class="sectorMain">
                 <div v-if="actualSession[0].title !== undefined">
                  <div class="d-flex w-100 box-row" :class="actualSession[0].summary.subject !== undefined || actualSession[0].summary.goal !== undefined || actualSession[0].summary.coachPoint !== undefined || actualSession[0].summary.qualityNotes !== undefined ? 'no-border-bottom' : ''">
                    <div class="box-col box-col-large text-center border-right box-subject">
                      <p class="text-uppercase">{{ 'outline.name' | translate }}</p>
                      <span class="print">{{actualSession[0].title}}</span>
                    </div>
                  </div>
                </div>

              <div v-if="actualSession[0].summary.subject !== undefined">
                <div class="d-flex w-100 box-row" :class="actualSession[0].summary.goal !== undefined || actualSession[0].summary.coachPoint !== undefined || actualSession[0].summary.qualityNotes !== undefined ? 'no-border-bottom' : ''">
                  <div class="box-col box-col-large text-center border-right">
                    <p class="text-uppercase">{{ 'outline.subject' | translate }}</p>
                    <span class="print">{{actualSession[0].summary.subject}}</span>
                  </div>
                </div>
              </div>
              <div v-if="actualSession[0].summary.goal !== undefined">
                <div class="d-flex w-100 box-row" :class="actualSession[0].summary.coachPoint !== undefined || actualSession[0].summary.qualityNotes !== undefined ? 'no-border-bottom' : ''">
                  <div class="box-col box-col-large text-center border-right">
                    <p class="text-uppercase">{{ 'outline.objective' | translate }}</p>
                    <span class="print">{{actualSession[0].summary.goal}}</span>
                  </div>
                </div>
              </div>
              <div v-if="actualSession[0].summary.coachPoint !== undefined">
                <div class="d-flex w-100 box-row" :class="actualSession[0].summary.qualityNotes !== undefined ? 'no-border-bottom' : ''">
                  <div class="box-col box-col-large text-center border-right">
                    <p class="text-uppercase">{{ 'outline.coachingPoints' | translate }}</p>
                    <span class="print">{{actualSession[0].summary.coachPoint}}</span>
                  </div>
                </div>
              </div>
              <div v-if="actualSession[0].summary.qualityNotes !== undefined">
                <div class="d-flex w-100 box-row">
                  <div class="box-col box-col-large text-center border-right">
                    <p class="text-uppercase">{{ 'outline.qualityNotes' | translate }}</p>
                    <div class="wrapperSpan">
                      <span class="print" v-for="note in actualSession[0].summary.qualityNotes" :key="note.id" v-html="note" :class="{'minHeight40' : actualSession[0].summary.qualityNotes.length == 1}"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </f7-block>
    </div>
    <aside v-if="actualSession[0].partIntroductory !== undefined" class="wrapperIntroduction1 wrapperPart">
            <f7-block class="wrapperColumn">
              <div>
                  <div class="print wrapperPartName">
                    <h5 class="partName text-uppercase">{{ 'outline.introductionName' | translate }}</h5>
                  </div>
              </div>
                <div class="w-100">
                    <f7-row class="wrapperRow" v-for="part in actualSession[0].partIntroductory" :key="part.id">
                        <f7-col class="content" width="60">
                            <p v-if="part.name" class="print font-weight-bold text">{{part.name}}</p>
                            <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.content" :key="paragraph.id" v-html="paragraph"></p>
                            <div v-if="part.mod">
                                <p class="font-weight-bold mod">{{ 'outline.modifications' | translate }}:</p>
                                <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.mod" :key="paragraph.id" v-html="paragraph"></p>
                            </div>
                        </f7-col>
                      <f7-col class="imageOnExercise" width="40">
                                <div class="wrapperImageExercise">
                                  <div v-for="image in part.image" :key="image.id">
                                    <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${image}`">
                                  </div>
                                  <div v-if="part.hasOwnProperty('imageMod')">
                                    <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                                      <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${imageMod}`">
                                    </div>
                                  </div>
                                </div>
                        </f7-col>
                    </f7-row>
                  </div>
          </f7-block>
            </aside>
    <aside v-if="actualSession[0].partIntroductoryI !== undefined" class="wrapperIntroduction1 wrapperPart">
            <f7-block class="wrapperColumn">
              <div>
                  <div class="print wrapperPartName">
                    <h5 class="partName text-uppercase">{{ 'outline.introduction1Name' | translate }}</h5>
                  </div>
              </div>
                <div class="w-100">
                    <f7-row class="wrapperRow" v-for="part in actualSession[0].partIntroductoryI" :key="part.id">
                        <f7-col class="content" width="60">
                            <p v-if="part.name" class="print font-weight-bold text">{{part.name}}</p>
                            <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.content" :key="paragraph.id" v-html="paragraph"></p>
                            <div v-if="part.mod">
                                <p class="font-weight-bold mod">{{ 'outline.modifications' | translate }}:</p>
                                <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.mod" :key="paragraph.id" v-html="paragraph"></p>
                            </div>
                        </f7-col>
                      <f7-col class="imageOnExercise" width="40">
                                <div class="wrapperImageExercise">
                                  <div v-for="image in part.image" :key="image.id">
                                    <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${image}`">
                                  </div>
                                  <div v-if="part.hasOwnProperty('imageMod')">
                                    <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                                      <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${imageMod}`">
                                    </div>
                                  </div>
                                </div>
                        </f7-col>
                    </f7-row>
                  </div>
          </f7-block>
            </aside>
    <aside v-if="actualSession[0].partIntroductoryII !== undefined" class="wrapperIntroduction2 wrapperPart">
            <f7-block class="wrapperColumn">
              <div>
                  <div class="print wrapperPartName">
                    <h5 class="partName text-uppercase">{{ 'outline.introduction2Name' | translate }}</h5>
                  </div>
              </div>
                <div class="w-100">
                    <f7-row class="wrapperRow" v-for="part in actualSession[0].partIntroductoryII" :key="part.id">
                        <f7-col class="content" width="60">
                            <p v-if="part.name" class="print font-weight-bold text">{{part.name}}</p>
                            <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.content" :key="paragraph.id" v-html="paragraph"></p>
                            <div v-if="part.mod">
                                <p class="font-weight-bold mod">{{ 'outline.modifications' | translate }}:</p>
                                <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.mod" :key="paragraph.id" v-html="paragraph"></p>
                            </div>
                        </f7-col>
                      <f7-col class="imageOnExercise" width="40">
                                <div class="wrapperImageExercise">
                                  <div v-for="image in part.image" :key="image.id">
                                    <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${image}`">
                                  </div>
                                  <div v-if="part.hasOwnProperty('imageMod')">
                                    <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                                      <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${imageMod}`">
                                    </div>
                                  </div>
                                </div>
                        </f7-col>
                    </f7-row>
                  </div>
          </f7-block>
            </aside>
            <aside v-if="actualSession[0].partMainI !== undefined" class="wrapperIntroduction2 wrapperPart">
            <f7-block class="wrapperColumn">
              <div>
                  <div class="print wrapperPartName">
                    <h5 class="partName text-uppercase">{{ 'outline.main1Name' | translate }}</h5>
                  </div>
              </div>
                <div class="w-100">
                    <f7-row class="wrapperRow" v-for="part in actualSession[0].partMainI" :key="part.id">
                        <f7-col class="content" width="60">
                            <p v-if="part.name" class="print font-weight-bold text">{{part.name}}</p>
                            <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.content" :key="paragraph.id" v-html="paragraph"></p>
                            <div v-if="part.mod">
                                <p class="font-weight-bold mod">{{ 'outline.modifications' | translate }}:</p>
                                <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.mod" :key="paragraph.id" v-html="paragraph"></p>
                            </div>
                        </f7-col>
                      <f7-col class="imageOnExercise" width="40">
                                <div class="wrapperImageExercise">
                                  <div v-for="image in part.image" :key="image.id">
                                    <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${image}`">
                                  </div>
                                  <div v-if="part.hasOwnProperty('imageMod')">
                                    <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                                      <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${imageMod}`">
                                    </div>
                                  </div>
                                </div>
                        </f7-col>
                    </f7-row>
                  </div>
          </f7-block>
            </aside>
            <aside v-if="actualSession[0].partMainII !== undefined" class="wrapperIntroduction2 wrapperPart">
            <f7-block class="wrapperColumn">
              <div>
                  <div class="print wrapperPartName">
                    <h5 class="partName text-uppercase">{{ 'outline.main2Name' | translate }}</h5>
                  </div>
              </div>
                <div class="w-100">
                    <f7-row class="wrapperRow" v-for="part in actualSession[0].partMainII" :key="part.id">
                        <f7-col class="content" width="60">
                            <p v-if="part.name" class="print font-weight-bold text">{{part.name}}</p>
                            <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.content" :key="paragraph.id" v-html="paragraph"></p>
                            <div v-if="part.mod">
                                <p class="font-weight-bold mod">{{ 'outline.modifications' | translate }}:</p>
                                <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.mod" :key="paragraph.id" v-html="paragraph"></p>
                            </div>
                        </f7-col>
                      <f7-col class="imageOnExercise" width="40">
                                <div class="wrapperImageExercise">
                                  <div v-for="image in part.image" :key="image.id">
                                    <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${image}`">
                                  </div>
                                  <div v-if="part.hasOwnProperty('imageMod')">
                                    <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                                      <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${imageMod}`">
                                    </div>
                                  </div>
                                </div>
                        </f7-col>
                    </f7-row>
                  </div>
          </f7-block>
            </aside>
                        <aside v-if="actualSession[0].partFinal !== undefined" class="wrapperIntroduction2 wrapperPart">
            <f7-block class="wrapperColumn">
              <div>
                  <div class="print wrapperPartName">
                    <h5 class="partName text-uppercase">{{ 'outline.finalName' | translate }}</h5>
                  </div>
              </div>
                <div class="w-100">
                    <f7-row class="wrapperRow" v-for="part in actualSession[0].partFinal" :key="part.id">
                        <f7-col class="content" width="60">
                            <p v-if="part.name" class="print font-weight-bold text">{{part.name}}</p>
                            <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.content" :key="paragraph.id" v-html="paragraph"></p>
                            <div v-if="part.mod">
                                <p class="font-weight-bold mod">{{ 'outline.modifications' | translate }}:</p>
                                <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.mod" :key="paragraph.id" v-html="paragraph"></p>
                            </div>
                        </f7-col>
                      <f7-col class="imageOnExercise" width="40">
                                <div class="wrapperImageExercise">
                                  <div v-for="image in part.image" :key="image.id">
                                    <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${image}`">
                                  </div>
                                  <div v-if="part.hasOwnProperty('imageMod')">
                                    <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                                      <img class="w-100" :src="`static/${bookPanel.keyImage}/session-${id}/${imageMod}`">
                                    </div>
                                  </div>
                                </div>
                        </f7-col>
                    </f7-row>
                  </div>
          </f7-block>
            </aside>
    </main>

    <f7-block style="max-width: 450px; margin: 0 auto">
      <f7-row class="mb-2">
          <f7-col width="50">
            <f7-button v-if="indexPage !== 0" style="margin: 0 12px;" @click="$f7router.navigate(`${previosPage}`);" raised>{{ 'books.previous' | translate }}</f7-button>
          </f7-col>
          <f7-col width="50">
            <f7-button v-if="indexPage !== contentBook.length - 1" style="margin: 0 12px;" @click="$f7router.navigate(`${nextPage}`);" raised>{{ 'books.next' | translate }}</f7-button>
          </f7-col>
        </f7-row>
        <div v-if="bookPanel.demo">
          <div v-if="bookPanel.shop !== '' && getLang === 'pl'">
            <f7-link style="margin: 0 12px;" :href="bookPanel.shop" external target="_blank">
              <p class="text-center font-weight-bold" style="color: #d62029">{{ 'global.more_buy_single' | translate }}.</p>
            </f7-link>
            <p class="text-uppercase text-center mt-0 mb-0">{{ 'global.or' | translate }}</p>
          </div>
          <f7-link style="margin: 0 12px;" href="/payments/">
            <p class="text-center font-weight-bold" style="color: #0fa300">{{ 'global.more_buy_subscriptions' | translate }}.</p>
          </f7-link>
        </div>
    </f7-block>
  </div>
    
      <div class="custom-fab">
        <div v-if="actualSession !== undefined && actualSession[0].isDone !== undefined" class="box cursor-pointer" @click="toggleDone(actualSession[0])">
            <f7-link v-if="actualSession[0].isDone" :tooltip="'books.done_false' | translate">
              <img height="15px" :src="'/static/icons/done-full.svg'" />
            </f7-link>
            <f7-link v-else :tooltip="'books.done_true' | translate">
              <img height="15px" :src="'/static/icons/done-empty.svg'" />
            </f7-link>
        </div>
        <div v-if="actualSession !== undefined && actualSession[0].isFavorite !== undefined" class="box cursor-pointer" @click="toggleFavorite(actualSession[0])">
            <f7-link v-if="actualSession[0].isFavorite" :tooltip="'books.favorite_false' | translate">
              <img height="15px" :src="'/static/icons/star-full.svg'" />
            </f7-link>
            <f7-link v-else :tooltip="'books.favorite_true' | translate">
              <img height="15px" :src="'/static/icons/star-empty.svg'" />
            </f7-link>
        </div>
        <div class="box cursor-pointer" @click="changePreviewMode()">
            <f7-link v-if="previewMode" :tooltip="'outline.goToTab' | translate" >
              <img height="20px" src="/static/icons/tabs.svg" alt="">
            </f7-link>
            <f7-link v-else :tooltip="'outline.previewMode' | translate" >
              <img height="20px" src="/static/icons/outline.svg" alt="">
            </f7-link>
        </div>
        <div class="box cursor-pointer" @click="goToPrivateOutline(actualSession[0], bookPanel)" :disabled="!loaderGoToPrivate">
            <loader v-if="loaderGoToPrivate" :color="'white'" class="loader"></loader>
            <f7-link v-else :tooltip="'outline.goToPrivateOutline' | translate" >
              <img height="20px" src="/static/icons/select-arrow.svg" alt="">
            </f7-link>
        </div>
      </div>


    </div>

  </f7-page>
</template>

<script>
import Navbarbook from "@/components/Navbar/Navbarbook.vue";
import Loader from "@/components/Loader";
import mixinBook from "@/mixins/mixinBook"

export default {
  mixins: [mixinBook],
  components: {
    Navbarbook,
    Loader
  },
  props: {
    idBook: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      loader: false,
      contentBook: [],
      bookPanel: {}
    };
  },
  computed: {
    actualSession() {
      let actual = this.contentBook.filter(elem => elem.id == this.id)
      if (actual.length > 0) {
        if (this.favorites.length == 0) {
          actual[0].isFavorite = false
        } else {
          this.favorites.forEach(favorite => {
            actual[0].isFavorite = favorite.book == actual[0].book && favorite.id == actual[0].id
          });
        }

        if (this.done.length == 0) {
          actual[0].isDone = false
        } else {
          this.done.forEach(done => {
            actual[0].isDone = done.book == actual[0].book && done.id == actual[0].id
          });
        }

        return actual
      } else {
        this.$f7router.navigate(`/books/${this.idBook}/`);
      }
    }
  },
  mounted() {
    if ( window.innerWidth >= 800 ) {
      this.previewMode = true;
    }
    this.$store.dispatch("appkonspektypilkanozna/setActualBook", this.idBook);
    if (this.isAuthenticated) {
      this.fetchDataBook()
    } else {
      this.fetchDataBookDemo()
    }
    if (this.$f7route.query.lang !== undefined) {
        const lang = this.$f7route.query.lang
        if (this.getLang !== lang) {
          this.changeLang()
        }
    }
  },
  methods: {
    toggleFavorite(session) {
      this.showInfoFavorite(session);
      this.toggleFavoriteSuccess(session);
    },
    toggleDone(session) {
      this.showInfoDone(session);
      this.toggleDoneSuccess(session);
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/session.scss";
</style>