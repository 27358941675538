<template>
  <f7-page login-screen>
    <navbarcrm></navbarcrm>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{ 'club.statistics' | translate}} <f7-link class="p-1" :tooltip="'popover.statistics' | translate"><img height="11px" src="/static/icons/help.svg" alt /></f7-link></h4>
          </f7-block>
          <f7-list form no-hairlines-md @submit.prevent="checkStats">
            <f7-list-item :title="'club.type_stats' | translate" class="custom-select">
              <select name="type_stats" v-model="statsForm.type_stats">
                <option v-for="stat in typeStats" :key="stat.id" :value="stat.id" :selected="stat.id == statsForm.type_stats">
                    {{stat.label | translate}}
                </option>
              </select>
            </f7-list-item>
            <f7-list-item :title="'club.group' | translate" class="custom-select">
              <select name="group" v-model="statsForm.group" @change="changeGroup(statsForm.group)">
                <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == statsForm.group">
                    {{group.name}}
                </option>
              </select>
            </f7-list-item>
            <f7-button style="margin: 0px 16px;" v-if="!groups.length > 0" href="/groups/" raised>{{'club.need_create_group' | translate}}!</f7-button>
            <f7-list-input
              :label="'club.start_range' | translate"
              type="date"
             :placeholder="'club.end_date' | translate"
              :value="statsForm.date_start"
              @input="statsForm.date_start = $event.target.value"
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.end_range' | translate"
              type="date"
              :placeholder="'club.end_date' | translate"
              :value="statsForm.date_end"
              @input="statsForm.date_end = $event.target.value"
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>

            <f7-block class="text-center">
              <f7-button v-if="!loaderStats" type="submit" raised>
                {{ 'club.see_statistics' | translate}}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
          <div v-if="statsForm.type_stats === 'attendance' &&  stats.attendance.length > 0" class="data-table" style="margin: 24px">
            <table>
              <thead>
                <tr>
                  <th class="label-cell">{{ 'club.player' | translate}}</th>
                  <th class="numeric-cell text-center" v-for="training in stats.attendance" :key="training.id">
                    <div v-html="getTraining(training.training)">
                    </div>
                  </th>
                  <th class="numeric-cell text-right">{{ 'club.frequency' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="player in players" :key="player.id">
                  <td class="label-cell">
                    <span>
                      <f7-link v-if="player.status_active" :tooltip="'club.status_active' | translate">
                        <div style="width: 10px; height: 10px; background-color: green; border-radius: 50%;"></div>
                      </f7-link>
                      <f7-link v-else :tooltip="'club.status_not_active' | translate">
                        <div style="width: 10px; height: 10px; background-color: red; border-radius: 50%;"></div>
                      </f7-link>
                        {{player.first_name}} {{player.last_name}}
                    </span>
                  </td>
                  <td class="text-center" v-for="stats in stats.attendance" :key="stats.id">
                    <span v-if="stats.was_presense.includes(player.id)" class="d-flex align-items-center justify-content-center">
                      <img height="20px" src="/static/icons/check.svg" alt/>
                    </span>
                    <span v-else class="d-flex align-items-center justify-content-center">
                      <img height="20px" src="/static/icons/remove.svg" alt />
                      <span v-if="player.player_attendance !== null && checkReportedAttendancePlayer(stats.training, player.player_attendance.data.reported)" class="ml-1">
                        <f7-link :tooltip="'club.reported_absence' | translate">!</f7-link>
                      </span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span v-if="mapFrequency[player.id]">{{ Math.round(mapFrequency[player.id] / stats.attendance.length * 100) }}</span>
                    <span v-else>
                      0
                    </span>
                    %
                  </td>
                </tr>
                <tr>
                  <td class="label-cell">
                    {{ 'club.count_player_training' | translate}}
                  </td>
                  <td class="text-center" v-for="stats in stats.attendance" :key="stats.id">
                    {{stats.was_presense.length}} 
                    <f7-link :tooltip="'club.training_attendance' | translate">({{ Math.round(stats.was_presense.length / players.length * 100) }}%)</f7-link>
                  </td>
                  <td class="text-center">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-else-if="statsForm.type_stats === 'dues' && stats.dues.length > 0" class="data-table" style="margin: 24px">
            <table>
              <thead>
                <tr>
                  <th class="label-cell">{{ 'club.player' | translate}}</th>
                  <th class="numeric-cell text-center" v-for="due in stats.dues" :key="due.id">
                    {{ due.date_of_payment }}
                  </th>
                  <th class="numeric-cell text-right">{{ 'club.amount_dues' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="player in players" :key="player.id">
                  <td class="label-cell">
                    <span>
                      <f7-link v-if="player.status_active" :tooltip="'club.status_active' | translate">
                        <div style="width: 10px; height: 10px; background-color: green; border-radius: 50%;"></div>
                      </f7-link>
                      <f7-link v-else :tooltip="'club.status_not_active' | translate">
                        <div style="width: 10px; height: 10px; background-color: red; border-radius: 50%;"></div>
                      </f7-link>
                        {{player.first_name}} {{player.last_name}}
                    </span>
                  </td>
                  <td class="text-center" v-for="stats in stats.dues" :key="stats.id">
                    <span v-if="stats.paid_dues.includes(player.id)">
                      <img height="20px" src="/static/icons/check.svg" alt />
                    </span>
                    <span v-else>
                      <img height="20px" src="/static/icons/remove.svg" alt />
                    </span>
                  </td>
                  <td class="text-right">
                    <span>
                      {{ player.due_price }} {{currency[account.currency]}}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="label-cell">
                    {{ 'club.count_player_dues' | translate}}
                  </td>
                  <td class="text-center" v-for="stats in stats.dues" :key="stats.id">
                    {{ calculateSumOfPaidDuesForPlayer(stats) }} {{currency[account.currency]}}
                  </td>
                  <td class="text-center">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="text-center text-uppercase">
            <div v-if="initStat">
              <img height="100px" src="/static/icons/stats.svg" alt />
              <h4 class="mb-0">{{ 'club.no_statistics' | translate}}</h4>
              <h4 class="mt-0">{{ 'club.change_group' | translate}}</h4>
            </div>
          </div>
    </div>

  </f7-page>
</template>

<script>
import moment from 'moment'
import Navbarcrm from "@/components/Navbar/Navbarcrm.vue";
import attendanceListServices from "@/services/AttendanceListServices.js"
import duesServices from "@/services/duesServices.js"
import groupsServices from "@/services/GroupsServices.js"
import Loader from "@/components/Loader";
import CONSTANTS from "@/utils/constants.js"
import mixinGroups from "@/mixins/mixinGroups"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinGroups, mixinUser],
  components: {
    Navbarcrm,
    Loader,
  },
  data: () => {
    return {
      loader: false,
      loaderStats: false,
      initStat: false,
      currency: CONSTANTS.CURRENCY,
      statsForm: {
        type_stats: 'attendance',
        date_start: '',
        date_end: '',
        group: ''
      },
      stats: {
        trainings: [],
        attendance: [],
        dues: [],
      },
      frequency: [],
      groups: [],
      players: [],
      typeStats: CONSTANTS.TYPE_STATS,
    };
  },
  watch: {
    'statsForm.group': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.stats.trainings = []
        this.stats.attendance = []
        this.stats.dues = []
        this.initStat = false;
        this.fetchAllPlayers(this.statsForm.group);
      }
    },
    'statsForm.date_start': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.stats.trainings = []
        this.stats.attendance = []
        this.stats.dues = []
        this.initStat = false;
      }
    },
    'statsForm.date_end': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.stats.trainings = []
        this.stats.attendance = []
        this.stats.dues = []
        this.initStat = false;
      }
    },
  },
  computed: {
    mapFrequency() {
      return this.frequency.reduce((acc, curr) => {
        if (typeof acc[curr] == 'undefined') {
          acc[curr] = 1;
        } else {
          acc[curr] += 1;
        }

        return acc;
      }, {});
    },
   },
  mounted() {
    this.fetchDataUser().then(() => {
        this.fetchGroups().then(() => {
          if (this.getRole == 'coach') {
            this.groups = this.profileGroups;
          }
          if (this.groups.length > 0) {
              const checkGroup = obj => obj.id === this.getActiveGroup;
              if (this.getActiveGroup !== '' && this.groups.some(checkGroup)) {
              this.statsForm.group = this.getActiveGroup
              } else {
              this.statsForm.group = this.groups[0].id
              }
          }
        });
      this.statsForm.date_end = moment(new Date()).format('YYYY-MM-DD');
      this.statsForm.date_start = moment(this.statsForm.date_end).subtract(30, 'days').format('YYYY-MM-DD');
    })
  },
  methods: {
    checkReportedAttendancePlayer(trainingId, dates) {
      const training = this.stats.trainings.find(obj => obj.id === trainingId)
      return dates.includes(training.training_date)
    },
    calculateSumOfPaidDuesForPlayer(stats) {
      let sum = 0;

      for (let player of this.players) {
        if (stats.paid_dues.includes(player.id)) {
          sum += player.due_price
        }
      }
      
      return sum;
    },
    getTime(training) {
      if (training.training_hours_start && training.training_hours_end) {
        const hours_start = training.training_hours_start.split(/:/)
        const hours_end = training.training_hours_end.split(/:/)
        return `${hours_start[0]}:${hours_start[1]} - ${hours_end[0]}:${hours_end[1]}`
      } else {
        return ''
      }
    },
    getTraining(id) {
      const subject = this.stats.trainings.filter(elem => elem.id == id)[0].subject
      const date = this.stats.trainings.filter(elem => elem.id == id)[0].training_date
      const time = this.getTime(this.stats.trainings.filter(elem => elem.id == id)[0])
      return `<p style="margin: 0px">${date}</p><p style="margin: 0px">${time}</p><p style="margin: 0px">${subject}</p>`
    },
    async fetchAllPlayers(group) {
      try {
        this.loader = true;

        const responseDataAllPlayers = await groupsServices.listAllPlayers(group);
        this.players = responseDataAllPlayers.data
        
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with the list of players in the group. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    async checkStats() {
      this.frequency = []
      this.loaderStats = true;
      try {
        if (this.statsForm.type_stats === 'attendance') {
          const responseDataAttendanceStats = await attendanceListServices.attendanceStats(this.statsForm);
          this.stats.attendance = responseDataAttendanceStats.data.attendance
          this.stats.trainings = responseDataAttendanceStats.data.trainings

          this.stats.attendance.forEach((attendance) => {
            this.frequency = this.frequency.concat(attendance.was_presense)
          })
        }

        if (this.statsForm.type_stats === 'dues') {
          const responseDataDuesStats = await duesServices.duesStats(this.statsForm);
          this.stats.dues = responseDataDuesStats.data.dues
        }

        this.initStat = true;

      } catch (error) {
          this.$f7.toast
          .create({
              text: "Something went wrong with the stats. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
          })
          .open();
          console.log(error);
      }
      this.loaderStats = false;
    },
  }
};
</script>