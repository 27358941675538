<template>
  <f7-page login-screen>
    <navbarcrm></navbarcrm>

    <loader v-if="loader"></loader>

    <div v-else>

        <f7-block class="text-uppercase">
          <h4>{{ 'club.coaches' | translate}} <f7-link class="p-1" :tooltip="'popover.coaches' | translate"><img height="11px" src="/static/icons/help.svg" alt /></f7-link></h4>
          <div class="countAll">
            <span>
              <p>{{ 'club.count' | translate}}</p>
              {{profileCoaches.length}}
            </span>
          </div>
        </f7-block>
        <div class="listCards" v-if="profileCoaches.length > 0">
          <f7-block>
            <f7-row>
              <f7-col v-for="coach in profileCoaches" :key="coach.id" width="100" class="position-relative">
                <div class="delete" @click.stop="showDialogDeleteCoach(coach)">
                  <img height="20px" src="/static/icons/trash.svg" alt />
                </div>
                <a :href="`/coaches/update/${coach.id}/`">
                <f7-card class="custom-card">
                  <div>
                    <p>{{coach.first_name}} {{coach.last_name}}</p>
                    <p class="info">{{coach.email}}</p>
                    <p class="info" v-if="coach.date_of_birth">{{ 'club.date_of_birth' | translate}}: {{coach.date_of_birth}}</p>
                    <p class="info" v-if="coach.groups.length > 0">
                      <span v-for="(group, index) in coach.groups.filter(elem => userId == elem.user)" :key="group.id">
                        {{group.name}} <i v-if="index !== coach.groups.length - 1">-</i>
                      </span>
                    </p>
                  </div>
                </f7-card>
                </a>
              </f7-col>
            </f7-row>
          </f7-block>
        </div>
        <div v-else class="text-center text-uppercase">
            <img height="100px" src="/static/icons/agent.svg" alt />
            <h4>{{ 'club.empty_coach_group' | translate}}!</h4>
        </div>
        <f7-block>
          <div class="d-flex justify-content-center aling-items-center">
            <div>
              <a :href="`/add-coach/`">
                <img class="cursor-pointer" height="30px" src="/static/icons/plus.svg" alt="">
              </a>
            </div>
          </div>
        </f7-block>
    </div>

  </f7-page>
</template>

<script>
import Navbarcrm from "@/components/Navbar/Navbarcrm.vue";
import Loader from "@/components/Loader";
import mixinUser from "@/mixins/mixinUser"
import coachesServices from "@/services/coachesServices.js"

export default {
  mixins: [mixinUser],
  components: {
    Navbarcrm,
    Loader
  },
  data: () => {
    return {
      loader: false,
      profileCoaches: '',
    };
  },
  mounted() {
    this.fetchDataUser()
  },
  methods: {
    showDialogDeleteCoach(caoch) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the trainer ${caoch.first_name} ${caoch.last_name} from the club? The change will be irreversible!`, '', () => {
          this.deleteCoach(caoch.id)
        });
    },
    async deleteCoach(id) {
      try {
        const responseDeleteCoach = await coachesServices.deleteCoach(id);
        this.fetchDataUser();
        this.$f7.toast
          .create({
            text: "Coach removed from club and groups!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with removing the trainer. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  }
};
</script>