<template>
  <f7-page login-screen>
    <navbar></navbar>
    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'global.partners' | translate }}</h4>
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 130px" src="/static/company/asystent_trenera.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 100px" src="/static/company/pozytywny_coaching.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 120px" src="/static/company/folder_srodkow_treningowych.png" alt="">
    </f7-block>
     <f7-block class="text-center">
      <img style="max-width: 100px" src="/static/company/better_way.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 100px" src="/static/company/only_futbol.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 100px" src="/static/company/adler_coach.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 100px" src="/static/company/football_challenge_app.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 100px" src="/static/company/juniorski_mecz.png" alt="">
    </f7-block>
        <f7-block class="text-center">
      <img style="max-width: 100px" src="/static/company/strefa_futbolu.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 100px" src="/static/company/falcons.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 100px" src="/static/company/pilkarski_trening.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 130px" src="/static/company/pilka_nozna_dla_dzieci.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 140px" src="/static/company/unicoach_creator.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 150px" src="/static/company/emsport.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 100px" src="/static/company/protrainup.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 100px" src="/static/company/socatots.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 100px" src="/static/company/bss.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 170px" src="/static/company/psychosportica.png" alt="">
    </f7-block>
     <f7-block class="text-center">
      <img style="max-width: 70px" src="/static/company/trening_pilkarski.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 80px" src="/static/company/ifj96.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 130px" src="/static/company/football_mind.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 180px" src="/static/company/football_coaching_lab.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 180px" src="/static/company/progress_coaching.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 180px" src="/static/company/iamfootballcoach.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 120px" src="/static/company/football_level_up.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 120px" src="/static/company/keepers_foundation.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 140px" src="/static/company/trening_dzieci_2_0.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <img style="max-width: 180px" src="/static/company/football_insight.png" alt="">
    </f7-block>
  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>

<style lang="scss">
</style>