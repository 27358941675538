<template>
  <f7-page login-screen>
    <navbarlist :list="'players'"></navbarlist>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{ 'club.new_player' | translate }}</h4>
          </f7-block>
          <f7-list form no-hairlines-md @submit.prevent="createPlayer">
            <f7-list-item :title="'club.status_player' | translate" class="list-custom-checkbox custom-item-inside-list">
              <div class="d-flex justify-content-between w-100 align-items-center">
                <div>
                  <span v-if="player.status_active" style="color: green">{{ 'club.status_active' | translate }}</span>
                <span v-else style="color: red">{{ 'club.status_not_active' | translate }}</span>
                </div>
                
                <div class="d-flex">
                  <input type="checkbox" class="custom-checkbox" v-model="player.status_active">
                </div>
              </div>
            </f7-list-item>
            <f7-list-input
              :label="'club.first_name' | translate"
              type="text"
              :placeholder="'club.first_name' | translate"
              :value="player.first_name"
              @input="player.first_name = $event.target.value"
              clear-button
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>
             <f7-list-input
              :label="'club.last_name' | translate"
              type="text"
              :placeholder="'club.last_name' | translate"
              :value="player.last_name"
              @input="player.last_name = $event.target.value"
              clear-button
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.email' | translate"
              type="text"
              :placeholder="'club.email' | translate"
              :value="player.email"
              @input="player.email = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.phone_number' | translate"
              type="text"
              :placeholder="'club.phone_number' | translate"
              :value="player.phone"
              @input="player.phone = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-item :title="'club.group' | translate" class="custom-select">
              <select name="group" v-model="player.group">
                <option v-if="groups.length == 0" value="" disabled>{{  'club.lack_group' | translate}}</option>
                <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == player.group">
                    {{group.name}}
                </option>
              </select>
            </f7-list-item>
            <f7-list-item v-if="groups.length > 0" :title="'club.additional_groups' | translate" smart-select :smart-select-params="{openIn: 'popover'}">
              <select name="additional_groups" multiple v-model="player.additional_groups">
                <option v-for="group in groups.filter(group => group.id !== player.group)" :key="group.id" :value="group.id">
                    {{group.name}}
                </option>
              </select>
            </f7-list-item>
            <f7-list-input
              :label="'club.premium_amount' | translate"
              type="number"
              :placeholder="'club.premium_amount' | translate"
              :value="player.due_price"
              @input="player.due_price = $event.target.value"
              clear-button
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.date_of_birth' | translate"
              type="date"
              :placeholder="'club.date_of_birth' | translate"
              :value="player.date_of_birth"
              @input="player.date_of_birth = $event.target.value"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.street' | translate"
              type="text"
              :placeholder="'club.street' | translate"
              :value="player.street"
              @input="player.street = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.city' | translate"
              type="text"
              :placeholder="'club.city' | translate"
              :value="player.city"
              @input="player.city = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.zip_code' | translate"
              type="text"
              :placeholder="'club.zip_code' | translate"
              :value="player.code"
              @input="player.code = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.notes' | translate"
              type="textarea"
              :placeholder="'club.notes' | translate"
              :value="player.notes"
              @input="player.notes = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-button style="margin: 0px 16px;" v-if="!groups.length > 0" href="/groups/" raised>{{'club.need_create_group' | translate}}!</f7-button>
            <f7-block class="text-center">
              <f7-button v-if="!loaderPlayer" type="submit" raised>
                  {{ 'club.add_player_group' | translate }}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import Navbarlist from "@/components/Navbar/Navbarlist.vue";
import Loader from "@/components/Loader";
import groupsServices from "@/services/groupsServices.js"
import mixinUser from "@/mixins/mixinUser"
import mixinGroups from "@/mixins/mixinGroups"

export default {
  mixins: [mixinGroups, mixinUser],
  components: {
    Navbarlist,
    Loader
  },
  data: () => {
    return {
      loader: false,
      loaderPlayer: false,
      player: {
        first_name: '',
        last_name: '',
        notes: '',
        group: '',
        due_price: '',
        date_of_birth: '',
        street: '',
        city: '',
        code: '',
        phone: '',
        email: '',
        status_active: true,
        player_attendance: {
          data: {
            reported: [],
            completed: [],
          }
        },
        additional_groups: []
      },
    };
  },
  watch: {
    'player.group': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.player.due_price = this.groups.find(elem => elem.id == this.player.group).due_price
      }
    },
  },
  mounted() {
    this.fetchDataUser().then(() => {
      this.fetchGroups().then(() => {
        if (this.getRole == 'coach') {
          this.groups = this.profileGroups;
        }
        if (this.groups.length > 0) {
          if (this.$f7route.query.group !== undefined) {
            this.player.group = this.$f7route.query.group
          } else {
            this.player.group = this.groups[0].id
          }
        }
      });
    })
  },
  methods: {
    async createPlayer() {
      if (this.player.group == '') {
        return this.$f7.toast
          .create({
            text: "No group",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
      }
      try {
        this.loaderPlayer = true;

        const responseCreatePlayer = await groupsServices.createPlayer(this.player);
        
        if (responseCreatePlayer.data.id) {
          this.$f7router.navigate(`/players/?group=${this.player.group}`)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with adding a player to the group. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderPlayer = false;
    },
  }
};
</script>