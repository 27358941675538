<template>
  <f7-page login-screen>
    <navbar></navbar>

    <f7-block class="text-center">
      <img style="height: 150px;" src="/static/icons/red-card.svg" alt />
    </f7-block>
    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'global.change_role' | translate}}!</h4>
    </f7-block>
    <f7-block class="text-center">{{ 'global.change_role_description' | translate}}
    </f7-block>
    <f7-block class="text-center">
      <f7-button panel-open="left" raised>{{ 'global.change_role_link' | translate}}</f7-button>
    </f7-block>

  </f7-page>
</template>
<script>
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>