<template>
  <f7-page login-screen class="pageCreatePlanningTraining">
    <navbarlist :list="'planning-trainings'"></navbarlist>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{ 'club.planning_training' | translate }}
              <img class="cursor-pointer" @click="showDialogDeletePlanningTrainings(planning)" height="14px" src="/static/icons/trash.svg" alt />
            </h4>
          </f7-block>
          <div class="text-center">
            <div style="display: inline-flex">
              <label class="toggle toggle-int color-black">
                <input type="checkbox" v-model="planning.new_pzpn"/>
                <span class="toggle-icon"></span>
              </label>
              <p class="ml-2">{{ 'club.new_pzpn' | translate }}</p>
            </div>
          </div>
          <f7-list form no-hairlines-md @submit.prevent="updatePlanningTraining">
            <f7-list-input
              :label="'club.mesocycle' | translate"
              type="text"
              :placeholder="'club.mesocycle' | translate"
              :value="planning.mesocycle"
              @input="planning.mesocycle = $event.target.value"
              clear-button
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>
            <f7-list-item :title="'club.group' | translate" class="custom-select">
              <select name="group" v-model="planning.group">
                <option v-if="groups.length == 0" value="" disabled>{{ 'club.lack_group' | translate }}</option>
                <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == planning.group">
                    {{group.name}}
                </option>
              </select>
            </f7-list-item>
            <f7-list-input
              :label="'club.start_date_mesocycle' | translate"
              type="date"
              :placeholder="'club.start_date_mesocycle' | translate"
              :value="planning.mesocycle_date_start"
              @input="planning.mesocycle_date_start = $event.target.value"
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.end_date_mesoccle_base_start' | translate"
              type="date"
              :placeholder="'club.end_date_mesoccle' | translate"
              :value="planning.mesocycle_date_end"
              @input="planning.mesocycle_date_end = $event.target.value"
              disabled
            >
            </f7-list-input>
            <div class="wrapperPlans">
              <f7-card class="custom-card" v-for="plan in planning.plans.data" :key="plan.id">
                
                <div class="w-100">
                  <f7-link v-if="plan.color !== ''" :tooltip="`Planning color: ${paleteColorsHighlight[plan.color].label}`" class="color" :style="{background : paleteColorsHighlight[plan.color].value}"></f7-link>
                  <p style="margin-top: 20px; margin-bottom: 20px" class="mt-0 font-weight-bold text-uppercase">{{plan.week}} <span class="text-muted" v-if="planning.mesocycle_date_start">({{plan.date_start}} - {{plan.date_end}})</span></p>
                
                
                <div class="d-flex align-items-center">
                  <p style="margin-right: 5px;" class="font-weight-bold text-uppercase">{{'club.microcycle' | translate}}: </p>
                  <div class="custom-select">
                    <select name="group" v-model="plan.microcycle">
                      <option v-for="(microcycle, index) in plansList" :key="index" :value="index" :selected="index == plan.microcycle">
                        {{microcycle}}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-if="plan.microcycle == 0" class="d-flex align-items-center">
                  <p style="margin-right: 5px;" class="font-weight-bold text-uppercase">{{'club.name' | translate}}: </p>
                  <input class="custom-input" type="text" v-model='plan.other'>
                </div>
                <div class="d-flex align-items-center">
                  <p style="margin-right: 5px;" class="font-weight-bold text-uppercase">{{'club.color' | translate}}: </p>
                  <div class="custom-select">
                    <select name="group" v-model="plan.color">
                      <option v-for="(color, index) in paleteColorsHighlight" :key="index" :value="index" :selected="index == plan.color">
                        {{color.label}}
                      </option>
                    </select>
                  </div>
                </div>
                </div>
              </f7-card>
            </div>
            <f7-list-input
              :label="planning.new_pzpn ? `outline.gameGoal` : `club.area_technique` | translate"
              type="textarea"
              :placeholder="planning.new_pzpn ? `outline.gameGoal` : `club.area_technique` | translate"
              :value="planning.area_technical_tactical"
              @input="planning.area_technical_tactical = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="planning.new_pzpn ? `outline.actions` : `club.area_motor` | translate"
              type="textarea"
              :placeholder="planning.new_pzpn ? `outline.actions` : `club.area_motor` | translate"
              :value="planning.area_motor"
              @input="planning.area_motor = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="planning.new_pzpn ? `outline.gameRules` : `club.area_mental` | translate"
              type="textarea"
              :placeholder="planning.new_pzpn ? `outline.gameRules` : `club.area_mental` | translate"
              :value="planning.area_mental"
              @input="planning.area_mental = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="planning.new_pzpn ? `outline.motorGoal` : `club.area_knowledge` | translate"
              type="textarea"
              :placeholder="planning.new_pzpn ? `outline.motorGoal` : `club.area_knowledge` | translate"
              :value="planning.area_knowledge"
              @input="planning.area_knowledge = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-block class="text-center">
              <f7-button v-if="!loaderPlanning" type="submit" raised>
                  {{ 'club.save' | translate }}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import moment from 'moment'
import Navbarlist from "@/components/Navbar/Navbarlist.vue";
import Loader from "@/components/Loader";
import trainingsServices from "@/services/TrainingsServices.js"
import mixinGroups from "@/mixins/mixinGroups"
import mixinUser from "@/mixins/mixinUser"
import CONSTANTS from "@/utils/constants.js"

export default {
  mixins: [mixinGroups, mixinUser],
  components: {
    Navbarlist,
    Loader
  },
  props: {
    idPlanningTraining: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      loader: false,
      loaderPlanning: false,
      plansList: CONSTANTS.PLANS_LIST,
      planning: {
        mesocycle: '',
        group: '',
        plans: {
          data: []
        }
      },
    };
  },
  mounted() {
    if (this.isAuthenticated) {
    this.fetchDataUser().then(() => {
          this.fetchGroups().then(() => {
            if (this.getRole == 'coach') {
              this.groups = this.profileGroups;
            }
            if (this.groups.length > 0) {
                if (this.$f7route.query.group !== undefined) {
                  this.planning.group = this.$f7route.query.group
                } else {
                  this.planning.group = this.groups[0].id
                }
              }
          });
          this.fetchPlanningTraining(this.idPlanningTraining)
      })
    }
  },
  watch: {
    'planning.mesocycle_date_start': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.planning.mesocycle_date_end = moment(this.planning.mesocycle_date_start).add(27, 'days').format('YYYY-MM-DD');
        this.planning.plans.data[0].date_start = moment(this.planning.mesocycle_date_start).format('YYYY-MM-DD');
        this.planning.plans.data[0].date_end = moment(this.planning.mesocycle_date_start).add(6, 'days').format('YYYY-MM-DD');
        this.planning.plans.data[1].date_start = moment(this.planning.mesocycle_date_start).add(7, 'days').format('YYYY-MM-DD');
        this.planning.plans.data[1].date_end = moment(this.planning.mesocycle_date_start).add(13, 'days').format('YYYY-MM-DD');
        this.planning.plans.data[2].date_start = moment(this.planning.mesocycle_date_start).add(14, 'days').format('YYYY-MM-DD');
        this.planning.plans.data[2].date_end = moment(this.planning.mesocycle_date_start).add(20, 'days').format('YYYY-MM-DD');
        this.planning.plans.data[3].date_start = moment(this.planning.mesocycle_date_start).add(21, 'days').format('YYYY-MM-DD');
        this.planning.plans.data[3].date_end = moment(this.planning.mesocycle_date_start).add(27, 'days').format('YYYY-MM-DD');
      }
    },
  },
  methods: {
    async fetchPlanningTraining(id) {
      try {
        this.loader = true;

        const responseDataPlanningTraining = await trainingsServices.itemPlanningTraining(id);
        this.planning = responseDataPlanningTraining.data
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with the training plan. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    async updatePlanningTraining() {
      if (this.planning.group == '') {
        return this.$f7.toast
          .create({
            text: "No group",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
      }
      try {
        this.loaderPlanning = true;

        const responseUpdatePlanningTraining = await trainingsServices.planningTraining(this.planning);
        if ('access' in responseUpdatePlanningTraining.data && !responseUpdatePlanningTraining.data.access) {
            this.$f7.toast
            .create({
              text: "Access to the module has expired!",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        } else {
          if (this.$f7route.query.day !== undefined) {
            this.$f7router.navigate(`/calendar/?day=${this.$f7route.query.day}&group=${this.planning.group}`)
          } else {
            this.$f7router.navigate(`/planning-trainings/?group=${this.planning.group}`)
          }
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the outline creation. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderPlanning = false;
    },
    showDialogDeletePlanningTrainings(planning) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the training plan ${planning.mesocycle}? The change will be irreversible!`, '', () => {
          this.deletePlanningTrainings(planning.id)
        });
    },
    async deletePlanningTrainings(id) {
      try {
        const responseDeletePlanningTraining = await trainingsServices.deletePlanningTraining(id);
        if (this.$f7route.query.day !== undefined) {
          this.$f7router.navigate(`/calendar/?day=${this.$f7route.query.day}&group=${this.planning.group}`)
        } else {
          this.$f7router.navigate(`/planning-trainings/?group=${this.planning.group}`)
        }
        this.$f7.toast
          .create({
            text: "The training plan has been deleted!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with deleting the training plan. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/createplanningtraining.scss";
</style>