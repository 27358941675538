<template>
  <f7-page login-screen>
    <navbar></navbar>
    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'global.recommendations' | translate }}</h4>
    </f7-block>
    <f7-block class="text-center">
      <img height="70px" src="/static/company/futsal_kolobrzeg.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <h3>{{ 'global.recommendation_futsal' | translate }}</h3>
    </f7-block>
    <f7-block class="text-justify">
      <p>{{ 'global.recommendation_futsal_description' | translate }}</p>
    </f7-block>
    <f7-block>
      <f7-button href="http://futsalkolobrzeg.pl/" external target="_blank" class="external" style="background: lightblue; color: white;" raised >{{ 'global.recommendation_futsal_link' | translate }}</f7-button>
    </f7-block>
    <f7-block class="text-center">
      <hr>
    </f7-block>
    <f7-block class="text-center">
      <img height="70px" src="/static/company/futbolovve.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <h3>{{ 'global.recommendation_futbolovve' | translate }}</h3>
    </f7-block>
    <f7-block class="text-justify">
      <p>{{ 'global.recommendation_futbolovve_description' | translate }}</p>
    </f7-block>
    <f7-block>
      <f7-button href="https://www.futbolovve.pl/" external target="_blank" class="external" style="background: green; color: white;" raised >{{ 'global.recommendation_futbolovve_link' | translate }}</f7-button>
    </f7-block>
    <f7-block class="text-center">
      <hr>
    </f7-block>
    <f7-block class="text-center">
      <img height="70px" src="/static/pzpn.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <h3>{{ 'global.recommendation_pzpn' | translate }}</h3>
    </f7-block>
    <f7-block class="text-justify">
      <p>{{ 'global.recommendation_pzpn_description' | translate }}</p>
    </f7-block>
    <f7-block class="text-center">
      <img height="150px" src="/static/cover-skrzat.png" alt="">
    </f7-block>
    <f7-block>
      <f7-button href="https://www.konspektypilkanozna.pl/sklep/konspekty-dla-skrzatow-5-6-lat/" external target="_blank" class="external" raised >{{ 'global.recommendation_pzpn_link' | translate }}</f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>

<style lang="scss">
</style>