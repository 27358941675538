<template>
  <f7-page class="pagePayments" login-screen>
    <navbar></navbar>

      <f7-block class="text-center">
        <img style="height: 150px;" src="/static/icons/billing-card.svg" alt />
      </f7-block>
      <f7-block class="text-center">
        <h3>{{ 'payment.payment_page' | translate}}</h3>
        <p>{{ 'payment.payment_page_description' | translate}}</p>
      </f7-block>

      <f7-block>
          <f7-list form>
            <div>
              <div class="d-flex justify-content-between align-items-center mb-1">
                <p class="font-weight-bold mt-0 mb-0">{{ 'payment.choice_currency' | translate}}</p>
                
              </div>
              <select name="currency" v-model="actualCurrencyType">
                  <option v-for="(elem, index) in currency" :key="index" :value="index" :selected="index == actualCurrencyType">
                      {{elem}} - 
                      <span v-if="elem === 'PLN'">polish zloty</span>
                      <span v-if="elem === 'USD'">dollar</span>
                      <span v-if="elem === 'EUR'">euro</span>
                  </option>
              </select>
             
              <div class="d-flex justify-content-between align-items-center mb-1">
                <p class="font-weight-bold mt-1 mb-0">{{ 'payment.choice_plan' | translate}}</p>
                <f7-link :tooltip="'Stripe'">
                  <img width="30px" src="/static/stripe.png" alt /> 
                </f7-link>
              </div>
              <f7-row class="box">
                <f7-col 
                  v-for="plan in subscriptionsList[actualCurrencyType]"
                  :key="plan.id"
                  width="50"
                  class="box-item active"
                  @click="goToStripe(plan.link)"
                >
                <img style="height: 150px;" :src="`/static/faq/plan_${plan.months}.png`" alt />

                  <h4>{{plan.price}} {{currency[actualCurrencyType]}}</h4>
                  <p>{{ `payment.plan_${plan.months}` | translate }}</p>
                </f7-col>
              </f7-row>
            </div>
            <f7-block class="text-center wrapper-form-button">
              <span class="font-size-12">{{ 'payment.payment_warning' | translate}}</span>
            </f7-block>
          </f7-list>
        </f7-block>



  </f7-page>
</template>

<script>
import CONSTANTS from "@/utils/constants.js"
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
  data: () => {
    return {
      currency: CONSTANTS.CURRENCY,
      actualCurrencyType: 0,
      subscriptionsList: CONSTANTS.STRIPE_REDIRECTS
    };
  },
  mounted() {
    if (window.location.host == 'app.soccer-sessions.com') {
      // Pixel facebook
      window.fbq('track', 'InitiateCheckout');

      // User.com event
      window.userengage('event.add_to_cart');
    };
  },
  methods: {
    goToStripe(link) {
      window.open(link, '_blank');
    }
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/pages/payments.scss";
</style>