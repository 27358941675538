<template>
  <f7-page login-screen class="no-print">
    <navbarbook></navbarbook>
    <loader v-if="loader"></loader>

    <div v-else>
      <f7-block class="text-center">
        <img height="200px" :src="`${host}${book.cover}`" alt />
      </f7-block>

      <f7-block>
        
        <f7-card class="homeHeaderCard book-card">
          <f7-card-header>
              <f7-block class="header">
                  <p>
                    {{getLang == 'pl' ? book.title : book.title_ang}}
                  </p>
                  <span  class="font-weight-bold text-uppercase fullSubscription" v-if="isSubscription">
                  <f7-link :tooltip="'books.full_extend' | translate">
                    {{ 'books.full' | translate }}*
                  </f7-link>
                </span>
                <span v-else :class="book.demo ? 'demo' : 'full'">
                  <template v-if="book.demo">{{ 'books.demo' | translate }}</template>
                  <template v-else>{{ 'books.full' | translate }}</template>
                </span>
              </f7-block>
          </f7-card-header>
          <f7-card-content>
            <f7-block class="progress">
              {{ 'books.marked' | translate }}:
              <strong>{{countDoneSessions}} / {{cleanSessions.length}}</strong>
              <f7-progressbar :progress="numberProgress"></f7-progressbar>
            </f7-block>
          </f7-card-content>

        </f7-card>
        <f7-button style="margin: 0 8px; background: #1A1A1A" raised panel-open="right"><span class="text-uppercase" style="margin-right: 5px">{{ 'books.table_of_contents' | translate }}</span><img height="20px" src="/static/icons/menu.svg" alt /></f7-button>
        <f7-input
          :label="'books.search' | translate"
          type="text"
          :placeholder="'books.search' | translate"
          :value="search"
          @input="search = $event.target.value"
          clear-button
          style="margin: 5px 8px ; padding: 10px 0px"
        ></f7-input>
        <f7-row no-gap>
              <f7-col v-for="content in filterSessions" :key="content.id" width="50" tablet-width="33" desktop-width="25">
                  <card :session="content" :keyImage="book.key_image" :bookId="getActualBook" :add="true" @on-favorite-success="toggleFavoriteSuccess" @on-done-success="toggleDoneSuccess"></card>
              </f7-col>
        </f7-row>
        <div v-if="book.demo">
          <div v-if="book.shop !== '' && getLang === 'pl'">
            <f7-button :href="book.shop" external target="_blank" class="font-weight-bold external" style="margin: 5px 8px; background: #d62029; color: white;"><div><img height="12px" src="/static/icons/shopping-cart.svg" alt /> {{ 'global.buy_single' | translate }}!</div></f7-button>
            <p class="text-uppercase text-center">{{ 'global.or' | translate }}</p>
          </div>
          <f7-button href="/payments/" class="font-weight-bold" style="margin: 5px 8px; background: #0fa300; color: white;"><div><img height="12px" src="/static/icons/shopping-cart.svg" alt /> {{ 'global.buy_subscriptions' | translate }}!</div></f7-button>
        </div>
          
       
      </f7-block>

    </div>

  </f7-page>
</template>

<script>
import Navbarbook from "@/components/Navbar/Navbarbook.vue";
import Loader from "@/components/Loader";
import Card from "@/components/Card.vue"
import mixinBook from "@/mixins/mixinBook"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinBook, mixinUser],
  components: {
    Loader,
    Navbarbook,
    Card
  },
  props: {
    idBook: {
      type: String,
      required: true
    },
  },
  data: () => {
    return {
      loader: false,
      book: {},
      contentBook: [],
      search: ''
    };
  },
  computed: {
    numberProgress() {
      return (this.countDoneSessions / this.cleanSessions.length) * 100;
    },
    countDoneSessions() {
      const bookDone = []
      
      if (this.cleanSessions.length > 0) {
        this.done.forEach(elem => {
          if (elem.book == this.cleanSessions[0].book) {
            bookDone.push(elem)
          }
        })
      }
      
      return bookDone.length
    },
    cleanSessions() {
      const cleanSessions = []

      this.contentBook.forEach((elem) => {
        if (elem.typeElem !== 'text') {
          cleanSessions.push(elem)
        }
      })
      return cleanSessions
    },
    filterSessions() {
      this.contentBook.map((x) =>{
          x.isFavorite = false;
          x.isDone = false;
          return x
      });

      const cleanSessions = []
      this.contentBook.forEach((elem) => {
        if (elem.typeElem !== 'text') {
          cleanSessions.push(elem)
        }
      })
      if (cleanSessions.length > 0) {
          this.done.forEach(done => {
            if (done.book == cleanSessions[0].book) {
              const index = cleanSessions.findIndex(elem => elem.id == done.id);
              if (index !== -1) {
                cleanSessions[index].isDone = true
              }
            }
          });

          this.favorites.forEach(favorite => {
          if (favorite.book == cleanSessions[0].book) {
            const index = cleanSessions.findIndex(elem => elem.id == favorite.id);
            if (index !== -1) {
              cleanSessions[index].isFavorite = true
            }
          }
        });
      }

      return cleanSessions.filter(elem => {
        return elem.title.toLowerCase().includes(this.search.toLowerCase()) || elem.content.toLowerCase().includes(this.search.toLowerCase())
      })

    }
  },
  mounted() {
    this.$store.dispatch("appkonspektypilkanozna/setActualBook", this.idBook);
    if (this.isAuthenticated) {
      this.fetchDataUser()
      this.fetchDataBook()
    } else {
      this.fetchDataBookDemo()
    }
    if (this.$f7route.query.lang !== undefined) {
        const lang = this.$f7route.query.lang
        if (this.getLang !== lang) {
          this.changeLang()
        }
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/book.scss";
</style>