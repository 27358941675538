<template>
  <f7-page login-screen>
    <navbar></navbar>
    <f7-block class="text-center">
      <img style="height: 150px;" src="/static/icons/football-badge.svg" alt />
    </f7-block>
    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'auth.register_page' | translate}}</h4>
    </f7-block>
    <div v-if="step == 'register'">

    <f7-block class="text-center">
      <p>{{ 'auth.register_page_description' | translate}}</p>
    </f7-block>

      <f7-list form no-hairlines-md @submit.prevent="onRegister">
        <f7-list-input
          :label="'auth.email' | translate"
          type="email"
          :placeholder="'auth.email' | translate"
          :value="register.email"
          @input="register.email = $event.target.value"
          required
          autocomplete="off"
          validate
          :error-message="'global.required' | translate"
          clear-button
        >
        </f7-list-input>
        <f7-block class="text-center">
          <f7-button v-if="!register.loader" type="submit" raised>{{ 'auth.create_account' | translate}}</f7-button>
          <f7-button v-else raised>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>
    </div>
    <div v-if="step == 'created'">
       <f7-block class="text-center">
          <p>{{ 'auth.register_created' | translate}}</p>
      </f7-block>
      <f7-block class="text-center">
        <f7-button href="/login/" raised>{{ 'auth.login_link' | translate}}</f7-button>
      </f7-block>
    </div>
    <div v-if="step == 'exist'">
       <f7-block class="text-center">
          <p>{{ 'auth.register_exist' | translate}} :)</p>
      </f7-block>
      <f7-block class="text-center">
        <f7-button href="/login/" raised>{{ 'auth.login_link' | translate}}</f7-button>
      </f7-block>
    </div>
  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";
import authServices from "@/services/AuthServices.js"

export default {
  components: {
    Navbar,
  },
  data: () => {
    return {
      register: {
        email: null,
        loader: false
      },
      step: 'register'
    };
  },
  mounted() {
        if (this.$f7route.query.lang !== undefined) {
            const lang = this.$f7route.query.lang
            if (this.getLang !== lang) {
              this.changeLang()
            }
        }
      },
  methods: {
    async onRegister() {
        try {
          this.register.loader = true;

          const responseRegister = await authServices.register(this.register.email.toLowerCase());
          if (responseRegister.data.status == 'created') {
            this.step = 'created'
            if (window.location.host == 'app.soccer-sessions.com') {
            // Pixel facebook
              window.fbq('track', 'CompleteRegistration');

              // User.com pageHit
              UE.pageHit({
                apiKey: 'uCdvLn',
                email: this.register.email,
                created_account: true,
                zgoda_marketing: true,
              });
            };
          }
          if (responseRegister.data.status == 'exist') {
            this.step = 'exist'
            this.register.email = '';
            this.$f7.toast
            .create({
              text: "The account is already created!",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          }
        } catch (error) {
          this.$f7.toast
            .create({
              text: "Something went wrong with the account creation in the app. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
        this.register.loader = false;
      }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/login.scss";
</style>