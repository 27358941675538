<template>
  <f7-page login-screen>
    <navbarlist :list="'playing-fields'"></navbarlist>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{ 'club.pitch' | translate }}
              <img class="cursor-pointer" @click="showDialogDeletePlayingField(playingField)" height="14px" src="/static/icons/trash.svg" alt />
            </h4>
          </f7-block>
          <f7-list form no-hairlines-md @submit.prevent="updatePlayingField">
              <f7-list-input
              :label="'club.name' | translate"
              type="text"
              :placeholder="'club.name_pitch' | translate"
              required
              validate
              :error-message="'global.required' | translate"
              :value="playingField.name"
              @input="playingField.name = $event.target.value"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.street' | translate"
              type="text"
              :placeholder="'club.steet' | translate"
              required
              validate
              :error-message="'global.required' | translate"
              :value="playingField.street"
              @input="playingField.street = $event.target.value"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.city' | translate"
              type="text"
              :placeholder="'club.city' | translate"
              required
              validate
              :error-message="'global.required' | translate"
              :value="playingField.city"
              @input="playingField.city = $event.target.value"
            >
            </f7-list-input>
            <f7-block class="text-center">
              <f7-button v-if="!loaderPlayingField" type="submit" raised>
                  {{ 'club.save_pitch' | translate }}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import Navbarlist from "@/components/Navbar/Navbarlist.vue";
import Loader from "@/components/Loader";
import playingFieldsServices from "@/services/PlayingFieldsServices.js"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  components: {
    Navbarlist,
    Loader,
  },
  props: {
    idPlayingField: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      loader: false,
      loaderPlayingField: false,
      playingField: {
        name: '',
        street: '',
        city: ''
      },
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchDataUser().then(() => {
          this.fetchPlayingField(this.idPlayingField)
      })
    }
  },
  methods: {
    async fetchPlayingField(id) {
      try {
        this.loader = true;

        const responseDataPlayingField = await playingFieldsServices.itemPlayingField(id);
        this.playingField = responseDataPlayingField.data
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with the pitch. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    showDialogDeletePlayingField(playingField) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the field ${playingField.name}? The change will be irreversible!`, '', () => {
          this.deletePlayingField(playingField.id)
        });
    },
    async deletePlayingField(id) {
      try {
        const responseDeletePlayingField = await playingFieldsServices.deletePlayingField(id);
        this.$f7router.navigate(`/playing-fields/`)
        this.$f7.toast
          .create({
            text: "The field has been removed!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the removal of the pitch. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
    async updatePlayingField() {
      this.loaderPlayingField = true;
      try {
        const responseUpdatePlayingField = await playingFieldsServices.playingField(this.playingField);
        if ('access' in responseUpdatePlayingField.data && !responseUpdatePlayingField.data.access) {
            this.$f7.toast
            .create({
              text: "Access to the module has expired!",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        } else {
          this.$f7router.navigate(`/playing-fields/`)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the field edit. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderPlayingField = false;
    },
  }
};
</script>