<template>
  <f7-page login-screen class="pagePrivateOutlines">
    <navbarchathistory></navbarchathistory>

    <loader v-if="loader"></loader>

    <div v-else>
        <f7-block class="text-uppercase">
          <h4>{{ 'global.coach_jose_chat_history' | translate}}</h4>
          <div class="d-flex justify-content-start align-items-center">
            <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{ 'books.type' | translate}}: </h4>
            <div class="custom-select">
                  <select name="category" v-model="actualType">
                    <option value="">{{ 'global.all' | translate}}</option>
                    <option v-for="typeBook in typeBooks" :key="typeBook.id" :value="typeBook.id" :selected="typeBook.id == actualType">
                        {{typeBook.label | translate}}
                    </option>
                  </select>
              </div>
          </div>
          <div>
              <f7-input
                :label="'global.search' | translate"
                type="text"
                :placeholder="'global.search' | translate"
                :value="search"
                @input="search = $event.target.value"
                clear-button
                style="margin: 10px 0px 0px 0px ; padding: 10px 0px"
              ></f7-input>
          </div>
          <div class="countAll">
            <span>
              <p>{{ 'base.count' | translate}}</p>
              {{chatHistory.count}}
            </span>
          </div>
        </f7-block>

        <loader v-if="loaderList"></loader>

        <div v-else>
          <div class="listCards" v-if="chatHistory.count > 0">
            <f7-block class="mt-0">
              <f7-row>
                <f7-col v-for="item in chatHistory.results" :key="item.id" width="100" class="position-relative">
                  <a :href="`/#/${item.id}/`">
                  <f7-card class="custom-card">
                    <div>
                      <p>{{item.subject}}</p>
                    </div>
                  </f7-card>
                  </a>
                </f7-col>
              </f7-row>
            </f7-block>
          </div>


        <div v-else class="text-center text-uppercase">
            <img height="100px" src="/static/coach_jose.png" alt />
            <h4>{{ 'global.coach_jose_empty_chat_history' | translate}}!</h4>
          </div>
          
        <f7-block>
          <div class="d-flex justify-content-between aling-items-center custom-pagination-view">
            <div class="pagination-item">
              <div v-if="chatHistory.previous" @click="fetchPrivateExercises(actualType, search, chatHistory.previous)">
                <img class="cursor-pointer" height="30px" src="/static/icons/previous.svg" alt />
              </div>
            </div>
            <div>
              
            </div>
            <div class="pagination-item">
              <div v-if="chatHistory.next" @click="fetchPrivateExercises(actualType, search, chatHistory.next)">
                <img class="cursor-pointer" height="30px" src="/static/icons/next.svg" alt />
              </div>
            </div>
          </div>
        </f7-block>
        </div>

    </div>

  </f7-page>
</template>

<script>
import navbarchathistory from "@/components/Navbar/navbarchathistory.vue";
import Loader from "@/components/Loader";
import CONSTANTS from "@/utils/constants.js"
import joseServices from "@/services/JoseServices.js"
import mixinUser from "@/mixins/mixinUser"
import debounce from 'lodash.debounce'

export default {
  mixins: [mixinUser],
  components: {
    navbarchathistory,
    Loader,
  },
  data: () => {
    return {
      loader: false,
      loaderList: false,
      chatHistory: {},
      actualType: '',
      typeBooks: CONSTANTS.TYPE_BOOKS,
      search: ''
    };
  },
  watch: {
    'actualType': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.fetchChatHistory(this.actualType, this.search)
      }
    },
    'search': debounce(function () {
        // this.fetchChatHistory(this.actualType, this.search)
    }, 1000),
  },
  mounted() {
    this.fetchDataUser().then(() => {
      // this.fetchInitChatHistory(this.actualType, this.search)
    })
  },
  methods: {
      fetchInitChatHistory(category, search) {
        this.loader = true;
        this.fetchChatHistory(category, search)
        this.loader = false;
      },
    async fetchChatHistory(category, search, page) {
      this.loaderList = true;
      try {
        const responseDataChatHistory = await joseServices.listChatHistory(category, search, page);
        this.chatHistory = responseDataChatHistory.data
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the chat history list. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderList = false;
    },
  }
};
</script>