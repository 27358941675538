import groupsServices from "@/services/GroupsServices.js"
import CONSTANTS from "@/utils/constants.js"

export default {
    data: () => {
        return {
          paleteColorsDot: CONSTANTS.COLORS_DOT,
          paleteColorsHighlight: CONSTANTS.COLORS_HIGHLIGHT,
          groups: [],
        };
      },
  methods: {
    async fetchGroups() {
        try {
          this.loader = true;
  
          const responseDataGroups = await groupsServices.listGroups();
          this.groups = responseDataGroups.data
        } catch (error) {
          this.$f7.toast
            .create({
              text: "Something went wrong with the group list. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
        this.loader = false;
      },
  }
}