<template>
  <f7-page login-screen>
    <navbar></navbar>
    <f7-block class="text-center d-flex align-items-center justify-content-center">
      <img height="70px" class="mr-1" src="/static/company/socatots.png" alt />
      <img height="90px" class="ml-1" src="/static/company/bss.png" alt />
    </f7-block>
    <f7-block class="text-center text-uppercase ">
      <h4>Logowanie Trenerzy</h4>
    </f7-block>
    <f7-list form no-hairlines-md @submit.prevent="onLogin">
      <f7-list-input
        label="Email"
        type="email"
        placeholder="Email"
        :value="login.email"
        @input="login.email = $event.target.value"
        required
        validate
        autocomplete="off"
        :error-message="'global.required' | translate"
        clear-button
      >
      </f7-list-input>
      <f7-list-input
        label="Hasło"
        type="password"
        placeholder="Hasło"
        :value="login.password"
        @input="login.password = $event.target.value"
        required
        validate
        autocomplete="off"
        :error-message="'global.required' | translate"
        clear-button
      >
      </f7-list-input>
      <f7-block class="text-center">
        <f7-button v-if="!login.loader" type="submit" raised>Zaloguj się</f7-button>
        <f7-button v-else raised>
          <f7-preloader :size="20" color="white"></f7-preloader>
        </f7-button>
      </f7-block>
    </f7-list>
  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";
import authServices from "@/services/AuthServices.js"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  components: {
    Navbar,
  },
  data: () => {
    return {
      login: {
        email: null,
        password: null,
        loader: false
      }
    };
  },
  methods: {
    async onLogin() {
      
    }
  }
};
</script>