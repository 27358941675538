import playingFieldsServices from "@/services/PlayingFieldsServices.js"

export default {
    data: () => {
        return {
          playingFields: {},
        };
      },
  methods: {
    async fetchPlayingFields() {
      try {
        this.loader = true;

        const responseDataPlayingFields = await playingFieldsServices.listPlayingFields();
        this.playingFields = responseDataPlayingFields.data
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the pitch list. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
  }
}