import Api from './index'
import store from '@/store'

export default {
  async createPlanningTraining(data) {
    return Api().post('planningtrainings/', data, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listPlanningTrainings(group, pagination) {
    let page = '';
    if (pagination) {
      const url = new URL(pagination);
      page= url.search
    }
    return Api().get(`planningtrainings/?group=${group}&${page}`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async itemPlanningTraining(id) {
    return Api().get(`planningtrainings/${id}/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async planningTraining(data) {
    return Api().put(`planningtrainings/${data.id}/`, data, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async deletePlanningTraining(id) {
    return Api().delete(`planningtrainings/${id}/`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async createTraining(data) {
    const training = {
      subject: data.subject,
      group: data.group,
      training_date: data.training_date == '' ? null : data.training_date,
      training_hours_start: data.training_hours_start == '' ? null : data.training_hours_start,
      training_hours_end: data.training_hours_end == '' ? null : data.training_hours_end,
      outline_url: data.outline_url,
      outline_private: data.outline_private,
      notes: data.notes,
      playing_field: data.playing_field == '' ? null : data.playing_field,
    }
    return Api().post('trainings/', training, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listTrainings(group, pagination) {
    let page = '';
    if (pagination) {
      const url = new URL(pagination);
      page= url.search
    }
    return Api().get(`trainings/?group=${group}&${page}`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listGroupTrainings(group) {
    return Api().get(`trainings/group/?group=${group}`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async itemTraining(id) {
    return Api().get(`trainings/${id}/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async training(data) {
    return Api().put(`trainings/${data.id}/`, data, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async deleteTraining(id) {
    return Api().delete(`trainings/${id}/`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listCalendarMonth(data) {
    return Api().get(`calendar/month/?year=${data.year}&month=${data.month}&group=${data.group}`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listCalendarDay(data) {
    return Api().get(`calendar/day/?year=${data.year}&month=${data.month}&day=${data.day}&group=${data.group}`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  }
}