import Api from './index'
import store from '@/store'

export default {
  async addCoach(data) {
    return Api().post('coaches/add/', data, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async coach(data) {
    return Api().put(`coaches/${data.id}/`, data, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async deleteCoach(id) {
    return Api().delete(`coaches/${id}/`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
}