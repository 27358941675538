<template>
  <f7-page login-screen class="pageGenerator">
    <navbar></navbar>

    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'tournament.create' | translate }}</h4>
    </f7-block>
    <f7-block>
      <p class="text-justify">{{ 'tournament.create_description' | translate }}</p>
    </f7-block>

    <f7-list form no-hairlines-md @submit.prevent="prepareTournament">

      <div style="margin: 0 15px;">
        <label class="item-label" for="">{{ 'tournament.logo' | translate }}</label>
        <div v-if="!form.uploadImageLogoClub">
          <div class="wrapperUpload">
            <f7-button class="d-flex" raised small>
                <img height="14px" class="icon" src="/static/icons/upload.svg" alt />
            </f7-button>
            <input type="file" @change="onFileChangeLogoClub" accept="image/*" />
          </div>
        </div>
        <f7-card v-else>
          <div class="logoPreviewWrapper" style="margin-bottom: 10px">
            <f7-button class="close" @click="form.uploadImageLogoClub = null" raised small>
              <img height="8px" src="/static/icons/close.svg" alt />
            </f7-button>
            <img class="logoPreview" :src="form.uploadImageLogoClub">
          </div>
        </f7-card>
      </div>

      <div style="margin: 0 15px;">
        <label class="item-label" for="">{{ 'tournament.brand' | translate }}</label>
        <div v-if="!form.uploadImageLogoOrganization">
          <div class="wrapperUpload">
            <f7-button class="d-flex" raised small>
                <img height="14px" class="icon" src="/static/icons/upload.svg" alt />
            </f7-button>
            <input type="file" @change="onFileChangeLogoOrganization" accept="image/*" />
          </div>
        </div>
        <f7-card v-else>
          <div class="logoPreviewWrapper">
            <f7-button class="close" @click="form.uploadImageLogoOrganization = null" raised small>
              <img height="8px" src="/static/icons/close.svg" alt />
            </f7-button>
            <img class="logoPreview" :src="form.uploadImageLogoOrganization">
          </div>
        </f7-card>
      </div>

      <f7-list-input
        :label="'tournament.title' | translate"
        type="text"
        :placeholder="'tournament.title' | translate"
        :value="form.title"
        @input="form.title = $event.target.value"
        validate
        required
        :error-message="'global.required' | translate"
        clear-button
      >
      </f7-list-input>

      <f7-list-input
        :label="'tournament.description' | translate"
        type="textarea"
        :placeholder="'tournament.description' | translate"
        :value="form.description"
        @input="form.description = $event.target.value"
        validate
        required
        :error-message="'global.required' | translate"
        clear-button
      >
      </f7-list-input>

      <f7-list-input
        :label="'tournament.organizer' | translate"
        type="text"
        :placeholder="'tournament.organizer' | translate"
        :value="form.owner"
        @input="form.owner = $event.target.value"
        validate
        required
        :error-message="'global.required' | translate"
        clear-button
      >
      </f7-list-input>

      <f7-list-input
        :label="'tournament.city' | translate"
        type="text"
        :placeholder="'tournament.city' | translate"
        :value="form.city"
        @input="form.city = $event.target.value"
        validate
        required
        :error-message="'global.required' | translate"
        clear-button
      >
      </f7-list-input>

      <f7-list-input
        :label="'tournament.place' | translate"
        type="text"
        :placeholder="'tournament.place' | translate"
        :value="form.place"
        @input="form.place = $event.target.value"
        validate
        required
        :error-message="'global.required' | translate"
        clear-button
      >
      </f7-list-input>

      <f7-list-input
        :label="'tournament.date' | translate"
        type="date"
        :placeholder="'tournament.date' | translate"
        :value="form.startDate"
        @input="form.startDate = $event.target.value"
        validate
        required
        :error-message="'global.required' | translate"
      >
      </f7-list-input>

      <f7-list-input
        :label="'tournament.day_of_the_week' | translate"
        type="text"
        :placeholder="'tournament.day_of_the_week' | translate"
        :value="form.startDay"
        @input="form.startDay = $event.target.value"
        validate
        required
        :error-message="'global.required' | translate"
      >
      </f7-list-input>

      <f7-list-input
        :label="'tournament.start_time' | translate"
        type="time"
        :placeholder="'tournament.start_time' | translate"
        :value="form.startTime"
        @input="form.startTime = $event.target.value"
        validate
        required
        :error-message="'global.required' | translate"
      >
      </f7-list-input>

      <f7-list-input
        :label="'tournament.number_of_teams' | translate"
        type="number"
        :placeholder="'tournament.number_of_teams' | translate"
        min="2"
        :value="form.countTeams"
        @input="form.countTeams = $event.target.value"
        validate
        required
        :error-message="'global.required' | translate"
      >
      </f7-list-input>

      <f7-list-item class="custom-card-on-list">
        <f7-card class="w-100">
            <div class="extraDataWrapper" style="margin-bottom: 10px">
              <f7-list-input
                v-for="(team, index) in form.teams"
                :key="team.id"
                :label="`Team ${index + 1}`"
                type="text"
                :placeholder="`Team ${index + 1}`"
                :value="team.name"
                @input="team.name = $event.target.value"
              >
              </f7-list-input>
            </div>
        </f7-card>
      </f7-list-item>

      <f7-list-item title="Ilość boisk" class="custom-select">
        <select name="countFields" v-model="form.countFields" @change="updateFields()">
          <option value="1" :selected="1 == form.countFields">{{ 'tournament.field_1' | translate }}</option>
          <option :disabled="form.countTeams <=3" value="2" :selected="2 == form.countFields">{{ 'tournament.field_2' | translate }}</option>
          <option :disabled="form.countTeams <=5" value="3" :selected="3 == form.countFields">{{ 'tournament.field_3' | translate }}</option>
          <option :disabled="form.countTeams <=7" value="4" :selected="4 == form.countFields">{{ 'tournament.field_4' | translate }}</option>
          <option :disabled="form.countTeams <=9" value="5" :selected="5 == form.countFields">{{ 'tournament.field_5' | translate }}</option>
          <option :disabled="form.countTeams <=11" value="6" :selected="6 == form.countFields">{{ 'tournament.field_6' | translate }}</option>
          <option :disabled="form.countTeams <=13" value="7" :selected="7 == form.countFields">{{ 'tournament.field_7' | translate }}</option>
          <option :disabled="form.countTeams <=15" value="8" :selected="8 == form.countFields">{{ 'tournament.field_8' | translate }}</option>
        </select>
      </f7-list-item>

      <f7-list-item class="custom-card-on-list">
        <f7-card class="w-100">
            <div class="extraDataWrapper" style="margin-bottom: 10px">
              <f7-list-input
                v-for="(field, index) in form.fields"
                :key="field.id"
                :label="`Field ${index + 1}`"
                type="text"
                :placeholder="`Field ${index + 1}`"
                :value="field.name"
                @input="field.name = $event.target.value"
              >
              </f7-list-input>
            </div>
        </f7-card>
      </f7-list-item>

      <f7-list-input
        :label="'tournament.match_length' | translate"
        type="number"
        :placeholder="'tournament.match_length' | translate"
        min="1"
        :value="form.matchTime"
        @input="form.matchTime = $event.target.value"
        validate
        required
        :error-message="'global.required' | translate"
      >
      </f7-list-input>

      <f7-list-input
        :label="'tournament.break_between_matches' | translate"
        type="number"
        :placeholder="'tournament.break_between_matches' | translate"
        :value="form.matchBreak"
        min="0"
        @input="form.matchBreak = $event.target.value"
        validate
        required
        :error-message="'global.required' | translate"
      >
      </f7-list-input>

      <f7-block class="text-center">
        <f7-button type="submit" raised>{{ 'tournament.prepare' | translate }}</f7-button>
      </f7-block>

    </f7-list>

    <f7-block v-if="isExport" >
      <p class="text-justify font-weight-bold">{{ 'tournament.export' | translate }}</p>
    </f7-block>

    <div v-if="isExport" id="wrapper-export">
      <div id="wrapper-content" :class="`field-${form.countFields}`">
      <h4>www.soccer-sessions.com</h4>
      <div class="d-flex wrapper-info">
        <div class="logo-club">
           <img height="100px" :src="form.uploadImageLogoClub">
        </div>
        <div>
          <div><span class="title">{{form.title}}</span></div>
          <div><span class="description">{{form.description}}</span></div>
          <div>{{ 'tournament.organizer' | translate }}: <span class="owner">{{form.owner}}</span></div>
          <div>{{ 'tournament.city' | translate }}: <span class="city">{{form.city}}</span></div>
          <div>{{ 'tournament.place' | translate }}: <span class="place">{{form.place}}</span></div>
          <div>{{ 'tournament.when' | translate }}: <span class="date">{{form.startDate}} ({{form.startDay}}) - {{form.startTime}}</span></div>
          <div>{{ 'tournament.match_length' | translate }}: <span class="time">{{form.matchTime}} min</span></div>
          <div>{{ 'tournament.break' | translate }}: <span class="time">{{form.matchBreak}} min</span></div>
        </div>
        <div class="logo-organization">
           <img height="100px" :src="form.uploadImageLogoOrganization">
        </div>
      </div>


          <div class="d-flex wrapper-table">
              <div class="pair-column">
                 <div>
                   <div class="wrapper-pair-border bg-time-0">
                     <span class="pair-border">
                      {{ 'tournament.time' | translate }}
                     </span>
                   </div>
                 </div>
                 <div v-for="(pair, index) in tournamentPairings" :key="pair.id">
                  <div v-if="index % form.countFields == 0" class="wrapper-pair-border bg-time-1">
                    <span class="pair-border">
                      <span v-if="index == 0">{{form.startTime}}</span>
                      <span v-else v-text="renderDateTime(index)"></span>
                    </span>
                  </div>
                 </div>
              </div>
              <div v-if="form.countFields >= 1" class="pair-column">
                <div>
                   <div class="wrapper-pair-border bg-header-0">
                     <span class="pair-border">
                      {{form.fields[0].name}}
                     </span>
                   </div>
                 </div>
                <div v-for="(pair, index) in tournamentPairings" :key="pair.id">
                  <div v-if="index % form.countFields == 0" class="wrapper-pair-border bg-0">
                    <span class="pair-border">
                      {{pair.team1}} - {{pair.team2}}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="form.countFields >= 2" class="pair-column">
                <div>
                   <div class="wrapper-pair-border bg-header-1">
                     <span class="pair-border">
                      {{form.fields[1].name}}
                     </span>
                   </div>
                 </div>
                <div v-for="(pair, index) in tournamentPairings" :key="pair.id">
                  <div v-if="index % form.countFields == 1" class="wrapper-pair-border bg-1">
                    <span class="pair-border">
                      {{pair.team1}} - {{pair.team2}}
                    </span>
                  </div>
                </div>
              </div>
               <div v-if="form.countFields >= 3" class="pair-column">
                 <div>
                   <div class="wrapper-pair-border bg-header-2">
                     <span class="pair-border">
                      {{form.fields[2].name}}
                     </span>
                   </div>
                 </div>
                <div v-for="(pair, index) in tournamentPairings" :key="pair.id">
                  <div v-if="index % form.countFields == 2" class="wrapper-pair-border bg-2">
                    <span class="pair-border">
                      {{pair.team1}} - {{pair.team2}}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="form.countFields >= 4" class="pair-column">
                <div>
                   <div class="wrapper-pair-border bg-header-3">
                     <span class="pair-border">
                      {{form.fields[3].name}}
                     </span>
                   </div>
                 </div>
                <div v-for="(pair, index) in tournamentPairings" :key="pair.id">
                  <div v-if="index % form.countFields == 3" class="wrapper-pair-border bg-3">
                    <span class="pair-border">
                      {{pair.team1}} - {{pair.team2}}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="form.countFields >= 5" class="pair-column">
                <div>
                   <div class="wrapper-pair-border bg-header-4">
                     <span class="pair-border">
                      {{form.fields[4].name}}
                     </span>
                   </div>
                 </div>
                <div v-for="(pair, index) in tournamentPairings" :key="pair.id">
                  <div v-if="index % form.countFields == 4" class="wrapper-pair-border bg-4">
                    <span class="pair-border">
                      {{pair.team1}} - {{pair.team2}}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="form.countFields >= 6" class="pair-column">
                <div>
                   <div class="wrapper-pair-border bg-header-5">
                     <span class="pair-border">
                      {{form.fields[5].name}}
                     </span>
                   </div>
                 </div>
                <div v-for="(pair, index) in tournamentPairings" :key="pair.id">
                  <div v-if="index % form.countFields == 5" class="wrapper-pair-border bg-5">
                    <span class="pair-border">
                      {{pair.team1}} - {{pair.team2}}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="form.countFields >= 7" class="pair-column">
                <div>
                   <div class="wrapper-pair-border bg-header-6">
                     <span class="pair-border">
                      {{form.fields[6].name}}
                     </span>
                   </div>
                 </div>
                <div v-for="(pair, index) in tournamentPairings" :key="pair.id">
                  <div v-if="index % form.countFields == 6" class="wrapper-pair-border bg-6">
                    <span class="pair-border">
                      {{pair.team1}} - {{pair.team2}}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="form.countFields >= 8" class="pair-column">
                <div>
                   <div class="wrapper-pair-border bg-header-7">
                     <span class="pair-border">
                      {{form.fields[7].name}}
                     </span>
                   </div>
                 </div>
                <div v-for="(pair, index) in tournamentPairings" :key="pair.id">
                  <div v-if="index % form.countFields == 7" class="wrapper-pair-border bg-7">
                    <span class="pair-border">
                      {{pair.team1}} - {{pair.team2}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
      <h4>www.soccer-sessions.com</h4>
      </div>
    </div>
    <f7-block>
      <f7-button v-if="isExport" @click="exportTournament" raised>
        {{ 'tournament.save' | translate }}
      </f7-button>
    </f7-block>
    
    <f7-block>
      <p class="text-justify">{{ 'tournament.error' | translate }} <a href="https://soccer-sessions.com/#contact" class="font-weight-bold external" target="_blank">{{ 'global.write_to_us' | translate }}</a>. </p>
    </f7-block>

  </f7-page>
</template>
<script>
import Navbar from "@/components/Navbar/Navbar.vue";
import moment from 'moment';
import html2canvas from 'html2canvas';

export default {
  components: {
    Navbar,
  },
  data: () => {
    return {
      form: {
        teams: [
                {
                  name: 'Team 1'
                },
              ],
              fields: [
                {
                  name: 'Pitch 1'
                },
              ],
              title: '',
              description: '',
              owner: '',
              city: '',
              place: '',
              countFields: 1,
              countTeams: '',
              startDate: '',
              startTime: '',
              startDay: '',
              matchTime: '',
              matchBreak: '',
              uploadImageLogoClub: '',
              uploadImageLogoOrganization: '',
      },
      imageWidth: 0,
      imageHeight: 0,
      allowableTypes: ['jpg', 'jpeg', 'png'],
      maximumSize: 3000000,
      isUpload: false,
      isExport: false,
      tournamentPairings: []
    };
  },
  watch: {
    'form.countTeams': function () {
      this.updateTeams()
    },
    form: {
      handler(val) {
        this.isExport = false
      },
      deep: true
    }
  },
  methods: {
    renderDateTime(index) {
      const dateTime = moment(this.form.startDate + ' ' + this.form.startTime)
      const nextDateTime = dateTime.add(this.form.matchTime * index / this.form.countFields, 'minutes').add(this.form.matchBreak * index / this.form.countFields, 'minutes');
      return moment(nextDateTime).format("HH:mm");
    },
    updateFields() {
      this.form.fields = []
      for (let i = 1; i <= this.form.countFields; i++) {
        this.form.fields.push({ name: `Pitch ${i}` })
      }
    },
    updateTeams() {
      this.form.teams = []
      this.form.countFields = 1;
      this.updateFields();
      for (let i = 1; i <= this.form.countTeams; i++) {
        this.form.teams.push({ name: `Team ${i}` })
      }
    },
    makeRoundRobinPairings(players) {

      if (players.length % 2 == 1) {
        players.push(null);
      }

      const playerCount = players.length;
      const rounds = playerCount - 1;
      const half = playerCount / 2;

      const playerIndexes = players.map((_, i) => i).slice(1);

      for (let round = 0; round < rounds; round++) {
        const roundPairings = [];

        const newPlayerIndexes = [0].concat(playerIndexes);

        const firstHalf = newPlayerIndexes.slice(0, half);
        const secondHalf = newPlayerIndexes.slice(half, playerCount).reverse();

        for (let i = 0; i < firstHalf.length; i++) {
          if (players[firstHalf[i]] && players[secondHalf[i]]) {
            roundPairings.push({
              team1: players[firstHalf[i]],
              team2: players[secondHalf[i]],
            });
          }
        }

        // rotating the array
        playerIndexes.push(playerIndexes.shift());
        roundPairings.forEach((round) => {
          this.tournamentPairings.push(round)
        })
      }
    },
    prepareTournament() {
        this.tournamentPairings = []
        this.isExport = true;
        // clean structure
        let teams = []
        this.form.teams.forEach((elem) => {
          teams.push(elem.name)
        })
        this.makeRoundRobinPairings(teams)
    },
    exportTournament() {
      html2canvas(document.getElementById('wrapper-content'), { allowTaint: true, scale: 2}).then(canvas => {
          const a = document.createElement('a');
          a.href = canvas.toDataURL("image/png");
          a.classList.add('external');
          a.download = 'app.soccer-sessions.com_create_tournament.png';
          a.click();
          this.isExport = false;
        });
    },
    onFileChangeLogoClub(e) {
      this.form.uploadImageLogoClub = ''

      if (!this.allowableTypes.includes(e.target.files[0].name.split('.').pop().toLowerCase())) {
          this.$f7.toast.create({
              text: 'Invalid file format. Acceptable file formats are: JPG, JPEG, PNG!',
              position: 'bottom',
              closeTimeout: 3000,
          }).open();
          this.isUpload = false;
          return false
      }
      if (e.target.files[0].size > this.maximumSize) {
          this.$f7.toast.create({
              text: 'The file is too heavy. The maximum size of the file is: 3 MB!',
              position: 'bottom',
              closeTimeout: 3000,
          }).open();
          this.isUpload = false;
          return false
      }

      if (e.target.files && e.target.files[0]) {

          const reader = new FileReader();
          reader.onload = (e) => {

            this.form.uploadImageLogoClub = e.target.result;
            this.isUpload = true;
          }
          reader.readAsDataURL(e.target.files[0]);
      }
    },
    onFileChangeLogoOrganization(e) {
      this.form.uploadImageLogoOrganization = ''

      if (!this.allowableTypes.includes(e.target.files[0].name.split('.').pop().toLowerCase())) {
          this.$f7.toast.create({
              text: 'Invalid file format. Acceptable file formats are: JPG, JPEG, PNG!',
              position: 'bottom',
              closeTimeout: 3000,
          }).open();
          this.isUpload = false;
          return false
      }
      if (e.target.files[0].size > this.maximumSize) {
          this.$f7.toast.create({
              text: 'The file is too heavy. The maximum size of the file is: 3 MB!',
              position: 'bottom',
              closeTimeout: 3000,
          }).open();
          this.isUpload = false;
          return false
      }

      if (e.target.files && e.target.files[0]) {

          const reader = new FileReader();
          reader.onload = (e) => {

            this.form.uploadImageLogoOrganization = e.target.result;
            this.isUpload = true;
          }
          reader.readAsDataURL(e.target.files[0]);
      }
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/generator.scss";
</style>