<template>
  <f7-page login-screen>
    <navbar></navbar>

    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'base.your_base' | translate}}</h4>
    </f7-block>
    <f7-block class="menuPage">
        <f7-row>
          <f7-col width="50">
            <a href="/private-outlines/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/football-ball.svg" alt />
                  <p>{{ 'base.your_outlines' | translate}}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/private-exercises/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/football-ball-2.svg" alt />
                  <p>{{ 'base.your_exercises' | translate}}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/private-microcycles/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/football-ball-3.svg" alt />
                  <p>{{ 'base.your_microcycles' | translate}}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/private-outlines-categories/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/category.svg" alt />
                  <p>{{ 'base.category_outlines' | translate}}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/private-exercises-categories/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/category-2.svg" alt />
                  <p>{{ 'base.category_exercises' | translate}}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/private-microcycles-categories/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/category-3.svg" alt />
                  <p>{{ 'base.category_microcycles' | translate}}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
        </f7-row>
    </f7-block>

  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>

<style lang="scss">
</style>