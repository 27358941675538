<template>
  <f7-page login-screen>
    <navbar></navbar>
    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'global.about' | translate }}</h4>
    </f7-block>
     <f7-block class="menuPage">
        <f7-row>
          <f7-col width="50">
            <a href="https://soccer-sessions.com/#contact" class="external" target="_blank">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/ball.svg" alt />
                  <p>{{ 'global.contact' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/coaching-notepad/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/goal-2.svg" alt />
                  <p>{{ 'global.coaching_notepad' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/recommendations/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/award.svg" alt />
                  <p>{{ 'global.recommendations' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/partners/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/goal.svg" alt />
                  <p>{{ 'global.partners' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/authors/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/gloves.svg" alt />
                  <p>{{ 'global.authors' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <!-- <f7-col width="50">
            <a href="/history/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/stopwatch.svg" alt />
                  <p>Historia</p>
                </div>
              </f7-card>
            </a>
          </f7-col> -->
        </f7-row>
     </f7-block>
    <f7-block class="text-center">
      <img style="width: 70%" src="/static/logo-soccer-sessions-bg.svg" alt />
    </f7-block>
    <f7-block class="text-justify">
      <p><strong>Soccer Sessions</strong> {{ 'global.soccer_sessions_about_1' | translate }}</p>
      <p>{{ 'global.soccer_sessions_about_2' | translate }}</p>
      <p>{{ 'global.soccer_sessions_about_3' | translate }}</p>
      <p>{{ 'global.soccer_sessions_about_4' | translate }}</p>
    </f7-block>
  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>

<style lang="scss">
</style>