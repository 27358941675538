export default {
    data () {
        return {
            zoom: 1,
            accordion: {
                introductory_part_1: true,
                introductory_part_2: true,
                main_part_1: true,
                main_part_2: true,
                final_part: true
            },
            previewMode: false,
            markEmail: '',
        }
    },
  mounted() {
    window.addEventListener('resize', this.eventOrientationChange, false);
  },
  destroyed() {
    window.removeEventListener('resize', this.eventOrientationChange, false);
  },
  methods: {
    initWaterMark() {
        const overlayText = this.markEmail;
        const watermarkDiv = document.getElementById('mark');
        const viewport = document.getElementById('outlineViewport');
        const viewportWidth = viewport.offsetWidth;
        const viewportHeight = viewport.offsetHeight;
    
        const textWidth = overlayText.length * 60;
        const textHeight = 350;
    
        const repetitionsX = Math.ceil(viewportWidth / textWidth);
        const repetitionsY = Math.ceil(viewportHeight / textHeight);
    
        for (let i = 0; i < repetitionsX; i++) {
            for (let j = 0; j < repetitionsY; j++) {
                const span = document.createElement('span');
                span.classList.add('mark-text');
                span.innerText = overlayText;
                span.style.left = `${i * textWidth}px`;
                span.style.top = `${j * textHeight}px`;
                watermarkDiv.appendChild(span);
            }
        }
    },
    resetZoomed() {
        this.zoom = 1;
        document.getElementById('outlineViewport').style.transform = `none`;
        document.getElementById('outlineViewport').style.margin = `0 auto`;
      },
    eventOrientationChange() {
        this.resetZoomed()
    },
    collapsed(part) {
        this.accordion[part] = !this.accordion[part]
    },
    resetCollapsed() {
        this.accordion.introductory_part_1 = true;
        this.accordion.introductory_part_2 = true;
        this.accordion.main_part_1 = true;
        this.accordion.main_part_2 = true;
        this.accordion.final_part = true;
    },
    printPDF() {
        this.resetZoomed();
        this.resetCollapsed();
        if (this.previewMode) {
            this.changePreviewMode()
        }
        document.title = '';
        setTimeout(() => {
            window.print();
        }, 500);
    },
    zoomPlus() {
        if (this.zoom >= 1) return
        this.zoom += 0.1;
        this.refreshZoom()
    },
    zoomMinus() {
        if (this.zoom <= 0.4) return
        this.zoom -= 0.1;
        this.refreshZoom()
    },
    changePreviewMode() {
        this.resetZoomed()
        this.previewMode = !this.previewMode
    },
    refreshZoom() {
        this.isEdit = false
        const outlineViewport = document.getElementById('outlineViewport')
        if (outlineViewport) {
            outlineViewport.style.transform = `scale(${this.zoom})`;
            if (window.innerWidth <= 780) {
                outlineViewport.style.transformOrigin = `0 0`;
            } else {
                outlineViewport.style.transformOrigin = `center 0`;
            }
            setTimeout(() => {
                const heightOnTopOutlineViewport = outlineViewport.offsetHeight;
                outlineViewport.style.marginBottom = `-${heightOnTopOutlineViewport - (heightOnTopOutlineViewport * this.zoom)}px`;
            }, 0);
        }
    },
   }
}