<template>
  <f7-page login-screen>
    <navbarcalendarcrm></navbarcalendarcrm>

    <loader v-if="loader"></loader>

    <div v-else>
        <f7-block class="text-uppercase">
          <h4>{{ 'club.players' | translate}} <f7-link class="p-1" :tooltip="'popover.players' | translate"><img height="11px" src="/static/icons/help.svg" alt /></f7-link></h4>
          <div class="d-flex justify-content-start align-items-center">
            <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{ 'club.group' | translate }}: </h4>
            <div class="custom-select">
                  <select name="group" v-model="actualGroup" @change="changeGroup(actualGroup)">
                      <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == actualGroup">
                          {{group.name}}
                      </option>
                  </select>
              </div>
          </div>
          <f7-button v-if="!groups.length > 0" href="/groups/" raised>{{ 'club.need_create_group' | translate }}!</f7-button>
          <div class="countAll">
            <span>
              <p>{{ 'club.count' | translate}}</p>
              <template v-if="players.count">
                {{players.count}}
              </template>
              <template v-else>
                0
              </template>
            </span>
          </div>
        </f7-block>
        <div class="listCards" v-if="players.count > 0">
          <f7-block class="mt-0">
            <f7-row>
              <f7-col v-for="player in players.results" :key="player.id" width="100" class="position-relative">
                <div class="delete" @click.stop="showDialogDeletePlayer(player)">
                  <img height="20px" src="/static/icons/trash.svg" alt />
                </div>
                <a :href="`/players/${player.id}/`">
                <f7-card class="custom-card">
                  <div>
                    <p>
                      <f7-link v-if="player.status_active" :tooltip="'club.status_active' | translate">
                        <div style="width: 10px; height: 10px; background-color: green; border-radius: 50%;"></div>
                      </f7-link>
                      <f7-link v-else :tooltip="'club.status_not_active' | translate">
                        <div style="width: 10px; height: 10px; background-color: red; border-radius: 50%;"></div>
                      </f7-link>
                        {{player.first_name}} {{player.last_name}}
                    </p>
                    <p class="info" v-if="player.group && groups.length > 0">
                      <span v-if="player.additional_groups.length > 0">{{ 'club.group_main' | translate }}</span><span v-else>{{ 'club.group' | translate }}</span>: {{groups.find(obj => obj.id == player.group).name}}</p>
                    <p class="info" v-if="player.additional_groups.length > 0 && groups.length > 0">{{ 'club.additional_groups' | translate }}: 
                      <span v-for="groupId in player.additional_groups" :key="groupId">{{ groups.find(obj => obj.id === groupId).name }}<span v-if="!isLastItem(player.additional_groups, groupId)">, </span>
                    </span>
                    
                    </p>
                    <p class="info" v-if="player.date_of_birth">{{ 'club.date_of_birth' | translate }}: {{player.date_of_birth}}</p>
                    <f7-link v-if="player.group && groups.length > 0" :tooltip="`Main group color: ${paleteColorsDot[groups.find(obj => obj.id == player.group).color].label}`" class="color" :style="{background : paleteColorsDot[groups.find(obj => obj.id == player.group).color].value}"></f7-link>
                    <f7-link v-if="player.phone !== null && player.phone !== ''" :tooltip="'club.has_a_telephone_number' | translate" class="phone">
                      <img height="20px" src="/static/icons/phone.svg" alt />
                    </f7-link>
                  </div>
                </f7-card>
                </a>
              </f7-col>
              
            </f7-row>
          </f7-block>
        </div>

        <div v-else class="text-center text-uppercase">
            <img height="100px" src="/static/icons/player.svg" alt />
            <h4>{{ 'club.empty_players_group' | translate }}!</h4>
        </div>

        <f7-block>
          <div class="d-flex justify-content-between aling-items-center custom-pagination-view">
            <div class="pagination-item">
              <div v-if="players.previous" @click="fetchPlayers(actualGroup, players.previous)">
                <img class="cursor-pointer" height="30px" src="/static/icons/previous.svg" alt />
              </div>
            </div>
            <div class="pagination-item">
              <a :href="`/create-player/?group=${actualGroup}`">
                <img class="cursor-pointer" height="30px" src="/static/icons/plus.svg" alt="">
              </a>
            </div>
            <div class="pagination-item">
              <div v-if="players.next" @click="fetchPlayers(actualGroup, players.next)">
                <img class="cursor-pointer" height="30px" src="/static/icons/next.svg" alt />
              </div>
            </div>
          </div>
        </f7-block>
    </div>

    <div class="custom-fab">
        <div class="box box-zoom cursor-pointer" @click="goToCreate()">
            <f7-link :tooltip="'global.add_new' | translate" >
              <img height="20px" src="/static/icons/plus-add.svg" alt="">
            </f7-link>
        </div>
      </div>

  </f7-page>
</template>

<script>
import Navbarcalendarcrm from "@/components/Navbar/Navbarcalendarcrm.vue";
import Loader from "@/components/Loader";
import mixinPlayers from "@/mixins/mixinPlayers"
import mixinUser from "@/mixins/mixinUser"
import mixinGroups from "@/mixins/mixinGroups"
import groupsServices from "@/services/GroupsServices.js"

export default {
  mixins: [mixinPlayers, mixinGroups, mixinUser],
  components: {
    Navbarcalendarcrm,
    Loader
  },
  data: () => {
    return {
      loader: false,
      actualGroup: '',
    };
  },
  watch: {
    'actualGroup': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchPlayers(this.actualGroup)
      }
    },
  },
  mounted() {
    this.fetchDataUser().then(() => {
        this.fetchGroups().then(() => {
            if (this.getRole == 'coach') {
              this.groups = this.profileGroups;
            }
            if (this.groups.length > 0) {
              if (this.$f7route.query.group !== undefined) {
                this.actualGroup = this.$f7route.query.group
              } else {
                const checkGroup = obj => obj.id === this.getActiveGroup;
                if (this.getActiveGroup !== '' && this.groups.some(checkGroup)) {
                  this.actualGroup = this.getActiveGroup
                } else {
                  this.actualGroup = this.groups[0].id
                }
              }
            }
        })
    })
  },
  methods: {
    isLastItem(array, item) {
        return array.indexOf(item) === array.length - 1;
    },
    goToCreate() {
      this.$f7router.navigate(`/create-player/?group=${this.actualGroup}`)
    },
    showDialogDeletePlayer(player) {
        this.$f7.dialog.confirm(`Are you sure you want to remove the player ${player.first_name} ${player.last_name}? By removing a player, you will also remove all his presences, contributions, research. The change will be irreversible!`, '', () => {
          this.deletePlayer(player.id)
        });
    },
    async deletePlayer(id) {
      try {
        const responseDeletePlayer = await groupsServices.deletePlayer(id);
        this.fetchPlayers(this.actualGroup);
        this.$f7.toast
          .create({
            text: "The contestant has been removed!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with removing a player. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  }
};
</script>