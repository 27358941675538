<template>
  <f7-page login-screen>
    <f7-navbar class="custom-navbar">
      <f7-nav-left>
        <f7-link href="/">
          <img class="logo" src="/static/logo-soccer-sessions-white.svg" style="max-height: 20px;" />
        </f7-link>
      </f7-nav-left>
      <f7-nav-right>
          <f7-link href="https://www.facebook.com/SoccerSessionsOfficial" external target="_blank">
            <img height="20px" style="margin-left: 0px" src="/static/icons/facebook.svg" alt="">
          </f7-link>
        <f7-link panel-open="left"><img height="20px" src="/static/icons/menu.svg" alt style="margin-left: 0px;"></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <div v-if="getRole == 'books' || getRole == null" class="linkImage">
      
      <img v-if="getLang == 'pl'"
        class="imageFull" 
        src="/static/slider_app_home_new.png">
        <img v-if="getLang == 'en'"
        class="imageFull" 
        src="/static/slider_app_home_new_ang.png">
    </div>

    <div v-if="getRole == 'coach'" class="linkImage">
      <img v-if="getLang == 'pl'"
        class="imageFull" 
        src="/static/slider_app_role_coach_new.png">
        <img v-if="getLang == 'en'"
        class="imageFull" 
        src="/static/slider_app_role_coach_new_ang.png">
    </div>

    <div v-if="getRole == 'owner'" class="linkImage">
      <img v-if="getLang == 'pl'"
        class="imageFull" 
        src="/static/slider_app_role_club_new.png">
        <img v-if="getLang == 'en'"
        class="imageFull" 
        src="/static/slider_app_role_club_new_ang.png">
    </div>

    <div v-if="getRole == 'player'" class="linkImage">
      <img v-if="getLang == 'pl'"
        class="imageFull" 
        src="/static/slider_app_role_player_new.png">
              <img v-if="getLang == 'en'"
        class="imageFull" 
        src="/static/slider_app_role_player_new_ang.png">
    </div>

    <div v-if="getRole == 'partner'" class="linkImage">
      <img v-if="getLang == 'pl'"
        class="imageFull" 
        src="/static/slider_app_role_partner_new.png">
        <img v-if="getLang == 'en'"
        class="imageFull" 
        src="/static/slider_app_role_partner_new_ang.png">
    </div>

    <f7-block class="menuPage">
      <div v-if="(getRole == 'books' || getRole == null)">
        <h4 class="text-uppercase font-size-18"><img height="22px" src="/static/icons/books-2.svg" alt /> {{ 'global.publication_zone' | translate }}</h4>
          <f7-row>
            <f7-col width="50">
              <a href="/books/">
                <f7-card class="custom-card">
                  <div>
                    <img height="40px" src="/static/icons/football-2.svg" alt />
                    <p>{{ 'global.publications' | translate }}</p>
                  </div>
                </f7-card>
              </a>
            </f7-col>
            <f7-col v-if="isAuthenticated" width="50">
              <a href="/favorites/">
                <f7-card class="custom-card">
                  <div>
                    <img height="40px" src="/static/icons/star.svg" alt />
                    <p>{{ 'global.favorite' | translate }}</p>
                  </div>
                </f7-card>
              </a>
            </f7-col>
            <f7-col width="50">
              <a href="/coach-jose/">
                <f7-card class="custom-card">
                  <div>
                    <img height="35px" src="/static/icons/cv.svg" alt />
                    <p>{{ 'global.coach_jose' | translate }}</p>
                  </div>
                </f7-card>
              </a>
            </f7-col>
            <f7-col width="50">
              <a href="/library/">
                <f7-card class="custom-card">
                  <div>
                    <img height="33px" src="/static/icons/library.svg" alt />
                    <p>{{ 'global.library' | translate }}</p>
                  </div>
                </f7-card>
              </a>
            </f7-col>
            <f7-col width="50">
              <a href="https://pdf.asystent-trenera.pl/" class="external" target="_blank">
                <f7-card class="custom-card">
                  <div>
                    <img height="33px" src="/static/icons/magazine.svg" alt />
                    <p>{{ 'global.coaching_magazines' | translate }}</p>
                  </div>
                </f7-card>
              </a>
            </f7-col>
            <f7-col v-if="isAuthenticated" width="50">
              <a href="/database/">
                <f7-card class="custom-card">
                  <div>
                    <img height="40px" src="/static/icons/database.svg" alt />
                    <p>{{ 'global.your_base' | translate }}</p>
                  </div>
                </f7-card>
              </a>
            </f7-col>
            <f7-col v-if="isAuthenticated" width="50">
              <a href="/account/">
                <f7-card class="custom-card">
                  <div>
                    <img height="40px" src="/static/icons/shirt.svg" alt />
                    <p>{{ 'global.account' | translate }}</p>
                  </div>
                </f7-card>
              </a>
            </f7-col>
            <f7-col v-if="!isAuthenticated || !isSubscription" width="50">
              <a href="/payments/">
                <f7-card class="custom-card">
                  <div>
                    <img height="40px" src="/static/icons/tv.svg" alt />
                    <p>{{ 'global.buy_access' | translate }}</p>
                  </div>
                </f7-card>
              </a>
            </f7-col>
            <f7-col v-if="!isAuthenticated || !isSubscription" width="50">
              <a href="/best-coach/">
                <f7-card class="custom-card">
                  <div>
                    <img height="40px" src="/static/icons/best-coach.svg" alt />
                    <p>{{ 'best_coach.what_is_sub' | translate }}</p>
                  </div>
                </f7-card>
              </a>
            </f7-col>
          </f7-row>
      </div>
       <div v-if="isAuthenticated && (getRole == 'coach' || getRole == 'owner')">
        <h4 class="text-uppercase font-size-18"><img height="22px" src="/static/icons/coach.svg" alt />
          <span v-if="getRole == 'owner'">{{ 'global.club_zone' | translate }}</span>
          <span v-if="getRole == 'coach'">{{ 'global.coach_zone' | translate }}</span>
        </h4>
        <f7-row>
          <f7-col width="50">
            <a href="/crm/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/sport.svg" alt />
                  <p>{{ 'global.crm' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/sms/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/sms.svg" alt />
                  <p>{{ 'global.sms' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/calendar/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/calendar-2.svg" alt />
                  <p>{{ 'global.calendar' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/stats/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/stats.svg" alt />
                  <p>{{ 'global.statistics' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col v-if="getRole == 'owner'" width="50">
            <a href="/settings/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/flag.svg" alt />
                  <p>{{ 'global.settings' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col v-if="isAuthenticated" width="50">
              <a href="/account/">
                <f7-card class="custom-card">
                  <div>
                    <img height="40px" src="/static/icons/shirt.svg" alt />
                    <p>{{ 'global.account' | translate }}</p>
                  </div>
                </f7-card>
              </a>
            </f7-col>
        </f7-row>
      </div>
      <div v-if="isAuthenticated && (getRole == 'partner')">
        <h4 class="text-uppercase font-size-18"><img height="22px" src="/static/icons/coach.svg" alt /> {{ 'global.partner_zone' | translate }}</h4>
        <f7-row>
          <f7-col width="50">
            <a href="/partner-wallet/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/dues.svg" alt />
                  <p>{{ 'global.wallet' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/partner-faq/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/whistle.svg" alt />
                  <p>{{ 'global.faq_partner' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col v-if="isAuthenticated" width="50">
            <a href="/account/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/shirt.svg" alt />
                  <p>{{ 'global.account' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
        </f7-row>
      </div>
      <div v-if="isAuthenticated && (getRole == 'player')">
        <h4 class="text-uppercase font-size-18"><img height="22px" src="/static/icons/player-2.svg" alt /> {{ 'global.player_zone' | translate }}</h4>
        <f7-row>
          <f7-col width="50">
            <a href="/player/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/player.svg" alt />
                  <p>{{ 'global.player' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/player-timetable/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/timetable.svg" alt />
                  <p>{{ 'global.information_board' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/player-attendance/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/stats.svg" alt />
                  <p>{{ 'global.presense' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/player-dues/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/dues.svg" alt />
                  <p>{{ 'global.dues' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/player-diet/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/food.svg" alt />
                  <p>{{ 'global.diet' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/player-training/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/custom-sport.svg" alt />
                  <p>{{ 'global.player_training' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col v-if="isAuthenticated" width="50">
            <a href="/account/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/shirt.svg" alt />
                  <p>{{ 'global.account' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
        </f7-row>
      </div>
      <div v-if="getRole == 'books' || getRole == null">

      <h4 class="text-uppercase font-size-18"><img height="22px" src="/static/icons/app.svg" alt /> {{ 'global.app_zone' | translate }}</h4>
        <f7-row>
          <f7-col width="50">
            <a href="/create-outline/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/strategy.svg" alt />
                  <p>{{ 'global.create_an_outline' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/tactic-board/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/field.svg" alt />
                  <p>{{ 'global.tactical_board' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/coach-block-generator/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/scoreboard.svg" alt />
                  <p>{{ 'global.block_generator' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/create-tournament/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/trophy.svg" alt />
                  <p>{{ 'global.create_a_tournament' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
        </f7-row>
      </div>
      <div v-if="getRole == 'books' || getRole == null">
        <h4 class="text-uppercase font-size-18"><img height="22px" src="/static/icons/information.svg" alt /> {{ 'global.information_zone' | translate }}</h4>
        <f7-row>
          <f7-col width="50">
            <a href="/about/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/boot.svg" alt />
                  <p>{{ 'global.about' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/faq/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/whistle.svg" alt />
                  <p>{{ 'global.faq' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="https://soccer-sessions.com/regulations" class="external" target="_blank">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/football-shield.svg" alt />
                  <p>{{ 'global.regulations' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="https://soccer-sessions.com/privacy-policy" class="external" target="_blank">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/football.svg" alt />
                  <p>{{ 'global.privacy_policy' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
        </f7-row>
        </div>
    </f7-block>
  </f7-page>
</template>

<script>
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  data: () => {
    return {
      countBackKeyDown: 0,
    };
  },
  mounted() {
    this.countBackKeyDown = 0;
    document.addEventListener("deviceready", this.onDeviceReady, false);
    if (this.isAuthenticated) {
      this.fetchDataUser()
    }
  },
  methods: {
    onDeviceReady() {
      document.addEventListener("backbutton", this.onBackKeyDown, false);
    },
    onBackKeyDown() {
      e.preventDefault();
      this.countBackKeyDown++;
      if (this.$f7router.url == '/home/' && this.countBackKeyDown > 1) {
        window.navigator.app.exitApp();
      }
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/all.scss";
@import "@/assets/scss/pages/home.scss";
@import "@/assets/scss/card.scss";
@import "@/assets/scss/navbar.scss";
</style>