import Api from './index'
import store from '@/store'

export default {
  async listPrivateExercises(category, search, pagination) {
    let page = '';
    if (pagination) {
      const url = new URL(pagination);
      page= url.search
    }
    return Api().get(`privateexercises/?category=${category}&search=${search}&${page}`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async itemPrivateExercise(id) {
    return Api().get(`privateexercises/${id}/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listAllPrivateExercises() {
    return Api().get(`privateexercises/list/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async deletePrivateExercise(id) {
    return Api().delete(`privateexercises/${id}/`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async createPrivateExercise(data) {
    const exercise = {
      subject: data.subject,
      objective: data.objective,
      coaching: data.coaching,
      age_category: data.age_category,
      part: data.part,
      category: data.category,
      images: data.images,
      type_copied: data.type_copied,
      id_book: data.id_book !== undefined ? data.id_book : undefined,
      demo: data.demo !== undefined ? data.demo : undefined,
    }
    return Api().post('privateexercises/', exercise ,{
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async privateExercise(data) {
    const exercise = {
      subject: data.subject,
      objective: data.objective,
      coaching: data.coaching,
      age_category: data.age_category,
      part: data.part,
      category: data.category,
      images: data.images,
      type_copied: data.type_copied,
    }
    return Api().put(`privateexercises/${data.id}/`, exercise, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listCategories(pagination) {
    let page = '';
    if (pagination) {
      const url = new URL(pagination);
      page= url.search
    }
    return Api().get(`privateexercises/categories/${page}`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listAllCategories() {
    return Api().get('privateexercises/categories/all/', {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async itemCategory(id) {
    return Api().get(`privateexercises/categories/${id}/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async category(data) {
    return Api().put(`privateexercises/categories/${data.id}/`, data, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async deleteCategory(id) {
    return Api().delete(`privateexercises/categories/${id}/`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async createCategory(data) {
    return Api().post('privateexercises/categories/', data, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
}