<template>
  <f7-page login-screen>
    <navbarcrm></navbarcrm>
    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-uppercase">
            <h4>{{ 'club.information_board_page' | translate}} <f7-link class="p-1" :tooltip="'popover.information_board_page' | translate"><img height="11px" src="/static/icons/help.svg" alt /></f7-link></h4>
            <div class="d-flex justify-content-start align-items-center">
                <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{ 'club.group' | translate }}: </h4>
                <div class="custom-select">
                    <select name="group" v-model="actualGroup" @change="changeGroup(actualGroup)">
                        <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == actualGroup">
                            {{group.name}}
                        </option>
                    </select>
                </div>
            </div>  
            <f7-button v-if="!groups.length > 0" href="/groups/" raised>{{ 'club.need_create_group' | translate }}!</f7-button>
          </f7-block>
          <f7-block>
            <div>
              <p class="font-weight-bold text-uppercase">{{ 'club.group_link' | translate }}
                <img @click="setClipboard(`${hostApp}/#!/timetable-public/?public=${settingsTimetable.public_key}&group=${actualGroup}`)" class="cursor-pointer" height="15px" style="margin-left: 0px" src="/static/icons/copy.svg" alt />
              </p>
            </div>
            <div class="wrapperCode text-center">
              <code>
                {{`${hostApp}/#!/timetable-public/?public=${settingsTimetable.public_key}&group=${actualGroup}`}}
              </code>
            </div>
            <div>
              <p class="font-weight-bold text-uppercase">{{ 'club.embedded_board' | translate }}
                <img @click='setClipboard(`&lt;iframe src="${hostApp}/#!/timetable-public/?public=${settingsTimetable.public_key}&group=${actualGroup}" width="100%" height="800px"&gt;&lt;/iframe&gt;`)' class="cursor-pointer" height="15px" style="margin-left: 0px" src="/static/icons/copy.svg" alt />
              </p>
            </div>
            <div class="wrapperCode text-center">
              <code>&lt;iframe src="{{`${hostApp}/#!/timetable-public/?public=${settingsTimetable.public_key}&group=${actualGroup}`}}"
              width="100%" height="800px"&gt;&lt;/iframe&gt;</code>
            </div>
          </f7-block>
          <!-- <div v-if="getRole == 'owner'">
            <f7-block class="text-uppercase">
              <h4>{{ 'club.board_settings' | translate }}</h4>
            </f7-block>
            <f7-block>
              <p>{{ 'club.board_settings_description' | translate }}</p>
            </f7-block>
            <f7-list form no-hairlines-md @submit.prevent="onUpdateSettingsTimetable">
              <f7-list-input
                :label="'club.bank_account_number' | translate"
                type="text"
                :placeholder="'club.bank_account_number' | translate"
                :value="settingsTimetable.account_number"
                @input="settingsTimetable.account_number = $event.target.value"
                clear-button
              >
              </f7-list-input>
              <f7-list-input
                class="height-textarea-300"
                :label="'club.description_to_the_account_number' | translate"
                type="textarea"
                :placeholder="'club.description_to_the_account_number' | translate"
                :value="settingsTimetable.account_number_description"
                @input="settingsTimetable.account_number_description = $event.target.value"
                clear-button
              >
              </f7-list-input>
              <f7-list-input
                :label="'club.facebook' | translate"
                type="text"
                :placeholder="'club.facebook' | translate"
                :value="settingsTimetable.fb_fanpage"
                @input="settingsTimetable.fb_fanpage = $event.target.value"
                clear-button
              >
              </f7-list-input>
              <f7-list-input
                :label="'club.instagram' | translate"
                type="text"
                :placeholder="'club.instagram' | translate"
                :value="settingsTimetable.instagram"
                @input="settingsTimetable.instagram = $event.target.value"
                clear-button
              >
              </f7-list-input>
              <f7-list-input
                :label="'club.youtube' | translate"
                type="text"
                :placeholder="'club.youtube' | translate"
                :value="settingsTimetable.youtube"
                @input="settingsTimetable.youtube = $event.target.value"
                clear-button
              >
              </f7-list-input>
              <f7-block class="text-center">
                <f7-button v-if="!settingsTimetable.loader" type="submit" raised>{{ 'club.save_settings' | translate }}</f7-button>
                <f7-button v-else raised>
                  <f7-preloader :size="20" color="white"></f7-preloader>
                </f7-button>
              </f7-block>
            </f7-list>
          </div> -->
    </div>
  </f7-page>
</template>

<script>
import Navbarcrm from "@/components/Navbar/Navbarcrm.vue";
import Loader from "@/components/Loader";
import mixinGroups from "@/mixins/mixinGroups"
import mixinUser from "@/mixins/mixinUser"
import timetableServices from "@/services/TimetableServices"

export default {
  mixins: [mixinGroups, mixinUser],
  components: {
    Navbarcrm,
    Loader
  },
  data: () => {
    return {
      loader: false,
      actualGroup: '',
      groups: [],
      settingsTimetable: {
        loader: false,
      }
    };
  },
  mounted() {
    this.fetchDataUser().then(() => {
        this.fetchGroups().then(() => {
          if (this.getRole == 'coach') {
            this.groups = this.profileGroups;
          }
          if (this.groups.length > 0) {
            const checkGroup = obj => obj.id === this.getActiveGroup;
            if (this.getActiveGroup !== '' && this.groups.some(checkGroup)) {
              this.actualGroup = this.getActiveGroup
            } else {
              this.actualGroup = this.groups[0].id
            }
          }
        })
        this.fetchSettingsTimetable();
    })
  },
  methods: {
    setClipboard(value) {
        const tempInput = document.createElement("input");
        tempInput.style = "position: absolute; left: -1000px; top: -1000px";
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        this.$f7.toast.create({
            text: 'Code copied to clipboard',
            position: 'bottom',
            closeTimeout: 3000,
        }).open();
    },
    async fetchSettingsTimetable() {
      try {
        this.loader = true;

        const responseDataSettingsTimetable = await timetableServices.settingsTimetable();
        if (responseDataSettingsTimetable.data.length > 0) {
          this.settingsTimetable = responseDataSettingsTimetable.data[0]
        } else {
          this.onCreateSettingsTimetable()
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the information board setting. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
    async onCreateSettingsTimetable() {
      try {
        this.loader = true;
        const data = {
          public_key: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10),
          title: 'Ustawienia tablica informacyjna',
        }
        const responseDataCreateSettingsTimetable = await timetableServices.createSettingsTimetable(data);
        this.settingsTimetable = responseDataCreateSettingsTimetable.data
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong creating the settings for the tactical board. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
    async onUpdateSettingsTimetable() {
      try {
        this.settingsTimetable.loader = true;
        this.settingsTimetable.title = `Ustawienia tablica informacyjna ${this.userId}`
        const responseDetailSettingsTimetable = await timetableServices.settingTimetable(this.settingsTimetable);
        this.$f7.toast.create({
            text: 'The data has been updated',
            position: 'bottom',
            closeTimeout: 1000,
        }).open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong updating the settings to the tactical board. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.settingsTimetable.loader = false;
    },
  }
};
</script>

<style lang="scss">
  
</style>