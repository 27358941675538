<template>
  <f7-app :params="f7params">
    <f7-panel right @panel:open="panelBookOpen()" @panel:close="panelBookClose()">
      <menu-page :contentMenu="contentMenu" :bookPanel="bookPanel" :loader="loader"></menu-page>
    </f7-panel>
    <f7-panel left @panel:open="panelLeftOpen()">
      <f7-page name="panelLeft" class="panelLeft">
        <div>
          <div class="wrapperLogo d-flex justify-content-center">
            <f7-link href="/" style="padding-left: 0px" panel-close>
              <img class="logo" src="/static/logo-soccer-sessions-white.svg" style="max-height: 20px;" />
            </f7-link>
          </div>
          <div class="d-flex justify-content-center">
            <f7-link @click="changeLang()">
              <img class="p-1" :class="{ 'opacity-2': getLang !== 'pl' }" height="25px" :src="`/static/lang/pl.svg`" alt />
            </f7-link>
            <f7-link @click="changeLang()">
              <img class="p-1" :class="{ 'opacity-2': getLang !== 'en' }" height="25px" :src="`/static/lang/en.svg`" alt />
            </f7-link>
          </div>
          <div v-if="isAuthenticated">
             <loader v-if="loader" :color="'white'"></loader>

            <div v-else>
            <h4 v-if="profileAcademy.length > 0 || existClub" class="text-white text-uppercase text-center mb-0">{{ 'global.choose_a_role' | translate }}</h4>
            <div class="text-white mx-4 listCards">
                <f7-link @click="setRole('books', userId)" class="cursor-pointer d-block" panel-close>
                  <f7-card class="custom-card custom-card-40" :class="{'active' : (getRole == 'books' && getGlobalId == userId)}">
                    <div>
                      <p>SOCCER SESSIONS</p>
                      <img class="check" height="15px" src="/static/icons/done-full.svg" alt="check">
                    </div>
                  </f7-card>
                </f7-link>
                <f7-link v-if="existClub" @click="setRole('owner', userId)" class="cursor-pointer d-block" panel-close>
                  <f7-card class="custom-card custom-card-40" :class="{'active' : (getRole == 'owner' && getGlobalId == userId)}">
                    <div>
                      <p>{{ 'global.club' | translate }}</p>
                      <p class="info">{{account.name_club}}</p>
                      <img class="check" height="15px" src="/static/icons/done-full.svg" alt="check">
                    </div>
                  </f7-card>
                </f7-link>
                <f7-link @click="setRole('coach', academy.id)" v-for="academy in profileAcademy" :key="academy.id" class="cursor-pointer d-block" panel-close>
                  <f7-card class="custom-card custom-card-40" :class="{'active' : (getRole == 'coach' && getGlobalId == academy.id)}">
                    <div>
                      <p>{{ 'global.coach' | translate }}</p>
                      <p class="info">{{academy.name_club}}</p>
                      <img class="check" height="15px" src="/static/icons/done-full.svg" alt="check">
                    </div>
                  </f7-card>
                </f7-link>
                <f7-link @click="setRole('player', parent.user)" v-for="parent in profileParents" :key="parent.id" class="cursor-pointer d-block" panel-close>
                  <f7-card class="custom-card custom-card-40" :class="{'active' : (getRole == 'player' && getGlobalId == parent.user)}">
                    <div>
                      <p>{{ 'global.player' | translate }}</p>
                      <p class="info">{{parent.email}}</p>
                      <img class="check" height="15px" src="/static/icons/done-full.svg" alt="check">
                    </div>
                  </f7-card>
                </f7-link>
                <f7-link v-if="isPartner" @click="setRole('partner', userId)" class="cursor-pointer d-block" panel-close>
                  <f7-card class="custom-card custom-card-40" :class="{'active' : (getRole == 'partner' && getGlobalId == userId)}">
                    <div>
                      <p>{{ 'global.partner' | translate }}</p>
                      <img class="check" height="15px" src="/static/icons/done-full.svg" alt="check">
                    </div>
                  </f7-card>
                </f7-link>
            </div>
            <!-- <div v-if="getRole == 'player'">
              <h4 class="text-white text-uppercase text-center mb-0">Aktualne saldo</h4>
              <div>
                <p class="font-weight-bold text-center text-white">{{currency[account.currency]}}</p>
              </div>
            </div> -->
            </div>
          </div>
        </div>
        <div>
          <f7-block class="wrapperBottom">
            <f7-link v-if="!isAuthenticated" href="/login/" panel-close>
              <span>{{ 'global.login' | translate }}</span>
              <img height="20px" src="/static/icons/login.svg" alt />
            </f7-link>
            <div v-else>
              <!-- <div class="mb-4 d-flex align-items-center justify-content-center">
                <f7-link @click="navigateToCoins()" panel-close>
                  <img height="30px" src="/static/kpn-coin.png" />
                </f7-link>
              </div> -->
              <f7-link @click="onLogout" panel-close>
                  <span>{{ 'global.logout' | translate }}</span>
                  <img height="20px" src="/static/icons/logout.svg" alt />
              </f7-link>
            </div>
          </f7-block>
        </div>
      </f7-page>
    </f7-panel>
    <f7-view main class="safe-areas" url="/#!"
    ></f7-view>
		<Cookies/>
  </f7-app>
</template>

<script>
  import authServices from "@/services/AuthServices.js"
  import routes from './routes';
  import Vue from 'vue';
  import MenuPage from '@/views/Pages/MenuPage'
  import mixinBook from "@/mixins/mixinBook"
  import Cookies from '@/components/Cookies';
  import mixinUser from "@/mixins/mixinUser"
  import Loader from "@/components/Loader";
  import CONSTANTS from "@/utils/constants.js"

export default {
    mixins: [mixinBook, mixinUser],
    components: {
      MenuPage,
      Cookies,
      Loader
    },
    data() {
      return {
        f7params: {
          id: 'com.konspektyPilkaNozna',
          name: 'konspektyPilkaNozna',
          theme: 'auto',
          routes,
          view: {
            pushState: true,
            pushStateSeparator: '#!',
            force: true,
            ignoreCache: true,
            reloadPrevious: true,
          },
        },
        loader: false,
        profileParents: [],
        profileCoaches: [],
        profileAcademy: [],
        userId: '',
        bookPanel: {},
        contentMenu: [],
        existClub: false,
        isPartner: false,
        currency: CONSTANTS.CURRENCY,
      }
    },
    created() {
  		Vue.i18n.set(this.getLang);
    },
    mounted() {
      if (process.env.NODE_ENV == 'production') {
        document.body.addEventListener('contextmenu', function(e) {
          e.preventDefault();
        });
      }
    },
    methods: {
      async setRole(role, id) {
        if (id == this.getGlobalId && role == this.getRole) return

        try {
          const responseChangeRole = await authServices.changeRole(id);
          this.$store.dispatch("appkonspektypilkanozna/setRole", role);
          this.$store.dispatch("appkonspektypilkanozna/setGlobalId", id);
          this.$f7.toast
          .create({
            text:
              "The role has been changed",
            position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
          this.$f7.views.main.router.navigate('/home/')
          this.$f7.views.main.history = []
        } catch (error) {
            this.$f7.toast
              .create({
                text:
                  "Something went wrong with the role selection. Please try again or contact your administrator.",
                            position: "bottom",
            
                closeTimeout: 3000
              })
              .open();
          console.log(error);
        }
      },
      panelBookOpen() {
        if (window.location.href.split('/').includes('books')) {
          if (this.isAuthenticated) {
            this.fetchDataBook()
          } else {
            this.fetchDataBookDemo()
          }
        }
      },
      panelBookClose() {
        this.bookPanel = {}
        this.contentMenu = []
      },
      // navigateToCoins() {
      //   this.$f7.views.main.router.navigate('/kpn-coins/')
      // },
      panelLeftOpen() {
        if (this.isAuthenticated) {
          this.fetchDataUser().then(() => {
            this.existClub = this.profileAcademy.some(el => el.id == this.userId);
            
            const indexId = this.profileAcademy.findIndex((o) => {
                return o.id == this.userId;
            })
            if (indexId !== -1) this.profileAcademy.splice(indexId, 1);

            if (this.getRole == null || this.getGlobalId == null) {
              this.$store.dispatch("appkonspektypilkanozna/setRole", 'books');
              this.$store.dispatch("appkonspektypilkanozna/setGlobalId", this.userId);
            }
          });
        }
      },
    }
  }
</script>

<style lang="scss">
@media print {
  body {
	   background: white !important;
  }
  .page, .page-content, html, body, .framework7-root, .views, .view {
      height: auto !important;
  }

  html, body, .framework7-root, .views, .view {
      overflow: visible !important;
      overflow-x: visible !important;
  }

  .page.page-previous {
      display: none;
  }

}
</style>