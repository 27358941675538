<template>
  <f7-page name="panelRight" class="panelRight">
    <loader style="padding-top: 20px" v-if="loader"></loader>
    
    <div v-else>

      <div class="blockHeader">
        <f7-block-title>
            <f7-link href="/" panel-close>
                <img v-if="bookPanel.company" style="background: white; padding: 15px; border-radius: 10px;" :src="`/static/company/${bookPanel.company}.png`" />
            </f7-link>
        </f7-block-title>
        <f7-block class="icons">
          <f7-link style="margin: 2px" href="https://www.facebook.com/SoccerSessionsOfficial/" external target="_blank">
              <img height="25px" src="/static/icons/facebook.svg" />
          </f7-link>
        </f7-block>
        <f7-block v-if="Object.keys(bookPanel).length" class="header">
          <p>
            {{ getLang == 'pl' ? bookPanel.title : bookPanel.title_ang }}
          </p>
        </f7-block>
      </div>
      
      <f7-list>
        <f7-list-item :link="`/books/${bookPanel.id}/`" class="access" :title="'books.start' | translate " panel-close></f7-list-item>
        <f7-list-item :link="item.access ? item.typeElem == 'text' ? `/books/${bookPanel.id}/text/${item.id}/` : item.typeElem == 'exercise' ? `/books/${bookPanel.id}/exercises/${item.id}/` : `/books/${bookPanel.id}/sessions/${item.id}/` : `/books/${bookPanel.id}/no-access/`" v-for="item in contentMenu" :key="item.id" :class="item.access ? 'access' : ''" :title="item.title" panel-close></f7-list-item>  
      </f7-list>
    </div>

  </f7-page>
</template>

<script>
import Loader from "@/components/Loader";
  export default {
      components: {
        Loader
      },
      props: {
          bookPanel: {
              type: Object,
              required: true
          },
          contentMenu: {
              type: Array,
              required: true
          },
          loader: {
            type: Boolean,
            required: true
          },
      },
  };
</script>

<style lang="scss">
    @import "@/assets/scss/panel.scss";
</style>
