import Api from './index'
import store from '@/store'

export default {
  async configOutlines() {
    return Api().get('configoutlines/', {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async createConfigOutline(data) {
    return Api().post('configoutlines/', data, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async configOutline(data) {
    const configOutline = {
      title: `Config do konspektu ${data.user}`,
      logo: data.logo,
      age_category: data.age_category,
      place: data.place,
      coach: data.coach,
      assistant: data.assistant,
      count_player: data.count_player == '' ? null : data.count_player,
      gear_balls: data.gear_balls == '' ? null : data.gear_balls,
      gear_goals: data.gear_goals == '' ? null : data.gear_goals,
      gear_markers: data.gear_markers == '' ? null : data.gear_markers,
      gear_cones_1: data.gear_cones_1 == '' ? null : data.gear_cones_1,
      gear_cones_2: data.gear_cones_2 == '' ? null : data.gear_cones_2,
      gear_poles: data.gear_poles == '' ? null : data.gear_poles,
      gear_ladders: data.gear_ladders == '' ? null : data.gear_ladders,
      gear_other: data.gear_other,
    }
    return Api().put(`configoutlines/${data.id}/`, configOutline, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listPrivateOutlines(category, search, pagination) {
    let page = '';
    if (pagination) {
      const url = new URL(pagination);
      page= url.search
    }
    return Api().get(`privateoutlines/?category=${category}&search=${search}&${page}`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listAllPrivateOutlines() {
    return Api().get(`privateoutlines/list/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async itemPrivateOutline(id) {
    return Api().get(`privateoutlines/${id}/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async createPrivateOutline(data) {
    const outline = {
      subject: data.subject,
      coach: data.coach,
      assistant: data.assistant,
      microcycle: data.microcycle,
      mesocycle: data.mesocycle,
      objective: data.objective,
      coaching: data.coaching,
      individual_actions: data.individual_actions,
      group_activities: data.group_activities,
      motor_skills: data.motor_skills,
      knowledge: data.knowledge,
      place: data.place,
      age_category: data.age_category,
      category: data.category,
      date: data.date == '' ? null : data.date,
      time: data.time == '' ? null : data.time,
      number: data.number == '' ? null : data.number,
      time_work: data.time_work == '' ? null : data.time_work,
      count_player: data.count_player == '' ? null : data.count_player,
      gear_balls: data.gear_balls == '' ? null : data.gear_balls,
      gear_goals: data.gear_goals == '' ? null : data.gear_goals,
      gear_markers: data.gear_markers == '' ? null : data.gear_markers,
      gear_cones_1: data.gear_cones_1 == '' ? null : data.gear_cones_1,
      gear_cones_2: data.gear_cones_2 == '' ? null : data.gear_cones_2,
      gear_poles: data.gear_poles == '' ? null : data.gear_poles,
      gear_ladders: data.gear_ladders == '' ? null : data.gear_ladders,
      gear_other: data.gear_other,
      notes: data.notes,
      introductory_part_1: data.introductory_part_1,
      introductory_part_2: data.introductory_part_2,
      main_part_1: data.main_part_1,
      main_part_2: data.main_part_2,
      final_part: data.final_part,
      images: data.images,
      type_copied: data.type_copied,
      id_book: data.id_book !== undefined ? data.id_book : undefined,
      demo: data.demo !== undefined ? data.demo : undefined,
    }
    return Api().post('privateoutlines/', outline ,{
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async deletePrivateOutline(id) {
    return Api().delete(`privateoutlines/${id}/`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async privateOutline(data) {
    const outline = {
      subject: data.subject,
      coach: data.coach,
      assistant: data.assistant,
      microcycle: data.microcycle,
      mesocycle: data.mesocycle,
      objective: data.objective,
      coaching: data.coaching,
      individual_actions: data.individual_actions,
      group_activities: data.group_activities,
      motor_skills: data.motor_skills,
      knowledge: data.knowledge,
      place: data.place,
      age_category: data.age_category,
      category: data.category,
      date: data.date == '' ? null : data.date,
      time: data.time == '' ? null : data.time,
      number: data.number == '' ? null : data.number,
      time_work: data.time_work == '' ? null : data.time_work,
      count_player: data.count_player == '' ? null : data.count_player,
      gear_balls: data.gear_balls == '' ? null : data.gear_balls,
      gear_goals: data.gear_goals == '' ? null : data.gear_goals,
      gear_markers: data.gear_markers == '' ? null : data.gear_markers,
      gear_cones_1: data.gear_cones_1 == '' ? null : data.gear_cones_1,
      gear_cones_2: data.gear_cones_2 == '' ? null : data.gear_cones_2,
      gear_poles: data.gear_poles == '' ? null : data.gear_poles,
      gear_ladders: data.gear_ladders == '' ? null : data.gear_ladders,
      gear_other: data.gear_other,
      notes: data.notes,
      introductory_part_1: data.introductory_part_1,
      introductory_part_2: data.introductory_part_2,
      main_part_1: data.main_part_1,
      main_part_2: data.main_part_2,
      final_part: data.final_part,
      images: data.images,
      type_copied: data.type_copied,
    }
    return Api().put(`privateoutlines/${data.id}/`, outline, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async createExerciseGraphics(data) {
    const exercise = {
      graphics: data,
    }
    return Api().post('exercisegraphics/', exercise ,{
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async itemExerciseGraphics(id) {
    return Api().get(`exercisegraphics/${id}/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async deleteExerciseGraphics(id) {
    return Api().delete(`exercisegraphics/${id}/`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listCategories(pagination) {
    let page = '';
    if (pagination) {
      const url = new URL(pagination);
      page= url.search
    }
    return Api().get(`privateoutlines/categories/${page}`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listAllCategories() {
    return Api().get('privateoutlines/categories/all/', {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async itemCategory(id) {
    return Api().get(`privateoutlines/categories/${id}/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async category(data) {
    return Api().put(`privateoutlines/categories/${data.id}/`, data, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async deleteCategory(id) {
    return Api().delete(`privateoutlines/categories/${id}/`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async createCategory(data) {
    return Api().post('privateoutlines/categories/', data, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
}