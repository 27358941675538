<template>
  <f7-page login-screen class="pageCalendar">
    <navbarcrm></navbarcrm>
    <loader v-if="loader"></loader>

    <div v-else>
            <f7-block class="text-center text-uppercase">
            <h4>{{'club.calendar' | translate}} <f7-link class="p-1" :tooltip="'popover.calendar' | translate"><img height="11px" src="/static/icons/help.svg" alt /></f7-link></h4>
            <div class="d-flex justify-content-center align-items-center">
                <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{'club.group' | translate}}: </h4>
                <div class="custom-select">
                    <select name="group" v-model="actualGroup" @change="changeGroup(actualGroup)">
                        <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == actualGroup">
                            {{group.name}}
                        </option>
                    </select>
                </div>
            </div>
            <f7-button v-if="!groups.length > 0" href="/groups/" raised>{{'club.need_create_group' | translate}}!</f7-button>
            </f7-block>
            <f7-block>
                <calendar
                    ref="calendar"
                    is-expanded
                    :attributes="attrs"
                    @update:from-page="changeMonth"
                    @dayclick="onDayClick"
                    >
                </calendar>
            </f7-block>
            <f7-block class="text-center">
                <h2 class="text-uppercase">{{actualDay}} {{months[actualMonth] | translate}} {{actualYear}}</h2>
            </f7-block>
            <f7-block>
                <loader v-if="loaderDay"></loader>

                <div v-else>
                    <div style="margin-bottom: 30px" v-if="(events.trainings.length > 0) || (events.planningtrainings.length > 0)">
                        <div v-if="events.trainings.length > 0">
                            <div v-for="training in events.trainings" :key="training.id">
                                <p class="mb-0"><span class="font-weight-bold text-uppercase">{{'club.training' | translate}}:</span> {{training.subject}} <span v-if="training.training_hours_start && training.training_hours_end" v-text="getTime(training)"></span> 
                                    <f7-link :href="`/trainings/${training.id}/?day=${actualYear}-${actualMonth}-${actualDay}&group=${actualGroup}`" >
                                        <img class="cursor-pointer eye" height="15px" src="/static/icons/eye-2.svg" alt />
                                    </f7-link>
                                    <div style="margin-top: 10px;">
                                        <f7-row v-if="training.outline_private || training.outline_url" style="margin-bottom: 10px">
                                            <f7-col width="50">
                                                <f7-button v-if="training.outline_private" :href="`/private-outlines/${training.outline_private}/`" raised small>{{ 'club.private_outnline' | translate }}</f7-button>
                                            </f7-col>
                                            <f7-col width="50">
                                                <f7-button v-if="training.outline_url" :href="training.outline_url" class="external" target="_blank" raised small>{{ 'club.outline_link' | translate }}</f7-button>
                                            </f7-col>
                                        </f7-row>
                                        <div>
                                            <f7-col v-if="attendanceList.filter(elem => (elem.training == training.id)).length > 0" width="100%">
                                                <f7-button style="background: #18c92a" :href="`/attendance/${attendanceList.filter(elem => (elem.training == training.id))[0].id}/?day=${actualYear}-${actualMonth}-${actualDay}&group=${actualGroup}`" raised>{{ 'club.view_attendance' | translate }} 
                                                <span class="ml-1" v-if="players.length > 0">{{Math.round(attendanceList.filter(elem => (elem.training == training.id))[0].was_presense.length / players.length * 100)}}%</span>
                                                </f7-button>
                                            </f7-col>
                                            <f7-col v-else>
                                                <f7-button style="background: #a1ad13" :href="`/create-attendance/?day=${actualYear}-${actualMonth}-${actualDay}&group=${actualGroup}&training=${training.id}`" raised>{{ 'club.check_attendance' | translate }}</f7-button>
                                            </f7-col>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                        <div v-else>
                            <p class="mt-0 font-weight-bold text-uppercase text-center">{{ 'club.empty_trainings' | translate }}!</p>
                            <f7-button :href="`/create-training/?day=${actualYear}-${actualMonth}-${actualDay}&group=${actualGroup}`" raised>{{ 'club.add_training' | translate }}</f7-button>
                            
                        </div>
                        <div v-for="planning in events.planningtrainings" :key="planning.id">
                            <div v-for="week in planning.plans.data" :key="week.id">
                                <p v-if="getActualWeek(week)">
                                    <span class="font-weight-bold text-uppercase">{{ 'club.microcycle' | translate }}:</span>
                                    {{plansList[week.microcycle]}} <f7-link v-if="week.color !== ''" :tooltip="`Microcycle color: ${paleteColorsHighlight[week.color].label}`" class="color" :style="{background : paleteColorsHighlight[week.color].value}"></f7-link>
                                </p>
                            </div>
                            <p><span class="font-weight-bold text-uppercase">{{ 'club.mesocycle' | translate }}:</span> {{planning.mesocycle}} 
                                <f7-link :href="`/planning-trainings/${planning.id}/?day=${actualYear}-${actualMonth}-${actualDay}&group=${actualGroup}`" >
                                    <img class="cursor-pointer eye" height="15px" src="/static/icons/eye-2.svg" alt />
                                </f7-link>
                            </p>
                            <p v-if="planning.area_technical_tactical"><span class="font-weight-bold text-uppercase">{{ planning.new_pzpn ? `outline.gameGoal` : `club.area_technique` | translate }}:</span> {{planning.area_technical_tactical}} </p>
                            <p v-if="planning.area_motor"><span class="font-weight-bold text-uppercase">{{ planning.new_pzpn ? `outline.actions` : `club.area_motor` | translate }}:</span> {{planning.area_motor}} </p>
                            <p v-if="planning.area_mental"><span class="font-weight-bold text-uppercase">{{ planning.new_pzpn ? `outline.gameRules` : `club.area_mental` | translate }}:</span> {{planning.area_mental}} </p>
                            <p v-if="planning.area_knowledge"><span class="font-weight-bold text-uppercase">{{ planning.new_pzpn ? `outline.motorGoal` : `club.area_knowledge` | translate }}:</span> {{planning.area_knowledge}} </p>
                        </div>
                    </div>
                    <div v-else class="text-center text-uppercase">
                        <img height="100px" src="/static/icons/schedule.svg" alt />
                        <h4>{{ 'club.no_events_days' | translate }}</h4>
                        <f7-button :href="`/create-training/?day=${actualYear}-${actualMonth}-${actualDay}&group=${actualGroup}`" raised>{{ 'club.add_training' | translate }}</f7-button>
                    </div>
                </div>
            </f7-block>
    </div>
    
  </f7-page>
</template>

<script>
import moment from 'moment';
import Navbarcrm from "@/components/Navbar/Navbarcrm.vue";
import Loader from "@/components/Loader";
import Calendar from 'v-calendar/lib/components/calendar.umd'
import trainingsServices from "@/services/TrainingsServices.js"
import CONSTANTS from "@/utils/constants.js"
import mixinGroups from "@/mixins/mixinGroups"
import groupsServices from "@/services/GroupsServices.js"
import mixinUser from "@/mixins/mixinUser"
import attendanceListServices from "@/services/AttendanceListServices.js"

export default {
  mixins: [mixinGroups, mixinUser],
  components: {
    Navbarcrm,
    Loader,
    Calendar,
  },
    data() {
        return {
            loader: false,
            loaderDay: false,
            attrs: [],
            actualYear: '',
            actualMonth: '',
            actualDay: '',
            actualGroup: '',
            calendar: {},
            events: {
                planningtrainings: [],
                trainings: []
            },
            players: [],
            attendanceList: [],
            months: CONSTANTS.MONTHS,
            plansList: CONSTANTS.PLANS_LIST,
        }
    },
    watch: {
    'actualGroup': function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.fetchCalendarDay(this.actualDay);
          this.fetchCalendarMonth(this.actualYear, this.actualMonth)
          this.fetchAllPlayers(this.actualGroup)
      }
    },
  },
    mounted() {
        this.fetchDataUser().then(() => {
            this.fetchGroups().then(() => {
                if (this.getRole == 'coach') {
                   this.groups = this.profileGroups;
                }
                if (this.groups.length > 0) {
                    if (this.$f7route.query.group !== undefined) {
                        this.actualGroup = this.$f7route.query.group
                    } else {
                        const checkGroup = obj => obj.id === this.getActiveGroup;
                        if (this.getActiveGroup !== '' && this.groups.some(checkGroup)) {
                        this.actualGroup = this.getActiveGroup
                        } else {
                        this.actualGroup = this.groups[0].id
                        }
                    }
                    this.fetchAllPlayers(this.actualGroup)
                }
                let today;
                if (this.$f7route.query.day !== undefined) {
                    let splitDay = (this.$f7route.query.day).split('-')
                    today = new Date(moment().year(splitDay[0]).month(splitDay[1]-1).date(splitDay[2]));
                    this.actualDay = today.getUTCDate()
                    this.actualMonth = today.getUTCMonth() + 1
                    this.actualYear = today.getUTCFullYear()
                    const calendar = this.$refs.calendar;
                    calendar.move({ month: this.actualMonth, year: this.actualYear })
                    setTimeout(() => { 
                        document.querySelector(`.vc-day.day-${this.actualDay} .vc-day-content`).classList.add('active');
                    }, 600);
                } else {
                    today = new Date();
                    this.actualDay = today.getUTCDate()
                    this.actualMonth = today.getUTCMonth() + 1
                    this.actualYear = today.getUTCFullYear()
                    document.querySelector('.vc-day.is-today .vc-day-content').classList.add('active');
                }
            })
      })
    },
    methods: {
        async fetchAllPlayers(group) {
            try {
                const responseDataAllPlayers = await groupsServices.listAllPlayers(group);
                this.players = responseDataAllPlayers.data
                
            } catch (error) {
                if (!error.response.data.access) {
                this.$f7router.navigate('/not-found/')
                } else {
                this.$f7.toast
                    .create({
                    text: "Something went wrong with the list of players in the group. Please try again or contact your administrator.",
                                position: "bottom",
            
                    closeTimeout: 3000
                    })
                    .open();
                }
            }
            this.loader = false;
        },
        getTime(training) {
            if (training.training_hours_start && training.training_hours_end) {
                const hours_start = training.training_hours_start.split(/:/)
                const hours_end = training.training_hours_end.split(/:/)
                return `${hours_start[0]}:${hours_start[1]} - ${hours_end[0]}:${hours_end[1]}`
            } else {
                return ''
            }
        },
        getActualWeek(week) {
            const actualDate = moment(`${this.actualYear}-${this.actualMonth}-${this.actualDay}`).format('YYYY-MM-DD')
            const weekStart = moment(week.date_start).format('YYYY-MM-DD')
            const weekEnd = moment(week.date_end).format('YYYY-MM-DD')
            return (actualDate >= weekStart) && (actualDate <= weekEnd)
        },
        async fetchCalendarMonth(year, month) {
            this.attrs = []
            try {
                const data = {
                    year,
                    month,
                    group: this.actualGroup
                }
                const responseDataCalendarMonth = await trainingsServices.listCalendarMonth(data);
                this.calendar = responseDataCalendarMonth.data

                this.calendar.planningtrainings.forEach((planning) => {
                    planning.plans.data.forEach((plan) => {
                        const colorGroup = this.paleteColorsHighlight[plan.color].text
                        const highlight = {
                            highlight: {
                                start: { fillMode: 'light', color: colorGroup },
                                base: { fillMode: 'light', color: colorGroup },
                                end: { fillMode: 'light', color: colorGroup },
                            },
                            dates: { start: new Date(plan.date_start), end: new Date(plan.date_end) },
                        }
                        this.attrs.push(highlight)
                    })
                })
                this.calendar.trainings.forEach((training) => {
                    const colorGroup = this.paleteColorsDot[this.groups.find(obj => obj.id == training.group).color].value
                    const dot = {
                        dot: {
                            style: {
                                backgroundColor: colorGroup,
                            },
                        },
                        dates: new Date(training.training_date),
                    }
                    this.attrs.push(dot)
                })
                const today = {
                    key: 'today',
                    highlight: 'blue',
                    dates: new Date(),
                }
                this.attrs.push(today)
            } catch (error) {
                this.$f7.toast
                .create({
                    text: "Something went wrong with the month on the calendar. Please try again or contact your administrator.",
                                position: "bottom",
            
                    closeTimeout: 3000
                })
                .open();
                console.log(error);
            }
        },
        async fetchCalendarDay(day) {
            this.attendanceList = []
            try {
                this.loaderDay = true;
                const data = {
                    year: this.actualYear,
                    month: this.actualMonth,
                    day,
                    group: this.actualGroup
                }
                const responseDataCalendarDay = await trainingsServices.listCalendarDay(data);
                this.events = responseDataCalendarDay.data
                this.events.trainings.forEach((training, index) => {
                    this.fetchAttendance(training.id, index)
                })
                if (this.events.trainings.length == 0) {
                    this.loaderDay = false;
                }
            } catch (error) {
                this.$f7.toast
                .create({
                    text: "Something went wrong with the month on the calendar. Please try again or contact your administrator.",
                                position: "bottom",
            
                    closeTimeout: 3000
                })
                .open();
                console.log(error);
            }
        },
        async fetchAttendance(training, index) {
            this.loaderDay = true;
             try {
                const responseDataAttendance = await attendanceListServices.attendanceTraining(training)
                responseDataAttendance.data.forEach((attendance) => {
                    const checkExistAttendance = obj => obj.id == attendance.id
                    
                    if (!this.attendanceList.some(checkExistAttendance)) {
                        this.attendanceList.push(attendance)
                    }
                })
            } catch (error) {
                this.$f7.toast
                .create({
                    text: "Something went wrong with the training attendance list. Please try again or contact your administrator.",
                                position: "bottom",
            
                    closeTimeout: 3000
                })
                .open();
                console.log(error);
            }
            this.loaderDay = false;
        },
        onDayClick(e) {
            this.actualDay = e.day;
            const elementsDays = document.querySelectorAll('.vc-day-content');
            Array.from(elementsDays).forEach((day, index) => {
                day.classList.remove('active');
            });
            if (e.el.querySelector('.vc-day-content .active') !== null) {
                e.el.querySelector('.vc-day-content .active').classList.remove('active');
            }
            e.el.classList.add('active');

            this.fetchCalendarDay(e.day);
        },
        changeMonth(page) {
            if ((this.actualYear !== page.year || this.actualMonth !== page.month) && this.actualGroup ) {
                this.actualMonth = page.month;
                this.actualYear = page.year;
                this.fetchCalendarMonth(page.year, page.month)
                this.actualDay = 1;
                this.fetchCalendarDay(this.actualDay)
                setTimeout(() => { 
                    document.querySelector('.vc-day.is-first-day .vc-day-content').classList.add('active');
                }, 600);
            }
        }
    }
};
</script>
<style lang="scss">
@import "@/assets/scss/pages/calendar.scss";
</style>