import Api from './index'
import store from '@/store'

export default {
  async chat(data) {
    return Api().post('jose/chat/', data, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listChatHistory(type, search, pagination) {
    let page = '';
    if (pagination) {
      const url = new URL(pagination);
      page= url.search
    }
    return Api().get(`jose/chat/history/?type=${type}&search=${search}&${page}`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
}

