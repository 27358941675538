<template>
  <f7-page login-screen>
    <navbar></navbar>
    <f7-block style="margin-top: -50px; margin-bottom: -75px;">
      <lottie
        :options="defaultOptionsDone"
        :height="300"
        :width="300"
        v-on:animCreated="handleAnimation"
      />
    </f7-block>
    <f7-block class="text-align-center">
      <h4>{{ 'payment.success_page' | translate}}!</h4>
      <span>{{ 'payment.success_page_description' | translate}}i :)</span>
    </f7-block>
    <f7-block class="text-center">
      <f7-button href="/library/" raised>{{ 'books.library' | translate}}</f7-button>
    </f7-block>

  </f7-page>
</template>
<script>
import * as animationDataDone from "@/assets/lottie/done.json";
import Lottie from "vue-lottie";
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    lottie: Lottie,
    Navbar,
  },
  data: () => {
    return {
      defaultOptionsDone: {
        animationData: animationDataDone.default,
        loop: false
      }
    };
  },
  mounted() {
    if (window.location.host == 'app.soccer-sessions.com') {
      // Pixel facebook
      window.fbq('track', 'Purchase');

      // User.com event
      window.userengage('event.new_order');
    };
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    }
  }
};
</script>