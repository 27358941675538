<template>
  <f7-page login-screen>
    <navbarlist :list="'playing-fields'"></navbarlist>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{ 'club.new_pitch' | translate }}</h4>
          </f7-block>
          <f7-list form no-hairlines-md @submit.prevent="createPlayingField">
             <f7-list-input
              :label="'club.name' | translate"
              type="text"
              :placeholder="'club.name_pitch' | translate"
              required
              validate
              :error-message="'global.required' | translate"
              :value="playingField.name"
              @input="playingField.name = $event.target.value"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.street' | translate"
              type="text"
              :placeholder="'club.street' | translate"
              required
              validate
              :error-message="'global.required' | translate"
              :value="playingField.street"
              @input="playingField.street = $event.target.value"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.city' | translate"
              type="text"
              :placeholder="'club.city' | translate"
              required
              validate
              :error-message="'global.required' | translate"
              :value="playingField.city"
              @input="playingField.city = $event.target.value"
            >
            </f7-list-input>
            <f7-block class="text-center">
              <f7-button v-if="!loaderPlayingField" type="submit" raised>
                  {{  'club.add_pitch' | translate}}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import CONSTANTS from "@/utils/constants.js"
import Navbarlist from "@/components/Navbar/Navbarlist.vue";
import Loader from "@/components/Loader";
import playingFieldsServices from "@/services/PlayingFieldsServices.js"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  components: {
    Navbarlist,
    Loader,
  },
  data: () => {
    return {
      loader: false,
      loaderPlayingField: false,
      playingField: {
        name: '',
        street: '',
        city: '',
      },
    };
  },
  mounted() {
    this.fetchDataUser();
  },
  methods: {
    async createPlayingField() {
      this.loaderPlayingField = true;
      try {
        const responseCreatePlayingField = await playingFieldsServices.createPlayingField(this.playingField);
        if (responseCreatePlayingField.data.id) {
          this.$f7router.navigate(`/playing-fields/`)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with adding the pitch. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderPlayingField = false;
    },
  }
};
</script>