<template>
  <div class="wrapperBox">
    <f7-block>
        <div class="sectorMain">
        <div class="d-flex w-100 box-row" v-if="config.subject" :class="config.microcycle || config.areas || config.objective || config.coaching || config.info || config.coach ? 'no-border-bottom' : ''">
          <div class="box-col box-col-medium text-center border-right box-subject">
            <p class="text-uppercase">{{ 'outline.subject' | translate }}</p>
            <span class="print">{{session.subject}}</span><input type="text" class="no-print" v-model="session.subject" :placeholder="'outline.subject' | translate">
          </div>
        </div>
        <div v-if="config.coach">
          <div class="d-flex w-100 box-row" :class="config.microcycle || config.info || config.areas || config.objective || config.coaching ? 'no-border-bottom' : ''">
            <div class="box-col box-col-2 text-center border-right">
              <p class="nowrap text-uppercase">{{ 'outline.coach' | translate }}</p>
              <span class="print">{{session.coach}}</span><input type="text" class="no-print" v-model="session.coach" :placeholder="'outline.coach' | translate">
            </div>
            <div class="box-col box-col-2 text-center border-right border-left">
              <p class="text-uppercase">{{ 'outline.assistant' | translate }}</p>
              <span class="print">{{session.assistant}}</span><input type="text" class="no-print" v-model="session.assistant" :placeholder="'outline.assistant' | translate">
            </div>
          </div>
      </div>
      <div v-if="config.microcycle">
        <div class="d-flex w-100 box-row" :class="config.info || config.areas || config.objective || config.coaching ? 'no-border-bottom' : ''">
          <div class="box-col box-col-2 text-center border-right">
            <p class="text-uppercase">{{ 'outline.microcycle' | translate }}</p>
            <span class="print">{{session.microcycle}}</span><input type="text" class="no-print" v-model="session.microcycle" :placeholder="'outline.microcycle' | translate">
          </div>
          <div class="box-col box-col-2 text-center border-right border-left">
            <p class="text-uppercase">{{ 'outline.mesocycle' | translate }}</p>
            <span class="print">{{session.mesocycle}}</span><input type="text" class="no-print" v-model="session.mesocycle" :placeholder="'outline.mesocycle' | translate">
          </div>
        </div>
        <div class="d-flex w-100 box-row no-border-bottom">
        <div class="box-col box-col-2 text-center border-right">
            <p class="nowrap text-uppercase">{{ 'outline.no' | translate }}</p>
            <span class="print">{{session.number}}</span><input type="text" class="no-print" v-model="session.number" :placeholder="'outline.no' | translate">
          </div>
          <div class="no-print text-center box-col box-col-2 border-left border-right">
            <p class="text-uppercase">{{ 'outline.category' | translate }}</p>
            <select name="category" v-model="session.category">
              <option value="">{{ 'outline.noCategories' | translate }}</option>
              <option v-for="category in categories" :key="category.id" :value="category.id" :selected="category.id == session.category">
                  {{category.title}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="config.objective">
        <div class="d-flex w-100 box-row" :class="config.info || config.areas || config.coaching ? 'no-border-bottom' : ''">
          <div class="box-col box-col-medium text-center border-right">
            <p class="text-uppercase">{{ 'outline.objective' | translate }}</p>
            <span class="print">{{session.objective}}</span><input type="text" class="no-print" v-model="session.objective" :placeholder="'outline.objective' | translate">
          </div>
        </div>
      </div>
      <div v-if="config.coaching">
        <div class="d-flex w-100 box-row" :class="config.info || config.areas ? 'no-border-bottom' : ''">
          <div class="box-col box-col-medium text-center border-right">
            <p class="text-uppercase">{{ 'outline.coachingPoints' | translate }}</p>
            <span class="print">{{session.coaching}}</span><input type="text" class="no-print" v-model="session.coaching" :placeholder="'outline.coachingPoints' | translate">
          </div>
        </div>
      </div>
      <div v-if="config.areas">
        <div class="d-flex w-100 box-row no-border-bottom">
          <div class="box-col box-col-large box-col-2 text-center border-right">
            <p class="text-uppercase">{{ config.new_pzpn ? `outline.gameGoal` : `outline.technique` | translate }}</p>
                <span class="print white-space-pre-wrap text-left">{{session.individual_actions}}</span>
                <textarea class="no-print" rows="7" v-model="session.individual_actions" :placeholder="config.new_pzpn ? `outline.gameGoal` : `outline.technique` | translate"></textarea>
          </div>
          <div class="box-col box-col-large box-col-2 text-center border-right border-left">
            <p class="text-uppercase">{{ config.new_pzpn ? `outline.gameRules` : `outline.motorSkills` | translate }}</p>
              <span class="print white-space-pre-wrap text-left">{{session.motor_skills}}</span>
              <textarea class="no-print" rows="7" v-model="session.motor_skills" :placeholder="config.new_pzpn ? `outline.gameRules` : `outline.motorSkills` | translate"></textarea>
          </div>
        </div>
        <div class="d-flex w-100 box-row" :class="config.info ? 'no-border-bottom' : ''">
          <div class="box-col box-col-large box-col-2 text-center border-right">
            <p class="text-uppercase">{{ config.new_pzpn ? `outline.actions` : `outline.group` | translate }}</p>
                <span class="print white-space-pre-wrap text-left">{{session.group_activities}}</span>
                <textarea class="no-print" rows="7" v-model="session.group_activities" :placeholder="config.new_pzpn ? `outline.actions` : `outline.group` | translate"></textarea>
          </div>
          <div class="box-col box-col-large box-col-2 text-center border-right border-left">
            <p class="text-uppercase">{{ config.new_pzpn ? `outline.motorGoal` : `outline.knowledge` | translate }}</p>
              <span class="print white-space-pre-wrap text-left">{{session.knowledge}}</span>
              <textarea class="no-print" rows="7" v-model="session.knowledge" :placeholder="config.new_pzpn ? `outline.motorGoal` : `outline.knowledge` | translate"></textarea>
          </div>
        </div>
      </div>
      <div v-if="config.info">
        <div class="d-flex w-100 box-row no-border-bottom">
          <div class="box-col box-col-4 text-center border-right">
            <p class="text-uppercase">{{ 'outline.date' | translate }}</p>
              <span class="print">{{session.date}}</span><input type="date" class="no-print" v-model="session.date">
          </div>
          <div class="box-col box-col-4 text-center border-right border-left">
            <p class="text-uppercase">{{ 'outline.hour' | translate }}</p>
              <span class="print">{{session.time}}</span><input type="time" class="no-print" v-model="session.time">
          </div>
          <div class="box-col box-col-2 text-center border-right border-left">
            <p class="text-uppercase">{{ 'outline.place' | translate }}</p>
              <span class="print">{{session.place}}</span><input type="text" class="no-print" v-model="session.place" :placeholder="'outline.place' | translate">
          </div>
        </div>
        <div class="d-flex w-100 box-row">
          <div class="box-col box-col-2 text-center border-right">
            <p class="text-uppercase">{{ 'outline.ageCategory' | translate }}</p>
            <span class="print">{{ageCategories[session.age_category] | translate}}</span>
            <select class="no-print" name="age_category" v-model="session.age_category">
              <option v-for="(ageCategory, index) in ageCategories" :key="index" :value="index" :selected="index == session.age_category">
                  {{ageCategory | translate}}
              </option>
            </select>
          </div>
          <div class="box-col box-col-4 text-center border-right border-left">
            <p class="nowrap text-uppercase">{{ 'outline.workTime' | translate }}</p>
            <span class="print">{{session.time_work}}</span><input type="text" class="no-print" v-model="session.time_work" :placeholder="'outline.workTime' | translate">
          </div>
          <div class="box-col box-col-4 text-center border-right border-left">
            <p class="nowrap text-uppercase">{{ 'outline.numberOfPlayers' | translate }}</p>
            <span class="print">{{session.count_player}}</span><input type="text" class="no-print" v-model="session.count_player" :placeholder="'outline.numberOfPlayers' | translate">
          </div>
        </div>
      </div>
      </div>
      <div class="sectorAccessories">
      <div class="d-flex w-100 box-row no-border-bottom" v-if="config.accessories" style="margin-top: 10px">
          <div class="box-col box-col-8 text-center w-100 border-right">
          <p class="text-uppercase overflow-hidden">{{ 'outline.balls' | translate }}</p>
        </div>
        <div class="box-col box-col-8 text-center w-100 border-right">
          <p class="text-uppercase overflow-hidden">{{ 'outline.goals' | translate }}</p>
        </div>
        <div class="box-col box-col-8 text-center w-100 border-right">
          <p class="text-uppercase overflow-hidden">{{ 'outline.markers' | translate }}</p>
        </div>
        <div class="box-col box-col-8 text-center w-100 border-right">
          <p class="text-uppercase overflow-hidden">{{ 'outline.cones1' | translate }}</p>
        </div>
          <div class="box-col box-col-8 text-center w-100 border-right">
            <p class="text-uppercase overflow-hidden">{{ 'outline.cones2' | translate }}</p>
        </div>
        <div class="box-col box-col-8 text-center w-100 border-right">
            <p class="text-uppercase overflow-hidden">{{ 'outline.poles' | translate }}</p>
        </div>
        <div class="box-col box-col-8 text-center w-100 border-right">
            <p class="text-uppercase overflow-hidden">{{ 'outline.ladders' | translate }}</p>
        </div>
        <div class="box-col box-col-8 text-center w-100">
          <p class="text-uppercase overflow-hidden">{{ 'outline.otherAccessories' | translate }}</p>
        </div>
      </div>
      <div class="d-flex w-100 box-row" v-if="config.accessories">
          <div class="box-col box-col-8 border-right">
          <span class="print">{{session.gear_balls}}</span><input type="text" class="no-print" v-model="session.gear_balls" :placeholder="'outline.balls' | translate">
        </div>
        <div class="box-col box-col-8 border-right">
          <span class="print">{{session.gear_goals}}</span><input type="text" class="no-print" v-model="session.gear_goals" :placeholder="'outline.goals' | translate">
        </div>
        <div class="box-col box-col-8 border-right">
          <span class="print">{{session.gear_markers}}</span><input type="text" class="no-print" v-model="session.gear_markers" :placeholder="'outline.markers' | translate">
        </div>
        <div class="box-col box-col-8 border-right">
          <span class="print">{{session.gear_cones_1}}</span><input type="text" class="no-print" v-model="session.gear_cones_1" :placeholder="'outline.cones1' | translate">
        </div>
          <div class="box-col box-col-8 border-right">
            <span class="print">{{session.gear_cones_2}}</span><input type="text" class="no-print" v-model="session.gear_cones_2" :placeholder="'outline.cones2' | translate">
        </div>
        <div class="box-col box-col-8 border-right">
            <span class="print">{{session.gear_poles}}</span><input type="text" class="no-print" v-model="session.gear_poles" :placeholder="'outline.poles' | translate">
        </div>
        <div class="box-col box-col-8 border-right">
            <span class="print">{{session.gear_ladders}}</span><input type="text" class="no-print" v-model="session.gear_ladders" :placeholder="'outline.ladders' | translate">
        </div>
        <div class="box-col box-col-8">
          <span class="print">{{session.gear_other}}</span><input type="text" class="no-print" v-model="session.gear_other" :placeholder="'outline.otherAccessories' | translate">
        </div>
      </div>
      </div>
    </f7-block>
  </div>
</template>

<script>
import CONSTANTS from "@/utils/constants.js"
import privateOutlinesServices from "@/services/PrivateOutlinesServices.js"

export default {
name: 'Infooutline',
props: {
  config: {
    type: Object,
    required: true,
  },
  session: {
    type: Object,
    required: true,
  },
},
data () {
  return {
    ageCategories: CONSTANTS.AGE_CATEGORIES,
    categories: [],
  }
},
mounted() {
  if (this.isAuthenticated) {
    this.fetchCategories();
  }
},
methods: {
  async fetchCategories() {
      try {
        const responseDataCategories = await privateOutlinesServices.listAllCategories();
        this.categories = responseDataCategories.data
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the list of private outline categories. Please try again or contact your administrator.",
                        position: "bottom",
          
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
}
}
</script>