<template>
    <div class="full-view full-view-privateoutlines">
        <div class="d-flex justify-content-between align-items-start w-100">
            <div>
                <div class="d-flex align-items-center">
                    <h4 class="mb-1 mt-1 font-weight-bold text-uppercase mr-2">{{ 'global.publications' | translate }}</h4>
                    <div v-if="isAuthenticated" class="d-flex align-items-center">
                        <h4 v-if="!isSubscription" class="mb-0 mt-0 ml-2 font-weight-bold text-uppercase mr-2" style="margin-right: 5px;">{{ 'books.full' | translate }}</h4>
                        <div v-if="!isSubscription" class="custom-select">
                            <label class="toggle toggle-init color-black">
                                <input type="checkbox" v-model="showFull" @change="handleFilter()" :disabled="loader" />
                                <span class="toggle-icon"></span>
                            </label>
                        </div>
                    </div>
                    
                </div>
                <div v-if="isAuthenticated" class="d-flex align-items-center">
                    <h4 class="mb-0 mt-0 font-weight-bold text-uppercase mr-2" style="margin-right: 5px;">{{ 'books.category' | translate }}</h4>
                    <div class="custom-select">
                        <select name="category" v-model="actualCategory" @change="handleFilter()" :disabled="loader">
                        <option value="">{{ 'global.all' | translate }}</option>
                            <option v-for="category in selectBooksCategories" :key="category.id" :value="category.id" :selected="category.id == actualCategory">
                                {{category.text | translate}}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="isAuthenticated" class="d-flex align-items-center">
                    <h4 class="mb-1 mt-1 font-weight-bold text-uppercase mr-2" style="margin-right: 5px;">{{ 'books.partners' | translate }}</h4>
                    <div class="custom-select">
                        <select name="partners" v-model="actualPartner" @change="handleFilter()" :disabled="loader">
                            <option value="">{{ 'global.all2' | translate }}</option>
                            <option v-for="partner in selectBooksPartners" :key="partner.id" :value="partner.id" :selected="partner.id == actualPartner">
                                {{partner.text}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <f7-link class="mt-1" @click="closeBooksViews()">
                <img height="20px" src="/static/icons/close-2.svg" alt />
            </f7-link>
        </div>

        <div v-if="isAuthenticated">
        
        <div class="d-flex hidden overflow-x-scroll" v-if="filteredBooks.length > 0">
            <div class="wrapper-custom-card d-flex" v-for="(book) in filteredBooks" :key="book.id" @click="changeBook(book)">
              <f7-card class="custom-card cursor-pointer" :class="book.id == bookInfo.id ? 'active' : ''" :id="book.id == bookInfo.id ? 'activeBook' : ''">
                <div class="text-left d-flex justify-content-start align-items-center w-100" :class="book.demo ? 'opacity-demo' : ''">
                  <img height="90px" :src="`${host}${book.cover}`" alt />
                </div>
              </f7-card>
            </div>
        </div>

        <loader v-if="loader"></loader>

        <div v-else class="wrapper-content hidden overflow-y-scroll">
            <div v-if="filteredBooks.length > 0">
                <div class="homeHeaderCard">
                        <div class="header">
                            <p>
                                {{ getLang=='pl' ? bookInfo.title : bookInfo.title_ang }}
                            </p>
                            <span class="font-weight-bold text-uppercase fullSubscription" v-if="isSubscription">
                                <f7-link :tooltip="'books.full_extend' | translate">
                                    {{ 'books.full' | translate }}*
                                </f7-link>
                            </span>
                            <span v-else :class="bookInfo.demo ? 'demo' : 'full'">
                                <template v-if="bookInfo.demo">{{ 'books.demo' | translate }}</template>
                                <template v-else>{{ 'books.full' | translate }}</template>
                            </span>
                        </div>
                        <f7-list form no-hairlines-md @submit.prevent="handleSearch">
                            <f7-list-input
                            :label="'books.search_exercise' | translate"
                            type="text"
                            :placeholder="'books.exercise_name' | translate"
                            :value="searchQuery"
                            @input="searchQuery = $event.target.value"
                            clear-button
                            >
                            </f7-list-input>
                        </f7-list>
                    </div>
                
                <div v-for="outline in exercises" :key="outline.id">
                    <div v-if="outline.partIntroductory">
                        <div class="part-exercise" v-for="exercise in outline.partIntroductory" :key="exercise.id" v-if="checkSearchOutline(exercise.name, outline.id, 'część wstępna')">
                           <div class="d-flex align-items-center wrapper-text cursor-pointer w-100" @click="openPopup({...exercise, keyImage: bookInfo.keyImage, type: 'session', id: outline.id})">
                                <img v-if="exercise.image && exercise.image.length > 0" class="image" :src="`static/${bookInfo.keyImage}/session-${outline.id}/${exercise.image[0]}`">
                                <img v-else class="image" src="static/blank.png" alt="">
                                <p><i>{{ 'books.outline' | translate }} {{outline.id}} {{ 'outline.introductionName' | translate }}:</i></p>
                                <p class="title mt-0 mb-0 font-weight-bold">{{exercise.name}}</p>
                                <p class="description mt-0 mb-0" v-for="enter in exercise.content" :key="enter.id" v-html="enter"></p>
                            </div>
                            <div @click="moveExerciseFromOutline(exercise, bookInfo.keyImage, outline.id)" class="text-truncate">
                                <f7-button raised small style="margin-left: 5px">
                                    <img class="icon" height="10px" src="/static/icons/select-arrow.svg" alt />
                                </f7-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="outline.partIntroductoryI">
                        <div class="part-exercise" v-for="exercise in outline.partIntroductoryI" :key="exercise.id" v-if="checkSearchOutline(exercise.name, outline.id, 'część wstępna I')">
                            <div class="d-flex align-items-center wrapper-text cursor-pointer w-100" @click="openPopup({...exercise, keyImage: bookInfo.keyImage, type: 'session', id: outline.id})">
                                <img v-if="exercise.image && exercise.image.length > 0" class="image" :src="`static/${bookInfo.keyImage}/session-${outline.id}/${exercise.image[0]}`">
                                <img v-else class="image" src="static/blank.png" alt="">
                                <p><i>{{ 'books.outline' | translate }} {{outline.id}} {{ 'outline.introduction1Name' | translate }}:</i></p>
                                <p class="title mt-0 mb-0 font-weight-bold">{{exercise.name}}</p>
                                <p class="description mt-0 mb-0" v-for="enter in exercise.content" :key="enter.id" v-html="enter"></p>
                            </div>
                            <div @click="moveExerciseFromOutline(exercise, bookInfo.keyImage, outline.id)" class="text-truncate">
                                <f7-button raised small style="margin-left: 5px">
                                    <img class="icon" height="10px" src="/static/icons/select-arrow.svg" alt />
                                </f7-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="outline.partIntroductoryII">
                        <div class="part-exercise" v-for="exercise in outline.partIntroductoryII" :key="exercise.id" v-if="checkSearchOutline(exercise.name, outline.id, 'część wstępnaI')">
                          <div class="d-flex align-items-center wrapper-text cursor-pointer w-100" @click="openPopup({...exercise, keyImage: bookInfo.keyImage, type: 'session', id: outline.id})">
                                <img v-if="exercise.image && exercise.image.length > 0" class="image" :src="`static/${bookInfo.keyImage}/session-${outline.id}/${exercise.image[0]}`">
                                <img v-else class="image" src="static/blank.png" alt="">
                                <p><i>{{ 'books.outline' | translate }} {{outline.id}} {{ 'outline.introduction2Name' | translate }}:</i></p>
                                <p class="title mt-0 mb-0 font-weight-bold">{{exercise.name}}</p>
                                <p class="description mt-0 mb-0" v-for="enter in exercise.content" :key="enter.id" v-html="enter"></p>
                            </div>
                            <div @click="moveExerciseFromOutline(exercise, bookInfo.keyImage, outline.id)" class="text-truncate">
                                <f7-button raised small style="margin-left: 5px">
                                    <img class="icon" height="10px" src="/static/icons/select-arrow.svg" alt />
                                </f7-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="outline.partMainI">
                        <div class="part-exercise" v-for="exercise in outline.partMainI" :key="exercise.id" v-if="checkSearchOutline(exercise.name, outline.id, 'część główna I')">
                            <div class="d-flex align-items-center wrapper-text cursor-pointer w-100" @click="openPopup({...exercise, keyImage: bookInfo.keyImage, type: 'session', id: outline.id})">
                                <img v-if="exercise.image && exercise.image.length > 0" class="image" :src="`static/${bookInfo.keyImage}/session-${outline.id}/${exercise.image[0]}`">
                                <img v-else class="image" src="static/blank.png" alt="">
                                <p><i>{{ 'books.outline' | translate }} {{outline.id}} {{ 'outline.main1Name' | translate }}:</i></p>
                                <p class="title mt-0 mb-0 font-weight-bold">{{exercise.name}}</p>
                                <p class="description mt-0 mb-0" v-for="enter in exercise.content" :key="enter.id" v-html="enter"></p>
                            </div>
                            <div @click="moveExerciseFromOutline(exercise, bookInfo.keyImage, outline.id)" class="text-truncate">
                                <f7-button raised small style="margin-left: 5px">
                                    <img class="icon" height="10px" src="/static/icons/select-arrow.svg" alt />
                                </f7-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="outline.partMainII">
                        <div class="part-exercise" v-for="exercise in outline.partMainII" :key="exercise.id" v-if="checkSearchOutline(exercise.name, outline.id, 'część główna II')">
                            <div class="d-flex align-items-center wrapper-text cursor-pointer" @click="openPopup({...exercise, keyImage: bookInfo.keyImage, type: 'session', id: outline.id})">
                                <img v-if="exercise.image && exercise.image.length > 0" class="image" :src="`static/${bookInfo.keyImage}/session-${outline.id}/${exercise.image[0]}`">
                                <img v-else class="image" src="static/blank.png" alt="">
                                <p><i>{{ 'books.outline' | translate }} {{outline.id}} {{ 'outline.main2Name' | translate }}:</i></p>
                                <p class="title mt-0 mb-0 font-weight-bold">{{exercise.name}}</p>
                                <p class="description mt-0 mb-0" v-for="enter in exercise.content" :key="enter.id" v-html="enter"></p>
                            </div>
                            <div @click="moveExerciseFromOutline(exercise, bookInfo.keyImage, outline.id)" class="text-truncate">
                                <f7-button raised small style="margin-left: 5px">
                                    <img class="icon" height="10px" src="/static/icons/select-arrow.svg" alt />
                                </f7-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="outline.partFinal">
                        <div class="part-exercise" v-for="exercise in outline.partFinal" :key="exercise.id" v-if="checkSearchOutline(exercise.name, outline.id, 'część końcowa')">
                            <div class="d-flex align-items-center wrapper-text cursor-pointer w-100" @click="openPopup({...exercise, keyImage: bookInfo.keyImage, type: 'session', id: outline.id})">
                                <img v-if="exercise.image && exercise.image.length > 0" class="image" :src="`static/${bookInfo.keyImage}/session-${outline.id}/${exercise.image[0]}`">
                                <img v-else class="image" src="static/blank.png" alt="">
                                <p><i>{{ 'books.outline' | translate }} {{outline.id}} {{ 'outline.finalName' | translate }}:</i></p>
                                <p class="title mt-0 mb-0 font-weight-bold">{{exercise.name}}</p>
                                <p class="description mt-0 mb-0" v-for="enter in exercise.content" :key="enter.id" v-html="enter"></p>
                            </div>
                            <div @click="moveExerciseFromOutline(exercise, bookInfo.keyImage, outline.id)" class="text-truncate">
                                <f7-button raised small style="margin-left: 5px">
                                    <img class="icon" height="10px" src="/static/icons/select-arrow.svg" alt />
                                </f7-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="outline.typeElem == 'exercise'">
                        <div class="part-exercise" v-for="exercise in outline.part" :key="exercise.id" v-if="checkSearch(outline.title)">
                            <div class="d-flex align-items-center wrapper-text cursor-pointer w-100" @click="openPopup({...exercise, name: outline.title, keyImage: bookInfo.keyImage, type: 'exercise'})">
                                <img v-if="exercise.image && exercise.image.length > 0" class="image" :src="`static/${bookInfo.keyImage}/${exercise.image[0]}`">
                                <img v-else class="image" src="static/blank.png" alt="">
                                <p class="title mt-0 mb-0 font-weight-bold" v-text="outline.title"></p>
                                <p class="description mt-0 mb-0 mr-1" v-for="enter in exercise.content" :key="enter.id" v-html="enter"></p>
                            </div>
                            
                            <div @click="moveExerciseFromExercise(exercise, outline.title, bookInfo.keyImage)" class="text-truncate">
                                <f7-button raised small style="margin-left: 5px">
                                    <img class="icon" height="10px" src="/static/icons/select-arrow.svg" alt />
                                </f7-button>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="info-demo" v-if="bookInfo.demo">
                    <f7-block class="text-center">
                        <f7-block>
                        <p>{{ 'global.no_access_books_1' | translate}}</p>
                        </f7-block>
                        <div v-if="bookInfo.shop !== '' && getLang === 'pl'">
                            <f7-button :href="bookInfo.shop" external target="_blank" class="font-weight-bold external" style="margin: 0 8px; background: #d62029; color: white;"><div><img height="12px" src="/static/icons/shopping-cart.svg" alt /> {{ 'global.buy_single' | translate}}!</div></f7-button>
                            <p class="text-uppercase">{{ 'global.or' | translate}}</p>
                        </div>
                        <f7-button href="/payments/" class="font-weight-bold" style="margin: 0 8px; background: #0fa300; color: white;"><div><img height="12px" src="/static/icons/shopping-cart.svg" alt />  {{ 'global.buy_subscriptions' | translate }}!</div></f7-button>
                        <p>{{ 'global.no_access_books_2' | translate}}</p>

                        <f7-link href="https://soccer-sessions.com" external target="_blank"><strong>www.soccer-sessions.com</strong></f7-link>
                        <p>{{ 'global.or_contact' | translate}}</p>
                        <f7-link href="mailto:contact@soccer-sessions.com" external><strong>contact@soccer-sessions.com</strong></f7-link>
                    </f7-block>
                </div>
                <f7-popup :opened="popupOpened" @popup:closed="popupOpened = false">
                <f7-page>
                    <f7-navbar>
                        <f7-nav-title>
                        <span style="margin-left: 20px">{{ 'books.preview' | translate }}</span>
                        </f7-nav-title>
                        <f7-nav-right>
                        <f7-link popup-close>
                            <img height="20px" style="margin-left: 0px" src="/static/icons/close.svg" alt />
                        </f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <f7-block>
                        <p v-if="actualEnter.name" class="font-weight-bold">{{actualEnter.name}}</p>
                        <div v-if="actualEnter.image && actualEnter.image.length > 0" >
                            <img v-if="actualEnter.type == 'session'" class="w-100" :src="`static/${bookInfo.keyImage}/session-${actualEnter.id}/${actualEnter.image[0]}`" alt="">
                            <img v-else class="w-100" :src="`static/${bookInfo.keyImage}/${actualEnter.image[0]}`" alt="">
                        </div>
                        <div v-if="actualEnter.content && actualEnter.content.length > 0">
                            <p v-for="content in actualEnter.content" :key="content.id" v-html="content"></p>
                        </div>
                        <div v-if="actualEnter.mod && actualEnter.mod.length > 0">
                            <p class="font-weight-bold">Modyfikacja:</p>
                            <p v-for="mod in actualEnter.mod" :key="mod.id" v-html="mod"></p>
                        </div>
                    </f7-block>
                    </f7-page>
                </f7-popup>
            </div>
            <div v-else class="text-center">
                <img height="40px" src="/static/icons/football-2.svg" alt />
                <p class="font-weight-bold text-uppercase">{{ 'books.empty_publications' | translate }}</p>
            </div>
        </div>
        </div>
        <div v-else>
          <f7-block class="text-center">
            <img style="height: 150px;" src="/static/icons/red-card.svg" alt />
          </f7-block>
          <f7-block class="text-center text-uppercase ">
            <h4>Nie masz dostępu!</h4>
          </f7-block>
          <f7-block class="text-center">Aby dostać się w to miejsce musisz posiadać konto w aplikacji :)</f7-block>
          <f7-block class="text-center" style="max-width: 480px; margin: 0 auto;">
            <f7-button href="/login/" raised>Zaloguj się</f7-button>
          </f7-block>
        </div>
    </div>
</template>


<script>
import Loader from "@/components/Loader";
import CONSTANTS from "@/utils/constants.js"

export default {
  name: 'Exerciseonbooks',
  components: {
    Loader
  },
  props: {
    profileBooks: {
      type: Array,
      required: true,
    },
    books: {
        type: Array,
        required: true,
    },
    isSubscription: {
        type: Boolean,
        required: true
    },
    typePrivate: {
        type: String,
        required: true,
    }
  },
  data () {
    return {
        selectBooksCategories: CONSTANTS.SELECT_BOOKS_CATEGORIES,
        selectBooksPartners: CONSTANTS.SELECT_BOOKS_PARTNERS,
        loader: false,
        exercises: {},
        bookInfo: {},
        searchQuery: '',
        filteredBooks: [],
        showFull: false,
        actualCategory: '',
        actualPartner: '',
        popupOpened: false,
        actualEnter: {}
    }
  },
  watch: {
    getLang: {
      handler(val) {
        this.changeBook(this.filteredBooks.find(elem => elem.id == this.bookInfo.id))
      },
      deep: true
    }
  },
  mounted() {
    this.$f7.toast.close();
    if (this.isAuthenticated) {
        this.loader = true
        this.showFull = false
        this.connectBooks()
        const isBookIdInFilteredBooks = this.filteredBooks.some(elem => elem.id === this.getActiveFilteredBookId);
        if (this.getActiveFilteredBookId !== null && isBookIdInFilteredBooks) {
            this.changeBook(this.filteredBooks.find(elem => elem.id == this.getActiveFilteredBookId))
            setTimeout(() => { 
                document.getElementById('activeBook').scrollIntoView();
            }, 100);
        } else {
            this.changeBook(this.filteredBooks[0])
        }
        if (!this.isSubscription) {
          this.$f7.toast.create({
            text: 'You do not have access to all publications in the database. Get access by purchasing subscriptions!',
            position: 'bottom',
            closeTimeout: 5000,
          }).open();
        }
    } else {
        this.$f7.toast.create({
          text: 'Only logged in user has access to purchased publications. Log in.',
          position: 'bottom',
          closeTimeout: 5000,
        }).open();
    }
  },
  methods: {
    openPopup(enter) {
      this.popupOpened = true;
      this.actualEnter = enter;
    },
    connectBooks() {
        this.filteredBooks = []
        if (this.isSubscription) {
            this.filteredBooks = this.profileBooks
        } else {
            this.books.forEach((elem) => {
                this.profileBooks.some((item) => {
                    if (item.connect == elem.connect) {
                        elem = item;
                    }
                });
                this.filteredBooks.push(elem)
            })
        }

    },
      prepareData(data) {
        let prepareContentJson = null

        if (this.getLang == 'pl') {
            if (data.content == null) {
                prepareContentJson = data.content_ang.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
            } else {
                prepareContentJson = data.content.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
            }
        } 
        if (this.getLang == 'en') {
            if (data.content_ang == null) {
                prepareContentJson = data.content.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
            } else {
                prepareContentJson = data.content_ang.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
            }
        }
        

        const contentBook = JSON.parse(prepareContentJson).data
        this.exercises = contentBook.filter((elem) => elem.typeElem == 'session' || elem.typeElem == 'exercise')
        this.bookInfo = {
            "title": data.title,
            "title_ang": data.title_ang,
            "demo": data.demo,
            "id": data.id,
            "shop": data.shop,
            "cover": data.cover,
            "description": data.description,
            "keyImage": data.key_image,
            "company": data.company,
        }
      },
      handleFilter() {
        this.loader = true

        this.connectBooks()
        if (this.showFull) {
            this.filteredBooks = this.filteredBooks.filter(elem => elem.demo == !this.showFull)
        }
        if (this.actualCategory !== '') {
            this.filteredBooks = this.filteredBooks.filter(elem => elem.category == this.actualCategory)
        }
        if (this.actualPartner !== '') {
            this.filteredBooks = this.filteredBooks.filter(elem => elem.partners == this.actualPartner)
        }

        if (this.filteredBooks.length > 0) {
            this.changeBook(this.filteredBooks[0])
        } else {
            setTimeout(() => { 
                this.loader = false
            }, 100);
        }
      },
      changeBook(book) {
          this.loader = true
          this.searchQuery = '';
          this.$store.dispatch("appkonspektypilkanozna/setFilteredBookId", book.id);
          this.prepareData(book)
          setTimeout(() => { 
            this.loader = false
        }, 100);
      },
      checkSearchOutline(title, outlineId, part) {
          const outline = `Outline ${outlineId}`;
          return title.toLowerCase().includes(this.searchQuery.toLowerCase()) || outline.toLowerCase().includes(this.searchQuery.toLowerCase()) || part.toLowerCase().includes(this.searchQuery.toLowerCase())
      },
      checkSearch(title) {
          return title.toLowerCase().includes(this.searchQuery.toLowerCase())
      },
      closeBooksViews() {
          this.$emit('on-close-books-views');
      },
      moveExerciseFromOutline(fromOutline, keyImage, outlineId) {
           const typeCopied = {
            typeCopied: this.isSubscription ? 2 : 1
          }
          const mainImage = {
              mainImage: fromOutline.image[0] ? `/static/${keyImage}/session-${outlineId}/${fromOutline.image[0]}` : ''
          }
          if (this.typePrivate == 'outline') {
            this.$emit('move-exercise-to-outline', {...fromOutline, ...mainImage, ...typeCopied});
          }
          if (this.typePrivate == 'exercise') {
            this.$emit('move-exercise-to-exercise', {...fromOutline, ...mainImage, ...typeCopied});
          }
      },
      moveExerciseFromExercise(fromExercise, nameExercise, keyImage) {
          const typeCopied = {
            typeCopied: this.isSubscription ? 2 : 1
          }
          const name = {
              name: nameExercise
          }
          const mainImage = {
              mainImage: fromExercise.image[0] ? `/static/${keyImage}/${fromExercise.image[0]}` : ''
          }
          if (this.typePrivate == 'outline') {
            this.$emit('move-exercise-to-outline', {...fromExercise, ...name, ...mainImage, ...typeCopied});
          }
          if (this.typePrivate == 'exercise') {
            this.$emit('move-exercise-to-exercise', {...fromExercise, ...name, ...mainImage, ...typeCopied});
          }
      },
      handleSearch(e) {
          e.preventDefault();
      }
  }
}
</script>