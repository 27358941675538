<template>
  <f7-page login-screen>
    <navbar></navbar>
    <f7-block class="text-center text-uppercase ">
      <h4>Często zadawane pytania</h4>
    </f7-block>
    <f7-block>
      <f7-list accordion-list accordion-opposite>
        <f7-list-item accordion-item title="Jak wygląda rozliczanie się z partnerami oraz portfel partnera?">
          <f7-accordion-content>
            <f7-block>
              <p>
                Z końcem każdego kwartału jest zamykany okres rozliczeniowy dla każdego partnera. Do 10 dni na saldzie partnera pojawią się pieniądze. Partner sam decyduje kiedy i jakie środki chce wypłacić. Minimalna kwota wypłaty to 300 PLN. Każdy partner jest rozliczany we własnej walucie w takiej jaka jest w kraju danego partnera. 
              </p>
              <img width="100%" src="/static/faq/wallet-1.png" alt />
            </f7-block>
            <f7-block>
              <p>
                Po zleceniu wypłaty w płatnościach partnera pojawia się pozycja zleconej płatności.
              </p>
              <img width="100%" src="/static/faq/wallet-2.png" alt />
            </f7-block>
            <f7-block>

              <p><strong>Oczekuje na dodanie faktury</strong>
                - partner zlecił wypłatę i teraz musi wystawić fakturę dla firmy Soccer Sessions (w przypadku innej formy prosimy o kontakt).
              </p>
              <img height="50px" src="/static/faq/wallet-3.png" alt />
              <p><strong>Potrzebna korekta</strong>
                -  jeśli wystawiona faktura zawiera błędy to partner dostanie informację dotycząca zmiany (prywatny kontakt mailowy).
              </p>
              <img height="50px" src="/static/faq/wallet-4.png" alt />
              <p><strong>W trakcie przetwarzania</strong>
                - przygotowywanie dokumentów oraz przelewu do partnera.
              </p>
              <img height="50px" src="/static/faq/wallet-5.png" alt />
              <p><strong>Płatność zrealizowana</strong>
                - pieniądze zostały wysłane na konto bankowe partnera.
              </p>
              <img height="50px" src="/static/faq/wallet-6.png" alt />

            </f7-block>
            <f7-block>
              <p>
                Po zleceniu wypłaty w płatnościach partnera zmienia się ikonka oraz dodana jest data wykonania przelewu.
              </p>
              <img width="100%" src="/static/faq/wallet-7.png" alt />

              <p>Wystawioną fakturę należy wysłać na adres e-mail <f7-link href="mailto:contact@soccer-sessions.com" external><strong>contact@soccer-sessions.com</strong></f7-link><br><br>
                Dane do faktury:<br>
                PKSTUDIO - PATRYK KLIMCZAK<br>
                ul. Janusza Kusocińskiego 1/36, 78-200 Białogard <br>
                NIP: 6711790583
              </p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
    </f7-block>
  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>