<template>
  <f7-page login-screen>
    <navbarlist :list="'dues'"></navbarlist>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{ 'club.dues' | translate }}
              <img class="cursor-pointer" @click="showDialogDeleteDue(due)" height="14px" src="/static/icons/trash.svg" alt />
            </h4>
          </f7-block>
          <f7-list form no-hairlines-md @submit.prevent="updateDue">
            <f7-list-item :title="'club.group' | translate" class="custom-select">
              <select name="group" v-model="due.group">
                <option v-if="groups.length == 0" value="" disabled>{{ 'club.lack_group' | translate }}</option>
                <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == due.group">
                    {{group.name}}
                </option>
              </select>
            </f7-list-item>
            <f7-list-input
              :label="'club.date_of_payment' | translate"
              type="date"
              :placeholder="'club.premium_payment_date' | translate"
              required
              validate
              :error-message="'global.required' | translate"
              :value="due.date_of_payment"
              @input="due.date_of_payment = $event.target.value"
            >
            </f7-list-input>
            <f7-list-item class="custom-item-inside-list-header dues">
              <h4 class="text-uppercase">{{ 'club.players' | translate }}</h4>
              <span class="due">{{ 'club.amount_dues' | translate }} ({{currency[account.currency]}})</span>
            </f7-list-item>
            <f7-list-item class="custom-item-inside-list" v-for="player in players" :key="player.id">
              <div class="d-flex justify-content-between w-100 align-items-center">
                <span>
                  <f7-link v-if="player.status_active" :tooltip="'club.status_active' | translate">
                    <div style="width: 10px; height: 10px; background-color: green; border-radius: 50%;"></div>
                  </f7-link>
                  <f7-link v-else :tooltip="'club.status_not_active' | translate">
                    <div style="width: 10px; height: 10px; background-color: red; border-radius: 50%;"></div>
                  </f7-link>
                  {{player.first_name}} {{player.last_name}}
                </span>
                <div class="d-flex">
                  <input type="number" min="0" class="custom-text" v-model="player.due_price">
                  <input type="checkbox" class="custom-checkbox" v-model="player.due">
                </div>
              </div>
            </f7-list-item>
            <f7-block class="text-center">
              <f7-button v-if="!loaderDue" type="submit" raised>
                {{ 'club.save_dues' | translate }}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import CONSTANTS from "@/utils/constants.js"
import Navbarlist from "@/components/Navbar/Navbarlist.vue";
import Loader from "@/components/Loader";
import duesServices from "@/services/DuesServices.js"
import groupsServices from "@/services/GroupsServices.js"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  components: {
    Navbarlist,
    Loader,
  },
  props: {
    idDue: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      loader: false,
      loaderDue: false,
      due: {
        group: '',
        paid_dues: [],
        date_of_payments: ''
      },
      currency: CONSTANTS.CURRENCY,
      groups: [],
      players: []
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchDataUser().then(() => {
          this.fetchDue(this.idDue)
      })
    }
  },
  methods: {
    async fetchDue(id) {
      try {
        this.loader = true;

        const responseDataDue = await duesServices.itemDue(id);
        this.due = responseDataDue.data
        this.fetchGroup(this.due.group)
        this.fetchAllPlayers(this.due.group)
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with the premium. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    async fetchGroup(id) {
      try {
        this.loader = true;

        const responseDataGroup = await groupsServices.itemGroup(id);
        this.groups.push(responseDataGroup.data)
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with the group. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    async fetchAllPlayers(group) {
      try {
        this.loader = true;

        const responseDataAllPlayers = await groupsServices.listAllPlayers(group);
        this.players = responseDataAllPlayers.data
        
        this.players.forEach((player) => {
          player.due = this.due.paid_dues.includes(player.id)
        })

      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with the list of players in the group. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    showDialogDeleteDue(due) {
        this.$f7.dialog.confirm(`Are you sure you want to remove the premium for the month ${due.date_of_payment}? The change will be irreversible!`, '', () => {
          this.deleteDue(due.id)
        });
    },
    async deleteDue(id) {
      try {
        const responseDeleteDue = await duesServices.deleteDue(id);
        this.$f7router.navigate(`/dues/?group=${this.due.group}`)
        this.$f7.toast
          .create({
            text: "The contribution has been removed!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with deleting the premium. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
    async updateDue() {
      if (this.due.group == '') {
        return this.$f7.toast
          .create({
            text: "No group",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
      }
      this.loaderDue = true;
      try {
        this.due.paid_dues = []
        
        this.loaderDue = true;
        
        this.players.forEach((player) => {
          if (player.due) {
            this.due.paid_dues.push(player.id)
          }
        })

        const responseUpdateDue = await duesServices.due(this.due);
        if ('access' in responseUpdateDue.data && !responseUpdateDue.data.access) {
            this.$f7.toast
            .create({
              text: "Access to the module has expired!",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        } else {
          this.$f7router.navigate(`/dues/?group=${this.due.group}`)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the premium edit. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderDue = false;
    },
  }
};
</script>