<template>
  <f7-page login-screen>
    <navbarcalendarcrm></navbarcalendarcrm>

    <loader v-if="loader"></loader>

    <div v-else>
        <f7-block class="text-uppercase">
          <h4>{{ 'club.dues_list' | translate}} <f7-link class="p-1" :tooltip="'popover.dues_list' | translate"><img height="11px" src="/static/icons/help.svg" alt /></f7-link></h4>
          <div class="d-flex justify-content-start align-items-center">
            <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{  'club.group' | translate}}: </h4>
            <div class="custom-select">
                  <select name="group" v-model="actualGroup" @change="changeGroup(actualGroup)">
                      <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == actualGroup">
                          {{group.name}}
                      </option>
                  </select>
              </div>
          </div>
          <f7-button v-if="!groups.length > 0" href="/groups/" raised>{{'club.need_create_group' | translate}}!</f7-button>
          <div class="countAll">
            <span>
              <p>{{ 'club.count' | translate}}</p>
              <template v-if="dues.count">
                {{dues.count}}
              </template>
              <template v-else>
                0
              </template>
            </span>
          </div>
        </f7-block>
        <div class="listCards" v-if="dues.count > 0">
          <f7-block class="mt-0">
            <f7-row>
              <f7-col v-for="due in dues.results" :key="due.id" width="100" class="position-relative">
                <div class="delete" @click.stop="showDialogDeleteDue(due)">
                  <img height="20px" src="/static/icons/trash.svg" alt />
                </div>
                <a :href="`/dues/${due.id}/`">
                <f7-card class="custom-card">
                  <div>
                    <p>{{ 'club.dues_for' | translate }} {{getMonthYear(due.date_of_payment)}}</p>
                    <p class="info">{{  'club.date_of_payment' | translate}}: {{due.date_of_payment}}</p>
                  </div>
                </f7-card>
                </a>
              </f7-col>
              
            </f7-row>
          </f7-block>
        </div>

        <div v-else class="text-center text-uppercase">
            <img height="100px" src="/static/icons/dues.svg" alt />
            <h4>{{'club.empty_dues' | translate}}!</h4>
        </div>

        <f7-block>
          <div class="d-flex justify-content-between aling-items-center custom-pagination-view">
            <div class="pagination-item">
              <div v-if="dues.previous" @click="fetchDues(actualGroup, dues.previous)">
                <img class="cursor-pointer" height="30px" src="/static/icons/previous.svg" alt />
              </div>
            </div>
            <div class="pagination-item">
              <a :href="`/create-due/?group=${actualGroup}`">
                <img class="cursor-pointer" height="30px" src="/static/icons/plus.svg" alt="">
              </a>
            </div>
            <div class="pagination-item">
              <div v-if="dues.next" @click="fetchDues(actualGroup, dues.next)">
                <img class="cursor-pointer" height="30px" src="/static/icons/next.svg" alt />
              </div>
            </div>
          </div>
        </f7-block>

    </div>

    <div class="custom-fab">
        <div class="box box-zoom cursor-pointer" @click="goToCreate()">
            <f7-link :tooltip="'global.add_new' | translate" >
              <img height="20px" src="/static/icons/plus-add.svg" alt="">
            </f7-link>
        </div>
      </div>

  </f7-page>
</template>

<script>
import moment from 'moment';
import Navbarcalendarcrm from "@/components/Navbar/Navbarcalendarcrm.vue";
import Loader from "@/components/Loader";
import mixinUser from "@/mixins/mixinUser"
import mixinGroups from "@/mixins/mixinGroups"
import duesServices from "@/services/DuesServices.js"

export default {
  mixins: [mixinGroups, mixinUser],
  components: {
    Navbarcalendarcrm,
    Loader
  },
  data: () => {
    return {
      loader: false,
      actualGroup: '',
      dues: {}
    };
  },
  watch: {
    'actualGroup': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchDues(this.actualGroup)
      }
    },
  },
  mounted() {
    this.fetchDataUser().then(() => {
        this.fetchGroups().then(() => {
            if (this.getRole == 'coach') {
              this.groups = this.profileGroups;
            }
            if (this.groups.length > 0) {
              if (this.$f7route.query.group !== undefined) {
                this.actualGroup = this.$f7route.query.group
              } else {
                const checkGroup = obj => obj.id === this.getActiveGroup;
                if (this.getActiveGroup !== '' && this.groups.some(checkGroup)) {
                  this.actualGroup = this.getActiveGroup
                } else {
                  this.actualGroup = this.groups[0].id
                }
              }
            }
        })
    })
  },
  methods: {
    goToCreate() {
      this.$f7router.navigate(`/create-due/?group=${this.actualGroup}`)
    },
    getMonthYear(date) {
       const month = moment(date).locale('pl').format('MMMM');
       const year = moment(date).format('YYYY')
       return `${month} ${year}`
    },
    async fetchDues(group, page) {
      try {
        this.loader = true;

        const responseDataDues = await duesServices.listDues(group, page);
        this.dues = responseDataDues.data
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the contribution list. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
    showDialogDeleteDue(due) {
        this.$f7.dialog.confirm(`Are you sure you want to remove the premium for the month ${due.date_of_payment}? The change will be irreversible!`, '', () => {
          this.deleteDue(due.id)
        });
    },
    async deleteDue(id) {
      try {
        const responseDeleteDue = await duesServices.deleteDue(id);
        this.fetchDues(this.actualGroup);
        this.$f7.toast
          .create({
            text: "The contribution has been removed!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with deleting the premium. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  }
};
</script>