<template>
  <f7-page login-screen>
    <navbar></navbar>
    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'global.coaching_notepad' | translate }}</h4>
    </f7-block>

    <f7-block class="text-center">
      <img width="100%" src="/static/sklep_kajet_mod_app_x.png" alt />
    </f7-block>
    <f7-block class="text-center text-uppercase ">
      <h4><i>{{ 'global.coaching_notepad' | translate }}</i> {{ 'global.coaching_notepad_description' | translate }}</h4>
    </f7-block>
    
    <f7-list accordion-list accordion-opposite>
        <f7-list-item accordion-item :title="'global.coaching_notepad_publications' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_publications_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_publications_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_publications_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_publications_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_publications_description_5' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.coaching_notepad_private_outlines' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_private_outlines_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_private_outlines_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_private_outlines_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_private_outlines_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_private_outlines_description_5' | translate }}</p>
              <p>{{ 'global.coaching_notepad_private_outlines_description_6' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.coaching_notepad_calendar' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_calendar_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_calendar_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_calendar_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_calendar_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_calendar_description_5' | translate }}</p>
              <p>{{ 'global.coaching_notepad_calendar_description_6' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.coaching_notepad_information_board' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_information_board_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_information_board_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_information_board_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_information_board_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_information_board_description_5' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item> 
        <f7-list-item accordion-item :title="'global.coaching_notepad_group' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_group_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_group_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_group_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_group_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_group_description_5' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.coaching_notepad_players' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_players_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_players_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_players_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_players_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_players_description_5' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.coaching_notepad_membership_dues' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_membership_dues_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_membership_dues_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_membership_dues_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_membership_dues_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_membership_dues_description_5' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.coaching_notepad_training_sessions' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_training_sessions_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_training_sessions_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_training_sessions_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_training_sessions_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_training_sessions_description_5' | translate }}</p>
              <p>{{ 'global.coaching_notepad_training_sessions_description_6' | translate }}</p>
              <p>{{ 'global.coaching_notepad_training_sessions_description_7' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.coaching_notepad_training_planning' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_training_planning_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_training_planning_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_training_planning_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_training_planning_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_training_planning_description_5' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.coaching_notepad_fields' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_fields_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_fields_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_fields_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_fields_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_fields_description_5' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.coaching_notepad_statistics' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_statistics_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_statistics_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_statistics_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_statistics_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_statistics_description_5' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.coaching_notepad_role' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_role_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_role_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_role_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_role_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_role_description_5' | translate }}</p>
              <p>{{ 'global.coaching_notepad_role_description_6' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.coaching_notepad_sms' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.coaching_notepad_sms_description_1' | translate }}</p>
              <p>{{ 'global.coaching_notepad_sms_description_2' | translate }}</p>
              <p>{{ 'global.coaching_notepad_sms_description_3' | translate }}</p>
              <p>{{ 'global.coaching_notepad_sms_description_4' | translate }}</p>
              <p>{{ 'global.coaching_notepad_sms_description_5' | translate }}</p>
              <p>{{ 'global.coaching_notepad_sms_description_6' | translate }}</p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>

<style lang="scss">
</style>