import Api from './index'
import store from '@/store'

export default {
  async createPlayingField(data) {
    return Api().post('playingfields/', data, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listPlayingFields() {
    return Api().get(`playingfields/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async itemPlayingField(id) {
    return Api().get(`playingfields/${id}/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async playingField(data) {
    return Api().put(`playingfields/${data.id}/`, data, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async deletePlayingField(id) {
    return Api().delete(`playingfields/${id}/`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  }
}