<template>
  <f7-page login-screen>
    <navbarlist :list="'coaches'"></navbarlist>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{ 'club.coach' | translate }}
              <img class="cursor-pointer" @click="showDialogDeleteCoach(coach)" height="14px" src="/static/icons/trash.svg" alt />
            </h4>
          </f7-block>
          <f7-list form no-hairlines-md @submit.prevent="updateCoach">
            <f7-list-input
              :label="'club.email' | translate"
              type="email"
              :placeholder="'club.email_coach' | translate"
              :value="coach.email"
              disabled
            >
            </f7-list-input>
             <f7-list-input
              :label="'club.first_name' | translate"
              type="text"
              :placeholder="'club.your_first_name' | translate"
              maxlength="64"
              :value="coach.first_name"
              @input="coach.first_name = $event.target.value"
              required
              validate
              autocomplete="off"
              :error-message="'global.required' | translate"
              clear-button
            ></f7-list-input>
            <f7-list-input
              :label="'club.last_name' | translate"
              type="text"
              :placeholder="'club.your_last_name' | translate"
              maxlength="64"
              :value="coach.last_name"
              @input="coach.last_name = $event.target.value"
              required
              validate
              autocomplete="off"
              :error-message="'global.required' | translate"
              clear-button
            ></f7-list-input>
            <f7-list-item v-if="groups.length > 0" :title="'club.groups' | translate" smart-select :smart-select-params="{openIn: 'popover'}">
              <select name="groups" multiple v-model="coach.groups">
                <option v-for="group in groups" :key="group.id" :value="group.id">
                    {{group.name}}
                </option>
              </select>
            </f7-list-item>
            <f7-button style="margin: 0px 16px;" v-if="!groups.length > 0" href="/groups/" raised>{{'club.need_create_group' | translate}}!</f7-button>
            <f7-block class="text-center">
              <f7-button v-if="!loaderCoach" type="submit" raised>
                  {{'club.update_coach_details' | translate}}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import Navbarlist from "@/components/Navbar/Navbarlist.vue";
import Loader from "@/components/Loader";
import coachesServices from "@/services/coachesServices.js"
import mixinUser from "@/mixins/mixinUser"
import mixinGroups from "@/mixins/mixinGroups"

export default {
  mixins: [mixinGroups, mixinUser],
  components: {
    Navbarlist,
    Loader
  },
  props: {
    idCoach: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      loader: false,
      loaderCoach: false,
      coach: {
        email: '',
        first_name: '',
        last_name: '',
        groups: [],
      },
    };
  },
  mounted() {
    this.fetchDataUser().then(() => {
      this.fetchGroups()
      this.coach.id = this.idCoach
      this.coach.email = this.profileCoaches.filter(elem => elem.id == this.idCoach)[0].email
      this.coach.first_name = this.profileCoaches.filter(elem => elem.id == this.idCoach)[0].first_name
      this.coach.last_name = this.profileCoaches.filter(elem => elem.id == this.idCoach)[0].last_name
      this.profileCoaches.filter(elem => elem.id == this.idCoach)[0].groups.forEach((elem) => {
        this.coach.groups.push(elem.id)
      })
    })
  },
  methods: {
    showDialogDeleteCoach(coach) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the trainer ${coach.first_name} ${coach.last_name}? The change will be irreversible!`, '', () => {
          this.deleteCoach(coach.id)
        });
    },
    async deleteCoach(id) {
      try {
        const responseDeleteCoach = await coachesServices.deleteCoach(id);
        this.$f7router.navigate(`/coaches/`)
        this.$f7.toast
          .create({
            text: "Coach removed from club and groups!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with removing the trainer. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
    async updateCoach() {
      if (this.coach.groups.length == 0) {
        this.$f7.toast
          .create({
            text: "You must assign the trainer to the group!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
          return
      }
      try {
        this.loaderCoach = true;

        const responseUpdateCoach = await coachesServices.coach(this.coach);
        if ('access' in responseUpdateCoach.data && !responseUpdateCoach.data.access) {
          if (responseUpdateCoach.data.type == 'no_match') {
            this.$f7.toast
            .create({
              text: "The coach is not assigned to the club!",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          }
        } else {
          this.$f7router.navigate(`/coaches/`)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the coach edit at the club. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderCoach = false;
    },
  }
};
</script>