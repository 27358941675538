<template>
  <f7-page class="pageCreateSession" :class="{ 'previewMode': previewMode }">
    <navbarcreateoutline :show="isEdit" :hide="!isBooksView && !isExercisesView" @on-show="togglePanel"></navbarcreateoutline>
    <div id="mark"></div>
    <loader v-if="loader"></loader>
    <main v-else id="outlineViewport">
      <div v-if="isEdit" class="no-print toolsPanel">
        <togglepaneloutline :config="config"></togglepaneloutline>
      </div>
      <f7-block>
        <div class="print logo">
          <img v-if="session.logo" :src="`${host}${session.logo}`">
        </div>
        <div class="print company d-flex justify-content-between text-center">
            <h4>www.soccer-sessions.com</h4>
        </div>
      </f7-block>

      <infooutline :config="config" :session="session"></infooutline>

        <div>
            <aside v-if="config.introduction1 && session.introductory_part_1" class="wrapperIntroduction1 wrapperPart">
              <f7-block class="wrapperColumn">
                <div>
                  <h2 class="no-print partName text-uppercase">{{ config.introduction1 && config.introduction2 ? 'outline.introduction1Name' : 'outline.introductionName' | translate }}</h2>
                  <img @click="collapsed('introductory_part_1')" class="iconAccordion no-print cursor-pointer" height="12px" :src="`/static/icons/line-angle-${accordion.introductory_part_1 ? 'up' : 'down'}.svg`" alt />
                  <div class="print wrapperPartName">
                    <p class="partName text-uppercase">{{ config.introduction1 && config.introduction2 ? 'outline.introduction1Name' : 'outline.introductionName' | translate }}</p>
                  </div>
                </div>
                  <div v-if="accordion.introductory_part_1" class="w-100">
                    <draggable class="list-group" :scroll-sensitivity="150" handle=".handleIntroductoryPart1"
    @end="endDrag"
    :force-fallback="true"
     :list="session.introductory_part_1.data" group="session">
                    <f7-row class="wrapperRow" v-for="(exercise, index) in session.introductory_part_1.data" :key="exercise.id">
                        <f7-col class="content" width="45">
                            <p class="print font-weight-bold text">{{exercise.topic}}</p>
                            <input class="no-print" v-model="exercise.topic" type="text" :placeholder="'outline.title' | translate"/>
                            <p class="print text white-space-pre-wrap text-left">{{exercise.description}}</p>
                            <textarea class="no-print autosize" rows="7" @input="autoResize" v-model="exercise.description" :placeholder="'outline.description' | translate"></textarea>
                            <div>
                                <p v-if="exercise.mod" class="font-weight-bold mod">{{ 'outline.modifications' | translate }}:</p>
                                
                                <p class="print text white-space-pre-wrap text-left">{{exercise.mod}}</p>
                                <textarea class="no-print" rows="2" @input="autoResize" v-model="exercise.mod" :placeholder="'outline.descriptionMod' | translate"></textarea>
                            </div>
                        </f7-col>
                      <f7-col class="imageOnExercise" width="45">
                        <div v-if="!exercise.img.graphics" class="no-print wrapperUpload d-flex justify-content-center">
                          <f7-button @click="addExerciseOnBooks(index, 'introductory_part_1')" :tooltip="'outline.addExerciseOnBooks' | translate" style="margin-right: 5px" class="d-flex" raised small :disabled="loaderBooks">
                              <img height="14px" class="icon" src="/static/icons/books.svg" alt />
                          </f7-button>
                           <f7-button class="d-flex" style="margin-right: 5px" raised small>
                              <img height="14px" class="icon" src="/static/icons/upload.svg" alt />
                          </f7-button>
                          <input type="file" @change="previewImage" @click="setActualPart(index, 'introductory_part_1')" accept="image/*" />
                          <f7-button @click="addPrivateExercise(index, 'introductory_part_1')" :tooltip="'outline.addPrivateExercise' | translate" class="d-flex" raised small>
                              <img height="14px" class="icon" src="/static/icons/cones.svg" alt />
                          </f7-button>
                        </div>
                            <div v-if="exercise.img.graphics" >
                                <div class="wrapperImageExercise">
                                  <div class="close text-center d-flex justify-content-center">
                                    <f7-button class="no-print" @click="showDialogRemoveImageExercise(exercise.id, 'introductory_part_1')" raised small>
                                      <img height="8px" src="/static/icons/close.svg" alt />
                                    </f7-button>
                                  </div>
                                  <div class="image">
                                    <div v-if="exercise.img.id">
                                      <img v-if="exercise.img.id == 'onBooks'" :src="`${hostApp}${exercise.img.graphics}`" alt="">
                                      <img v-else :src="`${host}${exercise.img.graphics}`" alt="">
                                    </div>
                                    <img v-else :src="exercise.img.graphics" alt="">
                                  </div>
                                </div>
                            </div>
                        </f7-col>
                        <f7-col width="5">
                          <p v-if="exercise.time" class="print font-weight-bold align-items-center"><span class="input-number">{{exercise.time}}</span>' <img height="14px" src="/static/icons/timer.svg" alt /></p>
                          <input class="time no-print" v-model="exercise.time" type="text" maxlength="2" :placeholder="'outline.time' | translate"/>
                        </f7-col>
                        <f7-col class="no-print m-0" width="5">
                          <div class="wrapperTrash d-flex ">
                            <div class="no-print cursor-pointer" @click="showDialogRemoveExercise(index, exercise.id, 'introductory_part_1')">
                              <img class="icon" height="22px" src="/static/icons/trash.svg" alt />
                            </div>
                          </div>
                          <div class="wrapperDrag d-flex handleIntroductoryPart1">
                            <div class="no-print cursor-pointer">
                              <img class="icon" height="22px" src="/static/icons/drag.svg" alt />
                            </div>
                          </div>
                        </f7-col>
                    </f7-row>
                    </draggable>
                  </div>
          </f7-block>
          <f7-block v-if="accordion.introductory_part_1" class="no-print text-center d-flex justify-content-center">
            <f7-button @click="addExercise('introductory_part_1')" raised small>
              <span class="text-uppercase">{{ 'outline.addExercise' | translate }}</span>
            </f7-button>
          </f7-block>
        </aside>
        <aside v-if="config.introduction2 && session.introductory_part_2" class="wrapperIntroduction2 wrapperPart">
            <f7-block class="wrapperColumn">
              <div>
                <h2 class="no-print partName text-uppercase">{{ config.introduction1 && config.introduction2 ? 'outline.introduction2Name' : 'outline.introductionName' | translate }}</h2>
                <img @click="collapsed('introductory_part_2')" class="iconAccordion no-print cursor-pointer" height="12px" :src="`/static/icons/line-angle-${accordion.introductory_part_2 ? 'up' : 'down'}.svg`" alt />
                  <div class="print wrapperPartName">
                    <h5 class="partName text-uppercase">{{ config.introduction1 && config.introduction2 ? 'outline.introduction2Name' : 'outline.introductionName' | translate }}</h5>
                  </div>
              </div>
                <div v-if="accordion.introductory_part_2" class="w-100">
                  <draggable class="list-group" :scroll-sensitivity="150" handle=".handleIntroductoryPart2"
    @end="endDrag"
    :force-fallback="true"
     :list="session.introductory_part_2.data" group="session">
                    <f7-row class="wrapperRow" v-for="(exercise, index) in session.introductory_part_2.data" :key="exercise.id">
                        <f7-col class="content" width="45">
                            <p class="print font-weight-bold text">{{exercise.topic}}</p>
                            <input class="no-print" v-model="exercise.topic" type="text" :placeholder="'outline.title' | translate"/>
                            <p class="print text white-space-pre-wrap text-left">{{exercise.description}}</p>
                            <textarea class="no-print" rows="7" @input="autoResize" v-model="exercise.description" :placeholder="'outline.description' | translate"></textarea>
                            <div>
                                <p v-if="exercise.mod" class="font-weight-bold mod">{{ 'outline.modifications' | translate }}:</p>
                                <p class="print text white-space-pre-wrap text-left">{{exercise.mod}}</p>
                                <textarea class="no-print" rows="2" @input="autoResize" v-model="exercise.mod" :placeholder="'outline.descriptionMod' | translate"></textarea>
                            </div>
                        </f7-col>
                      <f7-col class="imageOnExercise" width="45">
                        <div v-if="!exercise.img.graphics" class="no-print wrapperUpload d-flex justify-content-center">
                          <f7-button @click="addExerciseOnBooks(index, 'introductory_part_2')" :tooltip="'outline.addExerciseOnBooks' | translate" style="margin-right: 5px" class="d-flex" raised small :disabled="loaderBooks">
                              <img height="14px" class="icon" src="/static/icons/books.svg" alt />
                          </f7-button>
                           <f7-button class="d-flex" style="margin-right: 5px" raised small>
                              <img height="14px" class="icon" src="/static/icons/upload.svg" alt />
                          </f7-button>
                          <input type="file" @change="previewImage" @click="setActualPart(index, 'introductory_part_2')" accept="image/*" />
                          <f7-button @click="addPrivateExercise(index, 'introductory_part_2')" :tooltip="'outline.addPrivateExercise' | translate" class="d-flex" raised small>
                              <img height="14px" class="icon" src="/static/icons/cones.svg" alt />
                          </f7-button>
                        </div>
                            <div v-if="exercise.img.graphics" >
                                <div class="wrapperImageExercise">
                                  <div class="close text-center d-flex justify-content-center">
                                    <f7-button class="no-print" @click="showDialogRemoveImageExercise(exercise.id, 'introductory_part_2')" raised small>
                                      <img height="8px" src="/static/icons/close.svg" alt />
                                    </f7-button>
                                  </div> 
                                  <div class="image">
                                    <div v-if="exercise.img.id">
                                      <img v-if="exercise.img.id == 'onBooks'" :src="`${hostApp}${exercise.img.graphics}`" alt="">
                                      <img v-else :src="`${host}${exercise.img.graphics}`" alt="">
                                    </div>
                                    <img v-else :src="exercise.img.graphics" alt="">
                                  </div>
                                </div>
                            </div>
                        </f7-col>
                        <f7-col width="5">
                          <p v-if="exercise.time" class="print font-weight-bold align-items-center"><span class="input-number">{{exercise.time}}</span>' <img height="14px" src="/static/icons/timer.svg" alt /></p>
                          <input class="time no-print" v-model="exercise.time" type="text" maxlength="2" :placeholder="'outline.time' | translate"/>
                        </f7-col>
                        <f7-col class="no-print m-0" width="5">
                          <div class="wrapperTrash d-flex ">
                            <div class="no-print cursor-pointer" @click="showDialogRemoveExercise(index, exercise.id, 'introductory_part_2')">
                              <img class="icon" height="22px" src="/static/icons/trash.svg" alt />
                            </div>
                          </div>
                          <div class="wrapperDrag d-flex handleIntroductoryPart2">
                            <div class="no-print cursor-pointer">
                              <img class="icon" height="22px" src="/static/icons/drag.svg" alt />
                            </div>
                          </div>
                        </f7-col>
                    </f7-row>
                  </draggable>
                  </div>
          </f7-block>
          <f7-block v-if="accordion.introductory_part_2" class="no-print text-center d-flex justify-content-center">
            <f7-button @click="addExercise('introductory_part_2')" raised small>
              <span class="text-uppercase">{{ 'outline.addExercise' | translate }}</span>
            </f7-button>
          </f7-block>
            </aside>
        <aside v-if="config.main1 && session.main_part_1" class="wrapperMain1 wrapperPart">
          <f7-block class="wrapperColumn">
            <div>
              <h2 class="no-print partName text-uppercase">{{ config.main1 && config.main2 ? 'outline.main1Name' : 'outline.mainName' | translate }}</h2>
              <img @click="collapsed('main_part_1')" class="iconAccordion no-print cursor-pointer" height="12px" :src="`/static/icons/line-angle-${accordion.main_part_1 ? 'up' : 'down'}.svg`" alt />
              <div class="print wrapperPartName">
                <h5 class="partName text-uppercase">{{ config.main1 && config.main2 ? 'outline.main1Name' : 'outline.mainName' | translate }}</h5>
              </div>
            </div>
            <div v-if="accordion.main_part_1" class="w-100">
                  <draggable class="list-group" :scroll-sensitivity="150" handle=".handleMainPart1"
    @end="endDrag"
    :force-fallback="true"
     :list="session.main_part_1.data" group="session">
                  <f7-row class="wrapperRow" v-for="(exercise, index) in session.main_part_1.data" :key="exercise.id">
                        <f7-col class="content" width="45">
                            <p class="print font-weight-bold text">{{exercise.topic}}</p>
                            <input class="no-print" v-model="exercise.topic" type="text" :placeholder="'outline.title' | translate"/>
                            <p class="print text white-space-pre-wrap text-left">{{exercise.description}}</p>
                            <textarea class="no-print" rows="7" @input="autoResize" v-model="exercise.description" :placeholder="'outline.description' | translate"></textarea>
                            <div>
                                <p v-if="exercise.mod" class="font-weight-bold mod">{{ 'outline.modifications' | translate }}:</p>
                                <p class="print text white-space-pre-wrap text-left">{{exercise.mod}}</p>
                                <textarea class="no-print" rows="2" @input="autoResize" v-model="exercise.mod" :placeholder="'outline.descriptionMod' | translate"></textarea>
                            </div>
                        </f7-col>
                      <f7-col class="imageOnExercise" width="45">
                        <div v-if="!exercise.img.graphics" class="no-print wrapperUpload d-flex justify-content-center">
                          <f7-button @click="addExerciseOnBooks(index, 'main_part_1')" :tooltip="'outline.addExerciseOnBooks' | translate" style="margin-right: 5px" class="d-flex" raised small :disabled="loaderBooks">
                              <img height="14px" class="icon" src="/static/icons/books.svg" alt />
                          </f7-button>
                           <f7-button class="d-flex" style="margin-right: 5px" raised small>
                              <img height="14px" class="icon" src="/static/icons/upload.svg" alt />
                          </f7-button>
                          <input type="file" @change="previewImage" @click="setActualPart(index, 'main_part_1')" accept="image/*" />
                          <f7-button @click="addPrivateExercise(index, 'main_part_1')" :tooltip="'outline.addPrivateExercise' | translate" class="d-flex" raised small>
                              <img height="14px" class="icon" src="/static/icons/cones.svg" alt />
                          </f7-button>
                        </div>
                            <div v-if="exercise.img.graphics" >
                                <div class="wrapperImageExercise">
                                  <div class="close text-center d-flex justify-content-center">
                                    <f7-button class="no-print" @click="showDialogRemoveImageExercise(exercise.id, 'main_part_1')" raised small>
                                      <img height="8px" src="/static/icons/close.svg" alt />
                                    </f7-button>
                                  </div> 
                                  <div class="image">
                                    <div v-if="exercise.img.id">
                                      <img v-if="exercise.img.id == 'onBooks'" :src="`${hostApp}${exercise.img.graphics}`" alt="">
                                      <img v-else :src="`${host}${exercise.img.graphics}`" alt="">
                                    </div>
                                    <img v-else :src="exercise.img.graphics" alt="">
                                  </div>
                                </div>
                            </div>
                        </f7-col>
                        <f7-col width="5">
                          <p v-if="exercise.time" class="print font-weight-bold align-items-center"><span class="input-number">{{exercise.time}}</span>' <img height="14px" src="/static/icons/timer.svg" alt /></p>
                          <input class="time no-print" v-model="exercise.time" type="text" maxlength="2" :placeholder="'outline.time' | translate"/>
                        </f7-col>
                        <f7-col class="no-print m-0" width="5">
                          <div class="wrapperTrash d-flex ">
                            <div class="no-print cursor-pointer" @click="showDialogRemoveExercise(index, exercise.id, 'main_part_1')">
                              <img class="icon" height="22px" src="/static/icons/trash.svg" alt />
                            </div>
                          </div>
                          <div class="wrapperDrag d-flex handleMainPart1">
                            <div class="no-print cursor-pointer">
                              <img class="icon" height="22px" src="/static/icons/drag.svg" alt />
                            </div>
                          </div>
                        </f7-col>
                    </f7-row>
                  </draggable>
                  </div>
          </f7-block>
          <f7-block v-if="accordion.main_part_1" class="no-print text-center d-flex justify-content-center">
            <f7-button @click="addExercise('main_part_1')" raised small>
              <span class="text-uppercase">{{ 'outline.addExercise' | translate }}</span>
            </f7-button>
          </f7-block>
        </aside>
        <aside v-if="config.main2 && session.main_part_2" class="wrapperMain2 wrapperPart">
          <f7-block class="wrapperColumn">
            <div>
              <h2 class="no-print partName text-uppercase">{{ config.main1 && config.main2 ? 'outline.main2Name' : 'outline.mainName' | translate }}</h2>
              <img @click="collapsed('main_part_2')" class="iconAccordion no-print cursor-pointer" height="12px" :src="`/static/icons/line-angle-${accordion.main_part_2 ? 'up' : 'down'}.svg`" alt />
              <div class="print wrapperPartName">
                <h5 class="partName text-uppercase">{{ config.main1 && config.main2 ? 'outline.main2Name' : 'outline.mainName' | translate }}</h5>
              </div>
            </div>
            <div v-if="accordion.main_part_2" class="w-100">
                  <draggable class="list-group" :scroll-sensitivity="150" handle=".handleMainPart2"
    @end="endDrag"
    :force-fallback="true"
     :list="session.main_part_2.data" group="session">
                    <f7-row class="wrapperRow" v-for="(exercise, index) in session.main_part_2.data" :key="exercise.id">
                        <f7-col class="content" width="45">
                            <p class="print font-weight-bold text">{{exercise.topic}}</p>
                            <input class="no-print" v-model="exercise.topic" type="text" :placeholder="'outline.title' | translate"/>
                            <p class="print text white-space-pre-wrap text-left">{{exercise.description}}</p>
                            <textarea class="no-print" rows="7" @input="autoResize" v-model="exercise.description" :placeholder="'outline.description' | translate"></textarea>
                            <div>
                                <p v-if="exercise.mod" class="font-weight-bold mod">{{ 'outline.modifications' | translate }}:</p>
                                <p class="print text white-space-pre-wrap text-left">{{exercise.mod}}</p>
                                <textarea class="no-print" rows="2" @input="autoResize" v-model="exercise.mod" :placeholder="'outline.descriptionMod' | translate"></textarea>
                            </div>
                        </f7-col>
                      <f7-col class="imageOnExercise" width="45">
                        <div v-if="!exercise.img.graphics" class="no-print wrapperUpload d-flex justify-content-center">
                          <f7-button @click="addExerciseOnBooks(index, 'main_part_2')" :tooltip="'outline.addExerciseOnBooks' | translate" style="margin-right: 5px" class="d-flex" raised small :disabled="loaderBooks">
                              <img height="14px" class="icon" src="/static/icons/books.svg" alt />
                          </f7-button>
                           <f7-button class="d-flex" style="margin-right: 5px" raised small>
                              <img height="14px" class="icon" src="/static/icons/upload.svg" alt />
                          </f7-button>
                          <input type="file" @change="previewImage" @click="setActualPart(index, 'main_part_2')" accept="image/*" />
                          <f7-button @click="addPrivateExercise(index, 'main_part_2')" :tooltip="'outline.addPrivateExercise' | translate" class="d-flex" raised small>
                              <img height="14px" class="icon" src="/static/icons/cones.svg" alt />
                          </f7-button>
                        </div>
                            <div v-if="exercise.img.graphics" >
                                <div class="wrapperImageExercise">
                                  <div class="close text-center d-flex justify-content-center">
                                    <f7-button class="no-print" @click="showDialogRemoveImageExercise(exercise.id, 'main_part_2')" raised small>
                                      <img height="8px" src="/static/icons/close.svg" alt />
                                    </f7-button>
                                  </div> 
                                  <div class="image">
                                    <div v-if="exercise.img.id">
                                      <img v-if="exercise.img.id == 'onBooks'" :src="`${hostApp}${exercise.img.graphics}`" alt="">
                                      <img v-else :src="`${host}${exercise.img.graphics}`" alt="">
                                    </div>
                                    <img v-else :src="exercise.img.graphics" alt="">
                                  </div>
                                </div>
                            </div>
                        </f7-col>
                        <f7-col width="5">
                          <p v-if="exercise.time" class="print font-weight-bold align-items-center"><span class="input-number">{{exercise.time}}</span>' <img height="14px" src="/static/icons/timer.svg" alt /></p>
                          <input class="time no-print" v-model="exercise.time" type="text" maxlength="2" :placeholder="'outline.time' | translate"/>
                        </f7-col>
                        <f7-col class="no-print m-0" width="5">
                          <div class="wrapperTrash d-flex ">
                            <div class="no-print cursor-pointer" @click="showDialogRemoveExercise(index, exercise.id, 'main_part_2')">
                              <img class="icon" height="22px" src="/static/icons/trash.svg" alt />
                            </div>
                          </div>
                          <div class="wrapperDrag d-flex handleMainPart2">
                            <div class="no-print cursor-pointer">
                              <img class="icon" height="22px" src="/static/icons/drag.svg" alt />
                            </div>
                          </div>
                        </f7-col>
                    </f7-row>
                  </draggable>
                  </div>
          </f7-block>
          <f7-block v-if="accordion.main_part_2" class="no-print text-center d-flex justify-content-center">
            <f7-button @click="addExercise('main_part_2')" raised small>
              <span class="text-uppercase">{{ 'outline.addExercise' | translate }}</span>
            </f7-button>
          </f7-block>
        </aside>
        <aside v-if="config.final && session.final_part" class="wrapperFinal wrapperPart">
          <f7-block class="wrapperColumn">
            <div>
              <h2 class="no-print partName text-uppercase">{{ 'outline.finalName' | translate }}</h2>
              <img @click="collapsed('final_part')" class="iconAccordion no-print cursor-pointer" height="12px" :src="`/static/icons/line-angle-${accordion.final_part ? 'up' : 'down'}.svg`" alt />
              <div class="print wrapperPartName">
                <h5 class="partName text-uppercase">{{ 'outline.finalName' | translate }}</h5>
              </div>
            </div>
              <div v-if="accordion.final_part" class="w-100">
                  <draggable class="list-group" :scroll-sensitivity="150" handle=".handleFinalPart"
    @end="endDrag"
    :force-fallback="true"
     :list="session.final_part.data" group="session">
                <f7-row class="wrapperRow" v-for="(exercise, index) in session.final_part.data" :key="exercise.id">
                        <f7-col class="content" width="45">
                            <p class="print font-weight-bold text">{{exercise.topic}}</p>
                            <input class="no-print" v-model="exercise.topic" type="text" :placeholder="'outline.title' | translate"/>
                            <p class="print text white-space-pre-wrap text-left">{{exercise.description}}</p>
                            <textarea class="no-print" rows="7" @input="autoResize" v-model="exercise.description" :placeholder="'outline.description' | translate"></textarea>
                            <div>
                                <p v-if="exercise.mod" class="font-weight-bold mod">{{ 'outline.modifications' | translate }}:</p>
                                <p class="print text white-space-pre-wrap text-left">{{exercise.mod}}</p>
                                <textarea class="no-print" rows="2" @input="autoResize" v-model="exercise.mod" :placeholder="'outline.descriptionMod' | translate"></textarea>
                            </div>
                        </f7-col>
                      <f7-col class="imageOnExercise" width="45">
                        <div v-if="!exercise.img.graphics" class="no-print wrapperUpload d-flex justify-content-center">
                          <f7-button @click="addExerciseOnBooks(index, 'final_part')" :tooltip="'outline.addExerciseOnBooks' | translate" style="margin-right: 5px" class="d-flex" raised small :disabled="loaderBooks">
                              <img height="14px" class="icon" src="/static/icons/books.svg" alt />
                          </f7-button>
                           <f7-button class="d-flex" style="margin-right: 5px" raised small>
                              <img height="14px" class="icon" src="/static/icons/upload.svg" alt />
                          </f7-button>
                          <input type="file" @change="previewImage" @click="setActualPart(index, 'final_part')" accept="image/*" />
                          <f7-button @click="addPrivateExercise(index, 'final_part')" :tooltip="'outline.addPrivateExercise' | translate" class="d-flex" raised small>
                              <img height="14px" class="icon" src="/static/icons/cones.svg" alt />
                          </f7-button>
                        </div>
                            <div v-if="exercise.img.graphics" >
                                <div class="wrapperImageExercise">
                                  <div class="close text-center d-flex justify-content-center">
                                    <f7-button class="no-print" @click="showDialogRemoveImageExercise(exercise.id, 'final_part')" raised small>
                                      <img height="8px" src="/static/icons/close.svg" alt />
                                    </f7-button>
                                  </div> 
                                  <div class="image">
                                    <div v-if="exercise.img.id">
                                      <img v-if="exercise.img.id == 'onBooks'" :src="`${hostApp}${exercise.img.graphics}`" alt="">
                                      <img v-else :src="`${host}${exercise.img.graphics}`" alt="">
                                    </div>
                                    <img v-else :src="exercise.img.graphics" alt="">
                                  </div>
                                </div>
                            </div>
                        </f7-col>
                        <f7-col width="5">
                          <p v-if="exercise.time" class="print font-weight-bold align-items-center"><span class="input-number">{{exercise.time}}</span>' <img height="14px" src="/static/icons/timer.svg" alt /></p>
                          <input class="time no-print" v-model="exercise.time" type="text" maxlength="2" :placeholder="'outline.time' | translate"/>
                        </f7-col>
                        <f7-col class="no-print m-0" width="5">
                          <div class="wrapperTrash d-flex ">
                            <div class="no-print cursor-pointer" @click="showDialogRemoveExercise(index, exercise.id, 'final_part')">
                              <img class="icon" height="22px" src="/static/icons/trash.svg" alt />
                            </div>
                          </div>
                          <div class="wrapperDrag d-flex handleFinalPart">
                            <div class="no-print cursor-pointer">
                              <img class="icon" height="22px" src="/static/icons/drag.svg" alt />
                            </div>
                          </div>
                        </f7-col>
                    </f7-row>
                  </draggable>
                  </div>
          </f7-block>
          <f7-block v-if="accordion.final_part" class="no-print text-center d-flex justify-content-center">
            <f7-button @click="addExercise('final_part')" raised small>
              <span class="text-uppercase">{{ 'outline.addExercise' | translate }}</span>
            </f7-button>
          </f7-block>
      </aside>
        </div> 
        <f7-block class="wrapperNotes" v-if="config.notes">
          <f7-row>
            <f7-col>
                <p class="font-weight-bold text-uppercase">{{ 'outline.notes' | translate }}</p>
                <p class="print white-space-pre-wrap text-left">{{session.notes}}</p>
                <textarea class="no-print" rows="4" @input="autoResize" v-model="session.notes" :placeholder="'outline.notes' | translate"></textarea>
            </f7-col>
          </f7-row>
        </f7-block>
    <f7-block>
        <div class="print company text-center">
            <h4>www.soccer-sessions.com</h4>
        </div>
      </f7-block>
    </main>
    <div>
        
        <exerciseonbooks v-if="isBooksView" :profileBooks="profileBooks" :books="books" :isSubscription="isSubscription" :typePrivate="'outline'" @move-exercise-to-outline="moveExerciseToOutline" @on-close-books-views="isBooksView = false"></exerciseonbooks>

        <exerciseonprivates v-if="isExercisesView" @move-exercise-to-outline-and-exercise-on-private-exercise="moveExerciseToOutlineOnPrivateExercise" @on-close-exercises-views="isExercisesView = false"></exerciseonprivates>

    </div>
    <div v-if="!isBooksView && !isExercisesView">
    <div class="no-print custom-fab">
        <div v-if="!previewMode" class="box box-zoom cursor-pointer" @click="zoomPlus()">
            <f7-link :tooltip="'outline.zoomPlus' | translate" >
              <img height="20px" src="/static/icons/zoom-in.svg" alt="">
            </f7-link>
        </div>
        <div v-if="!previewMode" class="box box-zoom cursor-pointer" @click="zoomMinus()">
            <f7-link :tooltip="'outline.zoomMinus' | translate" >
              <img height="20px" src="/static/icons/zoom-out.svg" alt="">
            </f7-link>
        </div>
      <div class="box cursor-pointer" @click="changePreviewMode()">
            <f7-link v-if="previewMode" :tooltip="'outline.goToEdit' | translate" >
              <img height="20px" src="/static/icons/edit-2.svg" alt="">
            </f7-link>
            <f7-link v-else :tooltip="'outline.previewMode' | translate" >
              <img height="20px" src="/static/icons/outline.svg" alt="">
            </f7-link>
        </div>
        <div class="box cursor-pointer" @click="printPDF()">
            <f7-link :tooltip="'outline.printPdf' | translate" >
              <img height="20px" src="/static/icons/print.svg" alt="">
            </f7-link>
        </div>
        <div v-if="isAuthenticated" class="box cursor-pointer" @click="saveOutline" :disabled="!loaderOutline">
            <loader v-if="loaderOutline" :color="'white'" class="loader"></loader>
            <f7-link v-else :tooltip="'outline.save' | translate" >
              <img height="20px" src="/static/icons/save.svg" alt="">
            </f7-link>
        </div>
        <div v-else class="box cursor-pointer">
            <f7-link :tooltip="'outline.save_is_authenticated' | translate" :disabled="true">
              <img height="20px" src="/static/icons/save.svg" alt="">
            </f7-link>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import mixinPrivateOutline from "@/mixins/mixinPrivateOutline";
import mixinPrivateCommon from "@/mixins/mixinPrivateCommon";
import mixinAutoResize from "@/mixins/mixinAutoResize";
import Navbarcreateoutline from "@/components/Navbar/Navbarcreateoutline.vue";
import Togglepaneloutline from "@/components/Outline/Togglepaneloutline";
import Infooutline from "@/components/Outline/Infooutline";
import Exerciseonbooks from "@/components/Outline/exerciseonbooks"
import Exerciseonprivates from "@/components/Outline/Exerciseonprivates"
import Loader from "@/components/Loader";
import mixinUser from "@/mixins/mixinUser"
import draggable from "vuedraggable";

import privateOutlinesServices from "@/services/PrivateOutlinesServices.js"
import CONSTANTS from "@/utils/constants.js"

export default {
  mixins: [mixinPrivateOutline, mixinPrivateCommon, mixinUser, mixinAutoResize],
  components: {
    Navbarcreateoutline,
    Togglepaneloutline,
    Infooutline,
    Exerciseonbooks,
    Exerciseonprivates,
    Loader,
    draggable
  },
  data () {
    return {
      loader: false,
      loaderOutline: false,
      loaderBooks: false,
      ageCategories: CONSTANTS.AGE_CATEGORIES,
      session: {
          subject: '',
          coach: '',
          logo: '',
          assistant: '',
          microcycle: '',
          mesocycle: '',
          individual_actions: '',
          group_activities: '',
          motor_skills: '',
          knowledge: '',
          date: '',
          place: '',
          age_category: '0',
          category: '',
          objective: '',
          coaching: '',
          time: '',
          number: '',
          time_work: '',
          count_player: '',
          gear_balls: '',
          gear_goals: '',
          gear_markers: '',
          gear_cones_1: '',
          gear_cones_2: '',
          gear_poles: '',
          gear_ladders: '',
          gear_other: '',
          notes: '',
          introductory_part_1: {
            data: [
              {
                id: 0,
                topic: '',
                description: '',
                img: {
                  id: '',
                  graphics: ''
                },
                mod: '',
                time: null
              }
            ],
          },
          introductory_part_2: {
            data: [
              {
                id: 0,
                topic: '',
                description: '',
                img: {
                  id: '',
                  graphics: ''
                },
                mod: '',
                time: null
              }
            ],
          },
          main_part_1: {
            data: [
              {
                id: 0,
                topic: '',
                description: '',
                img: {
                  id: '',
                  graphics: ''
                },
                mod: '',
                time: null
              }
            ],
          },
          main_part_2: {
            data: [
              {
                id: 0,
                topic: '',
                description: '',
                img: {
                  id: '',
                  graphics: ''
                },
                mod: '',
                time: null
              }
            ],
          },
          final_part: {
            data: [
              {
                id: 0,
                topic: '',
                description: '',
                img: {
                  id: '',
                  graphics: ''
                },
                mod: '',
                time: null
              }
            ],
          },
          images: {
            data: []
          },
          type_copied: 0,
      },
    }
  },
  watch: {
    session: {
      handler() {
        if (!this.loader) {
          this.refreshZoom()
        }
      },
      deep: true
    }
  },
  computed: {
      countPart() {
          return Object.values(this.config).filter(elem => elem == true).length
      },
  },
  mounted() {
    this.fetchDataBooks();
    if (this.isAuthenticated) {
      this.loaderBooks = true
      this.fetchDataUserBooks().then(() => {
          this.loaderBooks = false
        })
      this.fetchConfig();
      this.fetchDataUser().then(() => {
        this.markEmail = this.account.email
        this.initWaterMark()
      });
    }
  },
  methods: {
    showDialogRemoveExercise(index, id, type) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the exericse with images? The change will be irreversible!`, '', () => {
          this.removeExercise(index, id, type)
        });
    },
    showDialogRemoveImageExercise(id, type) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the image? The change will be irreversible!`, '', () => {
          this.removeImageExercise(id, type) 
        });
    },
    async fetchConfig() {
      try {
        this.loader = true;

        const responseDataConfig = await privateOutlinesServices.configOutlines();
        if (responseDataConfig.data.length > 0) {
          this.session.age_category = responseDataConfig.data[0].age_category
          this.session.place = responseDataConfig.data[0].place
          this.session.logo = responseDataConfig.data[0].logo
          this.session.coach = responseDataConfig.data[0].coach
          this.session.assistant = responseDataConfig.data[0].assistant
          this.session.count_player = responseDataConfig.data[0].count_player
          this.session.gear_balls = responseDataConfig.data[0].gear_balls
          this.session.gear_goals = responseDataConfig.data[0].gear_goals
          this.session.gear_markers = responseDataConfig.data[0].gear_markers
          this.session.gear_cones_1 = responseDataConfig.data[0].gear_cones_1
          this.session.gear_cones_2 = responseDataConfig.data[0].gear_cones_2
          this.session.gear_poles = responseDataConfig.data[0].gear_poles
          this.session.gear_ladders = responseDataConfig.data[0].gear_ladders
          this.session.gear_other = responseDataConfig.data[0].gear_other
        }
        
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the config. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
      async createExerciseGraphics(exercise) {
      try {
        const responseCreateExerciseGraphics = await privateOutlinesServices.createExerciseGraphics(exercise.image);
        if (responseCreateExerciseGraphics.data.id) {
          this.session[exercise.type].data[exercise.index].img = {
            id: responseCreateExerciseGraphics.data.id,
            graphics: responseCreateExerciseGraphics.data.graphics
          }
          this.session.images.data.push(responseCreateExerciseGraphics.data.id)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with adding the image to the database. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
      async createPrivateOutline() {
      this.loaderOutline = true;
      try {
        const responseCreatePrivateOutline = await privateOutlinesServices.createPrivateOutline(this.session);
        
        if (responseCreatePrivateOutline.data.id) {
          this.$f7router.navigate(`/private-outlines/`)
        }
        this.$f7.toast
        .create({
          text: "Outline added!",
                      position: "bottom",
          
          closeTimeout: 3000
        })
        .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the outline creation. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderOutline = false;
    },
    saveOutline() {
      if (this.session.subject == '') {
        this.$f7.toast
          .create({
            text: "The subject of the outline must be completed!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
          return
      }
      this.loaderOutline = true;
      if (this.tmpImages.length > 0) {
        let promises = [];

        this.tmpImages.forEach((obj) => {
          promises.push(
            this.createExerciseGraphics(obj)
          );
        })

        Promise.all(promises).then(() => 
          this.createPrivateOutline()
        );
      } else {
        this.createPrivateOutline();
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/createoutline.scss";
</style>