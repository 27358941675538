<template>
  <f7-page login-screen>
    <navbarbook></navbarbook>
    <loader v-if="loader"></loader>

    <div v-else>
      <f7-block class="text-center">
        <f7-block>
          <img height="200px" :src="`${host}${bookPanel.cover}`" alt />
        </f7-block>
        <f7-block>
        <p>{{ 'global.no_access_books_1' | translate}}</p>
        </f7-block>
        <div v-if="bookPanel.shop !== '' && getLang === 'pl'">
          <f7-button :href="bookPanel.shop" external target="_blank" class="font-weight-bold external" style="margin: 0 8px; background: #d62029; color: white;"><div><img height="12px" src="/static/icons/shopping-cart.svg" alt /> {{ 'global.buy_single' | translate }}!</div></f7-button>
          <p class="text-uppercase">{{ 'global.or' | translate}}</p>
        </div>
        <f7-button href="/payments/" class="font-weight-bold" style="margin: 0 8px; background: #0fa300; color: white;"><div><img height="12px" src="/static/icons/shopping-cart.svg" alt /> {{ 'global.buy_subscriptions' | translate }}!</div></f7-button>
          <p>{{ 'global.no_access_books_2' | translate}}</p>
        <f7-link href="https://soccer-sessions.com/" external target="_blank"><strong>www.soccer-sessions.com</strong></f7-link>
        <p>{{ 'global.or_contact' | translate}}</p>
        <f7-link href="mailto:contact@soccer-sessions.com" external><strong>contact@soccer-sessions.com</strong></f7-link>
      </f7-block>
    </div>
  </f7-page>
</template>

<script>
import Navbarbook from "@/components/Navbar/Navbarbook.vue";
import mixinBook from "@/mixins/mixinBook"
import Loader from "@/components/Loader";

export default {
  mixins: [mixinBook],
  components: {
    Loader,
    Navbarbook,
  },
  props: {
    idBook: {
      type: String,
      required: true
    },
  },
  data: () => {
    return {
      loader: false,
      bookPanel: {}
    };
  },
  mounted() {
    this.$store.dispatch("appkonspektypilkanozna/setActualBook", this.idBook);
    if (this.isAuthenticated) {
      this.fetchDataBook()
    } else {
      this.fetchDataBookDemo()
    }
  },
};
</script>