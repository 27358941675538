<template>
  <f7-page class="pageCreateSession" :class="{ 'previewMode': previewMode }">
    <navbarcreateexercise :show="isEdit" :hide="!isBooksView && !isExercisesView" @on-show="togglePanel"></navbarcreateexercise>
    <div id="mark"></div>
    <loader v-if="loader"></loader>
    
    <main v-else id="outlineViewport">  
      <div v-if="isEdit" class="no-print toolsPanel">
        <togglepanelexercise :configExercise="configExercise"></togglepanelexercise>
      </div>
      <f7-block>
        <div class="print company d-flex justify-content-between text-center">
            <h4>www.soccer-sessions.com</h4>
        </div>
       </f7-block>

        <infoexercise :configExercise="configExercise" :exercise="exercise"></infoexercise>
        <div>
        <aside v-if="configExercise.part" class="wrapperIntroduction1 wrapperPart">
              <f7-block class="wrapperColumn">
                <div>
                  <h2 class="no-print partName text-uppercase">{{ 'outline.exercise' | translate }}</h2>
                  <div class="print wrapperPartName">
                    <p class="partName text-uppercase">{{ 'outline.exercise' | translate }}</p>
                  </div>
                </div>
                  <div class="w-100">
<draggable class="list-group" :scroll-sensitivity="150" handle=".handlePart"
    @end="endDrag"
    :force-fallback="true"
     :list="exercise.part.data" group="exercise">
                    <f7-row class="wrapperRow" v-for="(exercise, index) in exercise.part.data" :key="exercise.id">
                        <f7-col class="content" width="45">
                            <p class="print font-weight-bold text">{{exercise.topic}}</p>
                            <input class="no-print" v-model="exercise.topic" type="text" :placeholder="'outline.title' | translate"/>
                            <p class="print text white-space-pre-wrap text-left">{{exercise.description}}</p>
                            <textarea class="no-print" rows="7" @input="autoResize" v-model="exercise.description" :placeholder="'outline.description' | translate"></textarea>
                            <div>
                                <p v-if="exercise.mod" class="font-weight-bold mod">{{ 'outline.modifications' | translate }}:</p>
                                <p class="print text white-space-pre-wrap text-left">{{exercise.mod}}</p>
                                <textarea class="no-print" rows="2" @input="autoResize" v-model="exercise.mod" :placeholder="'outline.descriptionMod' | translate"></textarea>
                            </div>
                        </f7-col>
                      <f7-col width="45">
                        <div v-if="!exercise.img.graphics" class="no-print wrapperUpload d-flex justify-content-center">
                          <f7-button @click="addExerciseOnBooks(index, 'exercise')" :tooltip="'outline.addExerciseOnBooks' | translate" style="margin-right: 5px" class="d-flex" raised small :disabled="loaderBooks">
                              <img height="14px" class="icon" src="/static/icons/books.svg" alt />
                          </f7-button>
                           <f7-button class="d-flex" style="margin-right: 5px" raised small>
                              <img height="14px" class="icon" src="/static/icons/upload.svg" alt />
                          </f7-button>
                          <input type="file" @change="previewImage" @click="setActualPart(index)" accept="image/*" />
                          <f7-button @click="addPrivateExercise(index)" :tooltip="'outline.addPrivateExercise' | translate" class="d-flex" raised small>
                              <img height="14px" class="icon" src="/static/icons/cones.svg" alt />
                          </f7-button>
                        </div>
                            <div v-if="exercise.img.graphics" >
                                <div class="wrapperImageExercise">
                                  <div class="close text-center d-flex justify-content-center">
                                    <f7-button class="no-print" @click="prepareRemoveImageExercise(exercise)" raised small>
                                      <img height="8px" src="/static/icons/close.svg" alt />
                                    </f7-button>
                                  </div>
                                  <div class="image">
                                    <div v-if="exercise.img.id">
                                      <img v-if="exercise.img.id == 'onBooks'" :src="`${hostApp}${exercise.img.graphics}`" alt="">
                                      <img v-else :src="`${host}${exercise.img.graphics}`" onerror="if (this.src != 'static/blank-remove-private-exercise.png') this.src = 'static/blank-remove-private-exercise.png';" alt="">
                                    </div>
                                    <img v-else :src="exercise.img.graphics" alt="">
                                  </div>
                                </div>
                            </div>
                        </f7-col>
                        <f7-col width="5">
                          <p v-if="exercise.time" class="print font-weight-bold align-items-center"><span class="input-number">{{exercise.time}}</span>' <img height="14px" src="/static/icons/timer.svg" alt /></p>
                          <input class="time no-print" v-model="exercise.time" type="text" maxlength="2" :placeholder="'outline.time' | translate"/>
                        </f7-col>
                        <f7-col class="no-print m-0" width="5">
                          <div class="wrapperTrash d-flex">
                            <div class="no-print cursor-pointer" @click="prepareRemoveExercise(exercise, index)">
                              <img class="icon" height="22px" src="/static/icons/trash.svg" alt />
                            </div>
                          </div>
                          <div class="wrapperDrag d-flex handlePart">
                            <div class="no-print cursor-pointer">
                              <img class="icon" height="22px" src="/static/icons/drag.svg" alt />
                            </div>
                          </div>
                        </f7-col>
                    </f7-row>
</draggable>
                  </div>
          </f7-block>
          <f7-block class="no-print text-center d-flex justify-content-center">
            <f7-button @click="addExercise()" raised small>
              <span class="text-uppercase">{{ 'outline.addExercise' | translate }}</span>
            </f7-button>
          </f7-block>
        </aside>
      </div>
      <f7-block>
        <div class="print company text-center">
            <h4>www.soccer-sessions.com</h4>
        </div>
      </f7-block>
        
    </main>

    <exerciseonbooks v-if="isBooksView" :profileBooks="profileBooks" :books="books" :isSubscription="isSubscription" :typePrivate="'exercise'" @move-exercise-to-exercise="moveExerciseToExercise" @on-close-books-views="isBooksView = false"></exerciseonbooks>

    <exerciseonprivates v-if="isExercisesView" @move-exercise-to-outline-and-exercise-on-private-exercise="moveExerciseToExerciseOnPrivateExercise" @on-close-exercises-views="isExercisesView = false"></exerciseonprivates>
            
    <div v-if="!isBooksView && !isExercisesView">
    <div class="no-print custom-fab">
        <div v-if="!previewMode" class="box box-zoom cursor-pointer" @click="zoomPlus()">
            <f7-link :tooltip="'outline.zoomPlus' | translate" >
              <img height="20px" src="/static/icons/zoom-in.svg" alt="">
            </f7-link>
        </div>
        <div v-if="!previewMode" class="box box-zoom cursor-pointer" @click="zoomMinus()">
            <f7-link :tooltip="'outline.zoomMinus' | translate" >
              <img height="20px" src="/static/icons/zoom-out.svg" alt="">
            </f7-link>
        </div>
        <div class="box cursor-pointer" @click="changePreviewMode()">
            <f7-link v-if="previewMode" :tooltip="'outline.goToEdit' | translate" >
              <img height="20px" src="/static/icons/edit-2.svg" alt="">
            </f7-link>
            <f7-link v-else :tooltip="'outline.previewMode' | translate" >
              <img height="20px" src="/static/icons/outline.svg" alt="">
            </f7-link>
        </div>
         <div class="box cursor-pointer" @click="printPDF()">
            <f7-link :tooltip="'outline.printPdf' | translate" >
              <img height="20px" src="/static/icons/print.svg" alt="">
            </f7-link>
        </div>
        <div class="box cursor-pointer" @click="updateExercise" :disabled="!loaderExercise">
          <loader v-if="loaderExercise" :color="'white'" class="loader"></loader>
          <f7-link v-else :tooltip="'outline.save' | translate" >
            <img height="20px" src="/static/icons/save.svg" alt="">
          </f7-link> 
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import Loader from "@/components/Loader";
import privateExercisesServices from "@/services/PrivateExercisesServices.js"
import mixinUser from "@/mixins/mixinUser"
import mixinAutoResize from "@/mixins/mixinAutoResize";
import Navbarcreateexercise from "@/components/Navbar/Navbarcreateexercise.vue";
import Togglepanelexercise from "@/components/Exercise/Togglepanelexercise";
import Infoexercise from "@/components/Exercise/Infoexercise";
import mixinPrivateExercise from "@/mixins/mixinPrivateExercise";
import privateOutlinesServices from "@/services/PrivateOutlinesServices.js"
import mixinPrivateCommon from "@/mixins/mixinPrivateCommon";
import draggable from "vuedraggable";
import Exerciseonbooks from "@/components/Outline/exerciseonbooks"
import Exerciseonprivates from "@/components/Outline/Exerciseonprivates"

export default {
  mixins: [mixinUser, mixinPrivateExercise, mixinPrivateCommon, mixinAutoResize],
  components: {
    Loader,
    Navbarcreateexercise,
    Togglepanelexercise,
    Infoexercise,
    Exerciseonbooks,
    Exerciseonprivates,
    draggable
  },
  props: {
    idExercise: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      loader: false,
      loaderExercise: false,
      loaderBooks: false,
      exercise: {
          subject: '',
          objective: '',
          coaching: '',
          age_category: '0',
          part: {
              data: [
                  {
                    id: 0,
                    topic: '',
                    description: '',
                    img: {
                        id: '',
                        graphics: ''
                    },
                    mod: '',
                    time: null
                }
              ]
          },
          category: '',
          images: {
            data: []
          },
      },
    };
  },
  watch: {
    exercise: {
      handler(val) {
        if (!this.loader) {
          this.refreshZoom()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.fetchPrivateExercise(this.idExercise).then(() => {
          this.autoResizeAllTextarea()
      })
    this.fetchDataBooks();
    if (this.isAuthenticated) {
      this.loaderBooks = true
      this.fetchDataUserBooks().then(() => {
          this.loaderBooks = false
        })
      this.fetchDataUser().then(() => {
        this.markEmail = this.account.email
        this.initWaterMark()
      });
    }
    window.addEventListener('keydown', this.keyDownExercise, false);
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownExercise, false);
  },
  methods: {
    keyDownExercise: function (e) {
      if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)  && e.keyCode == 83) {
        e.preventDefault();
        this.updateExercise()
      }
    },
    async fetchPrivateExercise(id) {
      try {
        this.loader = true;

        const responseDataPrivateExercise = await privateExercisesServices.itemPrivateExercise(id);
        this.exercise = responseDataPrivateExercise.data
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with a private exercise. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    prepareRemoveImageExercise(graphics) {
      this.$f7.dialog.confirm(`Are you sure you want to delete the image? The change will be irreversible!`, '', () => {
        if (graphics.img.id) {
          this.deleteExerciseGraphics(graphics, 'image')
          this.removeGraphicsOnImages(graphics.img.id);
        } else {
          this.removeImageExercise(graphics.id);
        }
      });
    },
    prepareRemoveExercise(graphics, index) {
      this.$f7.dialog.confirm(`Are you sure you want to delete the exericse with image? The change will be irreversible!`, '', () => {
        if (graphics.img.id) {
          this.deleteExerciseGraphics(graphics, 'exercise', index)
          this.removeGraphicsOnImages(graphics.img.id);
        } else {
          this.removeExercise(index, graphics.id);
        }
      });
    },
    // Usuwanie id grafiki z images[]
    removeGraphicsOnImages(id) {
      const findIndex = this.exercise.images.data.findIndex(item => item == id)
      this.exercise.images.data.splice(findIndex, 1)
    },
    async deleteExerciseGraphics(graphics, action, index) {
      try {
        const responseDeleteExerciseGraphics = await privateOutlinesServices.deleteExerciseGraphics(graphics.img.id);
        if (action == 'image') {
          this.removeImageExercise(graphics.id);
        }
        if (action == 'exercise') {
          this.removeExercise(index, graphics.id);
        }
        this.updateExercise();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the removal of the associated exercise graphics. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
    async createExerciseGraphics(exercise) {
      try {
        const responseCreateExerciseGraphics = await privateOutlinesServices.createExerciseGraphics(exercise.image);
        if (responseCreateExerciseGraphics.data.id) {
          this.exercise.part.data[exercise.index].img = {
            id: responseCreateExerciseGraphics.data.id,
            graphics: responseCreateExerciseGraphics.data.graphics
          }
          this.exercise.images.data.push(responseCreateExerciseGraphics.data.id)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the outline creation. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
    async onUpdatePrivateExercise() {
      try {
        this.loaderExercise = true;

        const responseDetailPrivateExercise = await privateExercisesServices.privateExercise(this.exercise);
        this.$f7.toast
          .create({
            text: "Exercise updated.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderExercise = false;
    },
    updateExercise() {
      if (this.exercise.subject == '') {
        this.exercise.subject = 'Temat'
      }
      this.loaderExercise = true;
      if (this.tmpImages.length > 0) {
        let promises = [];

        this.tmpImages.forEach((obj) => {
          promises.push(
            this.createExerciseGraphics(obj)
          );
        })

        Promise.all(promises).then(() => 
          this.onUpdatePrivateExercise()
        );

      } else {
        this.onUpdatePrivateExercise();
      }

    }
  }
}
</script>