<template>
    <f7-navbar back-link="Back">
      <f7-nav-title>
          <f7-link href="/" style="padding-left: 0px">
            <img class="logo" src="/static/logo-soccer-sessions-white.svg" style="max-height: 20px;" />
          </f7-link>
      </f7-nav-title>
      <f7-nav-right>
        <f7-link href="/calendar/">
            <img height="20px" style="margin-left: 0px" src="/static/icons/calendar-3.svg" alt />
          </f7-link>
          <f7-link panel-open="left"><img height="20px" src="/static/icons/menu.svg" alt style="margin-left: 0px;"></f7-link>
      </f7-nav-right>
    </f7-navbar>
</template>

<script>
import onbackbutton from '@/mixins/onBackButton.js';

export default {
  mixins: [onbackbutton],
  name: 'navbarcalendar',
}
</script>