<template>
  <f7-page login-screen class="pageCreateTraining">
    <navbarlist :list="'trainings'"></navbarlist>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{'club.new_training' | translate}}</h4>
          </f7-block>
          <f7-list form no-hairlines-md @submit.prevent="beforeCreateTraining">
            <f7-list-input
              :label="'club.subject' | translate"
              type="text"
              :placeholder="'club.subject' | translate"
              :value="training.subject"
              @input="training.subject = $event.target.value"
              clear-button
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>
            <f7-list-item :title="'club.group' | translate" class="custom-select">
              <select name="group" v-model="training.group">
                <option v-if="groups.length == 0" value="" disabled>{{ 'club.lack_group' | translate }}</option>
                <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == training.group">
                    {{group.name}}
                </option>
              </select>
            </f7-list-item>
            <f7-list-input
              :label="'club.training_date' | translate"
              type="date"
            :placeholder="'club.training_date' | translate"
              :value="training.training_date"
              @input="training.training_date = $event.target.value"
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.start_time' | translate"
              type="time"
              :placeholder="'club.start_time' | translate"
              :value="training.training_hours_start"
              @input="training.training_hours_start = $event.target.value"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.end_time' | translate"
              type="time"
              :placeholder="'club.end_time' | translate"
              :value="training.training_hours_end"
              @input="training.training_hours_end = $event.target.value"
            >
            </f7-list-input>
            <f7-list-item :title="'club.place_training' | translate" class="custom-select">
              <select name="field" v-model="training.playing_field">
                <option value="" disabled>{{  'global.lack' | translate}}</option>
                <option v-for="field in playingFields" :key="field.id" :value="field.id" :selected="field.id == training.playing_field">
                    {{field.name}}
                </option>
              </select>
            </f7-list-item>
            <f7-list-input
              :label="'club.notes' | translate"
              type="textarea"
              :placeholder="'club.notes' | translate"
              :value="training.notes"
              @input="training.notes = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              v-if="typeOutline == 1"
              :label="'club.outline_link' | translate"
              type="text"
              :placeholder="'club.outline_link' | translate"
              :value="training.outline_url"
              @input="training.outline_url = $event.target.value"
              clear-button
              style="margin-bottom: 19px"
            >
            </f7-list-input>
            <f7-list-item v-if="typeOutline == 2" :title="'club.private_outline' | translate" class="custom-select">
              <f7-link v-if="training.outline_private !== ''" class="removeIconSelect" @click="training.outline_private = ''">
                <img class="cursor-pointer" height="15px" src="/static/icons/circle-cross.svg" alt="">
              </f7-link>
              <select v-model="training.outline_private">
                <option value="" disabled>{{ 'global.lack' | translate }}</option>
                <option v-for="outline in privateOutlines" :key="outline.id" :value="outline.id" :selected="outline.id == training.outline_private">
                    {{outline.subject}}
                </option>
              </select>
            </f7-list-item>

             <f7-row style="margin: 16px;">
              <f7-col width="50">
                 <f7-button @click="typeOutline = 1" raised :style="typeOutline == 1 ? 'opacity: 1' : 'opacity: 0.1'">
                  {{ 'club.link' | translate }}
                </f7-button>
              </f7-col>
              <f7-col width="50">
                <f7-button @click="typeOutline = 2" raised :style="typeOutline == 2 ? 'opacity: 1' : 'opacity: 0.1'">
                  {{ 'club.private_outline' | translate }}
                </f7-button>
              </f7-col>
             </f7-row>

             <f7-row style="margin: 16px;">
               <f7-col>
                  <div class="d-flex">
                    <input type="checkbox" id="advanced" v-model="advanced.check">
                    <label for="advanced">
                      {{ 'club.advanced_settings' | translate }}
                    </label>
                  </div>
                  <div style="margin-top: 10px" v-if="advanced.check">
                    {{ 'club.add_training_in_each' | translate }} 
                    <select class="d-flex font-weight-bold" v-model="advanced.type">
                      <option value="0">{{'club.week' | translate}}</option>
                      <option value="1">{{ 'club.month' | translate }}</option>
                    </select> {{ 'club.next_period' | translate }}
                    <input class="d-flex font-weight-bold" type="number" min="1" max="12" v-model="advanced.loop">
                  </div>
               </f7-col>
             </f7-row>
            <f7-button style="margin: 0px 16px;" v-if="!groups.length > 0" href="/groups/" raised>{{'club.need_create_group' | translate}}!</f7-button>

            <f7-block class="text-center">
              <f7-button v-if="!loaderTraining" type="submit" raised>
                  {{ 'club.add_new_training' | translate }}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import moment from 'moment'
import Navbarlist from "@/components/Navbar/Navbarlist.vue";
import Loader from "@/components/Loader";
import trainingsServices from "@/services/TrainingsServices.js"
import privateOutlinesServices from "@/services/PrivateOutlinesServices.js"
import mixinGroups from "@/mixins/mixinGroups"
import mixinUser from "@/mixins/mixinUser"
import mixinPlayingFields from "@/mixins/mixinPlayingFields"

export default {
  mixins: [mixinGroups, mixinUser, mixinPlayingFields],
  components: {
    Navbarlist,
    Loader
  },
  data: () => {
    return {
      loader: false,
      loaderTraining: false,
      typeOutline: 1,
      training: {
        subject: '',
        group: '',
        training_date: '',
        training_hours_start: '',
        training_hours_end: '',
        outline_url: '',
        outline_private: '',
        notes: '',
        playing_field: ''
      },
      advanced: {
        check: false,
        type: '0',
        loop: 1,
      },
      privateOutlines: []
    };
  },
  mounted() {
    if (this.$f7route.query.day !== undefined) {
      this.training.training_date = moment(new Date(this.$f7route.query.day)).format('YYYY-MM-DD')
    } else {
      this.training.training_date = moment(new Date()).format('YYYY-MM-DD')
    }
    this.training.training_hours_start = '16:00';
    this.training.training_hours_end = '17:30';

    this.fetchDataUser().then(() => {
          this.fetchAllPrivateOutlines();
          this.fetchPlayingFields().then(() => {
            if (this.playingFields.length > 0) {
              this.training.playing_field = this.playingFields[0].id
            }
          });
          this.fetchGroups().then(() => {
            if (this.getRole == 'coach') {
              this.groups = this.profileGroups;
            }
            if (this.groups.length > 0) {
              if (this.$f7route.query.group !== undefined) {
                this.training.group = this.$f7route.query.group
              } else {
                this.training.group = this.groups[0].id
              }
            }
          });
    })
  },
  watch: {
    'training.training_hours_start': function (newVal, oldVal) {
      let time = newVal.split(':')
      let hour = Number(time[0])
      if (hour == 22 || hour == 23) {
        return this.training.training_hours_end = '00:00'
      }
      if (hour <= 7) {
        return this.training.training_hours_end = `0${hour + 2}:00`
      }
      this.training.training_hours_end = `${hour + 2}:00`
    },
  },
  methods: {
    async fetchAllPrivateOutlines() {
      try {
        this.loader = true;

        const responseDataAllPrivateOutlines = await privateOutlinesServices.listAllPrivateOutlines();
        this.privateOutlines = responseDataAllPrivateOutlines.data
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the list of private outlines. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
    async beforeCreateTraining() {
      if (this.advanced.check) {
        if (this.advanced.loop <= 0 || this.advanced.loop > 12) {
          this.$f7.toast
          .create({
            text: "The period of dynamic addition of workouts must be in the range of 1 to 12",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
          return
        }
        for (let loop = 0; loop < this.advanced.loop; loop++) {
          let advancedDate;
          const last = loop + 1 == this.advanced.loop
          if (this.advanced.type == '0') {
            advancedDate = moment(this.training.training_date).add(loop * 7, 'days').format("YYYY-MM-DD")
            this.createTraining(advancedDate, last)
          }
          if (this.advanced.type == '1') {
            advancedDate = moment(this.training.training_date).add(loop * 1, 'months').format("YYYY-MM-DD")
            this.createTraining(advancedDate, last)
          }
        }
      } else {
        this.createTraining(this.training.training_date, true);
      }
    },
    async createTraining(advancedDate, last) {
      if (this.training.group == '') {
        return this.$f7.toast
          .create({
            text: "No group",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
      }
      try {
        this.loaderTraining = true;

        const data = {
          group: this.training.group,
          notes: this.training.notes,
          outline_private: this.training.outline_private,
          outline_url: this.training.outline_url,
          subject: this.training.subject,
          training_date: advancedDate,
          training_hours_end: this.training.training_hours_end,
          training_hours_start: this.training.training_hours_start,
          playing_field: this.training.playing_field,
        }

        const responseCreateTraining = await trainingsServices.createTraining(data);
        if (responseCreateTraining.data.id && last) {
          if (this.$f7route.query.day !== undefined) {
            this.$f7router.navigate(`/calendar/?day=${this.$f7route.query.day}&group=${this.training.group}`)
          } else {
            this.$f7router.navigate(`/trainings/?group=${this.training.group}`)
          }
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the creation of the workout. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderTraining = false;
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/createtraining.scss";
</style>