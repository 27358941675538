import store from '@/store'

//Auth
import LoginPage from '@/views/Auth/LoginPage'
import LoginSocatotsBssPage from '@/views/Auth/LoginSocatotsPage'
import RegisterPage from '@/views/Auth/RegisterPage'
import ForgotPasswordPage from '@/views/Auth/ForgotPasswordPage'
import ForgotPasswordSuccessPage from '@/views/Auth/ForgotPasswordSuccessPage'

import HomePage from '@/views/Pages/HomePage'

// Book
import BooksPage from '@/views/Pages/Books/BooksPage'
import BookPage from '@/views/Pages/Books/BookPage'
import BookTextPage from '@/views/Pages/Books/BookTextPage'
import BookSessionPage from '@/views/Pages/Books/BookSessionPage'
import BookExercisePage from '@/views/Pages/Books/BookExercisePage'
import BookNoAccessPage from '@/views/Pages/Books/BookNoAccessPage'
import FavoritesPage from '@/views/Pages/Books/FavoritesPage'

// Private Outlines
import PrivateOutlinesPage from '@/views/Pages/PrivateOutlines/PrivateOutlinesPage'
import PrivateOutlineItemPage from '@/views/Pages/PrivateOutlines/PrivateOutlineItemPage'
import CreateOutlinePage from '@/views/Pages/PrivateOutlines/CreateOutlinePage'
import PrivateOutlinesCategoriesPage from '@/views/Pages/PrivateOutlines/Categories/CategoriesPage'
import PrivateOutlinesCreateCategoryPage from '@/views/Pages/PrivateOutlines/Categories/CreateCategoryPage'
import PrivateOutlinesCategoryItemPage from '@/views/Pages/PrivateOutlines/Categories/CategoryItemPage'

// Private Exercises
import CreateExercisePage from '@/views/Pages/PrivateExercises/CreateExercisePage'
import PrivateExercisesPage from '@/views/Pages/PrivateExercises/PrivateExercisesPage'
import PrivateExerciseItemPage from '@/views/Pages/PrivateExercises/PrivateExerciseItemPage'
import PrivateExercisesCategoriesPage from '@/views/Pages/PrivateExercises/Categories/CategoriesPage'
import PrivateExercisesCreateCategoryPage from '@/views/Pages/PrivateExercises/Categories/CreateCategoryPage'
import PrivateExercisesCategoryItemPage from '@/views/Pages/PrivateExercises/Categories/CategoryItemPage'
import DatabasePage from '@/views/Pages/DatabasePage'

// Coaches
import CoachesPage from '@/views/Pages/Coaches/CoachesPage'
import AddCoachPage from '@/views/Pages/Coaches/AddCoachPage'
import CoachItemPage from '@/views/Pages/Coaches/CoachItemPage'

// PlayingFields
import PlayingFieldsPage from '@/views/Pages/PlayingFields/PlayingFieldsPage'
import CreatePlayingFieldPage from '@/views/Pages/PlayingFields/CreatePlayingFieldPage'
import PlayingFieldItemPage from '@/views/Pages/PlayingFields/PlayingFieldItemPage'

// Dues
import DuesPage from '@/views/Pages/Dues/DuesPage'
import CreateDuePage from '@/views/Pages/Dues/CreateDuePage'
import DueItemPage from '@/views/Pages/Dues/DueItemPage'

// Players
import PlayersPage from '@/views/Pages/Players/PlayersPage'
import CreatePlayerPage from '@/views/Pages/Players/CreatePlayerPage'
import PlayerItemPage from '@/views/Pages/Players/PlayerItemPage'

// Groups
import GroupsPage from '@/views/Pages/Groups/GroupsPage'
import CreateGroupPage from '@/views/Pages/Groups/CreateGroupPage'
import GroupItemPage from '@/views/Pages/Groups/GroupItemPage'

// PlanningTrainings
import PlanningTrainingsPage from '@/views/Pages/PlanningTrainings/PlanningTrainingsPage'
import CreatePlanningTrainingPage from '@/views/Pages/PlanningTrainings/CreatePlanningTrainingPage'
import PlanningTrainingItemPage from '@/views/Pages/PlanningTrainings/PlanningTrainingItemPage'

// PlanningTrainings
import TrainingsPage from '@/views/Pages/Trainings/TrainingsPage'
import CreateTrainingPage from '@/views/Pages/Trainings/CreateTrainingPage'
import TrainingItemPage from '@/views/Pages/Trainings/TrainingItemPage'

import CalendarPage from '@/views/Pages/CalendarPage'
import StatsPage from '@/views/Pages/StatsPage'

// Timetable
import PublicTimetable from '@/views/Pages/Timetable/PublicTimetable'
import Timetable from '@/views/Pages/Timetable/Timetable'

// AttendanceList
import CreateAttendancePage from '@/views/Pages/AttendanceList/CreateAttendancePage'
import AttendanceItemPage from '@/views/Pages/AttendanceList/AttendanceItemPage'

// CRM
import CrmPage from '@/views/Pages/CrmPage'
import SmsPage from '@/views/Pages/Sms/SmsPage'
import AccountPage from '@/views/Pages/AccountPage'
import RecommendationsPage from '@/views/Pages/RecommendationsPage'
import AboutPage from '@/views/Pages/AboutPage'
import ContactPage from '@/views/Pages/ContactPage'
import PartnersPage from '@/views/Pages/PartnersPage'
import FaqPage from '@/views/Pages/FaqPage'
import AuthorsPage from '@/views/Pages/AuthorsPage'
import CoachingNotepadPage from '@/views/Pages/CoachingNotepadPage';

// Settings
import SettingsOutlinesPage from '@/views/Pages/SettingsOutlinesPage'
import SettingsPage from '@/views/Pages/SettingsPage'

// Partner
import PartnerFaq from '@/views/Pages/Partner/PartnerFaqPage'
import PartnerWallet from '@/views/Pages/Partner/PartnerWalletPage'

// Apps
import CoachBlockGenerator from '@/views/Pages/CoachBlockGenerator';
import TacticBoard from '@/views/Pages/TacticBoard';
import CreateTournament from '@/views/Pages/CreateTournament';

// Other
import NoAccessPage from '@/views/Pages/NoAccessPage';
import NoAccessPrivatePage from '@/views/Pages/NoAccessPrivatePage';
import ChangeRolePage from '@/views/Pages/ChangeRolePage';
import BestCoachPage from '@/views/Pages/BestCoachPage';
import NotFoundPage from '@/views/Pages/NotFoundPage';
import NotPublicFoundPage from '@/views/Pages/NotPublicFoundPage';
import ConstructionPage from '@/views/Pages/ConstructionPage';

// Library
import LibraryPage from '@/views/Pages/Library/LibraryPage';

// Payments
import Payments from '@/views/Pages/Payments/PaymentsPage'
import PaymentsSuccess from '@/views/Pages/Payments/PaymentsSuccessPage'
import PaymentsFailed from '@/views/Pages/Payments/PaymentsFailedPage'
import PaymentsPricingPage from '@/views/Pages/Payments/PaymentsPricingPage';

// Jose
import CoachJose from '@/views/Pages/CoachJose'
import ChatHistory from '@/views/Pages/ChatHistory'


function checkAuth(to, from, resolve, reject) {
  const router = this;
  resolve();
  router.navigate('/home/');
}

const routes = [
  {
    path: '/',
    beforeEnter: checkAuth,
  },
  {
    path: '/home/',
    component: HomePage,
  },
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/login-socatots-bss/',
    component: LoginSocatotsBssPage,
  },
  {
    path: '/register/',
    component: RegisterPage,
  },
  {
    path: '/forgot-password/',
    component: ForgotPasswordPage,
    beforeEnter(to, from, next) {
      if (to.query.token !== undefined && to.query.email !== undefined) {
        next({path: to.path, query: { token: to.query.token, email: to.query.email }})
      } else {
        next()
      }
    },
  },
  {
    path: '/forgot-password-success/',
    component: ForgotPasswordSuccessPage,
  },
  {
    path: '/books/',
    component: BooksPage,
  },
  {
    path: '/books/:idBook/',
    component: BookPage,
    props: true,
  },
  {
    path: '/books/:idBook/text/:id/',
    component: BookTextPage,
    props: true,
  },
  {
    path: '/books/:idBook/no-access/',
    component: BookNoAccessPage,
    props: true,
  },
  {
    path: '/no-access-private/',
    component: NoAccessPrivatePage,
    props: true,
  },
  {
    path: '/books/:idBook/sessions/:id/',
    component: BookSessionPage,
    props: true,
  },
  {
    path: '/books/:idBook/exercises/:id/',
    component: BookExercisePage,
    props: true,
  },
  {
    path: '/favorites/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated']) {
        resolve({component: FavoritesPage});
      } else {
        reject();
        router.navigate('/no-access/');
      }
    }
  },
  {
    path: '/library/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated']) {
        resolve({component: LibraryPage});
      } else {
        reject();
        router.navigate('/no-access/');
      }
    }
  },
  {
    path: '/account/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated']) {
        resolve({component: AccountPage});
      } else {
        reject();
        router.navigate('/no-access/');
      }
    }
  },
  {
    path: '/settings/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: SettingsPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/settings-outlines/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: SettingsOutlinesPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/trainings/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: TrainingsPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
  },
  {
    path: '/trainings/:idTraining',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: TrainingItemPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
    props: true,
  },
  {
    path: '/create-training/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: CreateTrainingPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/create-attendance/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: CreateAttendancePage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/attendance/:idAttendance/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: AttendanceItemPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
    props: true,
  },
  {
    path: '/groups/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: GroupsPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/groups/:idGroup/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: GroupItemPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
    props: true,
  },
  {
    path: '/create-group/',
    async: function(to, from, resolve, reject) {
      const router = this;
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: CreateGroupPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/players/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: PlayersPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/create-player/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: CreatePlayerPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
  path: '/players/:idPlayer/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: PlayerItemPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
    props: true,
  },
  {
    path: '/player/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && store.getters['appkonspektypilkanozna/getRole'] == 'player') {
        resolve({component: ConstructionPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && store.getters['appkonspektypilkanozna/getRole'] !== 'player') {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/player-dues/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && store.getters['appkonspektypilkanozna/getRole'] == 'player') {
        resolve({component: ConstructionPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && store.getters['appkonspektypilkanozna/getRole'] !== 'player') {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/player-diet/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && store.getters['appkonspektypilkanozna/getRole'] == 'player') {
        resolve({component: ConstructionPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && store.getters['appkonspektypilkanozna/getRole'] !== 'player') {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/player-training/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && store.getters['appkonspektypilkanozna/getRole'] == 'player') {
        resolve({component: ConstructionPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && store.getters['appkonspektypilkanozna/getRole'] !== 'player') {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/player-timetable/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && store.getters['appkonspektypilkanozna/getRole'] == 'player') {
        resolve({component: ConstructionPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && store.getters['appkonspektypilkanozna/getRole'] !== 'player') {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/player-attendance/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && store.getters['appkonspektypilkanozna/getRole'] == 'player') {
        resolve({component: ConstructionPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && store.getters['appkonspektypilkanozna/getRole'] !== 'player') {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/create-outline/',
    component: CreateOutlinePage,
  },
  {
    path: '/private-exercises/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: PrivateExercisesPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/private-microcycles/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: ConstructionPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/private-microcycles-categories/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: ConstructionPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/private-exercises/:idExercise/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: PrivateExerciseItemPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
    props: true,
  },
  {
    path: '/create-exercise/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: CreateExercisePage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/private-outlines/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: PrivateOutlinesPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/private-outlines/:idOutline/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: PrivateOutlineItemPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
    props: true,
  },
  {
    path: '/private-outlines-categories/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: PrivateOutlinesCategoriesPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/private-outlines-categories/:idCategory/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: PrivateOutlinesCategoryItemPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
    props: true,
  },
  {
    path: '/private-outlines-create-category/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: PrivateOutlinesCreateCategoryPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/private-exercises-categories/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: PrivateExercisesCategoriesPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/private-exercises-categories/:idCategory/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: PrivateExercisesCategoryItemPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
    props: true,
  },
  {
    path: '/private-exercises-create-category/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: PrivateExercisesCreateCategoryPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/planning-trainings/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: PlanningTrainingsPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/planning-trainings/:idPlanningTraining/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: PlanningTrainingItemPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
    props: true,
  },
  {
    path: '/create-planning-training/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: CreatePlanningTrainingPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/stats/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: StatsPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/calendar/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: CalendarPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/crm/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: CrmPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/database/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner' || store.getters['appkonspektypilkanozna/getRole'] == 'books')) {
        resolve({component: DatabasePage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner' || store.getters['appkonspektypilkanozna/getRole'] !== 'books')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/playing-fields/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: PlayingFieldsPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/playing-fields/:idPlayingField/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: PlayingFieldItemPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
    props: true,
  },
  {
    path: '/create-playing-field/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: CreatePlayingFieldPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/matches/',
    component: ConstructionPage,
  },
  {
    path: '/physical-test/',
    component: ConstructionPage,
  },
  {
    path: '/medical-examination/',
    component: ConstructionPage,
  },
  {
    path: '/dues/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: DuesPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/dues/:idDue/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: DueItemPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
    props: true,
  },
  {
    path: '/create-due/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: CreateDuePage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/history-sms/',
    component: ConstructionPage,
  },
  {
    path: '/sms/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: SmsPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/timetable/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: Timetable});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/timetable-public/',
    component: PublicTimetable,
  },
  {
    path: '/coach-block-generator/',
    component: CoachBlockGenerator,
  },
  {
    path: '/create-tournament/',
    component: CreateTournament,
  },
  {
    path: '/tactic-board/',
    component: TacticBoard,
  },
  {
    path: '/create-cv/',
    component: ConstructionPage,
  },
  {
    path: '/coaches/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: CoachesPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/coaches/update/:idCoach/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: CoachItemPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    },
    props: true,
  },
  {
    path: '/add-coach/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'coach' || store.getters['appkonspektypilkanozna/getRole'] == 'owner')) {
        resolve({component: AddCoachPage});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'coach' || store.getters['appkonspektypilkanozna/getRole'] !== 'owner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/recommendations/',
    component: RecommendationsPage,
  },
  {
    path: '/coaching-notepad/',
    component: CoachingNotepadPage,
  },
  {
    path: '/contact/',
    component: ContactPage,
  },
  {
    path: '/partners/',
    component: PartnersPage,
  },
  {
    path: '/ad/',
    component: ConstructionPage,
  },
  {
    path: '/partnership/',
    component: ConstructionPage,
  },
  {
    path: '/faq/',
    component: FaqPage,
  },
  {
    path: '/social-media/',
    component: ConstructionPage,
  },
  {
    path: '/authors/',
    component: AuthorsPage,
  },
  {
    path: '/history/',
    component: ConstructionPage,
  },
  {
    path: '/payments/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated']) {
        resolve({component: Payments});
      } else {
        reject();
        router.navigate('/pricing/');
      }
    }
  },
  {
    path: '/payments-success/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated']) {
        resolve({component: PaymentsSuccess});
      } else {
        reject();
        router.navigate('/pricing/');
      }
    }
  },
  {
    path: '/payments-failed/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated']) {
        resolve({component: PaymentsFailed});
      } else {
        reject();
        router.navigate('/pricing/');
      }
    }
  },
  {
    path: '/best-coach/',
    component: BestCoachPage,
  },
    {
    path: '/partner-wallet/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'partner')) {
        resolve({component: PartnerWallet});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'partner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/partner-faq/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] == 'partner')) {
        resolve({component: PartnerFaq});
      } else {
        if (store.getters['appkonspektypilkanozna/isAuthenticated'] && (store.getters['appkonspektypilkanozna/getRole'] !== 'partner')) {
          reject();
          router.navigate('/change-role/');
        } else {
          reject();
          router.navigate('/no-access/');
        }
      }
    }
  },
  {
    path: '/coach-jose/',
    component: CoachJose,
  },
  {
    path: '/chat-history/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['appkonspektypilkanozna/isAuthenticated']) {
        resolve({component: ChatHistory});
      } else {
        reject();
        router.navigate('/no-access/');
      }
    }
  },
  {
    path: '/change-role/',
    component: ChangeRolePage,
  },
  {
    path: '/no-access/',
    component: NoAccessPage,
  },
  {
    path: '/pricing/',
    component: PaymentsPricingPage,
  },
  {
    path: '/not-found/',
    component: NotFoundPage,
  },
  {
    path: '/not-public-found/',
    component: NotPublicFoundPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
