<template>
  <f7-page login-screen>
    <navbar></navbar>
    <f7-block class="text-center">
      <img style="height: 150px;" src="/static/icons/shirt-2.svg" alt />
    </f7-block>
    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'auth.login_page' | translate}}</h4>
    </f7-block>
    <f7-list form no-hairlines-md @submit.prevent="onLogin">
      <f7-list-input
        :label="'auth.email' | translate"
        type="email"
        :placeholder="'auth.email' | translate"
        :value="login.email"
        @input="login.email = $event.target.value"
        required
        validate
        autocomplete="off"
        :error-message="'global.required' | translate"
        clear-button
      >
      </f7-list-input>
      <f7-list-input
        :label="'auth.password' | translate"
        type="password"
        :placeholder="'auth.password' | translate"
        :value="login.password"
        @input="login.password = $event.target.value"
        required
        validate
        autocomplete="off"
        :error-message="'global.required' | translate"
        clear-button
      >
      </f7-list-input>
      <f7-block class="text-center">
        <f7-button v-if="!login.loader" type="submit" raised>{{ 'auth.login_link' | translate}}</f7-button>
        <f7-button v-else raised>
          <f7-preloader :size="20" color="white"></f7-preloader>
        </f7-button>
      </f7-block>
    </f7-list>
    <f7-block>
      <f7-link href="/forgot-password/" class="text-primary">{{ 'auth.forgot_password_link' | translate}}?</f7-link>
    </f7-block>
    <f7-block class="text-center">
      <f7-button href="/register/" raised>{{ 'auth.register_link' | translate}}</f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";
import authServices from "@/services/AuthServices.js"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  components: {
    Navbar,
  },
  data: () => {
    return {
      login: {
        email: null,
        password: null,
        loader: false
      }
    };
  },
  methods: {
    async onLogin() {
      try {
        this.login.loader = true;

        const responseToken = await authServices.token(this.login.email.toLowerCase(), this.login.password);
        if (window.location.host == 'app.soccer-sessions.com') {
            // User.com pageHit
            UE.pageHit({
              apiKey: 'uCdvLn',
              email: this.login.email,
            });
          };
        this.$store.dispatch("appkonspektypilkanozna/setToken", responseToken.data.access);
        this.checkVerified(responseToken.data.access)
        this.fetchDataUser().then(() => {
          this.$store.dispatch("appkonspektypilkanozna/setRole", 'books');
          this.$store.dispatch("appkonspektypilkanozna/setGlobalId", this.userId);
        });
        this.$f7router.navigate('/');
        this.$f7.toast.create({
            text: 'Correct login to the application.',
            position: 'bottom',
            closeTimeout: 3000,
        }).open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Invalid login details.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.login.loader = false;
    }
  }
};
</script>