export default {
    methods: {
      autoResize(event) {
        event.target.style.height = "auto";
        event.target.style.height = `${event.target.scrollHeight}px`;
      },
      autoResizeLoad(event) {
        event.style.height = "auto";
        event.style.height = `${event.scrollHeight}px`;
      },
      autoResizeAllTextarea() {
        const allTextarea = document.querySelectorAll('textarea');
        for (let element of allTextarea) {
          this.autoResizeLoad(element)
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.$el.setAttribute("style", "height",
        `${this.$el.scrollHeight}px`);
      });
    }
  };