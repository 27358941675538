<template>
  <div id="cookies-message-container" style="display: none;">
      <div id="cookies-message" class="cookies-message">
          By continuing to use this website, you consent to the use of cookies in accordance with
          <f7-link href="https://soccer-sessions.com/privacy-policy" external target="_blank">
          our privacy policy.
            </f7-link>
            <div href="#" id="accept-cookies-checkbox" class="accept-cookies-checkbox" name="accept-cookies">
                <svg height="12.5" viewBox="0 0 26 25" width="13" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="50" stroke-width="4"><path d="M2.25 2.683l20.314 20.315M2.685 22.753L23.001 2.438"/></g></svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
      if (document.querySelector('#accept-cookies-checkbox')) {
        document.getElementById('accept-cookies-checkbox').addEventListener('click', () => {
          this.WHCreateCookie('cookies', 'T', 365);
          document.getElementById('cookies-message-container')
            .removeChild(document.getElementById('cookies-message'));
        });
      }
      const isTimetablePublicView = window.location.href.indexOf('timetable-public') > -1

      if (!isTimetablePublicView) {
        window.onload = this.WHCheckCookies;
      }
    },
    methods: {
      WHCreateCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = `; expires=${date.toGMTString()}`;
        document.cookie = `${name}=${value}${expires}; path=/`;
      },
      WHReadCookie(name) {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
          let c = ca[i];
          while (c.charAt(0) === ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
      },
      WHCheckCookies() {
        if (this.WHReadCookie('cookies') !== 'T' && document.querySelector('#accept-cookies-checkbox')) {
          const cookiesMessage = document.getElementById('cookies-message-container');
          cookiesMessage.style.display = 'block';
        }
      },
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/cookies.scss";
</style>