<template>
  <f7-page login-screen>
    <navbarlist :list="'private-exercises-categories'"></navbarlist>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{ 'base.category' | translate}}
              <img class="cursor-pointer" @click="showDialogDeleteCategory(category)" height="14px" src="/static/icons/trash.svg" alt />
            </h4>
          </f7-block>
          <f7-list form no-hairlines-md @submit.prevent="updateCategory">
           <f7-list-input
              :label="'base.category' | translate"
              type="text"
              :placeholder="'base.category' | translate"
              :value="category.title"
              @input="category.title = $event.target.value"
              clear-button
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>
            <f7-list-item :title="'base.color_category' | translate" class="custom-select">
              <select name="color" v-model="category.color">
                <option :value="null">{{ 'global.lack' | translate}}</option>
                <option v-for="(color, index) in paleteColorsDot" :key="index" :value="index" :selected="index == category.color">
                    {{color.label}}
                </option>
              </select>
            </f7-list-item>
            <f7-block class="text-center">
              <f7-button v-if="!loaderCategory" type="submit" raised>
                {{ 'global.save' | translate}}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import Navbarlist from "@/components/Navbar/Navbarlist.vue";
import Loader from "@/components/Loader";
import privateExercisesServices from "@/services/privateExercisesServices.js"
import mixinUser from "@/mixins/mixinUser"
import CONSTANTS from "@/utils/constants.js"

export default {
  mixins: [mixinUser],
  components: {
    Navbarlist,
    Loader,
  },
  props: {
    idCategory: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      loader: false,
      loaderCategory: false,
      paleteColorsDot: CONSTANTS.COLORS_DOT,
      category: {
        title: '',
        color: null,
      },
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchDataUser().then(() => {
        this.fetchCategory(this.idCategory)
      })
    }
  },
  methods: {
    async fetchCategory(id) {
      try {
        this.loader = true;

        const responseDataCategory = await privateExercisesServices.itemCategory(id);
        this.category = responseDataCategory.data
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with the category in private outlines. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    async updateCategory() {
      try {
        this.loaderCategory = true;

        const responseUpdateCategory = await privateExercisesServices.category(this.category);
        if ('access' in responseUpdateCategory.data && !responseUpdateCategory.data.access) {
            this.$f7.toast
            .create({
              text: "Access to the module has expired!",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        } else {
          this.$f7router.navigate(`/private-exercises-categories/`)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with category editing in private outlines. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderCategory = false;
    },
    showDialogDeleteCategory(category) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the category ${category.title}? By removing a player, you will also remove all his presences, contributions, research. The change will be irreversible!`, '', () => {
          this.deleteCategory(category.id)
        });
    },
    async deleteCategory(id) {
      try {
        const responseDeleteCategory = await privateExercisesServices.deleteCategory(id);
        this.$f7router.navigate(`/private-exercises-categories/`)
        this.$f7.toast
          .create({
            text: "The category has been removed!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with deleting categories in private outlines. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  }
};
</script>