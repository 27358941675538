<template>
  <f7-page login-screen>
    <navbarcalendar></navbarcalendar>
    <loader v-if="loader"></loader>

    <div v-else>

    <f7-block class="text-center text-uppercase ">
      <h4>CRM</h4>
    </f7-block>
    <f7-block class="menuPage">
        <f7-row>
           <f7-col width="50">
            <a href="/groups/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/drink.svg" alt />
                  <p>{{ 'global.groups' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/players/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/player.svg" alt />
                  <p>{{ 'global.players' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/dues/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/dues.svg" alt />
                  <p>{{ 'global.dues' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <!-- <f7-col width="50">
            <a href="/medical-examination/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/medical-examination.svg" alt />
                  <p>Badania lekarskie</p>
                </div>
              </f7-card>
            </a>
          </f7-col> -->
           <!-- <f7-col width="50">
            <a href="/physical-test/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/podium.svg" alt />
                  <p>Testy sprawnościowe</p>
                </div>
              </f7-card>
            </a>
          </f7-col> -->
           <f7-col width="50">
            <a href="/trainings/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/training.svg" alt />
                  <p>{{ 'global.trainings' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/planning-trainings/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/pencil.svg" alt />
                  <p>{{ 'global.training_planning' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
         
           <f7-col width="50">
            <a href="/playing-fields/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/light.svg" alt />
                  <p>{{ 'global.pitches' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/matches/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/field-2.svg" alt />
                  <p>{{ 'global.match' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col width="50">
            <a href="/history-sms/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/history-sms.svg" alt />
                  <p>{{ 'global.historySms' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
        </f7-row>
    </f7-block>
    </div>

  </f7-page>
</template>

<script>
import Navbarcalendar from "@/components/Navbar/Navbarcalendar.vue";
import mixinUser from "@/mixins/mixinUser"
import Loader from "@/components/Loader";

export default {
  mixins: [mixinUser],
  components: {
    Loader,
    Navbarcalendar
  },
  data: () => {
    return {
      loader: false,
    }
  },
  mounted() {
    this.fetchDataUser()
  }
};
</script>

<style lang="scss">
</style>