import Vue from 'vue';
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';
import 'framework7/css/framework7.bundle.css';
import App from './App.vue';
import Filters from './filters'
import store from './store'
import mixinAuth from './mixins/mixinAuth'
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import JwPagination from 'jw-vue-pagination';

Vue.component('jw-pagination', JwPagination);

if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    Vue,
    dsn: "https://00056f0a80b347beb9ed52d52bd62fd4@o564855.ingest.sentry.io/5705836",
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

Framework7.use(Framework7Vue);

Vue.mixin(mixinAuth)
Vue.use(Filters)
Vue.use(VueGtag, {
  config: { id: "G-102WKETPPN" }
});

new Vue({
  el: '#app',
  render: (h) => h(App),
  store,
  components: {
    App
  },
});