<template>
  <f7-page class="no-print">
    <navbar></navbar>
    <loader v-if="loader"></loader>

    <div v-else>
      <f7-block class="text-uppercase">
        <h4 style="margin-bottom: 30px;" :class="{'text-center': favorites.length == 0}">{{ 'global.favorite' | translate }}</h4>
              
        <div v-if="favorites.length > 0" class="countAll">
            <span>
              <p>{{ 'base.count' | translate }}</p>
              {{favorites.length}}
            </span>
          </div>
      </f7-block>
    <f7-block style="padding-left: 8px; padding-right: 8px;">
      <div v-if="favorites.length == 0">
          <f7-block class="text-center">
            <p>{{ 'global.favorite_description_1' | translate}}</p>
            <p>{{ 'global.favorite_description_2' | translate}}</p>
            <img height="50px" src="/static/icons/star-black.svg" alt />
          </f7-block>
      </div>
        <f7-row v-else no-gap class="rowListCard">
          <f7-col width="50" medium="33" large="25" xlarge="13" v-for="(session, index) in favorites" :key=index>
            <card :session="session" :keyImage="session.keyImage" :bookId="session.idBook" :remove="true" @on-remove-success="onRemoveSuccess"></card>
          </f7-col>
        </f7-row>
    </f7-block>
    </div>
  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";
import Card from "@/components/Card.vue"
import Loader from "@/components/Loader";
import authServices from "@/services/AuthServices"

export default {
  data: () => {
    return {
      favorites: [],
      loader: false,
    };
  },
  components: {
    Navbar,
    Card,
    Loader
  },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchDataUserFavoritesDone()
    }
  },
  methods: {
    onRemoveSuccess(session) {
      const index = this.favorites.map(elem => {
        if (elem.book == session.book) {
          return elem.id;
        }
      }).indexOf(session.id);

      this.favorites.splice(index, 1);

      this.onFavoriteSuccess(this.favorites)
    },
    async onFavoriteSuccess(favorites) {
      try {
        const responseDataFavorites = await authServices.updateFavorites(favorites);
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
    async fetchDataUserFavoritesDone() {
      try {
        this.loader = true;
        const responseDataUserFavoritesDone = await authServices.userFavoritesDone();
        this.favorites = responseDataUserFavoritesDone.data.favorites
        this.books = responseDataUserFavoritesDone.data.books
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
  }
};
</script>

<style lang="scss">

</style>