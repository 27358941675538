<template>
  <f7-page login-screen>
    <navbar></navbar>
    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'global.faq_page' | translate }}</h4>
    </f7-block>
    <f7-block>
     
      <f7-list accordion-list accordion-opposite>
        <f7-list-item accordion-item :title="'global.faq_stripe' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.stripe_1' | translate }}</p>
              <img width="100%" src="/static/faq/stripe_1.png">
              <p>{{ 'global.stripe_2' | translate }}</p>
              <img width="100%" src="/static/faq/stripe_2.png">
              <p>{{ 'global.stripe_3' | translate }}</p>
              <img width="100%" src="/static/faq/stripe_3.png">
              <p>{{ 'global.stripe_4' | translate }}</p>
              <img width="100px"  src="/static/faq/stripe_4.png">
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.faq_pwa' | translate">
          <f7-accordion-content>
            <f7-block>
              <p>{{ 'global.pwa_0' | translate }}</p>
              <p>{{ 'global.pwa_1' | translate }}</p>
              <p>{{ 'global.pwa_2' | translate }}</p>
              <p>{{ 'global.pwa_3' | translate }}</p>
              <img width="60px" src="/static/faq/pwa_3A.png">
              <img width="250px" src="/static/faq/pwa_3B.png">
              <img width="250px" src="/static/faq/pwa_3C.png">
              <p>{{ 'global.pwa_4' | translate }}</p>
              <img width="250px" src="/static/faq/pwa_4.png">
              <p>{{ 'global.pwa_5' | translate }}</p>
              <img width="60px" src="/static/faq/pwa_5.png">
              <p>{{ 'global.pwa_6' | translate }}</p>
              <p>{{ 'global.pwa_7' | translate }}</p>
              <img width="300px" src="/static/faq/pwa_7A.png">
              <img width="300px" src="/static/faq/pwa_7B.png">
              <p>{{ 'global.pwa_8' | translate }}</p>
              <img width="300px" src="/static/faq/pwa_8A.png">
              <img width="300px" src="/static/faq/pwa_8B.png">
              <p>{{ 'global.pwa_9' | translate }}</p>
              <img width="60px" src="/static/faq/pwa_9.png">



            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.faq_account' | translate">
          <f7-accordion-content>
            <f7-block>
              <video id="video-player" width="100%" controls loop muted>
                  <source src="/static/faq/guide_kpn_account.mp4" type="video/mp4">
                </video>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.faq_publications' | translate">
          <f7-accordion-content>
            <f7-block>
              <video id="video-player" width="100%" controls loop muted>
                <source src="/static/faq/guide_kpn_publications.mp4" type="video/mp4">
              </video>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item accordion-item :title="'global.faq_private_outlines' | translate">
          <f7-accordion-content>
            <f7-block>
              <video id="video-player" width="100%" controls loop muted>
                <source src="/static/faq/guide_kpn_private_outlines.mp4" type="video/mp4">
              </video>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
      <f7-block>
        <video id="video-player" width="100%" controls loop muted>
          <source src="/static/faq/soccer_sessions_mobile_app_promo_.mp4" type="video/mp4">
        </video>
      </f7-block>
    </f7-block>
  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>