<template>
  <f7-page login-screen>
    <navbarcrm></navbarcrm>
    <loader v-if="loader"></loader>

    <div v-else>
    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'club.settings' | translate }}</h4>
    </f7-block>
    <f7-block class="menuPage">
        <f7-row>
          <f7-col v-if="getRole == 'owner'" width="50">
            <a href="/coaches/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/agent.svg" alt />
                  <p>{{ 'club.coaches' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <f7-col v-if="getRole == 'owner'" width="50">
            <a href="/timetable/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/timetable.svg" alt />
                  <p>{{ 'club.information_board' | translate }}</p>
                </div>
              </f7-card>
            </a>
          </f7-col>
          <!-- <f7-col width="50">
            <a href="/settings-tournament/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/trophy.svg" alt />
                  <p>Ustawienia turniej</p>
                </div>
              </f7-card>
            </a>
          </f7-col> -->
          <!-- <f7-col width="50">
            <a href="/history-sms/">
              <f7-card class="custom-card">
                <div>
                  <img height="40px" src="/static/icons/cv.svg" alt />
                  <p>Ustawienia CV Trenera</p>
                </div>
              </f7-card>
            </a>
          </f7-col> -->
        </f7-row>
    </f7-block>
    </div>
  </f7-page>
</template>

<script>
import Navbarcrm from "@/components/Navbar/Navbarcrm.vue";
import mixinUser from "@/mixins/mixinUser"
import Loader from "@/components/Loader";

export default {
  mixins: [mixinUser],
  components: {
    Navbarcrm,
    Loader
  },
  data: () => {
    return {
      loader: false,
    }
  },
  mounted() {
    this.fetchDataUser()
  }
};
</script>

<style lang="scss">
</style>