<template>
    <div
    class="cursor-pointer"
    >
        <f7-card class="book-card">
            <a :href="session.typeElem == 'exercise' ? `/books/${bookId}/exercises/${session.id}/` : `/books/${bookId}/sessions/${session.id}/`">
              <f7-card-header
                  class="no-border"
                  :class="{ 'image-white': checkBgMainImage(session.mainImage) }"
                  valign="bottom"
                  :style="{ 'background-image': 'url('+ mainImage +')' }"
              >{{session.title}}</f7-card-header>
              <f7-card-content>
                  <p>{{session.content}}</p>
              </f7-card-content>
            </a>
            <f7-card-footer>
                  <div v-if="add" class="w-100 d-flex justify-content-between align-items-center">
                    <div @click.stop="toggleDone(session)" class="cursor-pointer p-1">
                      <img height="11px" :src="session.isDone ? '/static/icons/done-full.svg' : '/static/icons/done-empty.svg'" />
                    </div>
                    <div @click.stop="toggleFavorite(session)" class="cursor-pointer p-1">
                      <img height="12px" :src="session.isFavorite ? '/static/icons/star-full.svg' : '/static/icons/star-empty.svg'" />
                    </div>
                  </div>
                  <div v-if="remove" class="w-100 d-flex justify-content-end">
                    <div @click.stop="removeToFavorites(session)" class="cursor-pointer p-1">
                      <img height="12px" :src="'/static/icons/trash.svg'" />
                    </div>
                  </div>
            </f7-card-footer>

    </f7-card>
    </div>
</template>

<script>
export default {
  name: 'cart',
  props: {
    session: {
      type: Object,
      required: true,
    },
    keyImage: {
      type: String,
      required: true,
    },
    bookId: {
      type: String,
      required: true,
    },
    add: {
        type: Boolean
    },
    remove: {
        type: Boolean
    }
  },
  computed: {
    mainImage() {
      if (this.session.typeElem == 'session') {
        return `static/${this.keyImage}/session-${this.session.id}/${this.session.mainImage}`
      }
      if (this.session.typeElem == 'exercise') {
        return `static/${this.keyImage}/${this.session.mainImage}`
      }
    },
  },
  methods: {
    checkBgMainImage(url) {
      return url.includes('adler_2_mlodzik_') || url.includes('adler_skrzat_') || url.includes('adler_3_') || url.includes('pep_') || url.includes('gry_treningowe_') || url.includes('skuteczny_') || url.includes('system_') || url.includes('ss_futsal_')
    },
    toggleFavorite(session) {
          if (session.isFavorite) {
            this.$f7.toast.create({
                text: `${session.title}: has been removed from favorites.`,
                position: 'bottom',
                closeTimeout: 1000,
            }).open();
          } else {
            this.$f7.toast.create({
              text: `${session.title}: has been added to favorites.`,
              position: 'bottom',
              closeTimeout: 1000,
            }).open();
          }
          this.$emit('on-favorite-success', session);
      },
      removeToFavorites(session) {
        this.$f7.toast.create({
            text: `${session.title}: has been removed from favorites.`,
            position: 'bottom',
            closeTimeout: 1000,
        }).open();
        this.$emit('on-remove-success', session);
     },
    toggleDone(session) {
        if (session.isDone) {
            this.$f7.toast.create({
              text: `${session.title}: has been marked as unread.`,
              position: 'bottom',
              closeTimeout: 1000,
            }).open();
        } else {
            this.$f7.toast.create({
              text: `${session.title}: has been marked as read.`,
              position: 'bottom',
              closeTimeout: 1500,
            }).open();
        }
        this.$emit('on-done-success', session);
    },

  }
}
</script>

<style lang="scss">
@import "@/assets/scss/card.scss";
</style>