<template>
  <f7-page>
    <navbar></navbar>

  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>