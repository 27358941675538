<template>
    <f7-page login-screen>
      <navbarcrm></navbarcrm>
  
      <loader v-if="loader"></loader>
  
      <div v-else>
            <f7-block class="text-center text-uppercase">
              <h4>{{'sms.new_sms' | translate}} <f7-link class="p-1" :tooltip="'popover.new_sms' | translate"><img height="11px" src="/static/icons/help.svg" alt /></f7-link></h4>
            </f7-block>
            <f7-list form no-hairlines-md @submit.prevent="beforeSendSms">
             
              <f7-list-item :title="'club.group' | translate" class="custom-select">
                <select name="group" v-model="sms.group">
                  <option v-if="groups.length == 0" value="" disabled>{{ 'club.lack_group' | translate }}</option>
                  <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == sms.group">
                      {{group.name}}
                  </option>
                </select>
              </f7-list-item>
              
              <f7-list-input
                :label="'sms.body' | translate"
                type="textarea"
                :placeholder="'sms.body' | translate"
                :value="sms.body"
                @input="sms.body = $event.target.value"
                maxlength="480"
                clear-button
                required
                validate
                :error-message="'global.required' | translate"
              >
              </f7-list-input>

              <f7-button style="margin: 0px 16px;" v-if="!groups.length > 0" href="/groups/" raised>{{'club.need_create_group' | translate}}!</f7-button>

              <loader v-if="loaderPlayer"></loader>
              
            <f7-list-item v-else-if="players.length > 0" class="custom-item-inside-list-header">
                <h4 class="text-uppercase">{{ 'club.players_list' | translate }} ({{ players.filter(player => player.presense === true).length }}):</h4>
                </f7-list-item>
                <f7-button v-else style="margin: 0px 16px;" href="/players/" raised>{{'club.need_add_player_to_group' | translate}}!</f7-button>


                <f7-list-item class="custom-item-inside-list" v-for="player in players" :key="player.id">
                <span>
                    <f7-link v-if="player.status_active" :tooltip="'club.status_active' | translate">
                    <div style="width: 10px; height: 10px; background-color: green; border-radius: 50%;"></div>
                    </f7-link>
                    <f7-link v-else :tooltip="'club.status_not_active' | translate">
                    <div style="width: 10px; height: 10px; background-color: red; border-radius: 50%;"></div>
                    </f7-link>
                    {{player.first_name}} {{player.last_name}}
                </span>
                
                <input type="checkbox" class="custom-checkbox" v-model="player.presense" :disabled="player.phone == null || player.phone == ''">
            </f7-list-item>
              
  
              <f7-block class="text-center">
                <f7-button v-if="!loaderSms" type="submit" raised :disabled="loaderPlayer">
                    {{ 'sms.send_sms' | translate }}
                </f7-button>
                <f7-button v-else raised>
                  <f7-preloader :size="20" color="white"></f7-preloader>
                </f7-button>
              </f7-block>
            </f7-list>
      </div>
  
    </f7-page>
  </template>
  
  <script>
  import Navbarcrm from "@/components/Navbar/Navbarcrm.vue";
  import Loader from "@/components/Loader";
  import mixinGroups from "@/mixins/mixinGroups"
  import mixinUser from "@/mixins/mixinUser"
  import groupsServices from "@/services/GroupsServices.js"
  
  export default {
    mixins: [mixinGroups, mixinUser],
    components: {
        Navbarcrm,
        Loader
    },
    data: () => {
      return {
        loader: false,
        loaderPlayer: false,
        loaderSms: false,
        sms: {
          group: '',
          body: '',
          trainer: '',
        },
        players: [],
      };
    },
    watch: {
        'sms.group': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchAllPlayers(this.sms.group);
            }
        },
    },
    mounted() {
      this.fetchDataUser().then(() => {
            this.fetchGroups().then(() => {
              if (this.getRole == 'coach') {
                this.groups = this.profileGroups;
              }
              if (this.groups.length > 0) {
                    const checkGroup = obj => obj.id === this.getActiveGroup;
                    if (this.getActiveGroup !== '' && this.groups.some(checkGroup)) {
                        this.sms.group = this.getActiveGroup
                    } else {
                        this.sms.group = this.groups[0].id
                    }
                    this.fetchAllPlayers(this.sms.group)
                }
            });
      })
    },
    methods: {
        isMobile() {
            const userAgent = navigator.userAgent;
            const mobileKeywords = ['Android', 'iPhone', 'iPad', 'iPod', 'Windows Phone', 'Mobile'];
            return mobileKeywords.some(keyword => userAgent.includes(keyword));
        },
        generateProvide(odbiorca, tresc) {
            return "sms:" + odbiorca + "?body=" + encodeURIComponent(tresc);
        },
        removePolishChars(text) {
            return text.replace(/[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, function(match) {
                const polishChars = 'ąćęłńóśźżĄĆĘŁŃÓŚŹŻ';
                const regularChars = 'acelnoszzACELNOSZZ';
                const index = polishChars.indexOf(match);
                return regularChars.charAt(index);
            });
        },
        async beforeSendSms() {
            if (this.isMobile) {
                const players = this.players.filter(player => player.presense);
                if (players.length === 0) {
                  return  this.$f7.toast
                    .create({
                    text: "You must select at least 1 player with a valid phone number",
                                position: "bottom",
                    
                    closeTimeout: 3000
                    })
                    .open();
                }
                const phones = players.map(entry => entry.phone).filter(phone => phone && phone.length >= 5);
                const formattedPhones = phones.map(phone => phone.startsWith('+') ? phone : `+${phone}`);
                const phoneString = formattedPhones.join(',');
                const cleanedBody = this.removePolishChars(this.sms.body);
                console.log(phoneString)
                const link = this.generateProvide(phoneString, cleanedBody)
                window.open(link, '_blank')
            } else {
                this.$f7.toast
                    .create({
                    text: "This operation is only available on mobile devices.",
                                position: "bottom",
                    
                    closeTimeout: 3000
                    })
                    .open();
            }
            
        },
        async fetchAllPlayers(group) {
            try {
                this.loaderPlayer = true;

                const responseDataAllPlayers = await groupsServices.listAllPlayers(group);
                this.players = responseDataAllPlayers.data
                for (let player of this.players) {
                    if (!player.status_active || player.phone == null || player.phone == '') {
                        player.presense = false
                    }
                }
                
            } catch (error) {
                if (!error.response.data.access) {
                this.$f7router.navigate('/not-found/')
                } else {
                this.$f7.toast
                    .create({
                    text: "Something went wrong with the list of players in the group. Please try again or contact your administrator.",
                                position: "bottom",
                    
                    closeTimeout: 3000
                    })
                    .open();
                }
                console.log(error);
            }
            this.loaderPlayer = false;
        },
        }
  };
  </script>
