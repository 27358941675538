<template>
  <f7-page class="no-print">
    <navbarlibrary></navbarlibrary>
    <div id="filterContent"></div>
    <loader v-if="loader"></loader>

    <div v-else>
      <f7-block class="text-uppercase">
        <h4 style="margin-bottom: 30px;">{{ 'books.library' | translate }}</h4>
              <f7-row class="custom-filters">
                <f7-col class="relative" width="100" medium="50" xlarge="33">
                  <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{ 'books.category' | translate }}</h4>
                  <div class="custom-select">
                      <select name="category" v-model="actualCategory" :disabled="loaderLibrary">
                        <option value="">{{ 'global.all' | translate }}</option>
                          <option v-for="category in selectBooksCategories" :key="category.id" :value="category.id" :selected="category.id == actualCategory">
                              {{category.text | translate}}
                          </option>
                      </select>
                  </div>
                </f7-col>
                <f7-col class="relative" width="100" medium="50" xlarge="33">
                  <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{ 'books.partners' | translate }}</h4>
                  <div class="custom-select">
                      <select name="partners" v-model="actualPartner" :disabled="loaderLibrary">
                        <option value="">{{ 'global.all2' | translate }}</option>
                          <option v-for="partner in selectBooksPartners" :key="partner.id" :value="partner.id" :selected="partner.id == actualPartner">
                              {{partner.text}}
                          </option>
                      </select>
                  </div>
                </f7-col>
                <f7-col class="relative" width="100" medium="50" xlarge="33">
                  <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{ 'books.type' | translate }}</h4>
                  <div class="custom-select">
                    <select name="typeBook" v-model="actualType" :disabled="loaderLibrary">
                          <option value="">{{ 'global.all' | translate }}</option>
                          <option v-for="typeBook in typeBooks" :key="typeBook.id" :value="typeBook.id" :selected="typeBook.id == actualType">
                              {{typeBook.label | translate}}
                          </option>
                      </select>
                  </div>
                </f7-col>
          </f7-row>
        <f7-list form class="mt-0 custom-filters-search">
                  <f7-list-input
                    :label="'books.search' | translate"
                    type="text"
                    :placeholder="'books.search' | translate"
                    :value="searchQuery"
                    @input="searchQuery = $event.target.value"
                    clear-button
                    :disabled="loaderLibrary"
                    >
                  </f7-list-input>
              </f7-list>
        <div class="countAll">
            <span>
              <p>{{ 'books.material_base' | translate }}</p>
              {{filterLibrary.length}}
            </span>
          </div>
      </f7-block>
      <loader v-if="loaderLibrary"></loader>
      <f7-block v-else>
        <div v-if="filterLibrary.length > 0">
          <loader v-if="loader"></loader>
          <f7-row v-else class="hidden relative">
              <f7-col class="relative" v-for="item in pageOfItems" :key="`${item.id_book}_${item.id}`" width="100" medium="50" xlarge="33">
                <a :href="`/books/${item.id_book}/${item.typeElem == 'session' ? 'sessions' : 'exercises'}/${item.id}/`">
                  <div class="categoryBook">
                    <span class="font-weight-bold text-uppercase">{{selectBooksCategories[item.category].text | translate}}</span>
                  </div>
                  <div class="demoBook">
                    <span class="font-weight-bold text-uppercase" :class="item.typeElem == 'session' ? 'typeElemSession' : 'typeElemExercise'">
                      <template v-if="item.typeElem == 'session' ">{{ 'books.outline' | translate }}</template>
                      <template v-else>{{ 'books.exercise' | translate }}</template>
                    </span>
                  </div>
                  <f7-card class="custom-card">
                    <div class="text-left d-flex justify-content-start align-items-center w-100 hidden">
                      <img style="width: 100px; margin-right: 20px; max-height: 70px;" :src=" item.typeElem == 'session' ? `/static/${item.key_image}/session-${item.id}/${item.mainImage}` : `/static/${item.key_image}/${item.mainImage}`" />
                      <div>
                        <img v-if="item.company" style="max-width: 100px; max-height: 40px;" :src="`/static/company/${item.company}.png`" />
                        <p class="text-left">
                          {{item.title}}  
                        </p>
                      </div>
                    </div>
                  </f7-card>
                </a>
              </f7-col>
            </f7-row>
            <div class="custom-pagination">
              <jw-pagination :items="filterLibrary" @changePage="onChangePage" :pageSize="60" :maxPages="100"></jw-pagination>
            </div>
          </div>
        <div v-else class="text-center">
          <div v-if="library.length > 0">
            <img height="40px" src="/static/icons/empty-box.svg" alt />
            <p class="font-weight-bold text-uppercase">{{ 'books.empty_materials' | translate }}</p>
            <p class="font-weight-bold text-uppercase">{{ 'books.change_filter' | translate }}!</p>
          </div>
          <div v-else>
            <img height="40px" src="/static/icons/shop-store.svg" alt />
            <p class="font-weight-bold text-uppercase">{{ 'books.lack_materials' | translate }}!</p>
            <f7-button href="/payments/" class="font-weight-bold" style="background: #0fa300; color: white; max-width: 300px; margin: 0 auto;"><div><img height="12px" src="/static/icons/shopping-cart.svg" alt /> {{ 'global.buy_subscriptions' | translate }}!</div></f7-button>
          </div>
        </div>
      </f7-block>
    </div>

  </f7-page>
</template>

<script>
import CONSTANTS from "@/utils/constants.js"

import Navbarlibrary from "@/components/Navbar/Navbarlibrary.vue";
import Loader from "@/components/Loader";
import authServices from "@/services/AuthServices.js"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  components: {
    Loader,
    Navbarlibrary,
  },
  data: () => {
    return {
      selectBooksCategories: CONSTANTS.SELECT_BOOKS_CATEGORIES,
      selectBooksPartners: CONSTANTS.SELECT_BOOKS_PARTNERS,
      typeBooks: CONSTANTS.TYPE_BOOKS,
      loader: false,
      books: [],
      searchQuery: '',
      actualCategory: '',
      actualPartner: '',
      actualType: '',
      library: [],
      loaderLibrary: false,
      pageOfItems: [],
    };
  },
  watch: {
    'actualCategory': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loaderLibrary = true
        setTimeout(() => { 
              this.loaderLibrary = false
          }, 100);  
      }
    },
    'actualPartner': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loaderLibrary = true
        setTimeout(() => { 
              this.loaderLibrary = false
          }, 100); 
      }
    },
    'actualType': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loaderLibrary = true
        setTimeout(() => { 
              this.loaderLibrary = false
          }, 100); 
      }
    },
    getLang: {
      handler(val) {
        this.loaderLibrary = true
        this.library = []
        this.profileBooks.forEach(book => {
            this.prepareData(book)
          })
        this.loaderLibrary = false
      },
      deep: true
    }
  },
  computed: {
    filterLibrary() {
      let result = this.library;
      if (this.searchQuery !== '') {
        result = result.filter(elem => elem.title.toLowerCase().includes(this.searchQuery.toLowerCase()) )
      }
      if (this.actualCategory !== '') {
        result = result.filter(elem => this.actualCategory === elem.category)
      }
      if (this.actualPartner !== '') {
        result = result.filter(elem => this.actualPartner === elem.partner)
      }
      if (this.actualType !== '') {
        result = result.filter(elem => this.actualType === elem.typeElem)
      }
      return result;
    }
  },
  mounted() {
    this.$f7.toast.close();
    if (this.isAuthenticated) {
      this.fetchDataBooks().then(() => {
        this.loaderLibrary = true
        this.fetchDataUser().then(() => {
          if (!this.isSubscription) {
            this.$f7.toast.create({
              text: 'You do not have access to all publications in the database. Get access by purchasing subscriptions!',
              position: 'bottom',
              closeTimeout: 5000,
            }).open();
          }
        })
        this.fetchDataUserBooks().then(() => {
          this.profileBooks.forEach(book => {
            this.prepareData(book)
          })
          this.loaderLibrary = false
        })
      })
    } else {
      this.$f7.toast.create({
          text: 'Only logged in user has access to purchased publications. Log in.',
          position: 'bottom',
          closeTimeout: 5000,
        }).open();
    }
  },
  methods: {
    onChangePage(pageOfItems) {
        this.pageOfItems = pageOfItems;
        document.getElementById('filterContent').scrollIntoView();
    },
    prepareData(data) {
      let prepareContentJson = null
      let contentBook = null
      if (this.getLang == 'pl') {
            if (data.content == null) {
                prepareContentJson = data.content_ang.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
            } else {
                prepareContentJson = data.content.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
            }
        } 
        if (this.getLang == 'en') {
            if (data.content_ang == null) {
                prepareContentJson = data.content.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
            } else {
                prepareContentJson = data.content_ang.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
            }
        }

        contentBook = JSON.parse(prepareContentJson).data
        contentBook.forEach(elem => {
          if (elem.typeElem == 'session' || elem.typeElem == 'exercise') {
            elem.id_book = this.books.find(item => item.connect == data.connect).id
            elem.company = data.company
            elem.partner = data.partners
            elem.key_image = data.key_image
            elem.category = data.category
            this.library.push(elem)
          }
        })
        return contentBook
      },
      async fetchDataBooks() {
        try {
          this.loader = true;

          const responseDataBooks = await authServices.books('', '');
          this.books = responseDataBooks.data
        } catch (error) {
          this.$f7.toast
            .create({
              text: "Something went wrong. Please try again or contact your administrator.",
                          position: "bottom",
              
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
        this.loader = false;
      }
  }
};
</script>