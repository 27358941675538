<template>
  <f7-page login-screen class="pageCoachJose">
    <navbarchat></navbarchat>

    <f7-messagebar
      ref="messagebar"
      :placeholder="'global.coach_jose_write' | translate"
    >
      <f7-link
        slot="inner-end"
        @click="sendMessage"
      >
      <img class="icon" height="20px" src="/static/icons/send.svg" alt />
    </f7-link>
    </f7-messagebar>
    <f7-messages ref="messages">
      <f7-messages-title>
        <img style="height: 150px;" src="/static/coach_jose.png" alt />
      </f7-messages-title>
      <f7-message
        v-for="(message, index) in messagesData"
        :key="index"
        :type="message.type"
        :image="message.image"
        :name="message.name"
        :avatar="message.type === 'received' ? `${hostApp}/static/coach_jose_avatar.png` : ''"
        :first="isFirstMessage(message, index)"
        :last="isLastMessage(message, index)"
        :tail="isTailMessage(message, index)"
      >
        <span slot="text" v-if="message.text" v-html="message.text"></span>
      </f7-message>
      <f7-message v-if="typingMessage"
        type="received"
        :typing="true"
        :first="true"
        :last="true"
        :tail="true"
        :header="`${typingMessage.name} is typing`"
        :avatar="`${hostApp}/static/coach_jose_avatar.png`"
      ></f7-message>
    </f7-messages>

  </f7-page>
</template>
<script>
import Navbarchat from "@/components/Navbar/Navbarchat.vue";
import joseServices from "@/services/JoseServices.js"

export default {
  components: {
    Navbarchat,
  },
    data() {
      return {
        typingMessage: null,
        messagesData: [
          {
            name: 'José',
            type: 'received',
            text: this.$t('global.coach_jose_init_full_1'),
            
          },
          {
            name: 'José',
            type: 'received',
            text: this.$t('global.coach_jose_init_full_2'),
          },
        ],
        responseInProgress: false,
        responseBackend: {
          search_text: '',
          results: null,
          status: ''
        },
        responseStatus: null
      };
    },
    computed: {
    },
    mounted() {
      const self = this;
      self.$f7ready(() => {
        self.messagebar = self.$refs.messagebar.f7Messagebar;
        self.messages = self.$refs.messages.f7Messages;
      });
    },
    methods: {
      async onChatMessage(message) {
          const ask = {
            message: message[0].text,
            lang: this.getLang
          }
          try {
            const responseChat = await joseServices.chat(ask);
            this.responseBackend = responseChat.data
          } catch (error) {
              this.$f7.toast
                .create({
                  text:
                    "Something went wrong with the chat. Please try again or contact your administrator.",
                              position: "bottom",
                
                  closeTimeout: 3000
                })
                .open();
            console.log(error);
          }
      },

      isFirstMessage(message, index) {
        const self = this;
        const previousMessage = self.messagesData[index - 1];
        if (message.isTitle) return false;
        if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) return true;
        return false;
      },
      isLastMessage(message, index) {
        const self = this;
        const nextMessage = self.messagesData[index + 1];
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
      },
      isTailMessage(message, index) {
        const self = this;
        const nextMessage = self.messagesData[index + 1];
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
      },
      sendMessage() {
        const self = this;
        const text = self.messagebar.getValue().replace(/\n/g, '<br>').trim();
        const messagesToSend = [];
        if (text.trim().length) {
          messagesToSend.push({
            text,
          });
        }
        if (messagesToSend.length === 0) {
          return;
        }
        // Clear area
        self.messagebar.clear();
        // Focus area
        if (text.length) self.messagebar.focus();
        // Send message
        self.messagesData.push(...messagesToSend);
        // Mock response
        if (self.responseInProgress) return;
        self.responseInProgress = true;
        setTimeout(() => {
          self.typingMessage = {
            name: 'José',
          };
          if (this.isAuthenticated) {
            this.onChatMessage(messagesToSend).then(() => {
              return setTimeout(() => {
                if (this.responseBackend.status === 'exist_and_subscription') {
                  self.messagesData.push({
                    text: `${this.responseBackend.results.data.typeElem == 'session' ? this.$t('global.coach_jose_response_subscription_outline') : this.$t('global.coach_jose_response_subscription_exercise')} 👇👇👇 <br><br> <a href="/#!/books/${this.responseBackend.results.book_id}/${this.responseBackend.results.data.typeElem}s/${this.responseBackend.results.data.id}/" class="external" target="_blank"><b>${this.responseBackend.results.data.title}</b></a>`,
                    type: 'received',
                    name: 'José',
                  });
                  self.typingMessage = null;
                  self.responseInProgress = false;
                }
                if (this.responseBackend.status === 'exist_and_user') {
                  self.messagesData.push({
                    text: `${this.responseBackend.results.data.typeElem == 'session' ? this.$t('global.coach_jose_response_user_outline') : this.$t('global.coach_jose_response_user_exercise')} 👇👇👇 <br><br> ${this.responseBackend.results.data.title} <br><br> <a href="/payments/"><b>${this.$t('global.coach_jose_select_plan_buy')}</b></a>`,
                    type: 'received',
                    name: 'José',
                  });
                  self.typingMessage = null;
                  self.responseInProgress = false;
                }
                if (this.responseBackend.status === 'quota') {
                    self.messagesData.push({
                      text: `${this.$t('global.coach_jose_select_plan')} 👇👇👇 <br><br> <a href="/payments/"><b>${this.$t('global.coach_jose_select_plan_buy')}</b></a>`,
                      type: 'received',
                      name: 'José',
                    });
                    self.typingMessage = null;
                    self.responseInProgress = false;
                }
                if (this.responseBackend.status === 'empty') {
                    self.messagesData.push({
                      text: `${this.$t('global.coach_jose_empty_response')}`,
                      type: 'received',
                      name: 'José',
                    });
                    self.typingMessage = null;
                    self.responseInProgress = false;
                }
              }, 4000);
            })
            } else {
            return setTimeout(() => {
              self.messagesData.push({
                text: `${this.$t('global.coach_jose_must_login_or_register')} 👇👇👇 <br><br> <a href="/login/"><b>${this.$t('auth.login_link')}</b></a> <br><br> ${this.$t('global.or')} <br><br> <a href="/register/"><b>${this.$t('auth.create_account')}</b></a>`,
                type: 'received',
                name: 'José',
              });
              self.typingMessage = null;
              self.responseInProgress = false;
            }, 4000);
          }
          
        }, 1000);
      },
    },
  };
</script>

<style scoped lang="scss">
.pageCoachJose {
  background: white;
}
</style>