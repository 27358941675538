<template>
  <f7-page class="no-print">
    <navbarbooks></navbarbooks>
    <loader v-if="loader"></loader>

    <div v-else>
      <f7-block class="text-uppercase">
        <h4>{{ 'books.list' | translate }}</h4>
        <f7-row class="custom-filters custom-filters-without-search">
                <f7-col class="relative" width="100" :medium="isAuthenticated && !isSubscription ? '33' : '50'">
                  <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{ 'books.category' | translate }}</h4>
                  <div class="custom-select">
                      <select name="category" v-model="actualCategory">
                        <option value="">{{ 'global.all' | translate }}</option>
                          <option v-for="category in selectBooksCategories" :key="category.id" :value="category.id" :selected="category.id == actualCategory">
                              {{category.text | translate}}
                          </option>
                      </select>
                  </div>
                </f7-col>
                <f7-col class="relative" width="100" :medium="isAuthenticated && !isSubscription ? '33' : '50'">
                  <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{ 'books.partners' | translate }}</h4>
                  <div class="custom-select">
                      <select name="partners" v-model="actualPartner">
                        <option value="">{{ 'global.all2' | translate }}</option>
                          <option v-for="partner in selectBooksPartners" :key="partner.id" :value="partner.id" :selected="partner.id == actualPartner">
                              {{partner.text}}
                          </option>
                      </select>
                  </div>
                </f7-col>
                <f7-col v-if="isAuthenticated && !isSubscription" class="relative" width="100" medium="33">
                  <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{ 'books.full' | translate }}</h4>
                  <div class="custom-select">
                    <label class="toggle toggle-init color-black">
                      <input type="checkbox" v-model="showFull" @change="handleShowFull()"/>
                      <span class="toggle-icon"></span>
                    </label>
                  </div>
                </f7-col>
          </f7-row>




        <div class="countAll">
            <span>
              <p>{{ 'books.count' | translate }}</p>
              {{books.length}}
            </span>
          </div>


      </f7-block>
      <loader v-if="loaderBooks"></loader>
      <f7-block v-else>
        <f7-row v-if="filterBooks.length > 0">
          <f7-col class="hidden relative" v-for="book in filterBooks" :key="book.id" width="100" medium="50" xlarge="33">
            <a :href="book.sku == '' ? '/books/' : book.sku.includes('ss_auto') && !isSubscription ? '/best-coach/' : `/books/${book.id}/`" :class="{'opacity-5': book.sku == ''}">
              <div v-if="createBook(book.created_at)" class="newBook">
                <div class="text">{{ 'global.new' | translate }}</div>
              </div>
              <div v-if="book.sku == ''" class="announcementoBook">
                <div class="text">{{ 'global.announcement' | translate }}</div>
              </div>
              <div v-if="book.sku.includes('ss_auto') && updateBook(book.updated_at)" class="updatedBook">
                <div class="text">{{ 'global.new' | translate }}</div>
              </div>
              <div class="categoryBook">
                <span class="font-weight-bold text-uppercase">{{selectBooksCategories[book.category].text | translate}}</span>
              </div>
              <div class="demoBook">
                <span  class="font-weight-bold text-uppercase fullSubscription" v-if="isSubscription && book.sku !== ''">
                  <f7-link :tooltip="'books.full_extend' | translate">
                    {{ 'books.full' | translate }}*
                  </f7-link>
                </span>
                <span v-else class="font-weight-bold text-uppercase" :class="book.sku.includes('ss_auto') || book.sku == '' ? 'auto' : book.demo ? 'demo' : 'full'">
                  <template v-if="book.sku.includes('ss_auto') || book.sku == ''">
                    <f7-link :tooltip="'books.auto_extend' | translate" class="autoExtend">
                      {{ 'books.auto' | translate }}
                    </f7-link>
                  </template>
                  <template v-else-if="book.demo">{{ 'books.demo' | translate }}</template>
                  <template v-else>{{ 'books.full' | translate }}</template>
                </span>
              </div>
              <div v-if="book.sku !== ''" class="langBook">
                <f7-link :tooltip="'books.book_pl'| translate">
                  <img style="padding-right: 2px;" height="15px" :src="`/static/lang/pl.svg`" alt />
                </f7-link>
                <f7-link :tooltip="book.content_ang == null ? 'books.book_current_translated' : 'books.book_en' | translate">
                    <img :class="{ 'opacity-2': book.content_ang == null }" height="15px" :src="`/static/lang/en.svg`" alt />
                </f7-link>
              </div>
              <f7-card class="custom-card">
                <div class="text-left d-flex justify-content-start align-items-center w-100">
                  <img height="90px" style="margin-right: 20px" :src="`${host}${book.cover}`" alt />
                  <div>
                    <img v-if="book.company" style="max-width: 100px; max-height: 50px; margin-bottom: 5px" :src="`/static/company/${book.company}.png`" />
                    <p class="text-left">
                      {{getLang == 'pl' ? book.title : book.title_ang}}  
                      </p>
                  </div>
                </div>
              </f7-card>
            </a>
          </f7-col>
        </f7-row>
        <div v-else class="text-center">
          <img height="40px" src="/static/icons/football-2.svg" alt />
          <p class="font-weight-bold text-uppercase">{{ 'global.empty_list_books' | translate}}</p>
        </div>
      </f7-block>
    </div>

  </f7-page>
</template>

<script>
import moment from 'moment';
import CONSTANTS from "@/utils/constants.js"

import Navbarbooks from "@/components/Navbar/Navbarbooks.vue";
import Loader from "@/components/Loader";
import authServices from "@/services/AuthServices.js"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  components: {
    Loader,
    Navbarbooks
  },
  data: () => {
    return {
      selectBooksCategories: CONSTANTS.SELECT_BOOKS_CATEGORIES,
      selectBooksPartners: CONSTANTS.SELECT_BOOKS_PARTNERS,
      loader: false,
      books: [],
      actualCategory: '',
      actualPartner: '',
      showFull: false,
      loaderBooks: false,
      filterBooks: []
    };
  },
  watch: {
    'actualCategory': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showFull = false
        this.fetchDataBooks(this.actualCategory, this.actualPartner, false).then(() => {
          if (this.isAuthenticated) {
            this.connectBooks(false);
          }
        })
      }
    },
    'actualPartner': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showFull = false
        this.fetchDataBooks(this.actualCategory, this.actualPartner, false).then(() => {
          if (this.isAuthenticated) {
            this.connectBooks(false);
          }
        })
      }
    },
  },
  mounted() {
    this.$f7.toast.close();
    if (this.$f7route.query.partner !== undefined) {
      this.actualPartner = this.$f7route.query.partner
    }
    this.fetchDataBooks(this.actualCategory, this.actualPartner, true).then(() => {
      if (this.isAuthenticated) {
        this.fetchDataUserBooksSmall().then(() => {
          this.connectBooks(true);
          this.fetchDataUser().then(() => {
            if (!this.isSubscription) {
              this.$f7.toast.create({
                text: 'You do not have access to all publications in the database. Get access by purchasing subscriptions!',
                position: 'bottom',
                closeTimeout: 5000,
              }).open();
            }
          })
        })
      } else {
        this.$f7.toast.create({
          text: 'Only logged in user has access to purchased publications. Log in.',
          position: 'bottom',
          closeTimeout: 5000,
        }).open();
        this.loader = false
      }
    })
    if (this.$f7route.query.lang !== undefined) {
        const lang = this.$f7route.query.lang
        if (this.getLang !== lang) {
          this.changeLang()
        }
    }
  },
  methods: {
    handleShowFull() {
      this.loaderBooks = true

      this.filterBooks = this.books
      if (this.showFull) {
        this.filterBooks = this.books.filter(book => book.demo === !this.showFull)
      }
      setTimeout(() => { 
        this.loaderBooks = false
      }, 100); 
    },
    connectBooks(init) {
        this.visualChangeLoader(init, true)
        if (this.books.length > 0) {
          this.books.forEach((elem, index) => {
            const existBook = this.profileBooks.some(item => item.connect == elem.connect);
            if (existBook) {
              elem.demo = false;
            }
            if (index == this.books.length - 1 || this.books.length == 0) {
              this.filterBooks = this.books
              this.visualChangeLoader(init, false)
            }
          })
        } else {
          this.filterBooks = this.books
          this.visualChangeLoader(init, false)
        }

    },
    visualChangeLoader(init, value) {
      if (init) {
        this.loader = value;
      } else {
        this.loaderBooks = value;
      }
    },
    createBook(date) {
      const actualDate = moment();
      const createDate = moment(date).add(14, 'days');
      return createDate >= actualDate
    },
    updateBook(date) {
      const actualDate = moment();
      const updateDate = moment(date).add(14, 'days');
      return updateDate >= actualDate
    },
    async fetchDataBooks(category, partner, init) {
      try {
        this.visualChangeLoader(init, true)

        const responseDataBooks = await authServices.books(category, partner);
        this.books = responseDataBooks.data
        this.filterBooks = this.books
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      if (!init) {
        this.loaderBooks = false;
      }
    }
  }
};
</script>