<template>
  <f7-page login-screen>
    <navbar></navbar>
    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'global.authors' | translate }}</h4>
    </f7-block>
    <f7-block class="text-justify">
      <p><strong>Soccer Sessions</strong> {{ 'global.authors_description_1' | translate }}</p>
      <p>{{ 'global.authors_description_2' | translate }}</p>
      <p>{{ 'global.authors_description_3' | translate }}</p>
    </f7-block>
  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>

<style lang="scss">
</style>