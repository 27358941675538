<template>
    <div class="full-view full-view-privateoutlines type-exercise">
        <div class="d-flex justify-content-between w-100 mb-4">
              <h4 class="mb-0 mt-0 font-weight-bold text-uppercase">{{ 'base.private_exercises' | translate}}</h4>
              <f7-link @click="closeExercisesViews()">
              <img height="20px" src="/static/icons/close-2.svg" alt />
              </f7-link>
          </div>
        <div v-if="isAuthenticated">

        <loader v-if="loader"></loader>
        <div v-else>
                <div class="wrapper-content hidden overflow-y-scroll">
                  <div>
                    <div class="homeHeaderCard">
                        
                          <f7-list form no-hairlines-md>
                              <f7-list-input
                              :label="'base.search_exercise' | translate"
                              type="text"
                              :placeholder="'base.exercise_name' | translate"
                              :value="searchQuery"
                              @input="searchQuery = $event.target.value"
                              clear-button
                              >
                              </f7-list-input>
                          </f7-list>

                      </div>
                    <div v-for="exercise in allPrivateExercises" :key="exercise.id">
                      <div class="part-exercise" v-for="enter in exercise.part.data" :key="enter.id" v-if="checkSearch(enter.topic)">
                          <div class="d-flex align-items-center wrapper-text cursor-pointer" @click="openPopup(enter)">

                              <div v-if="enter.img && enter.img.graphics !== ''">
                                <img v-if="enter.img.id == 'onBooks' || enter.img.id == 'onPrivateExercise'" class="image" :src="`${hostApp}${enter.img.graphics}`" alt="">
                                <img v-else class="image" :src="`${host}${enter.img.graphics}`" alt="">
                              </div>
                              <img v-else class="image" src="static/blank.png" alt="">

                              <p class="title mt-0 mb-0 font-weight-bold">{{enter.topic}}</p>
                              <p class="description mt-0 mb-0">{{enter.description}}</p>
                          </div>
                          <div @click="moveExerciseFromExercise(enter, exercise.type_copied)" class="text-truncate">
                            <f7-button raised small style="margin-left: 5px">
                                <img class="icon" height="10px" src="/static/icons/select-arrow.svg" alt />
                            </f7-button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <f7-popup :opened="popupOpened" @popup:closed="popupOpened = false">
                  <f7-page>
                    <f7-navbar>
                      <f7-nav-title>
                        <span style="margin-left: 20px">Podgląd</span>
                      </f7-nav-title>
                      <f7-nav-right>
                        <f7-link popup-close>
                          <img height="20px" style="margin-left: 0px" src="/static/icons/close.svg" alt />
                        </f7-link>
                      </f7-nav-right>
                    </f7-navbar>
                    <f7-block>
                      <p v-if="actualEnter.topic" class="font-weight-bold">{{actualEnter.topic}}</p>
                      <img v-if="actualEnter.img && actualEnter.img.graphics !== ''" class="w-100" :src="`${host}${actualEnter.img.graphics}`" alt="">
                      <p v-if="actualEnter.description">{{actualEnter.description}}</p>
                      <div v-if="actualEnter.mod">
                        <p class="font-weight-bold">Modyfikacja:</p>
                        <p>{{actualEnter.mod}}</p>
                      </div>
                    </f7-block>
                  </f7-page>
                </f7-popup>
        </div>
        </div>
        <div v-else>
          <f7-block class="text-center">
            <img style="height: 150px;" src="/static/icons/red-card.svg" alt />
          </f7-block>
          <f7-block class="text-center text-uppercase ">
            <h4>Nie masz dostępu!</h4>
          </f7-block>
          <f7-block class="text-center">Aby dostać się w to miejsce musisz posiadać konto w aplikacji :)</f7-block>
          <f7-block class="text-center" style="max-width: 480px; margin: 0 auto;">
            <f7-button href="/login/" raised>Zaloguj się</f7-button>
          </f7-block>
        </div>
        </div>
</template>


<script>
import Loader from "@/components/Loader";
import privateExercisesServices from "@/services/PrivateExercisesServices.js"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  name: 'Exerciseonprivates',
  components: {
    Loader
  },
  data () {
    return {
      actualCategory: '',
      allPrivateExercises: [],
      loader: false,
      searchQuery: '',
      popupOpened: false,
      actualEnter: {}
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchDataUser().then(() => {
        this.fetchAllPrivateExercises()
      })
    }
  },
  methods: {
    openPopup(enter) {
      this.popupOpened = true;
      this.actualEnter = enter;
    },
    async fetchAllPrivateExercises() {
      try {
        this.loader = true;
        const responseDataAllPrivateExercises = await privateExercisesServices.listAllPrivateExercises();
        this.allPrivateExercises = responseDataAllPrivateExercises.data
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the private exercise list. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
    checkSearch(title) {
        return title.toLowerCase().includes(this.searchQuery.toLowerCase())
    },
    moveExerciseFromExercise(fromExercise, copied) {
        const typeCopied = {
          typeCopied: copied
        }
        const name = {
            name: fromExercise.topic
        }
        const mainImage = {
            mainImage: fromExercise.img && fromExercise.img.graphics !== '' ? `${fromExercise.img.graphics}` : ''
        }
        
        this.$emit('move-exercise-to-outline-and-exercise-on-private-exercise', {...fromExercise, ...name, ...mainImage, ...typeCopied});
    },
    closeExercisesViews() {
        this.$emit('on-close-exercises-views');
    }
  }
}
</script>