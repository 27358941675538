<template>
    <f7-block class="row text-center">
      <f7-col>
        <f7-preloader :color="color"></f7-preloader>
      </f7-col>
    </f7-block>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'black'
    }
  },
};
</script>