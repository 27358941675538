import Api from './index'
import store from '@/store'

export default {
  async listOrders() {
    return Api().get('orders/', {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async createOrder(data) {
    return Api().post('orders/', data, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async listSubscriptions(data) {
    return Api().get(`orders/subscriptions/?currency_type=${data.currency_type}`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
}

