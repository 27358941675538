import { mapState } from 'vuex';
import Compressor from 'compressorjs';
import authServices from "@/services/AuthServices.js"

export default {
    data () {
        return {
            books: [],
            isEdit: false,
            actualPart: {
                index: null,
            },
            allowableTypes: ['jpg', 'jpeg', 'png'],
            maximumSize: 1000000,
            tmpImages: [],
            isBooksView: false,
            isExercisesView: false,
            positionScroll: 0,
        }
    },
    computed: {
        ...mapState('appkonspektypilkanozna', ['configExercise'])
    },
  methods: {
    endDrag() {
        this.exercise.part.data.forEach((elem, index) => {
          elem.id = index;
        })
      },
    previewImage(e) {
        this.$f7.toast.create({
            text: 'The recommended dimensions are 900x600',
            position: 'bottom',
            closeTimeout: 3000,
        }).open();
        if (!this.allowableTypes.includes(e.target.files[0].name.split('.').pop().toLowerCase())) {
            this.$f7.toast.create({
                text: 'Invalid file format. Acceptable file formats are: JPG, JPEG, PNG!',
                position: 'bottom',
                closeTimeout: 3000,
            }).open();
            return false
        }
        if (e.target.files[0].size > this.maximumSize) {
            this.$f7.toast.create({
                text: 'The file is too heavy. The maximum size of the file is: 1 MB!',
                position: 'bottom',
                closeTimeout: 3000,
            }).open();
            return false
        }
        const self = this
        if (e.target.files && e.target.files[0]) {
            new Compressor(e.target.files[0], {
                quality: 0.6,
                maxHeight: 600,
                maxWidth: 900,
            
                success(result) {
                  var reader = new FileReader();
                  reader.onload = (e) => {
                      const image = {
                        index: self.actualPart.index,
                        image: e.target.result,
                      }
                      self.tmpImages.push(image);
                      self.exercise.part.data[self.actualPart.index].img = {
                          id: '',
                          graphics: e.target.result
                      }
                  }
                  reader.readAsDataURL(result);
                },
                error(err) {
                  console.log(err.message);
                },
              });
        }
    },
    togglePanel(show) {
        this.resetZoomed();
        this.isEdit = show;
    },
    setActualPart(index) {
        this.actualPart.index = index;
    },
    async fetchDataBooks() {
        try {
            this.loader = true;
            const responseDataBooks = await authServices.books('','');
            this.books = responseDataBooks.data
        } catch (error) {
            this.$f7.toast
            .create({
                text: "Something went wrong. Please try again or contact your administrator.",
                            position: "bottom",
            
                closeTimeout: 3000
            })
            .open();
            console.log(error);
        }
        this.loader = false;
        },
    addExerciseOnBooks(index) {
        this.isEdit = false
        this.isBooksView = true;
        this.setActualPart(index)
      },
      addPrivateExercise(index) {
        this.isEdit = false
        this.isExercisesView = true;
        this.setActualPart(index)
      },
    removeExercise(index, id) {
        this.removeImageExercise(id)
        this.exercise.part.data.splice(index, 1);
    },
    removeImageExercise(id) {
        const findIndex = this.tmpImages.findIndex(obj => obj.index == id)
        this.tmpImages.splice(findIndex, 1)

        this.exercise.part.data.find(elem => elem.id == id).img.id = '';
        this.exercise.part.data.find(elem => elem.id == id).img.graphics = '';
    },
    addExercise() {
        this.isEdit = false
        let maxId;
        if (this.exercise.part.data.length > 0) {
            maxId = this.exercise.part.data.reduce((p, c) => p.value > c.value ? p : c);
        }
        const exercise = {
            id: this.exercise.part.data.length > 0 ? maxId.id + 1 : 1,
            topic: '',
            description: '',
            img: {
                id: '',
                graphics: ''
            },
            mod: '',
            time: null
        }
        this.exercise.part.data.push(exercise);
    },
    moveExerciseToExercise(data) {
        this.exercise.type_copied = data.typeCopied
        this.exercise.part.data[this.actualPart.index].topic = ''
        this.exercise.part.data[this.actualPart.index].description = ''
        this.exercise.part.data[this.actualPart.index].mod = ''
        this.exercise.part.data[this.actualPart.index].img = {
            id: '',
            graphics: ''
        }
        this.isBooksView = false;
        this.isExercisesView = false;
        if (data.content && data.content.length > 0) {
            data.content.forEach((elem) => {
                const row = elem.replaceAll('<strong>', '').replaceAll('</strong>', '.')
                this.exercise.part.data[this.actualPart.index].description += row + ' \n'
            });
        }
        if (data.mod && data.mod.length > 0) {
            data.mod.forEach((elem) => {
                this.exercise.part.data[this.actualPart.index].mod += elem + ' \n'
            });
        }
        if (data.name) {
            this.exercise.part.data[this.actualPart.index].topic = data.name
        }
        if (data.mainImage) {
            this.exercise.part.data[this.actualPart.index].img = {
                id: 'onBooks',
                graphics: data.mainImage
            }
        }
        this.$nextTick(() => {
            this.autoResizeAllTextarea()
          });
      },
      moveExerciseToExerciseOnPrivateExercise(data) {
        console.log(data)
        this.exercise.type_copied = data.typeCopied
        this.exercise.part.data[this.actualPart.index].topic = ''
        this.exercise.part.data[this.actualPart.index].description = ''
        this.exercise.part.data[this.actualPart.index].mod = ''
        this.exercise.part.data[this.actualPart.index].img = {
            id: '',
            graphics: ''
        }
        this.isBooksView = false;
        this.isExercisesView = false;
        if (data.description) {
            this.exercise.part.data[this.actualPart.index].description = data.description
        }
        if (data.mod) {
            this.exercise.part.data[this.actualPart.index].mod = data.mod
        }
        if (data.topic) {
            this.exercise.part.data[this.actualPart.index].topic = data.topic
        }
        if (data.mainImage) {
            this.exercise.part.data[this.actualPart.index].img = {
                id: 'onPrivateExercise',
                graphics: data.mainImage
            }
        }
        this.$nextTick(() => {
            this.autoResizeAllTextarea()
            });
       }
   }
}