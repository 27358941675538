<template>
  <f7-page login-screen>
    <navbar></navbar>

    <f7-block class="text-center">
      <img style="height: 150px;" src="/static/icons/ambulance.svg" alt />
    </f7-block>
    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'global.not_found' | translate}}!</h4>
    </f7-block>
    <f7-block class="text-center">{{ 'global.not_found_description' | translate}} :)</f7-block>
    <f7-block class="text-center">
      <f7-button href="/" raised>{{ 'global.home' | translate}}</f7-button>
    </f7-block>

  </f7-page>
</template>
<script>

import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>