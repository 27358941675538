<template>
  <f7-page login-screen>
    <navbarcalendarcrm></navbarcalendarcrm>

    <loader v-if="loader"></loader>

    <div v-else>

        <f7-block class="text-uppercase">
          <h4>{{ 'club.your_groups' | translate}} <f7-link class="p-1" :tooltip="'popover.your_groups' | translate"><img height="11px" src="/static/icons/help.svg" alt /></f7-link></h4>
          <div class="countAll">
            <span v-if="getRole == 'owner'">
              <p>{{ 'club.count' | translate}}</p>
              {{groups.length}}
            </span>
            <span v-if="getRole == 'coach'">
              <p>{{ 'club.count' | translate}}</p>
              {{profileGroups.length}}
            </span>
          </div>
        </f7-block>
        <div class="listCards" v-if="groups.length > 0">
          <f7-block>
            <f7-row>
              <f7-col v-for="group in groups" :key="group.id" width="100" class="position-relative">
                <div v-if="getRole == 'owner'" class="delete" @click.stop="showDialogDeleteGroup(group)">
                  <img height="20px" src="/static/icons/trash.svg" alt />
                </div>
                <a :href="getRole == 'owner' ? `/groups/${group.id}/` : '#'">
                <f7-card class="custom-card">
                  <div>
                    <p>{{group.name}}</p>
                    <p class="info">{{group.description}}</p>
                    <f7-link v-if="group.color" :tooltip="`Group color: ${paleteColorsDot[group.color].label}`" class="color" :style="{background : paleteColorsDot[group.color].value}"></f7-link>
                  </div>
                </f7-card>
                </a>
              </f7-col>
            </f7-row>
          </f7-block>
        </div>
        <div v-else class="text-center text-uppercase">
            <img height="100px" src="/static/icons/drink.svg" alt />
            <h4>{{'club.empty_group' | translate}}!</h4>
        </div>
        <f7-block v-if="getRole == 'owner'">
          <div class="d-flex justify-content-center aling-items-center">
            <div>
              <a href="/create-group/">
                <img class="cursor-pointer" height="30px" src="/static/icons/plus.svg" alt="">
              </a>
            </div>
          </div>
        </f7-block>
    </div>

    <div class="custom-fab" v-if="getRole == 'owner'">
        <div class="box box-zoom cursor-pointer" @click="goToCreate()">
            <f7-link :tooltip="'global.add_new' | translate" >
              <img height="20px" src="/static/icons/plus-add.svg" alt="">
            </f7-link>
        </div>
      </div>

  </f7-page>
</template>

<script>
import Navbarcalendarcrm from "@/components/Navbar/Navbarcalendarcrm.vue";
import Loader from "@/components/Loader";
import groupsServices from "@/services/GroupsServices.js"
import mixinGroups from "@/mixins/mixinGroups"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinGroups, mixinUser],
  components: {
    Navbarcalendarcrm,
    Loader
  },
  data: () => {
    return {
      loader: false,
      profileGroups: []
    };
  },
  mounted() {
    this.fetchDataUser().then(() => {
        this.fetchGroups().then(() => {
          if (this.getRole == 'coach') {
            this.groups = this.profileGroups;
          }
        })
    })
  },
  methods: {
    goToCreate() {
      this.$f7router.navigate(`/create-group/`)
    },
    showDialogDeleteGroup(group) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the group ${group.name}? By deleting a group, you will also delete all workouts, training plans, attendance lists associated with this group. The change will be irreversible!`, '', () => {
          this.deleteGroup(group.id)
        });
    },
    async deleteGroup(id) {
      try {
        const responseDeleteGroup = await groupsServices.deleteGroup(id);
        this.fetchGroups();
        this.$f7.toast
          .create({
            text: "The group has been deleted!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with group deletion. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  }
};
</script>