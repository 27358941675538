<template>
  <f7-navbar back-link="Back">
    <f7-nav-title>
        <f7-link href="/" style="padding-left: 0px">
          <img class="logo" src="/static/logo-soccer-sessions-white.svg" style="max-height: 20px;" />
        </f7-link>
    </f7-nav-title>
    <f7-nav-right>
      <!-- <f7-link @click="onUndo()">
          <img height="20px" style="margin-left: 0px" src="/static/icons/undo.svg" alt />
        </f7-link>
        <f7-link @click="onRedo()">
          <img height="20px" style="margin-left: 0px" src="/static/icons/redo.svg" alt />
        </f7-link> -->
        <f7-link @click="onClear()">
          <img height="20px" style="margin-left: 0px" src="/static/icons/trash2.svg" alt />
        </f7-link>
        <f7-link @click="onDownload()">
          <img height="20px" style="margin-left: 0px" src="/static/icons/download.svg" alt />
        </f7-link>
        <f7-link @click="toggleSettings()">
          <img v-if="!show" height="20px" style="margin-left: 0px" src="/static/icons/settings.svg" alt />
          <img v-else height="20px" style="margin-left: 0px" src="/static/icons/close.svg" alt />
        </f7-link>
        <f7-link panel-open="left"><img height="20px" src="/static/icons/menu.svg" alt style="margin-left: 0px;"></f7-link>
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import onbackbutton from '@/mixins/onBackButton.js';

export default {
  mixins: [onbackbutton],
  name: 'navbartacticboard',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggleSettings() {
      if (this.show) {
        this.$emit('on-settings', false);
      } else {
        this.$emit('on-settings', true);
      }
    },
    onUndo() {
      this.$emit('on-undo');
    },
    onRedo() {
      this.$emit('on-redo');
    },
    onClear() {
      this.$emit('on-clear');
    },
    onDownload() {
      this.$emit('on-download');
    }
  }
}
</script>