<template>
  <f7-page login-screen class="pageSettingsConfig">
    <navbar></navbar>
    
    <loader v-if="loader"></loader>

    <div v-else>
      <f7-block class="text-center text-uppercase">
        <h4>{{ 'outline.settings' | translate}}</h4>
      </f7-block>
      <f7-block>
        <p>{{ 'outline.settings_description' | translate}}</p>
      </f7-block>
      <f7-list form no-hairlines-md @submit.prevent="onUpdateConfig">
        <div style="margin: 0 15px;">
          <label class="item-label" for="">{{ 'outline.logo' | translate}}</label>
          <div v-if="!privateConfig.logo">
            <div class="wrapperUpload">
              <f7-button class="d-flex" raised small>
                  <img height="14px" class="icon" src="/static/icons/upload.svg" alt />
              </f7-button>
              <input type="file" @change="onFileChange" accept="image/*" />
            </div>
          </div>
          <f7-card v-else>
            <div class="logoPreviewWrapper">
              <f7-button class="close" @click="privateConfig.logo = null" raised small>
                <img height="8px" src="/static/icons/close.svg" alt />
              </f7-button>
              <img v-if="isUpload" class="logoPreview" :src="privateConfig.logo">
              <img v-else class="logoPreview" :src="`${host}${privateConfig.logo}`">
            </div>
          </f7-card>
        </div>
        <f7-list-input
          :label="'outline.coach' | translate"
          type="text"
          :placeholder="'outline.coach' | translate"
          :value="privateConfig.coach"
          @input="privateConfig.coach = $event.target.value"
          clear-button
        >
        </f7-list-input>
        <f7-list-input
          :label="'outline.assistant' | translate"
          type="text"
          :placeholder="'outline.coach' | translate"
          :value="privateConfig.assistant"
          @input="privateConfig.assistant = $event.target.value"
          clear-button
        >
        </f7-list-input>
        <f7-list-item :title="'outline.ageCategory' | translate" class="custom-select">
          <select name="age_category" v-model="privateConfig.age_category">
            <option v-for="(ageCategory, index) in ageCategories" :key="index" :value="index" :selected="index == privateConfig.age_category">
                {{ageCategory | translate}}
            </option>
          </select>
        </f7-list-item>
        <f7-list-input
          :label="'outline.trainingPlace' | translate"
          type="text"
          :placeholder="'outline.trainingPlace' | translate"
          :value="privateConfig.place"
          @input="privateConfig.place = $event.target.value"
          clear-button
        >
        </f7-list-input>
        <f7-list-input
          :label="'outline.numberOfPlayers' | translate"
          type="number"
          min=0
          :placeholder="'outline.numberOfPlayers' | translate"
          :value="privateConfig.count_player"
          @input="privateConfig.count_player = $event.target.value"
          clear-button
        >
        </f7-list-input>
        <f7-list-input
          :label="'outline.numberOfBalls' | translate"
          type="number"
          min=0
          :placeholder="'outline.numberOfBalls' | translate"
          :value="privateConfig.gear_balls"
          @input="privateConfig.gear_balls = $event.target.value"
          clear-button
        >
        </f7-list-input>
        <f7-list-input
          :label="'outline.numberOfGoals' | translate"
          type="number"
          min=0
          :placeholder="'outline.numberOfGoals' | translate"
          :value="privateConfig.gear_goals"
          @input="privateConfig.gear_goals = $event.target.value"
          clear-button
        >
        </f7-list-input>
        <f7-list-input
          :label="'outline.numberOfMarkers' | translate"
          type="number"
          min=0
          :placeholder="'outline.numberOfMarkers' | translate"
          :value="privateConfig.gear_markers"
          @input="privateConfig.gear_markers = $event.target.value"
          clear-button
        >
        </f7-list-input>
        <f7-list-input
          :label="'outline.numberOfCones1' | translate"
          type="number"
          min=0
          :placeholder="'outline.numberOfCones1' | translate"
          :value="privateConfig.gear_cones_1"
          @input="privateConfig.gear_cones_1 = $event.target.value"
          clear-button
        >
        </f7-list-input>
        <f7-list-input
          :label="'outline.numberOfCones2' | translate"
          type="number"
          min=0
          :placeholder="'outline.numberOfCones2' | translate"
          :value="privateConfig.gear_cones_2"
          @input="privateConfig.gear_cones_2 = $event.target.value"
          clear-button
        >
        </f7-list-input>
        <f7-list-input
          :label="'outline.numberOfPoles' | translate"
          type="number"
          min=0
          :placeholder="'outline.numberOfPoles' | translate"
          :value="privateConfig.gear_poles"
          @input="privateConfig.gear_poles = $event.target.value"
          clear-button
        >
        </f7-list-input>
        <f7-list-input
          :label="'outline.numberOfLadders' | translate"
          type="number"
          min=0
          :placeholder="'outline.numberOfLadders' | translate"
          :value="privateConfig.gear_ladders"
          @input="privateConfig.gear_ladders = $event.target.value"
          clear-button
        >
        </f7-list-input>
        <f7-list-input
          :label="'outline.otherAccessories' | translate"
          type="text"
          :placeholder="'outline.otherAccessories' | translate"
          :value="privateConfig.gear_other"
          @input="privateConfig.gear_other = $event.target.value"
          clear-button
        >
        </f7-list-input>
        <f7-block class="text-center">
          <f7-button v-if="!config.loader" type="submit" raised>{{'outline.settings_save' | translate}}</f7-button>
          <f7-button v-else raised>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>
    </div>
  </f7-page>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";
import Loader from "@/components/Loader";
import privateOutlinesServices from "@/services/PrivateOutlinesServices.js"
import CONSTANTS from "@/utils/constants.js"

export default {
  components: {
    Navbar,
    Loader,
  },
  data: () => {
    return {
      loader: false,
      ageCategories: CONSTANTS.AGE_CATEGORIES,
      privateConfig: {},
      config: {
        loader: false
      },
      allowableTypes: ['jpg', 'jpeg', 'png'],
      maximumSize: 1000000,
      isUpload: false,
    };
  },
  mounted() {
    this.fetchConfig();
  },
  methods: {
    async fetchConfig() {
      try {
        this.loader = true;

        const responseDataConfig = await privateOutlinesServices.configOutlines();
        if (responseDataConfig.data.length > 0) {
          this.privateConfig = responseDataConfig.data[0]
        } else {
          this.onCreateConfig()
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the config. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
    async onCreateConfig() {
      try {
        this.loader = true;
        const data = {
          title: 'Config do konspektów',
          age_category: '0',
        }
        const responseDataCreateConfig = await privateOutlinesServices.createConfigOutline(data);
        this.privateConfig = responseDataCreateConfig.data
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
    async onUpdateConfig() {
      try {
        this.config.loader = true;

        const responseDetailConfig = await privateOutlinesServices.configOutline(this.privateConfig);
        this.$f7.toast.create({
            text: 'The data has been updated',
            position: 'bottom',
            closeTimeout: 1000,
        }).open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.config.loader = false;
    },
    onFileChange(e) {
      this.privateConfig.logo = ''
      if (!this.allowableTypes.includes(e.target.files[0].name.split('.').pop().toLowerCase())) {
          this.$f7.toast.create({
              text: 'Invalid file format. Acceptable file formats are: JPG, JPEG, PNG!',
              position: 'bottom',
              closeTimeout: 3000,
          }).open();
          this.isUpload = false;
          return false
      }
      if (e.target.files[0].size > this.maximumSize) {
          this.$f7.toast.create({
              text: 'The file is too heavy. The maximum size of the file is: 1 MB!',
              position: 'bottom',
              closeTimeout: 3000,
          }).open();
          this.isUpload = false;
          return false
      }

      if (e.target.files && e.target.files[0]) {

          const reader = new FileReader();
          reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;

            this.privateConfig.logo = e.target.result;
            this.isUpload = true;
          }
          reader.readAsDataURL(e.target.files[0]);
      }
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/settingsconfig.scss";
</style>