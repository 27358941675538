<template>
  <f7-page login-screen>
    <navbarlist :list="'players'"></navbarlist>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{ 'club.player' | translate }}
              <img class="cursor-pointer" @click="showDialogDeletePlayer(player)" height="14px" src="/static/icons/trash.svg" alt />
            </h4>
          </f7-block>

          <f7-block>
            <f7-button style="background: #a1ad13" class="mt-1 font-weight-bold" @click="openManagerAttendance()" raised>{{ 'club.manager_attendance' | translate }} </f7-button>
          </f7-block>

          <f7-popup :opened="popupOpenedManagerAttendance" @popup:closed="popupOpenedManagerAttendance = false">
            <f7-page>
              <f7-navbar>
                <f7-nav-title>
                  <span style="margin-left: 20px">{{ 'club.manager_attendance' | translate}}</span>
                </f7-nav-title>
                <f7-nav-right>
                  <f7-link popup-close>
                    <img height="20px" style="margin-left: 0px" src="/static/icons/close.svg" alt />
                  </f7-link>
                </f7-nav-right>
              </f7-navbar>
              <f7-block v-if="player.player_attendance !== null">
                <div class="d-flex justify-content-between">
                  <div class="text-center flex-column w-50">
                    <div>
                      <p class="font-weight-bold mt-0">
                        {{ 'club.list_reported_absences' | translate}}
                      </p>
                      <div v-if="player.player_attendance.data.reported.length > 0">
                        <div v-for="(reported, index) in player.player_attendance.data.reported" :key="reported.id">
                          <p>{{ reported }} <img height="10px" @click="removeReported(index)" class="ml-1 cursor-pointer" src="/static/icons/trash.svg" alt /></p>
                        </div>
                      </div>
                      <div v-else>
                        <p>{{ 'club.lack' | translate}}</p>
                      </div>
                      
                    </div>
                    <f7-list form no-hairlines-md @submit.prevent="addReported" class="mt-2 px-1 list-border-radius-1 mb-2">
                      <f7-list-input 
                        :value="newReported"
                        @input="newReported = $event.target.value" 
                        type="date" 
                        clear-button>
                      </f7-list-input>
                      <f7-block class="text-center mt-2 p-0 mb-2">
                        <f7-button type="submit" :disabled="newReported === ''" small raised>
                            {{ 'club.add' | translate }}
                        </f7-button>
                      </f7-block>
                    </f7-list>
                  </div>

                  <div class="text-center flex-column w-50">
                    <div>
                      <p class="font-weight-bold mt-0">
                        {{ 'club.list_completed_absences' | translate}}
                      </p>
                     
                      <div v-if="player.player_attendance.data.completed.length > 0">
                        <div v-for="completed in player.player_attendance.data.completed" :key="completed.id">
                          <p>{{ completed }} <img height="10px" @click="removeCompleted(index)" class="ml-1 cursor-pointer" src="/static/icons/trash.svg" alt /></p>
                        </div>
                      </div>
                      <div v-else>
                        <p>{{ 'club.lack' | translate}}</p>
                      </div>
                    </div>
                    <f7-list form no-hairlines-md @submit.prevent="addCompleted" class="mt-2 px-1 list-border-radius-1 mb-2">
                      <f7-list-input 
                        :value="newCompleted"
                        @input="newCompleted = $event.target.value" 
                        type="date" 
                        clear-button>
                      </f7-list-input>
                      <f7-block class="text-center mt-2 p-0 mb-2">
                        <f7-button type="submit" :disabled="newCompleted === ''" small raised>
                            {{ 'club.add' | translate }}
                        </f7-button>
                      </f7-block>
                    </f7-list>
                  </div>
                </div>
                
              </f7-block>
            </f7-page>
          </f7-popup>

          <f7-list form no-hairlines-md @submit.prevent="updatePlayer(true)">
            <f7-list-item :title="'club.status_player' | translate" class="list-custom-checkbox custom-item-inside-list">
              <div class="d-flex justify-content-between w-100 align-items-center">
                <div>
                  <span v-if="player.status_active" style="color: green">{{ 'club.status_active' | translate }}</span>
                <span v-else style="color: red">{{ 'club.status_not_active' | translate }}</span>
                </div>
                
                <div class="d-flex">
                  <input type="checkbox" class="custom-checkbox" v-model="player.status_active">
                </div>
              </div>
            </f7-list-item>
            <f7-list-input
              :label="'club.first_name' | translate"
              type="text"
              :placeholder="'club.first_name' | translate"
              :value="player.first_name"
              @input="player.first_name = $event.target.value"
              clear-button
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>
             <f7-list-input
              :label="'club.last_name' | translate"
              type="text"
              :placeholder="'club.last_name' | translate"
              :value="player.last_name"
              @input="player.last_name = $event.target.value"
              clear-button
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.email' | translate"
              type="text"
              :placeholder="'club.email' | translate"
              :value="player.email"
              @input="player.email = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.phone_number' | translate"
              type="text"
              :placeholder="'club.phone_number' | translate"
              :value="player.phone"
              @input="player.phone = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-item :title="'club.group' | translate" class="custom-select">
              <select name="group" v-model="player.group" @change="changeGroup">
                <option v-if="groups.length == 0" value="" disabled>{{ 'club.last_group' | translate }}</option>
                <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == player.group">
                    {{group.name}}
                </option>
              </select>
            </f7-list-item>
            <f7-list-item v-if="groups.length > 0" :title="'club.additional_groups' | translate" smart-select :smart-select-params="{openIn: 'popover'}">
              <select name="additional_groups" multiple v-model="player.additional_groups">
                <option v-for="group in groups.filter(group => group.id !== player.group)" :key="group.id" :value="group.id">
                    {{group.name}}
                </option>
              </select>
            </f7-list-item>
            <f7-list-input
              :label="'club.premium_amount' | translate"
              type="number"
              :placeholder="'club.premium_amount' | translate"
              :value="player.due_price"
              @input="player.due_price = $event.target.value"
              clear-button
              required
              validate
              :error-message="'global.required' | translate"
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.date_of_birth' | translate"
              type="date"
              :placeholder="'club.date_of_birth' | translate"
              :value="player.date_of_birth"
              @input="player.date_of_birth = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.street' | translate"
              type="text"
              :placeholder="'club.street' | translate"
              :value="player.street"
              @input="player.street = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.city' | translate"
              type="text"
              :placeholder="'club.city' | translate"
              :value="player.city"
              @input="player.city = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.zip_code' | translate"
              type="text"
              :placeholder="'club.zip_code' | translate"
              :value="player.code"
              @input="player.code = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="'club.notes' | translate"
              type="textarea"
              :placeholder="'club.notes' | translate"
              :value="player.notes"
              @input="player.notes = $event.target.value"
              clear-button
            >
            </f7-list-input>
            <f7-block class="text-center">
              <f7-button v-if="!loaderPlayer" type="submit" raised>
                  {{ 'club.save' | translate }}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import Navbarlist from "@/components/Navbar/Navbarlist.vue";
import Loader from "@/components/Loader";
import groupsServices from "@/services/GroupsServices.js"
import mixinUser from "@/mixins/mixinUser"
import mixinGroups from "@/mixins/mixinGroups"

export default {
  mixins: [mixinGroups, mixinUser],
  components: {
    Navbarlist,
    Loader
  },
  props: {
    idPlayer: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      loader: false,
      loaderPlayer: false,
      popupOpenedManagerAttendance: false,
      newReported: '',
      newCompleted: '',
      player: {
        first_name: '',
        last_name: '',
        notes: '',
        due_price: '',
        group: '',
        date_of_birth: '',
        player_attendance: {
          data: {
            reported: [],
            completed: [],
          }
        },
        additional_groups: []
      },
    };
  },
  watch: {
    // 'player.group': function (newVal, oldVal) {
    //   console.log(newVal)
    //   console.log(oldVal)
    //   if (newVal !== oldVal) {
    //     this.player.due_price = this.groups.find(elem => elem.id == this.player.group).due_price
    //   }
    // },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchDataUser().then(() => {
          this.fetchGroups().then(() => {
            if (this.getRole == 'coach') {
              this.groups = this.profileGroups;
            }
            if (this.groups.length > 0) {
              if (this.$f7route.query.group !== undefined) {
                this.player.group = this.$f7route.query.group
              } else {
                this.player.group = this.groups[0].id
              }
            }
            this.fetchPlayer(this.idPlayer).then(() => {
              if (this.player.player_attendance == null) {
                this.player.player_attendance = {
                  data: {
                    reported: [],
                    completed: []
                  }
                }
              }
            })
          });
      })
    }
  },
  methods: {
    changeGroup() {
      this.player.due_price = this.groups.find(elem => elem.id == this.player.group).due_price
    },
    async fetchPlayer(id) {
      try {
        this.loader = true;

        const responseDataPlayer = await groupsServices.itemPlayer(id);
        this.player = responseDataPlayer.data
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with the player. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    openManagerAttendance() {
      this.popupOpenedManagerAttendance = true
      this.newReported = ''
      this.newCompleted = ''
    },
    removeReported(index) {
      this.$f7.dialog.confirm(`Are you sure you want to remove the date? The change will be irreversible!`, '', () => {
        this.player.player_attendance.data.reported.splice(index, 1)
        this.updatePlayer(false)
      });
    },
    removeCompleted(index) {
      this.$f7.dialog.confirm(`Are you sure you want to remove the date? The change will be irreversible!`, '', () => {
        this.player.player_attendance.data.completed.splice(index, 1)
        this.updatePlayer(false)
      });
    },
    addReported() {
      this.player.player_attendance.data.reported.push(this.newReported)
      this.newReported = ''
      this.updatePlayer(false)
    },
    addCompleted() {
      this.player.player_attendance.data.completed.push(this.newCompleted)
      this.newCompleted = ''
      this.updatePlayer(false)
    },
    async updatePlayer(redirect) {
      if (this.player.group == '') {
        return this.$f7.toast
          .create({
            text: "No group",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
      }
      try {
        this.loaderPlayer = true;

        const responseUpdatePlayer = await groupsServices.player(this.player);
        if ('access' in responseUpdatePlayer.data && !responseUpdatePlayer.data.access) {
            this.$f7.toast
            .create({
              text: "Access to the module has expired!",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        } else {
          if (redirect) {
            this.$f7router.navigate(`/players/?group=${this.player.group}`)
          }
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the player edit. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderPlayer = false;
    },
    showDialogDeletePlayer(player) {
        this.$f7.dialog.confirm(`Are you sure you want to remove the player ${player.first_name} ${player.last_name}? By removing a player, you will also remove all his presences, contributions, research. The change will be irreversible!`, '', () => {
          this.deletePlayer(player.id)
        });
    },
    async deletePlayer(id) {
      try {
        const responseDeletePlayer = await groupsServices.deletePlayer(id);
        this.$f7router.navigate(`/players/?group=${this.player.group}`)
        this.$f7.toast
          .create({
            text: "The contestant has been removed!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with removing a player. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  }
};
</script>