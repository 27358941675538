<template>
  <f7-page login-screen>
    <navbarcalendarcrm></navbarcalendarcrm>

    <loader v-if="loader"></loader>

    <div v-else>
        <f7-block class="text-uppercase">
          <h4>{{ 'club.pitches_list' | translate}} <f7-link class="p-1" :tooltip="'popover.pitches_list' | translate"><img height="11px" src="/static/icons/help.svg" alt /></f7-link></h4>
          <div class="countAll">
            <span>
              <p>{{ 'club.count' | translate}}</p>
              {{playingFields.length}}
            </span>
          </div>
        </f7-block>
        <div class="listCards" v-if="playingFields.length > 0">
          <f7-block class="mt-0">
            <f7-row>
              <f7-col v-for="playingField in playingFields" :key="playingField.id" width="100" class="position-relative">
                <div class="delete" @click.stop="showDialogDeletePlayingField(playingField)">
                  <img height="20px" src="/static/icons/trash.svg" alt />
                </div>
                <a :href="`/playing-fields/${playingField.id}/`">
                <f7-card class="custom-card">
                  <div>
                    <p>{{playingField.name}}</p>
                    <p class="info">{{ 'club.street' | translate }}: {{playingField.street}}</p>
                    <p class="info">{{ 'club.city' | translate }}: {{playingField.city}}</p>
                  </div>
                </f7-card>
                </a>
              </f7-col>
              
            </f7-row>
          </f7-block>
        </div>

        <div v-else class="text-center text-uppercase">
            <img height="100px" src="/static/icons/light.svg" alt />
            <h4>{{ 'club.empty_pitches' | translate }}!</h4>
        </div>

        <f7-block>
          <div class="d-flex justify-content-center aling-items-center">
            <div>
              <a :href="`/create-playing-field/`">
                <img class="cursor-pointer" height="30px" src="/static/icons/plus.svg" alt="">
              </a>
            </div>
          </div>
        </f7-block>
    </div>

    <div class="custom-fab">
        <div class="box box-zoom cursor-pointer" @click="goToCreate()">
            <f7-link :tooltip="'global.add_new' | translate" >
              <img height="20px" src="/static/icons/plus-add.svg" alt="">
            </f7-link>
        </div>
      </div>

  </f7-page>
</template>

<script>
import Navbarcalendarcrm from "@/components/Navbar/Navbarcalendarcrm.vue";
import Loader from "@/components/Loader";
import mixinUser from "@/mixins/mixinUser"
import playingFieldsServices from "@/services/PlayingFieldsServices.js"
import mixinPlayingFields from "@/mixins/mixinPlayingFields"

export default {
  mixins: [mixinUser, mixinPlayingFields],
  components: {
    Navbarcalendarcrm,
    Loader
  },
  data: () => {
    return {
      loader: false,
    };
  },
  mounted() {
    this.fetchDataUser().then(() => {
      this.fetchPlayingFields();
    });
  },
  methods: {
    goToCreate() {
      this.$f7router.navigate(`/create-playing-field/`)
    },
    showDialogDeletePlayingField(playingField) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the field ${playingField.name}? The change will be irreversible!`, '', () => {
          this.deletePlayingField(playingField.id)
        });
    },
    async deletePlayingField(id) {
      try {
        const responseDeletePlayingField = await playingFieldsServices.deletePlayingField(id);
        this.fetchPlayingFields();
        this.$f7.toast
          .create({
            text: "The field has been removed!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the removal of the pitch. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  }
};
</script>