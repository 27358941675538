<template>
  <f7-page class="pagePayments" login-screen>
    <navbar></navbar>
    <loader v-if="loader"></loader>
    <div v-else>
      <f7-block class="text-center">
        <img style="height: 150px;" src="/static/icons/credit-card.svg" alt />
      </f7-block>
      <f7-block class="text-center">
        <h3>{{ 'payment.payment_page' | translate}}</h3>
        <p>{{ 'payment.payment_page_description' | translate}}</p>
      </f7-block>
        <f7-block>
          <f7-list form>
            <div>
              <div class="d-flex justify-content-between align-items-center mb-1">
                <p class="font-weight-bold mt-0 mb-0">{{ 'payment.choice_currency' | translate}}</p>
                
              </div>
              <select name="currency" v-model="actualCurrencyType" @change="fetchSubscriptions(false)">
                  <option v-for="(elem, index) in currency" :key="index" :value="index" :selected="index == actualCurrencyType">
                      {{elem}} - 
                      <span v-if="elem === 'PLN'">{{ 'payment.pln_currency' | translate}}</span>
                      <span v-if="elem === 'USD'">dollar</span>
                      <span v-if="elem === 'EUR'">euro</span>
                  </option>
              </select>
             
              <div class="d-flex justify-content-between align-items-center mb-1">
                <p class="font-weight-bold mt-1 mb-0">{{ 'payment.choice_plan' | translate}}</p>
                <f7-link :tooltip="'Stripe'">
                  <img width="30px" src="/static/stripe.png" alt /> 
                </f7-link>
              </div>
              <f7-row class="box">
                <f7-col 
                  v-for="plan in subscriptions.list"
                  :key="plan.id"
                  width="50"
                  class="box-item"
                  :class="{'active': plan.id == activePlan.id}"
                  @click="setPlan(plan)"
                >
                  <h4>{{plan.price}} {{currency[plan.currency_type]}}</h4>
                  <p>{{ `payment.plan_${plan.months}` | translate }}</p>
                </f7-col>
              </f7-row>
            </div>

            <div class="d-flex justify-content-between align-items-center cursor-pointer" @click="popupOpenedCompany = true">
              <p class="font-weight-bold mt-0 mb-0">{{ 'payment.choice_company' | translate}}</p>
              <f7-link :tooltip="'payment.view_company_details' | translate">
                <img height="20px" src="/static/icons/plus.svg" alt />
              </f7-link>
            </div>

            <f7-popup :opened="popupOpenedCompany" @popup:closed="popupOpenedCompany = false">
            <f7-page login-screen>
              <f7-navbar>
                <f7-nav-title>
                  <span style="margin-left: 20px">{{ 'payment.company_data' | translate}}</span>
                </f7-nav-title>
                <f7-nav-right>
                  <f7-link popup-close>
                    <img height="20px" style="margin-left: 0px" src="/static/icons/close.svg" alt />
                  </f7-link>
                </f7-nav-right>
              </f7-navbar>
              <f7-block>
                <f7-list form @submit.prevent="onChangeBuyerData">
                  <f7-list-input
                    :label="'payment.company' | translate"
                    type="text"
                    required
                    validate
                    :error-message="'global.required' | translate"
                    :placeholder="'payment.company' | translate"
                    maxlength="200"
                    :value="company.buyer_name"
                    @input="company.buyer_name = $event.target.value"
                  ></f7-list-input>
                  <f7-list-input
                    :label="'payment.street' | translate"
                    type="text"
                    required
                    validate
                    :error-message="'global.required' | translate"
                    :placeholder="'payment.street' | translate"
                    maxlength="200"
                    :value="company.buyer_street"
                    @input="company.buyer_street = $event.target.value"
                  ></f7-list-input>
                  <f7-list-input
                    :label="'payment.zip_code' | translate"
                    type="text"
                    required
                    validate
                    :error-message="'global.required' | translate"
                    :placeholder="'payment.zip_code' | translate"
                    maxlength="200"
                    :value="company.buyer_zipcode"
                    @input="company.buyer_zipcode = $event.target.value"
                  ></f7-list-input>
                  <f7-list-input
                    :label="'payment.city' | translate"
                    type="text"
                    required
                    validate
                    :error-message="'global.required' | translate"
                    :placeholder="'payment.city' | translate"
                    maxlength="200"
                    :value="company.buyer_city"
                    @input="company.buyer_city = $event.target.value"
                  ></f7-list-input>
                  <f7-list-input
                    :label="'payment.country' | translate"
                    type="text"
                    required
                    validate
                    :error-message="'global.required' | translate"
                    :placeholder="'payment.country' | translate"
                    maxlength="200"
                    :value="company.buyer_country"
                    @input="company.buyer_country = $event.target.value"
                  ></f7-list-input>
                  <f7-list-input
                    :label="'payment.vat' | translate"
                    type="text"
                    maxlength="200"
                    :placeholder="'payment.vat' | translate"
                    :value="company.buyer_tax_number"
                    @input="company.buyer_tax_number = $event.target.value"
                    >
                    </f7-list-input>
                  <div class="text-center mt-2">
                    <f7-button v-if="!company.loaderComapny" type="submit" raised>{{ 'payment.save_company' | translate}}</f7-button>
                    <f7-button v-else raised>
                      <f7-preloader :size="20" color="white"></f7-preloader>
                    </f7-button>
                  </div>
                </f7-list>
              </f7-block>
            </f7-page>
          </f7-popup>

          </f7-list>
        </f7-block>

        <f7-block class="text-center wrapper-form-button">
          <f7-button v-if="!loaderSubscriptions" @click="submit" raised :disabled="loaderSubscriptions">{{ 'payment.pay' | translate}}</f7-button>
          <f7-button v-else raised>
          <f7-preloader :size="20" color="white"></f7-preloader>
        </f7-button>
          <span class="font-size-12">{{ 'payment.payment_warning' | translate}}</span>
        </f7-block>

        <stripe-checkout
          ref="checkoutRef"
          mode="subscription"
          :pk="pkStripe"
          :customerEmail="account.email"
          :line-items="lineItems"
          :success-url="successURL"
          :cancel-url="cancelURL"
          :locale="getLang"
        />
    </div>
      
  </f7-page>
</template>

<script>
import CONSTANTS from "@/utils/constants.js"
import Navbar from "@/components/Navbar/Navbar.vue";
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import mixinUser from "@/mixins/mixinUser"
import Loader from "@/components/Loader";
import ordersServices from "@/services/OrdersServices.js"
import authServices from "@/services/AuthServices.js"

export default {
  mixins: [mixinUser],
  components: {
    Loader,
    Navbar,
    StripeCheckout,
  },
  data: () => {
    return {
      loader: false,
      loaderCurrency: false,
      popupOpenedCompany: false,
      currency: CONSTANTS.CURRENCY,
      actualCurrencyType: 0,
      subscriptions: {
        list: []
      },
      activePlan: {
        id: '',
        plan_id: ''
      },
      autoRenewal: false,
      loaderSubscriptions: false,
      company: {
        loaderComapny: false,
        buyer_name: '',
        buyer_street: '',
        buyer_zipcode: '',
        buyer_city: '',
        buyer_country: '',
        buyer_tax_number: '',
      },
      publishableKey: '',
      lineItems: [
        {
          price: '',
          quantity: 1,
        }, 
      ],
      successURL: '',
      cancelURL: '',
    };
  },
  mounted() {
    if (window.location.host == 'app.soccer-sessions.com') {
      // Pixel facebook
      window.fbq('track', 'InitiateCheckout');

      // User.com event
      window.userengage('event.add_to_cart');
    };
    if (this.isAuthenticated) {
      this.fetchDataUser().then(() => {
        this.actualCurrencyType = this.getLang == 'pl' ? 0 : 2;
        if (this.autoRenewal) {
          this.$f7router.navigate(`/library/`)
        } else {
          this.successURL = `${this.hostApp}/#!/payments-success/`;
          this.cancelURL = `${this.hostApp}/#!/payments-failed/`;
          this.fetchSubscriptions(true)
        }
      })
    }
  },
  methods: {
    visualChangeLoader(init, value) {
      if (init) {
        this.loader = value;
      } else {
        this.loaderCurrency = value;
      }
    },
    async fetchSubscriptions(init) {
      try {
        this.visualChangeLoader(init, true)

        const data = {
          currency_type: this.actualCurrencyType
        }

        const responseDataSubscriptions = await ordersServices.listSubscriptions(data);
        this.subscriptions = responseDataSubscriptions.data
        if (this.subscriptions.list.length > 0) {
          this.activePlan = this.subscriptions.list[2];
          this.lineItems[0].price = this.activePlan.plan_id
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the subscriptions list. Please try again or contact your administrator.",
                        position: "bottom",
          
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.visualChangeLoader(init, false)
    },
    setPlan(plan) {
      this.activePlan = plan;
      this.lineItems[0].price = this.activePlan.plan_id
    },
    async onChangeBuyerData() {
      this.company.loaderComapny = true;

      const data = {
        buyer_name: this.company.buyer_name, 
        buyer_street: this.company.buyer_street,
        buyer_zipcode: this.company.buyer_zipcode,
        buyer_city: this.company.buyer_city,
        buyer_country: this.company.buyer_country,
        buyer_tax_number: this.company.buyer_tax_number, 
      }

      try {
        const responseChangeBuyerData = await authServices.updateUser(data);
        this.popupOpenedCompany = false
        this.$f7.toast
          .create({
            text:
              "Company details updated",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
          this.$f7.toast
            .create({
              text:
                "Something went wrong with the company data update. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        console.log(error);
      }
      this.company.loaderComapny = false;
      
    },
   async submit () {
      this.loaderSubscriptions = true;

      const data = {
        "amount": this.activePlan.price,
        "plan_type": this.activePlan.id,
        "buyer_tax_number": this.company.buyer_tax_number,
        "invoice": this.company.buyer_tax_number !== '' ? true : false,
        "currency": this.actualCurrencyType
      }

      try {
        const responseOrder = await ordersServices.createOrder(data);

        if (responseOrder.data.id) {
          // You will be redirected to Stripe's secure checkout page
          this.$refs.checkoutRef.redirectToCheckout();
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text:
              "Something went wrong with order creation. Please try again or contact your administrator.",
                        position: "bottom",
          
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/pages/payments.scss";
</style>