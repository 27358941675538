import authServices from "@/services/AuthServices.js"

export default {
    data: () => {
        return {
          profileBooks: [],
          account: {
            email: ''
          },
          company: {
          },
          isSubscription: false,
          expirationDate: ''
        };
      },
  methods: {
    async fetchDataUserBooks() {
      try {
        this.loader = true;

        const responseDataUserBooks = await authServices.userBooks();
        this.profileBooks = responseDataUserBooks.data.books
        
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Invalid login details.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
    async fetchDataUserBooksSmall() {
      try {
        this.loader = true;

        const responseDataUserBooks = await authServices.userBooksSmall();
        this.profileBooks = responseDataUserBooks.data.books
        
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Invalid login details.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
    async fetchDataUser() {
      try {
        this.loader = true;
        const responseDataUser = await authServices.user();
        this.account.email = responseDataUser.data.user.email
        this.account.first_name = responseDataUser.data.user.first_name
        this.account.phone = responseDataUser.data.user.phone
        this.account.last_name = responseDataUser.data.user.last_name
        this.account.name_club = responseDataUser.data.user.name_club
        this.account.date_of_birth = responseDataUser.data.user.date_of_birth
        this.company.buyer_name = responseDataUser.data.user.buyer_name
        this.company.buyer_street = responseDataUser.data.user.buyer_street
        this.company.buyer_zipcode = responseDataUser.data.user.buyer_zipcode
        this.company.buyer_city = responseDataUser.data.user.buyer_city
        this.company.buyer_country = responseDataUser.data.user.buyer_country
        this.company.buyer_tax_number = responseDataUser.data.user.buyer_tax_number
        this.profileGroups = responseDataUser.data.user.groups,
        this.isSubscription = responseDataUser.data.user.is_subscription
        this.autoRenewal = responseDataUser.data.user.auto_renewal
        this.expirationDate = responseDataUser.data.user.expiration_date
        this.isPartner = responseDataUser.data.user.is_partner
        this.profileAcademy = responseDataUser.data.user.academy.user.map((elem)=> {
          let haveEqualId = (user) => user.id === elem.id
          let userWithEqualId = responseDataUser.data.user.academy.profile.find(haveEqualId)
          return Object.assign({}, elem, userWithEqualId)
        })
        this.profileCoaches = responseDataUser.data.user.coaches.user.map((elem)=> {
          let haveEqualId = (user) => user.id === elem.id
          let userWithEqualId = responseDataUser.data.user.coaches.profile.find(haveEqualId)
          return Object.assign({}, elem, userWithEqualId)
        })
        this.profileParents = responseDataUser.data.user.parents
        this.userId = responseDataUser.data.user.id
        this.account.currency = responseDataUser.data.user.currency
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Invalid login details.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
  }
}