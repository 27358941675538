<template>
  <f7-block class="wrapperPanel">
    <div class="no-print">

      <p class="no-print font-weight-bold">
        <span class="text-uppercase">{{ 'outline.showInfoExercise' | translate }}</span>
        </p>
        <div class="d-flex">
          <input type="checkbox" id="subject" class="no-print" v-model="configExercise.subject">
          <label class="no-print" for="subject">
              <span class="text-uppercase">{{ 'outline.subject' | translate }}</span>

          </label>
        </div>
        <div class="d-flex">
          <input type="checkbox" id="objective" class="no-print" v-model="configExercise.objective">
          <label class="no-print" for="objective">
              <span class="text-uppercase">{{ 'outline.objective' | translate }}</span>
          </label>
        </div>
        <div class="d-flex">
          <input type="checkbox" id="coaching" class="no-print" v-model="configExercise.coaching">
          <label class="no-print" for="coaching">
              <span class="text-uppercase">{{ 'outline.coachingPoints' | translate }}</span>
          </label>
        </div>
        <div class="d-flex">
          <input type="checkbox" id="info" class="no-print" v-model="configExercise.info">
          <label class="no-print" for="info">
              <span class="text-uppercase">{{ 'outline.addittionalInformations' | translate }}</span>
          </label>
        </div>
    </div>
    <div class="no-print">
        <p class="no-print font-weight-bold">
            <span class="text-uppercase">{{ 'outline.showPartExercise' | translate }}</span>
            </p>
            <div class="d-flex">
              <input type="checkbox" id="part_introduction_I" class="no-print" v-model="configExercise.part">
              <label class="no-print" for="part_introduction_I">
                  <span class="text-uppercase">{{ 'outline.partName' | translate }}</span>
              </label>
            </div>
    </div>
  </f7-block>
</template>

<script>
export default {
  name: 'Togglepanelexercise',
  props: {
    configExercise: {
      type: Object,
      required: true,
    },
  },
  watch: {
    configExercise: {
      handler(val) {
        this.$store.dispatch("appkonspektypilkanozna/setConfigExersice", val);
      },
      deep: true
    }
  },
}
</script>