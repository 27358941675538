<template>
  <f7-page login-screen>
    <navbarlist :list="'dues'"></navbarlist>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{ 'club.new_dues' | translate }}</h4>
          </f7-block>
          <f7-list form no-hairlines-md @submit.prevent="createDue">
             <f7-list-item title="Grupa" class="custom-select">
              <select name="group" v-model="due.group">
                <option v-if="groups.length == 0" value="" disabled>{{ 'club.lack_group' | translate }}</option>
                <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == due.group">
                    {{group.name}}
                </option>
              </select>
            </f7-list-item>
             <f7-list-input
              :label="'club.date_of_payment' | translate"
              type="date"
              :placeholder="'club.premium_payment_date' | translate"
              required
              validate
              :error-message="'global.required' | translate"
              :value="due.date_of_payment"
              @input="due.date_of_payment = $event.target.value"
            >
            </f7-list-input>
            <f7-list-item v-if="groups.length > 0" class="custom-item-inside-list-header dues">
              <h4 class="text-uppercase">{{ 'club.players' | translate }}</h4>
              <span class="due">{{ 'club.amount_dues' | translate }} ({{currency[account.currency]}})</span>
            </f7-list-item>
            <f7-list-item class="custom-item-inside-list" v-for="player in players" :key="player.id">
              <div class="d-flex justify-content-between w-100 align-items-center">
                <span>
                  <f7-link v-if="player.status_active" :tooltip="'club.status_active' | translate">
                    <div style="width: 10px; height: 10px; background-color: green; border-radius: 50%;"></div>
                  </f7-link>
                  <f7-link v-else :tooltip="'club.status_not_active' | translate">
                    <div style="width: 10px; height: 10px; background-color: red; border-radius: 50%;"></div>
                  </f7-link>
                  {{player.first_name}} {{player.last_name}}
                </span>
                
                <div class="d-flex">
                  <div>
                    <input type="number" min="0" class="custom-text" v-model="player.due_price">
                  </div>
                </div>
              </div>
             
            </f7-list-item>
            <f7-button style="margin: 0px 16px;" v-if="!groups.length > 0" href="/groups/" raised>{{ 'club.need_create_group' | translate }}!</f7-button>
            <f7-block class="text-center">
              <f7-button v-if="!loaderDue" type="submit" raised>
                {{ 'club.generate_dues' | translate }}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import CONSTANTS from "@/utils/constants.js"
import Navbarlist from "@/components/Navbar/Navbarlist.vue";
import Loader from "@/components/Loader";
import duesServices from "@/services/DuesServices.js"
import mixinUser from "@/mixins/mixinUser"
import mixinGroups from "@/mixins/mixinGroups"
import groupsServices from "@/services/GroupsServices.js"

export default {
  mixins: [mixinUser, mixinGroups],
  components: {
    Navbarlist,
    Loader,
  },
  data: () => {
    return {
      loader: false,
      loaderDue: false,
      due: {
        group: '',
        paid_dues: [],
        date_of_payment: ''
      },
      currency: CONSTANTS.CURRENCY,
      groups: [],
      players: []
    };
  },
  watch: {
    'due.group': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchAllPlayers(this.due.group);
      }
    },
  },
  mounted() {
    this.fetchDataUser().then(() => {
      this.fetchGroups().then(() => {
        if (this.getRole == 'coach') {
          this.groups = this.profileGroups;
        }
        if (this.groups.length > 0) {
          if (this.$f7route.query.group !== undefined) {
            this.due.group = this.$f7route.query.group
          } else {
            this.due.group = this.groups[0].id
          }
        }
        this.fetchAllPlayers(this.due.group);
      });
    })
  },
  methods: {
    async fetchAllPlayers(group) {
      try {
        this.loader = true;

        const responseDataAllPlayers = await groupsServices.listAllPlayers(group);
        this.players = responseDataAllPlayers.data
        
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with the list of players in the group. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    async createDue() {
      if (this.due.group == '') {
        return this.$f7.toast
          .create({
            text: "No group",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
      }
      this.loaderDue = false;
      try {
        this.due.paid_dues = []
        
        this.loaderDue = true;

        this.players.forEach((player) => {
          if (player.due) {
            this.due.paid_dues.push(player.id)
          }
        })

        const responseCreateDue = await duesServices.createDue(this.due);
        if (responseCreateDue.data.id) {
          this.$f7router.navigate(`/dues/?group=${this.due.group}`)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the creation of the premium. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderDue = false;
    },
  }
};
</script>