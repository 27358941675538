import Api from './index'
import store from '@/store'

export default {
  async createAttendance(data) {
    return Api().post('attendancelist/', data, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async attendanceTraining(training) {
    return Api().get(`attendancelist/training/?training=${training}`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async attendanceStats(data) {
    return Api().get(`attendancelist/stats/?group=${data.group}&date_start=${data.date_start}&date_end=${data.date_end}`, {
      headers: {
        'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async itemAttendance(id) {
    return Api().get(`attendancelist/${id}/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async attendance(data) {
    return Api().put(`attendancelist/${data.id}/`, data, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
}