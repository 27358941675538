<template>
  <f7-page login-screen>
    <navbar></navbar>

    <loader v-if="loader"></loader>

    <div v-else>
      <f7-block>
        <div v-if="getRole == 'books'">
          <div>
            <p class="font-weight-bold mb-0">{{ 'account.publications' | translate }}</p>
            <div v-if="profileBooks.length > 0">
              <f7-badge v-if="isSubscription" color="green">{{ 'account.active' | translate }} <span style="margin-left: 5px" class="font-weight-bold">{{ 'account.during_subscription' | translate }}</span></f7-badge>
              <f7-badge v-else color="green">{{ 'account.active' | translate }} <span style="margin-left: 5px" class="font-weight-bold">{{ 'account.for_life' | translate }}</span></f7-badge>
              <f7-button class="mt-1 font-weight-bold" @click="popupOpenedBooks = true" raised>{{ 'account.view_publications' | translate }} </f7-button>
            </div>
            <div v-else>
              <f7-badge color="red">{{ 'account.none_purchased' | translate }} <span style="margin-left: 5px" class="font-weight-bold">{{ 'account.publication' | translate }}</span></f7-badge>
            </div>
            <f7-popup :opened="popupOpenedBooks" @popup:closed="popupOpenedBooks = false">
            <f7-page>
              <f7-navbar>
                <f7-nav-title>
                  <span style="margin-left: 20px">{{  'account.list_publications' | translate}}</span>
                </f7-nav-title>
                <f7-nav-right>
                  <f7-link popup-close>
                    <img height="20px" style="margin-left: 0px" src="/static/icons/close.svg" alt />
                  </f7-link>
                </f7-nav-right>
              </f7-navbar>
              <f7-block>
                <div v-for="book in profileBooks" :key="book.id" class="d-flex mb-1 align-items-center">
                  <f7-link :href="`/books/${book.id}/`" popup-close>
                    <img height="20px" style="margin-right: 5px" :src="`${host}${book.cover}`" alt />
                    <p class="mb-0 mt-0" >
                      {{ getLang == 'pl' ? book.title : book.title_ang }}
                    </p>
                  </f7-link>
                </div>
              </f7-block>
            </f7-page>
          </f7-popup>
          </div>
          <div>
            <p class="font-weight-bold mb-0">{{'account.subscriptions' | translate}}</p>
            <div v-if="isSubscription">
              <f7-badge color="green">{{  'account.active_until' | translate}}: <span style="margin-left: 5px" class="font-weight-bold">{{ expirationDate | formatDate }}</span></f7-badge>
            </div>
            <div v-else>
              <f7-badge color="red">{{ 'account.no_active_subscriptions' | translate }}</f7-badge>
            </div>
            

            <f7-button class="mt-1 font-weight-bold" @click="popupOpenedOrders = true" raised>{{'account.view_orders' | translate}}</f7-button>
            <f7-popup :opened="popupOpenedOrders" @popup:closed="popupOpenedOrders = false">
            <f7-page>
              <f7-navbar>
                <f7-nav-title>
                  <span style="margin-left: 20px">{{ 'account.list_subscriptions' | translate }}</span>
                </f7-nav-title>
                <f7-nav-right>
                  <f7-link popup-close>
                    <img height="20px" style="margin-left: 0px" src="/static/icons/close.svg" alt />
                  </f7-link>
                </f7-nav-right>
              </f7-navbar>
              <f7-block>
                <p>
                  {{'account.only_subscriptions' | translate}}
                </p>
                <div class="data-table">
                  <table v-if="orders.list.length > 0">
                    <thead>
                      <tr>
                        <th scope="col pl-0">Id</th>
                        <th class="label-cell pl-0">{{ 'account.date' | translate}}</th>
                        <th class="label-cell pl-0">{{  'account.amount' | translate}}</th>
                        <th class="label-cell pl-0">{{  'account.status' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in orders.list" :key="item.id">
                        <th class="label-cell pl-0">
                          <div class="d-flex aling-items-center">
                             <f7-link :tooltip="'Stripe'" class="mr-1">
                                <img width="20px" src="/static/stripe.png" alt /> 
                              </f7-link>
                              #{{item.id}}
                          </div>
                          <!-- <div v-if="item.shoper_id_transaction" class="d-flex aling-items-center">
                            <f7-link :tooltip="'Aystent Trenera'" class="mr-1">
                              <img width="30px" src="/static/company/asystent_trenera.png" alt />
                            </f7-link>
                          </div>
                          <div v-if="item.kpn_id_transaction" class="d-flex aling-items-center">
                            <f7-link :tooltip="'Konspekty Piłka Nożna'" class="mr-1">
                              <img width="30px" src="/static/company/konspekty_pilka_nozna.png" alt />
                            </f7-link>
                          </div> -->
                        </th>
                        <th class="label-cell pl-0">{{item.created_at | formatDate}}</th>
                        <th class="label-cell pl-0">
                            {{item.amount}} {{currency[item.currency_type]}}
                            <span v-if="item.invoice">
                              <a v-if="item.invoice_pdf !== null" :href="`${host}${item.invoice_pdf}`" :tooltip="'Stripe'" class="external" target="_blank" download><img height="13px" src="/static/icons/docs-4.svg" alt /></a>
                              <f7-link v-else :tooltip="'account.invoice_prepare' | translate">
                                <img width="13px" src="/static/icons/docs-3.svg" alt /> 
                              </f7-link>
                            </span>
                            
                        </th>
                        <th class="label-cell pl-0">
                          <div>
                              <f7-link v-if="item.paid" :tooltip="'account.status_1' | translate">
                                <img height="13px" src="/static/icons/success.svg" alt />
                              </f7-link>
                              <f7-link v-else :tooltip="'account.status_2' | translate">
                                <img height="13px" src="/static/icons/error.svg" alt />
                              </f7-link>
                          </div>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else class="text-center mt-2">
                    <img height="70px" src="/static/icons/no-data.svg" alt />
                    <p class="mt-0">{{  'account.empty_orders' | translate}}</p>
                  </div>
                </div>
              </f7-block>
            </f7-page>
          </f7-popup>
          </div>
        </div>
        <div v-if="getRole == 'owner'">
          <div>
            <div>
              <p class="font-weight-bold d-flex align-items-center mb-0">
                {{ 'account.private_outlines' | translate }}
              </p>
              <div><f7-badge color="green">{{ 'account.active' | translate }} <span style="margin-left: 5px" class="font-weight-bold">{{ 'account.for_life' | translate }}</span></f7-badge></div>
            </div>
            <div>
              <p class="font-weight-bold d-flex align-items-center mb-0">
                {{ 'account.training_planning' | translate }}
              </p>
                <div><f7-badge color="green">{{ 'account.active' | translate }} <span style="margin-left: 5px" class="font-weight-bold">{{ 'account.for_life' | translate }}</span></f7-badge> </div>
            </div>
            <div>
              <p class="font-weight-bold d-flex align-items-center mb-0">
                {{ 'account.crm' | translate }}
              </p>
                <div><f7-badge color="green">{{ 'account.active' | translate }} <span style="margin-left: 5px" class="font-weight-bold">{{ 'account.for_life' | translate }}</span></f7-badge></div>
            </div>
          </div>
        </div>
      </f7-block>
      <div v-if="profileAcademy.length > 0 && getRole == 'coach'">
        <f7-block>
          <h3>{{ 'account.coach_club' | translate }}</h3>
        </f7-block>
        <f7-block v-for="academy in profileAcademy" :key="academy.id">
          <p>
            {{academy.name_club}}
          </p>
        </f7-block>
      </div>
      
      <f7-block>
        <h3>{{ 'account.basic_information' | translate }}</h3>
      </f7-block>
      <f7-list form @submit.prevent="onChangeData">
        <f7-list-input
          :label="'account.email' | translate"
          type="text"
          :placeholder="'account.email_placeholder' | translate"
          :value="account.email"
          disabled
        ></f7-list-input>
        <f7-list-input v-if="existClub && getRole == 'owner'"
          :label="'account.club' | translate"
          type="text"
          required
          validate
          :error-message="'global.required' | translate"
          :placeholder="'account.club_placeholder' | translate"
          maxlength="64"
          :value="account.name_club"
          @input="account.name_club = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="'account.name' | translate"
          type="text"
          :placeholder="'account.name_placeholder' | translate"
          maxlength="64"
          :value="account.first_name"
          @input="account.first_name = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="'account.last_name' | translate"
          type="text"
          :placeholder="'account.last_name_placeholder' | translate"
          maxlength="64"
          :value="account.last_name"
          @input="account.last_name = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="'account.phone_number' | translate"
          type="text"
          :placeholder="'account.phone_number_placeholder' | translate"
          maxlength="100"
          :value="account.phone"
          @input="account.phone = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="'account.birth' | translate"
          type="date"
          :placeholder="'account.birth' | translate"
          :value="account.date_of_birth"
          @input="account.date_of_birth = $event.target.value"
          >
          </f7-list-input>
          <f7-list-item  v-if="existClub && getRole == 'owner'" :title="'account.currency' | translate" class="custom-select">
            <select name="currency" v-model="account.currency">
              <option v-for="(curr, index) in currency" :key="index" :value="index" :selected="index == account.currency">
                  {{curr}}
              </option>
            </select>
          </f7-list-item>
        <f7-block class="text-center">
          <f7-button v-if="!account.loaderData" type="submit" raised>{{  'account.update_data' | translate}}</f7-button>
          <f7-button v-else raised>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>
      <f7-block>
        <h3>{{ 'payment.choice_company' | translate }}</h3>
      </f7-block>
      <f7-list form @submit.prevent="onChangeBuyerData">
        <f7-list-input
          :label="'payment.company' | translate"
          type="text"
          required
          validate
          :error-message="'global.required' | translate"
          :placeholder="'payment.company' | translate"
          maxlength="200"
          :value="company.buyer_name"
          @input="company.buyer_name = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="'payment.street' | translate"
          type="text"
          required
          validate
          :error-message="'global.required' | translate"
          :placeholder="'payment.street' | translate"
          maxlength="200"
          :value="company.buyer_street"
          @input="company.buyer_street = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="'payment.zip_code' | translate"
          type="text"
          required
          validate
          :error-message="'global.required' | translate"
          :placeholder="'payment.zip_code' | translate"
          maxlength="200"
          :value="company.buyer_zipcode"
          @input="company.buyer_zipcode = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="'payment.city' | translate"
          type="text"
          required
          validate
          :error-message="'global.required' | translate"
          :placeholder="'payment.city' | translate"
          maxlength="200"
          :value="company.buyer_city"
          @input="company.buyer_city = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="'payment.country' | translate"
          type="text"
          required
          validate
          :error-message="'global.required' | translate"
          :placeholder="'payment.country' | translate"
          maxlength="200"
          :value="company.buyer_country"
          @input="company.buyer_country = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="'payment.vat' | translate"
          type="text"
          :placeholder="'payment.vat' | translate"
          maxlength="200"
          :value="company.buyer_tax_number"
          @input="company.buyer_tax_number = $event.target.value"
          >
          </f7-list-input>
        <f7-block class="text-center">
          <f7-button v-if="!company.loaderComapny" type="submit" raised>{{ 'payment.save_company' | translate }}</f7-button>
          <f7-button v-else raised>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>

      <f7-block>
        <h3>{{ 'account.password_change' | translate }}</h3>
      </f7-block>
      <f7-list form @submit.prevent="onChangePassword">
        <f7-list-input
          :label="'account.old_password' | translate"
          type="password"
          :placeholder="'account.old_password' | translate"
          :value="account.passwordOld"
          @input="account.passwordOld = $event.target.value"
          autocomplete="off"
        ></f7-list-input>
        <f7-list-input
          :label="'account.new_password' | translate"
          type="password"
          :placeholder="'account.new_password' | translate"
          :value="account.passwordNew"
          @input="account.passwordNew = $event.target.value"
          autocomplete="off"
        ></f7-list-input>
        <f7-list-input
          :label="'account.confirm_password' | translate"
          type="password"
          :placeholder="'account.confirm_password' | translate"
          :value="account.passwordNewConfirm"
          @input="account.passwordNewConfirm = $event.target.value"
          autocomplete="off"
        ></f7-list-input>
        <f7-block class="text-center">
          <f7-button v-if="!account.loaderPassword" type="submit" raised>{{ 'account.change_password' | translate }}</f7-button>
          <f7-button v-else raised>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>

      <div v-if="getRole == 'books'">
        <div v-if="autoRenewal">
          <f7-block>
            <h3>{{ 'account.subscription_cancellation' | translate}}</h3>
          </f7-block>
          <f7-block class="text-center">
            <f7-button v-if="!loaderCancel" @click="onCancelSubscription" style="background: #d62029; color: white;" raised>{{ 'account.cancel_your_subscription' | translate}}</f7-button>
            <f7-button v-else style="background: #d62029; color: white;" raised>
              <f7-preloader :size="20" color="white"></f7-preloader>
            </f7-button>
          </f7-block>
        </div>
        
        <f7-block>
          <h3>{{ 'account.deleting_accounts' | translate}}</h3>
        </f7-block>
        <f7-block v-if="desireToDeleteTheAccount">
          <p>{{ 'account.deleting_accounts_description' | translate}}</p>
        </f7-block>
        <f7-block class="text-center">
          <f7-button v-if="!desireToDeleteTheAccount" @click="desireToDeleteTheAccount = true" style="background: #d62029; color: white;" raised>{{ 'account.delete_account' | translate}} </f7-button>
          <f7-button v-else href="https://soccer-sessions.com" external target="_blank" class="external" style="background: #d62029; color: white;" raised >{{ 'account.delete_account' | translate}}</f7-button>
        </f7-block>
      </div>
     
    </div>

  </f7-page>
</template>

<script>
import CONSTANTS from "@/utils/constants.js"
import Navbar from "@/components/Navbar/Navbar.vue";
import Loader from "@/components/Loader";
import authServices from "@/services/AuthServices.js"
import mixinUser from "@/mixins/mixinUser"
import ordersServices from "@/services/OrdersServices.js"

export default {
  mixins: [mixinUser],
  components: {
    Navbar,
    Loader,
  },
  data: () => {
    return {
      loader: false,
      popupOpenedOrders: false,
      popupOpenedBooks: false,
      loaderCancel: false,
      account: {
        name: "",
        first_name: "",
        last_name: "",
        name_club: "",
        phone: "",
        date_of_birth: "",
        loaderData: false,
        passwordOld: "",
        passwordNew: "",
        passwordNewConfirm: "",
        loaderPassword: false,
        currency: '',
      },
      company: {
        loaderComapny: false,
        buyer_name: '',
        buyer_street: '',
        buyer_zipcode: '',
        buyer_city: '',
        buyer_country: '',
        buyer_tax_number: '',
      },
      currency: CONSTANTS.CURRENCY,
      existClub: false,
      profileAcademy: [],
      autoRenewal: false,
      isSubscription: false,
      expirationDate: '',
      desireToDeleteTheAccount: false,
      orders: {
        list: []
      }
    };
  },
  computed: {
    isConfirmNewPassowrd() {
      return this.account.passwordNew === this.account.passwordNewConfirm;
    },
  },
  mounted() {
    this.fetchDataUser().then(() => {
      this.existClub = this.profileAcademy.some(el => el.id == this.userId) && this.getRole == 'owner';
      const indexId = this.profileAcademy.findIndex((o) => {
          return o.id == this.userId;
      })
      if (indexId !== -1) this.profileAcademy.splice(indexId, 1);
      this.fetchDataUserBooksSmall();
      this.fetchOrders()
    })
  },
  methods: {
    async fetchOrders() {
        try {
          const responseDataOrders = await ordersServices.listOrders();
          this.orders = responseDataOrders.data
        } catch (error) {
          this.$f7.toast
            .create({
              text: "Something went wrong with the order list. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
      },
      async onCancelSubscription() {
        this.loaderCancel = true;
        try {
            const responseCancelSubscription = await authServices.cancelSubscription();
            this.autoRenewal = false
            if (window.location.host == 'app.soccer-sessions.com') {
              // User.com pageHit
              UE.pageHit({
                apiKey: 'uCdvLn',
                cancel_subscriber: true,
              });
            };
            this.$f7.toast
              .create({
                text:
                  "Subscription has been cancelled",
                            position: "bottom",
                
                closeTimeout: 3000
              })
              .open();
          } catch (error) {
            this.$f7.toast
                .create({
                  text:
                    "Something went wrong with unsubscribing. Please try again or contact your administrator.",
                              position: "bottom",
                
                  closeTimeout: 3000
                })
                .open();
          }
        this.loaderCancel = false;
      },
    async onChangePassword() {
      if (!this.isConfirmNewPassowrd) {
        this.$f7.toast
          .create({
            text: "The passwords are not identical",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        return false;
      }

      this.account.loaderPassword = true;
      try {
        const responseChangePassword = await authServices.changePassword(this.account.passwordNew, this.account.passwordOld);
        this.$f7.toast
          .create({
            text:
              "Password has been changed",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
          this.account.passwordOld = ''
          this.account.passwordNew = ''
          this.account.passwordNewConfirm = ''
      } catch (error) {
        if (error.response.status == 400) {
          if ('new_password' in error.response.data) {
            this.$f7.toast
            .create({
              text:
                "This password is too short or too popular. It must contain at least 8 characters and cannot consist of numbers only.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          } else if ('old_password' in error.response.data) {
            this.$f7.toast
            .create({
              text:
                "The old password is not correct!",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          } else {
            this.$f7.toast
            .create({
              text:
                "Password error. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          }
        } else {
          this.$f7.toast
            .create({
              text:
                "Something went wrong with the password change. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.account.loaderPassword = false;
    },
    async onChangeData() {
      this.account.loaderData = true;

    const data = {
      first_name: this.account.first_name, 
      last_name: this.account.last_name,
      name_club: this.account.name_club,
      date_of_birth: this.account.date_of_birth,
      phone: this.account.phone,
      currency: this.account.currency, 
    }
      try {
        const responseChangeData = await authServices.updateUser(data);
        this.$f7.toast
          .create({
            text:
              "Data updated",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
          this.$f7.toast
            .create({
              text:
                "Something went wrong with the data update. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        console.log(error);
      }
      this.account.loaderData = false;
    },
    async onChangeBuyerData() {
      this.company.loaderComapny = true;

      const data = {
        buyer_name: this.company.buyer_name, 
        buyer_street: this.company.buyer_street,
        buyer_zipcode: this.company.buyer_zipcode,
        buyer_city: this.company.buyer_city,
        buyer_country: this.company.buyer_country,
        buyer_tax_number: this.company.buyer_tax_number, 
      }

      try {
        const responseChangeBuyerData = await authServices.updateUser(data);
        this.$f7.toast
          .create({
            text:
              "Company details have been updated",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
          this.$f7.toast
            .create({
              text:
                "Something went wrong with the company data update. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        console.log(error);
      }
      this.company.loaderComapny = false;

    }
   }
};
</script>