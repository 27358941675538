<template>
  <f7-page class="pageTacticBoard">
    <navbartacticboard :show="isEdit" @on-settings="togglePanel" @on-clear="clear" @on-download="download"></navbartacticboard>
        <div class="draw">
            <canvas ref="canvas" id="paint-canvas" @click="addPlayer()" 
                    :style="{ backgroundImage: 'url(' + `/static/skeleton-soccer-horizontal-${activeField}.svg` + ')' }"
                    v-on:touchstart="handleTouchDown" 
                    v-on:touchend="handleTouchUp"
                    v-on:touchmove="handleTouchMove" 
                    v-on:mousemove="handleMouseMove"
                    v-on:mousedown="handleMouseDown"  
                    v-on:mouseup="handleMouseUp"></canvas>
        </div>
        <img style="display: none" id="soccerBall" src="/static/icons/soccer-ball.svg" alt />
        <div v-if="isEdit" class="toolsPanel">
          <f7-block>
            <h4>{{ 'tactic.field' | translate }}</h4>
            <div class="fields">
              <f7-link :tooltip="'tactic.field_white' | translate" class="field" :class="{active: activeField == '1'}" @click="activeField = 1">
                <img height="80px" src="/static/skeleton-soccer-horizontal-1.svg" alt="">
              </f7-link>
              <f7-link :tooltip="'tactic.field_green' | translate" class="field" :class="{active: activeField == '2'}" @click="activeField = 2">
                <img height="80px" src="/static/skeleton-soccer-horizontal-2.svg" alt="">
              </f7-link>
            </div>
            <div class="fields">
              <f7-link :tooltip="'tactic.futsal_white' | translate" class="field" :class="{active: activeField == '3'}" @click="activeField = 3">
                <img height="80px" src="/static/skeleton-soccer-horizontal-3.svg" alt="">
              </f7-link>
              <f7-link :tooltip="'tactic.futsal_red' | translate" class="field" :class="{active: activeField == '4'}" @click="activeField = 4">
                <img height="80px" src="/static/skeleton-soccer-horizontal-4.svg" alt="">
              </f7-link>
            </div>
            <h4>{{ 'tactic.tools' | translate }}</h4>
            <div class="tools">
              <f7-link :tooltip="'tactic.change_marker' | translate" class="tool" v-for="color in colors" :key=color.id @click="changeColor(color)" :class="{active: activeTool == `color${color}`}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path :style="{ fill: color }" d="M51.2 353.28L0 512l158.72-51.2zM87.16 316.492L336.96 66.69l108.61 108.61L195.77 425.102zM504.32 79.36L432.64 7.68c-10.24-10.24-25.6-10.24-35.84 0l-23.04 23.04 107.52 107.52 23.04-23.04c10.24-10.24 10.24-25.6 0-35.84z"/></svg>
              </f7-link>
            </div>
            <div class="tools">
              <f7-link :tooltip="'tactic.player_circle' | translate" class="tool" v-for="player in players" :key=player.id @click="changePlayerCircle(player)" :class="{active: activeTool == `playerCircle${player}`}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path :style="{ fill: player }" d="M256 0C115.39 0 0 115.39 0 256s115.39 256 256 256 256-115.39 256-256S396.61 0 256 0z"/></svg>
              </f7-link>
            </div>
            <div class="tools">
              <f7-link :tooltip="'tactic.player_cross' | translate" class="tool" v-for="player in players" :key=player.id @click="changePlayerCross(player)" :class="{active: activeTool == `playerCross${player}`}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 298.667 298.667"><path :style="{ fill: player }" d="M298.667 30.187L268.48 0 149.333 119.147 30.187 0 0 30.187l119.147 119.146L0 268.48l30.187 30.187L149.333 179.52 268.48 298.667l30.187-30.187L179.52 149.333z"/></svg>
              </f7-link>
            </div>
            <div class="tools">
              <f7-link :tooltip="'tactic.goalkeeper' | translate" class="tool" v-for="goalkeeper in goalkeepers" :key="goalkeeper.id" @click="changeGoalkeeper(goalkeeper)" :class="{active: activeTool == `goalkeeper${goalkeeper}`}">
                  <div class="goalkeeper" :style="{backgroundColor: goalkeeper}"></div>
              </f7-link>
            </div>
            <div class="tools">
              <f7-link :tooltip="'tactic.coach' | translate" class="tool" @click="changeTrainer()" :class="{active: activeTool == 'trainer#f0da16'}">
                <div class="trainer"></div>
              </f7-link>
              <f7-link :tooltip="'tactic.ball' | translate" class="tool" @click="changeSoccerBall()" :class="{active: activeTool == 'soccerBall'}">
                <div class="soccerBall"><img height="25px" src="/static/icons/soccer-ball.svg" alt /></div>
              </f7-link>
              <f7-link :tooltip="'tactic.eraser' | translate" class="tool" @click="changeEraser()" :class="{active: activeTool == 'eraser'}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"><path d="M315 285H201.214l124.393-124.394c5.858-5.857 5.858-15.355 0-21.213l-120-120c-5.857-5.858-15.355-5.858-21.213 0l-180 179.999a14.996 14.996 0 000 21.214l90 90A14.999 14.999 0 00105 314.999l60 .001.017-.001L315 315c8.283 0 15-6.716 15-15 0-8.284-6.716-15-15-15zM195 51.213L293.787 150 207 236.787 108.213 138 195 51.213z"/></svg>
              </f7-link>
              <f7-link :tooltip="'tactic.area_drawing' | translate" class="tool" @click="changeRectangle()" :class="{active: activeTool == 'rectangle'}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384"><path d="M341.333 85.333H42.667C19.093 85.333 0 104.427 0 128v128c0 23.573 19.093 42.667 42.667 42.667h298.667C364.907 298.667 384 279.573 384 256V128c0-23.573-19.093-42.667-42.667-42.667zm0 170.667H42.667V128h298.667v128z"/></svg>
              </f7-link>
            </div>
          </f7-block>
        </div>
  </f7-page>
</template>
<script>
import html2canvas from 'html2canvas';
import Navbartacticboard from "@/components/Navbar/Navbartacticboard.vue";

export default {
  components: {
    Navbartacticboard,
  },
  data: () => {
    return {
      margin: 135,
      colors: [
          '#000000', '#2b4bff', '#a30000', '#009623', 
      ],
      players: [
          '#000000', '#2b4bff', '#a30000', '#009623'
      ],
      goalkeepers: [
          '#000000', '#2b4bff', '#a30000', '#009623'
      ],
      draw: true,
      type: 'draw',
      sizeCircle: 14,
      isDrawing: false,
      isEdit: false,
      mouseX: 0,
      mouseY: 0,
      mouseXup: 0,
      mouseYup: 0,
      maxWidth: 320,
      history: [],
      historyStep: -1,
      actualColorPlayer: '',
      activeTool: 'color#000000',
      activeField: 2,
    }
  },
  computed: {
       currentMouse() {
        let c = document.getElementById('paint-canvas');
        let rect = c.getBoundingClientRect();

        return {
            x: this.mouseX - rect.left,
            y: this.mouseY - rect.top
        }
      },
      currentMouseUp() {
        let c = document.getElementById('paint-canvas');
        let rect = c.getBoundingClientRect();

        return {
            x: this.mouseXup - rect.left,
            y: this.mouseYup - rect.top
        }
      }
  },
  mounted() {
    document.head.insertAdjacentHTML("beforeend", `<style>html, body { overscroll-behavior-y: contain }</style>`);
    let context = this.$refs.canvas.getContext('2d');
    let widthVieport = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    let heightVieport = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    this.maxWidth = widthVieport - 20;
    this.loadImage().then((background) => {
        let ratio = background.height / background.width;
        this.$refs.canvas.width  = (heightVieport - this.margin) / ratio
        this.$refs.canvas.height = heightVieport - this.margin;
        context.drawImage(background, 0, 0, (heightVieport - this.margin) / ratio, heightVieport - this.margin);
        context.strokeStyle = 'black';
        context.lineWidth = 3;
    })
  },
  methods: {
      download() {
        html2canvas(document.getElementById('paint-canvas'), { allowTaint: true, scale: 1}).then(canvas => {
          const a = document.createElement('a');
          a.href = canvas.toDataURL("image/png");
          a.classList.add('external');
          a.download = 'app.soccer-sessions.com_tactic_board.png';
          a.click();
        });
      },
      togglePanel(show) {
        this.isEdit = show;
      },
      loadImage() {
        return new Promise((resolve, reject) => {
            let background = new Image();
            background.onload = () => {
                resolve(background)
            }
            background.src = 'static/skeleton-soccer-blank.svg';
        });
      },
      changeEraser() {
        let c = document.getElementById('paint-canvas');
        let ctx = c.getContext('2d');
        this.draw = true;
        this.type = 'eraser';
        ctx.globalCompositeOperation = "destination-out";
        ctx.strokeStyle = "rgba(255,255,255,1)";
        ctx.lineWidth = 22;
        this.isEdit = false
        this.activeTool = 'eraser';
      },
      changeColor(color) {
        let c = document.getElementById('paint-canvas');
        let ctx = c.getContext('2d');
        this.draw = true;
        this.type = 'draw';
        ctx.globalCompositeOperation = "source-over";
        ctx.strokeStyle = color;
        ctx.lineWidth = 3;
        this.isEdit = false
        this.activeTool = `color${color}`
      },
      changePlayerCircle(player) {
        let c = document.getElementById('paint-canvas');
        let ctx = c.getContext('2d');
        this.draw = false;
        this.type = 'playerCircle';
        ctx.globalCompositeOperation = "source-over";
        ctx.fillStyle = player;
        this.actualColorPlayer = player;
        this.isEdit = false
        this.activeTool = `playerCircle${player}`
      },
      changePlayerCross(player) {
        let c = document.getElementById('paint-canvas');
        let ctx = c.getContext('2d');
        this.draw = false;
        this.type = 'playerCross';
        ctx.globalCompositeOperation = "source-over";
        ctx.fillStyle = player;
        this.actualColorPlayer = player;
        this.isEdit = false;
        this.activeTool = `playerCross${player}`
      },
      changeGoalkeeper(goalkeeper) {
        let c = document.getElementById('paint-canvas');
        let ctx = c.getContext('2d');
        this.draw = false;
        this.type = 'goalkeeper';
        ctx.globalCompositeOperation = "source-over";
        ctx.fillStyle = goalkeeper;
        this.actualColorPlayer = goalkeeper;
        this.isEdit = false;
        this.activeTool = `goalkeeper${goalkeeper}`
      },
      changeTrainer() {
        let c = document.getElementById('paint-canvas');
        let ctx = c.getContext('2d');
        this.draw = false;
        this.type = 'trainer';
        ctx.globalCompositeOperation = "source-over";
        ctx.fillStyle = '#f0da16';
        this.actualColorPlayer = '#f0da16';
        this.isEdit = false;
        this.activeTool = 'trainer#f0da16'
      },
      changeSoccerBall() {
        let c = document.getElementById('paint-canvas');
        let ctx = c.getContext('2d');
        this.draw = false;
        this.type = 'soccerBall';
        ctx.globalCompositeOperation = "source-over";
        this.isEdit = false;
        this.activeTool = 'soccerBall'
      },
      changeRectangle() {
        let c = document.getElementById('paint-canvas');
        let ctx = c.getContext('2d');
        this.draw = false;
        this.type = 'rectangle';
        ctx.globalCompositeOperation = "source-over";
        this.isEdit = false;
        this.activeTool = 'rectangle'
      },
       addPlayer(event) {
        let c = document.getElementById('paint-canvas');
        let ctx = c.getContext('2d');
        if (this.type === 'playerCircle') {
            ctx.beginPath();
            ctx.globalAlpha = 1;
            ctx.fillStyle = this.actualColorPlayer;
            ctx.arc(this.currentMouse.x, this.currentMouse.y, this.sizeCircle, 0, Math.PI * 2, true);
            ctx.fill();
        }
        if (this.type === 'goalkeeper') {
            ctx.beginPath();
            ctx.globalAlpha = 1;
            ctx.fillStyle = this.actualColorPlayer;
            ctx.arc(this.currentMouse.x, this.currentMouse.y, this.sizeCircle, 0, Math.PI * 2, true);
            ctx.fill();
            ctx.beginPath();
            ctx.font = '13px sans-serif';
            ctx.fillStyle = "white";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText('BR', this.currentMouse.x, this.currentMouse.y);
            ctx.stroke();
        }
        if (this.type === 'trainer') {
            ctx.beginPath();
            ctx.globalAlpha = 1;
            ctx.fillStyle = this.actualColorPlayer;
            ctx.rect(this.currentMouse.x - 14, this.currentMouse.y - 14, 28, 28, true);
            ctx.fill();
            ctx.beginPath();
            ctx.font = '13px sans-serif';
            ctx.fillStyle = "white";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText('T', this.currentMouse.x, this.currentMouse.y);
            ctx.stroke();
        }
        if (this.type === 'playerCross') {
            let line = 9;
            ctx.globalAlpha = 1;
            ctx.beginPath();
            ctx.lineWidth = 3;
            ctx.strokeStyle = this.actualColorPlayer;
            ctx.moveTo((this.currentMouse.x + line), (this.currentMouse.y - line));
            ctx.lineTo((this.currentMouse.x - line), (this.currentMouse.y + line));
            ctx.stroke();
            ctx.beginPath();
            ctx.lineWidth = 3;
            ctx.strokeStyle = this.actualColorPlayer;
            ctx.moveTo((this.currentMouse.x + line), (this.currentMouse.y + line));
            ctx.lineTo((this.currentMouse.x - line), (this.currentMouse.y - line));
            ctx.stroke();
        }
        if (this.type === 'soccerBall') {
            const img = document.getElementById("soccerBall");
            ctx.beginPath();
            ctx.fillStyle = '#ffffff';
            ctx.globalAlpha = 1;
            ctx.arc(this.currentMouse.x, this.currentMouse.y, 14, 0, Math.PI * 2, true);
            ctx.fill();
            ctx.drawImage(img, this.currentMouse.x - 14, this.currentMouse.y - 14);
        }
        if (this.type === 'rectangle') {
          this.drawRectangle();
        }
      },
      drawRectangle() {
        let c = document.getElementById('paint-canvas');
        let ctx = c.getContext('2d');
        const w = this.currentMouse.x - this.currentMouseUp.x;
        const h = this.currentMouse.y - this.currentMouseUp.y;
        const offsetX = (w < 0) ? w : 0;
        const offsetY = (h < 0) ? h : 0;
        const width = Math.abs(w);
        const height = Math.abs(h);

        ctx.beginPath();
        ctx.globalAlpha = 0.5;
        ctx.rect(this.currentMouseUp.x + offsetX, this.currentMouseUp.y + offsetY, width, height);
        ctx.fillStyle = '#e2eb13';
        ctx.fill();
        ctx.lineWidth = 3;
        ctx.strokeStyle = '#333600';
        ctx.stroke();
      },
      handleMouseDown(event) {
        let c = document.getElementById('paint-canvas');
        let ctx = c.getContext('2d');
        this.setMouseCoordinatesDesktop(event);
        if (this.draw) {
            this.isDrawing = true;
        }
        if (this.type == 'draw' || this.type == 'eraser') {
          ctx.beginPath();
          ctx.moveTo(this.currentMouse.x, this.currentMouse.y);
        }
      },
      handleTouchDown(event) {
        let c = document.getElementById('paint-canvas');
        let ctx = c.getContext('2d');
        this.setMouseCoordinatesMobile(event);
        if (this.draw) {
            this.isDrawing = true;
        }
        if (this.type == 'draw' || this.type == 'eraser') {
          ctx.beginPath();
          ctx.moveTo(this.currentMouse.x, this.currentMouse.y);
        }
      },
      handleMouseMove(event) {
        if (this.isDrawing){
            let c = document.getElementById('paint-canvas');
            let ctx = c.getContext('2d');
            this.setMouseCoordinatesDesktop(event);
            if (this.type == 'draw' || this.type == 'eraser') {
              ctx.lineTo(this.currentMouse.x, this.currentMouse.y);
              ctx.stroke();
            }
            // if (this.type == 'rectangle') {
            //   this.drawRectangle();
            // }
        }
      },
      handleTouchMove(event) {
        if (this.isDrawing){
            let c = document.getElementById('paint-canvas');
            let ctx = c.getContext('2d');
            this.setMouseCoordinatesMobile(event);
            if (this.type == 'draw' || this.type == 'eraser') {
              ctx.lineTo(this.currentMouse.x, this.currentMouse.y);
              ctx.stroke();
            }
            // if (this.type == 'rectangle') {
            //   this.drawRectangle();
            // }
        }
      },
      handleMouseUp(event) {
        this.isDrawing = false;
        this.setMouseCoordinatesDesktopUp(event);
      },
      handleTouchUp(event) {
        this.isDrawing = false;
        this.setMouseCoordinatesMobileUp(event);
      },
      setMouseCoordinatesDesktop(event) {
        this.mouseX = event.clientX;
        this.mouseY = event.clientY;
      },
      setMouseCoordinatesDesktopUp(event) {
        this.mouseXup = event.clientX;
        this.mouseYup = event.clientY;
      },
      setMouseCoordinatesMobile(event) {
        this.mouseX = event.changedTouches[0].pageX;
        this.mouseY = event.changedTouches[0].pageY;
      },
      setMouseCoordinatesMobileUp(event) {
        this.mouseXup = event.changedTouches[0].pageX;
        this.mouseYup = event.changedTouches[0].pageY;
      },
      clear() {
          let c = document.getElementById('paint-canvas');
          let context = this.$refs.canvas.getContext('2d');
          let widthVieport = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
          let heightVieport = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
          this.maxWidth = widthVieport - 20;
          this.loadImage().then((background) => {
            let ratio = background.height / background.width;
            this.$refs.canvas.width  = (heightVieport - this.margin) / ratio
            this.$refs.canvas.height = heightVieport - this.margin;
            context.drawImage(background, 0, 0, (heightVieport - this.margin) / ratio, heightVieport - this.margin);
            context.strokeStyle = 'black';
            context.lineWidth = 3;
        })
      },
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/pages/tacticboard.scss";
</style>
