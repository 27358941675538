<template>
  <f7-page login-screen class="pagePrivateOutlines">
    <navbardatabase></navbardatabase>

    <loader v-if="loader"></loader>

    <div v-else>
        <f7-block class="text-uppercase">
          <h4>{{ 'base.private_exercises' | translate}}</h4>
          <div class="d-flex justify-content-start align-items-center">
            <h4 class="mt-0 mb-0" style="margin-right: 5px;">{{ 'base.category' | translate}}: </h4>
            <div class="custom-select">
                  <select name="category" v-model="actualCategory">
                    <option value="">{{ 'global.all' | translate}}</option>
                      <option v-for="category in categories" :key="category.id" :value="category.id" :selected="category.id == actualCategory">
                          {{category.title}}
                      </option>
                  </select>
              </div>
          </div>
          <div>
              <f7-input
                :label="'global.search' | translate"
                type="text"
                :placeholder="'global.search' | translate"
                :value="search"
                @input="search = $event.target.value"
                clear-button
                style="margin: 10px 0px 0px 0px ; padding: 10px 0px"
              ></f7-input>
          </div>
          <div class="countAll">
            <span>
              <p>{{ 'base.count' | translate}}</p>
              {{privateExercises.count}}
            </span>
          </div>
        </f7-block>

        <loader v-if="loaderList"></loader>

        <div v-else>
          <div class="listCards" v-if="privateExercises.count > 0">
            <f7-block class="mt-0">
              <f7-row>
                <f7-col v-for="item in privateExercises.results" :key="item.id" width="100" class="position-relative">
                  <div class="delete" @click.stop="showDialogDeletePrivateExercise(item)">
                    <img height="20px" src="/static/icons/trash.svg" alt />
                  </div>
                  <f7-link v-if="item.category" :tooltip="`Group color: ${getCategoryExerciseColor(item.category)}`" class="color" :style="{background : getCategoryExerciseHex(item.category)}"></f7-link>
                  <f7-link v-if="!isSubscription && item.type_copied == 2" :tooltip="'global.type_copied_2_exercise' | translate" class="copied"><img height="20px" src="/static/icons/lock.svg" alt /></f7-link>
                  <f7-link v-else-if="!isSubscription && item.type_copied == 1" :tooltip="'global.type_copied_1' | translate" class="copied"><img height="20px" src="/static/icons/unlocked.svg" alt /></f7-link>
                  <f7-link v-else-if="item.type_copied == 1 || item.type_copied == 2" :tooltip="'global.type_copied' | translate" class="copied"><img height="20px" src="/static/icons/copied.svg" alt /></f7-link>
                  <a :href="!isSubscription && item.type_copied == 2 ? `/no-access-private/` : `/private-exercises/${item.id}/`">
                  <f7-card class="custom-card" :style="!isSubscription && item.type_copied == 2 ? 'opacity: 0.2' : 'opacity: 1'">
                    <div>
                      <p>{{item.subject}}</p>
                      <p v-if="item.category" class="info">{{getCategoryExercise(item.category).title}}</p>
                    </div>
                  </f7-card>
                  </a>
                </f7-col>
              </f7-row>
            </f7-block>
          </div>


        <div v-else class="text-center text-uppercase">
            <img height="100px" src="/static/icons/football-ball-2.svg" alt />
            <h4>{{ 'base.empty_exercises' | translate}}!</h4>
          </div>
          
        <f7-block>
          <div class="d-flex justify-content-between aling-items-center custom-pagination-view">
            <div class="pagination-item">
              <div v-if="privateExercises.previous" @click="fetchPrivateExercises(actualCategory, search, privateExercises.previous)">
                <img class="cursor-pointer" height="30px" src="/static/icons/previous.svg" alt />
              </div>
            </div>
            <div class="pagination-item">
              <a href="/create-exercise/">
                <img class="cursor-pointer" height="30px" src="/static/icons/plus.svg" alt="">
              </a>
            </div>
            <div class="pagination-item">
              <div v-if="privateExercises.next" @click="fetchPrivateExercises(actualCategory, search, privateExercises.next)">
                <img class="cursor-pointer" height="30px" src="/static/icons/next.svg" alt />
              </div>
            </div>
          </div>
        </f7-block>
        </div>

    </div>

    <div class="custom-fab">
        <div class="box box-zoom cursor-pointer" @click="goToCreate()">
            <f7-link :tooltip="'global.add_new' | translate" >
              <img height="20px" src="/static/icons/plus-add.svg" alt="">
            </f7-link>
        </div>
      </div>

  </f7-page>
</template>

<script>
import Navbardatabase from "@/components/Navbar/Navbardatabase.vue";
import Loader from "@/components/Loader";
import privateExercisesServices from "@/services/PrivateExercisesServices.js"
import privateOutlinesServices from "@/services/PrivateOutlinesServices.js"
import CONSTANTS from "@/utils/constants.js"
import mixinUser from "@/mixins/mixinUser"
import debounce from 'lodash.debounce'

export default {
  mixins: [mixinUser],
  components: {
    Navbardatabase,
    Loader,
  },
  data: () => {
    return {
      loader: false,
      loaderList: false,
      privateExercises: {},
      ageCategories: CONSTANTS.AGE_CATEGORIES,
      paleteColorsDot: CONSTANTS.COLORS_DOT,
      actualCategory: '',
      categories: [],
      search: ''
    };
  },
  watch: {
    'actualCategory': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchPrivateExercises(this.actualCategory, this.search)
      }
    },
    'search': debounce(function () {
        this.fetchPrivateExercises(this.actualCategory, this.search)
    }, 1000),
  },
  mounted() {
    this.fetchDataUser().then(() => {
      this.fetchCategories().then(() => {
        this.fetchInitPrivateExercises(this.actualCategory, this.search)
      })
    })
  },
  methods: {
    goToCreate() {
      this.$f7router.navigate(`/create-exercise/`)
    },
    getCategoryExercise(categoryId) {
      if (!categoryId) return
      return this.categories.find((elem) => elem.id == categoryId)
    },
    getCategoryExerciseColor(categoryId) {
      if (!categoryId) return
      const category = this.categories.find((elem) => elem.id == categoryId)
      if (!category.color) return
      return this.paleteColorsDot[category.color].label
    },
    getCategoryExerciseHex(categoryId) {
      if (!categoryId) return
      const category = this.categories.find((elem) => elem.id == categoryId)
      if (!category.color) return
      return this.paleteColorsDot[category.color].value
    },
    async fetchCategories() {
        try {
          this.loader = true;
  
          const responseDataCategories = await privateExercisesServices.listAllCategories();
          this.categories = responseDataCategories.data
        } catch (error) {
          this.$f7.toast
            .create({
              text: "Something went wrong with the list of private practice categories. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
        this.loader = false;
      },
      fetchInitPrivateExercises(category, search) {
        this.loader = true;
        this.fetchPrivateExercises(category, search)
        this.loader = false;
      },
    async fetchPrivateExercises(category, search, page) {
      this.loaderList = true;
      try {
        const responseDataPrivateExercises = await privateExercisesServices.listPrivateExercises(category, search, page);
        this.privateExercises = responseDataPrivateExercises.data
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the private exercise list. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderList = false;
    },
    showDialogDeletePrivateExercise(item) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the exercise ${item.subject}? The change will be irreversible!`, '', () => {
          if (item.images.data.length > 0) {
            item.images.data.forEach((image) => {
              this.deleteExerciseGraphics(image)
            })
          }
          this.deletePrivateExercise(item.id)
        });
    },
    async deleteExerciseGraphics(id) {
      try {
        const responseDeleteExerciseGraphics = await privateOutlinesServices.deleteExerciseGraphics(id);
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the removal of the associated exercise graphics. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
    async deletePrivateExercise(id) {
      try {
        const responseDeletePrivateExercise = await privateExercisesServices.deletePrivateExercise(id);
        this.fetchPrivateExercises(this.actualCategory, this.search);
        this.$f7.toast
          .create({
            text: "The exercise has been deleted!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with deleting the exercise. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  }
};
</script>