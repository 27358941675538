<template>
  <f7-navbar back-link="Back">
    <f7-nav-title>
        <f7-link href="/" style="padding-left: 0px">
          <img class="logo" src="/static/logo-soccer-sessions-white.svg" style="max-height: 20px;" />
        </f7-link>
    </f7-nav-title>
    <f7-nav-right>
        <f7-link href="/private-outlines/">
          <img height="20px" style="margin-left: 0px" src="/static/icons/list.svg" alt />
        </f7-link>
        <f7-link href="/settings-outlines/">
          <img height="20px" style="margin-left: 0px" src="/static/icons/settings.svg" alt />
        </f7-link>
        <f7-link v-if="hide" @click="toggleShow()">
          <img v-if="!show" height="20px" style="margin-left: 0px" src="/static/icons/eye.svg" alt />
          <img v-else height="20px" style="margin-left: 0px" src="/static/icons/close.svg" alt />
        </f7-link>
        <f7-link panel-open="left"><img height="20px" src="/static/icons/menu.svg" alt style="margin-left: 0px;"></f7-link>
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import onbackbutton from '@/mixins/onBackButton.js';

export default {
  mixins: [onbackbutton],
  name: 'navbarcreateoutline',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    hide: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    toggleShow() {
      if (this.show) {
        this.$emit('on-show', false);
      } else {
        this.$emit('on-show', true);
      }
    },
  }
}
</script>