<template>
  <f7-page login-screen>
    <navbar></navbar>
    <div v-if="step == 'forgot'">
      <f7-block class="text-center">
        <img style="height: 150px;" src="/static/icons/first-aid-kit.svg" alt />
      </f7-block>
      <f7-block class="text-center text-uppercase ">
        <h4>{{ 'auth.forgot_page' | translate}}</h4>
      </f7-block>
      <f7-list form @submit.prevent="onRemindPassword">
        <f7-list-input
          :label="'auth.email' | translate"
          type="email"
          :placeholder="'auth.email' | translate"
          :value="account.email"
          @input="account.email = $event.target.value"
          required
          autocomplete="off"
          validate
          :error-message="'global.required' | translate"
        ></f7-list-input>
        <f7-block class="text-center">
          <f7-button v-if="!account.loaderForgot" type="submit" raised>{{ 'auth.reset_password' | translate}}</f7-button>
          <f7-button v-else raised>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>
    </div>
    <div v-if="step == 'info'">
      <f7-block class="text-center">
        <img style="height: 150px;" src="/static/icons/first-aid-kit.svg" alt />
      </f7-block>
      <f7-block class="text-center text-uppercase ">
        <h4>{{'auth.reminder_password' | translate }}</h4>
      </f7-block>
      <f7-block class="text-center">
        <span>{{'auth.reminder_password_description' | translate }}</span>
      </f7-block>
    </div>
    <div v-if="step == 'verification'">
      <loader v-if="verification.loader"></loader>
    </div>
    <div v-if="step == 'reset'">
      <f7-block class="text-center">
        <img style="height: 150px;" src="/static/icons/whistle.svg" alt />
      </f7-block>
      <f7-block class="text-center text-uppercase ">
        <h4>{{  'auth.set_new_password' | translate}}</h4>
      </f7-block>
      <f7-list form @submit.prevent="onResetPassword">
        <f7-list-input
          raised
          floating-label
          :label="'auth.new_password' | translate"
          type="password"
          :placeholder="'auth.new_password' | translate"
          :value="account.passwordNew"
          @input="account.passwordNew = $event.target.value"
          required
          validate
          :error-message="'global.required' | translate"
        ></f7-list-input>
        <f7-list-input
          raised
          floating-label
          :label="'auth.confirm_password' | translate"
          type="password"
          :placeholder="'auth.confirm_password' | translate"
          :value="account.passwordNewConfirm"
          @input="account.passwordNewConfirm = $event.target.value"
          required
          validate
          :error-message="'global.required' | translate"
        ></f7-list-input>
        <f7-block class="text-center">
          <f7-button v-if="!account.loaderReset" type="submit" raised>{{ 'global.save' | translate}}</f7-button>
          <f7-button v-else raised>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>
    </div>
  </f7-page>
</template>
<script>
import authServices from "@/services/AuthServices.js";
import Loader from "@/components/Loader";
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Loader,
    Navbar,
  },
  data: () => {
    return {
      step: "forgot",
      account: {
        email: null,
        loaderForgot: false,
        loaderReset: false,
        passwordNew: "",
        passwordNewConfirm: ""
      },
      verification: {
        token: null,
        email: '',
        loader: true
      }
    };
  },
  computed: {
    isConfirmNewPassowrd() {
      return this.account.passwordNew === this.account.passwordNewConfirm;
    }
  },
  mounted() {
    if (this.$f7route.query.token !== undefined && this.$f7route.query.email !== undefined) {
      window.history.replaceState(null, null, window.location.pathname);
      this.step = 'verification'
      this.verification.token = this.$f7route.query.token.split('?')[0]
      this.verification.email = this.$f7route.query.email
      this.onVerification(this.verification.token, this.verification.email)
    }
  },
  methods: {
    async onRemindPassword() {
      this.account.loaderForgot = true;
      try {
        const responseForgotPassword = await authServices.requestResetPassword(this.account.email);
        if (responseForgotPassword.status == 200) {
          this.step = 'info'
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.step = 'info'
        } else {
          this.$f7.toast
            .create({
              text:
                "Something went wrong. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
      }
      this.account.loaderForgot = false;
    },
    async onVerification(token, email) {
      this.verification.loader = true;
      try {
        const credentialCode = {
          email: this.account.email,
          token: this.verification.token
        };

        const responseVerification = await authServices.validateToken(token, email);
        if (responseVerification.status == 200) {
          this.step = "reset";
        }
      } catch (error) {
          this.$f7.toast
            .create({
              text:
                "Something went wrong. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
      this.verification.loader = false;
    },
    async onResetPassword() {
      if (!this.isConfirmNewPassowrd) {
        this.$f7.toast
          .create({
            text: "The passwords are not identical",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        return false;
      }

      this.account.loaderReset = true;
      try {
        const responseResetPassword = await authServices.confirmResetPassword(this.verification.token, this.account.passwordNew);
        if (responseResetPassword.status == 200) {
          this.$f7router.navigate("/forgot-password-success/");
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.$f7.toast
            .create({
              text: "Password is too short or popular. Must contain at least 8 characters.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();  
        } else {
          this.$f7.toast
            .create({
              text:
                "Something went wrong. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.account.loaderReset = false;
    }
  }
};
</script>