<template>
  <f7-page login-screen>
    <navbardatabase></navbardatabase>

    <loader v-if="loader"></loader>

    <div v-else>

        <f7-block class="text-uppercase">
          <h4>{{ 'base.category_outlines' | translate}}</h4>
          <div class="countAll">
            <span>
              <p>{{ 'base.count' | translate}}</p>
              {{categories.count}}
            </span>
          </div>
        </f7-block>
        <div class="listCards" v-if="categories.count > 0">
          <f7-block>
            <f7-row>
              <f7-col v-for="category in categories.results" :key="category.id" width="100" class="position-relative">
                <div class="delete" @click.stop="showDialogDeleteCategory(category)">
                  <img height="20px" src="/static/icons/trash.svg" alt />
                </div>
                <a :href="`/private-outlines-categories/${category.id}/`">
                <f7-card class="custom-card">
                  <div>
                    <p>{{category.title}}</p>
                    <f7-link v-if="category.color" :tooltip="`Group color: ${paleteColorsDot[category.color].label}`" class="color" :style="{background : paleteColorsDot[category.color].value}"></f7-link>
                  </div>
                </f7-card>
                </a>
              </f7-col>
            </f7-row>
          </f7-block>
        </div>
        
        <div v-else class="text-center text-uppercase">
            <img height="100px" src="/static/icons/category.svg" alt />
            <h4>{{ 'base.no_category' | translate}}</h4>
        </div>

        <f7-block>
          <div class="d-flex justify-content-between aling-items-center custom-pagination-view">
            <div class="pagination-item">
              <div v-if="categories.previous" @click="fetchCategories(categories.previous)">
                <img class="cursor-pointer" height="30px" src="/static/icons/previous.svg" alt />
              </div>
            </div>
            <div class="pagination-item">
              <a :href="'/private-outlines-create-category/'">
                <img class="cursor-pointer" height="30px" src="/static/icons/plus.svg" alt="">
              </a>
            </div>
            <div class="pagination-item">
              <div v-if="categories.next" @click="fetchCategories(categories.next)">
                <img class="cursor-pointer" height="30px" src="/static/icons/next.svg" alt />
              </div>
            </div>
          </div>
        </f7-block>
    </div>

    <div class="custom-fab">
        <div class="box box-zoom cursor-pointer" @click="goToCreate()">
            <f7-link :tooltip="'global.add_new' | translate" >
              <img height="20px" src="/static/icons/plus-add.svg" alt="">
            </f7-link>
        </div>
      </div>

  </f7-page>
</template>

<script>
import privateOutlinesServices from "@/services/PrivateOutlinesServices.js"
import Navbardatabase from "@/components/Navbar/Navbardatabase";
import Loader from "@/components/Loader";
import mixinUser from "@/mixins/mixinUser"
import CONSTANTS from "@/utils/constants.js"

export default {
  mixins: [mixinUser],
  components: {
    Navbardatabase,
    Loader,
  },
  data: () => {
    return {
      loader: false,
      paleteColorsDot: CONSTANTS.COLORS_DOT,
      categories: []
    };
  },
  mounted() {
    this.fetchDataUser().then(() => {
      this.fetchCategories()
    })
  },
  methods: {
    goToCreate() {
      this.$f7router.navigate(`/private-outlines-create-category/`)
    },
    async fetchCategories(page) {
        try {
          this.loader = true;
  
          const responseDataCategories = await privateOutlinesServices.listCategories(page);
          this.categories = responseDataCategories.data
        } catch (error) {
          this.$f7.toast
            .create({
              text: "Something went wrong with the list of private outline categories. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
        this.loader = false;
      },
    showDialogDeleteCategory(category) {
        this.$f7.dialog.confirm(`Are you sure you want to delete the category ${category.title}? The change will be irreversible!`, '', () => {
          this.deleteCategory(category.id)
        });
    },
    async deleteCategory(id) {
      try {
        const responseDeleteCategory = await privateOutlinesServices.deleteCategory(id);
        this.fetchCategories();
        this.$f7.toast
          .create({
            text: "The category has been removed!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the deletion of the private theme category. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
  }
};
</script>