export default {
  SELECT_BOOKS_PARTNERS: [
    { id: 0, text: 'Konspekty Piłka Nożna' },
    { id: 1, text: 'Asystent Trenera' },
    { id: 2, text: 'Pozytywny Coaching' },
    { id: 3, text: 'Folder Środków Treningowych' },
    { id: 4, text: 'Better Way' },
    { id: 5, text: 'Only Futbol' },
    { id: 6, text: 'Adler Coach' },
    { id: 7, text: 'Juniorski Mecz' },
    { id: 8, text: 'Strefa Futbolu' },
    { id: 9, text: 'Polonia Falcons' },
    { id: 10, text: 'Piłkarski Trening' },
    { id: 11, text: 'Piłka Nożna Dla Dzieci' },
    { id: 12, text: 'PsychoSportica' },
    { id: 13, text: 'Emsport' },
    { id: 14, text: 'Football Coaching Lab' },
    { id: 15, text: 'Football Mind' },
    { id: 16, text: 'Football Level Up' },
    { id: 17, text: 'Soccer Sessions' },
    { id: 18, text: 'Keepers Foundation' },
    { id: 19, text: 'Football Insight' },
  ],
  SELECT_BOOKS_CATEGORIES: [
    { id: 0, text: 'books.U6U7' },
    { id: 1, text: 'books.U8U9' },
    { id: 2, text: 'books.U10U11' },
    { id: 3, text: 'books.U12U13' },
    { id: 4, text: 'books.U14U15' },
    { id: 5, text: 'books.U16U17' },
    { id: 6, text: 'books.U18U19' },
    { id: 7, text: 'books.S' },
    { id: 8, text: 'books.game' },
    { id: 9, text: 'books.motor' },
    { id: 10, text: 'books.futsal' },
    { id: 11, text: 'books.intelligence' },
    { id: 12, text: 'books.a_collection_of_outlines' },
    { id: 13, text: 'books.other' },
    { id: 14, text: 'books.player' },
    { id: 15, text: 'books.individual' },
  ],
  AGE_CATEGORIES: ['books.U6U7', 'books.U8U9', 'books.U10U11', 'books.U12U13', 'books.U14U15', 'books.U16U17', 'books.U18U19', 'books.S'],
  MONTHS: ['', 'global.january', 'global.february', 'global.march', 'global.april', 'global.may', 'global.june', 'global.jule', 'global.august', 'global.september', 'global.october', 'global.november', 'global.december'],
  COLORS_DOT: [
    {
      label: 'Red',
      value: '#F59698',
    },
    {
      label: 'Purple',
      value: '#CDB3D5',
    },
    {
      label: 'Navy',
      value: '#6899AB',
    },
    {
      label: 'Blue',
      value: '#8ABADF',
    },
    {
      label: 'Light blue',
      value: '#A7C7E9',
    },
    {
      label: 'Green',
      value: '#88BBAA',
    },
    {
      label: 'Light green',
      value: '#BCDCAD',
    },
    {
      label: 'Yellow',
      value: '#FAF18D',
    },
    {
      label: 'Orange',
      value: '#FEBD7C',
    },
    {
      label: 'Pink',
      value: '#FBDEDF',
    },
    {
      label: 'Brown',
      value: '#CCAC8F',
    },
    {
      label: 'Grey',
      value: '#EDF2F7',
    },
  ],
  COLORS_HIGHLIGHT: [
    {
      label: 'Red',
      value: '#F59698',
      text: 'red'
    },
    {
      label: 'Blue',
      value: '#8ABADF',
      text: 'blue'
    },
    {
      label: 'Green',
      value: '#88BBAA',
      text: 'green'
    },
    {
      label: 'Yellow',
      value: '#FAF18D',
      text: 'yellow'
    },
    {
      label: 'Orange',
      value: '#FEBD7C',
      text: 'orange'
    },
    {
      label: 'Pink',
      value: '#FBDEDF',
      text: 'pink'
    },
    {
      label: 'Grey',
      value: '#EDF2F7',
      text: 'gray'
    },
  ],
  PLANS_LIST: [
    // 'Własna nazwa mikrocyklu',
    // 'Gra 1x1',
    // 'Podanie i przyjęcie + strzały',
    // 'Podanie i przyjęcie w grach',
    // 'Rozwijanie inteligencji',
    // 'Faza atakowania',
    // 'Faza bronienia',
    // 'Faza przejścia z obrony do ataku',
    // 'Faza przejścia z ataku do obrony',
    // 'Małe gry',
    // 'Atakowanie: otwarcie',
    // 'Atakowanie: budowanie',
    // 'Atakowanie: zakończenie',
    // 'Bronienie: indywidualne',
    // 'Bronienie: grupowe'
    'Own microcycle name',
    '1x1 game',
    'Pass and Reception + Shots',
    'Application and reception in games',
    'Developing intelligence',
    'Attack Phase',
    'Defending Phase',
    'Transition phase from defense to attack',
    'Transition phase from attack to defense',
    'Small Games',
    'Attacking: opening',
    'Attacking: building',
    'Attacking: ending',
    'Defending: individual',
    'Defend: group'
  ],
  CURRENCY: [
    'PLN',
    'USD',
    'EUR'
  ],
  TYPE_BOOKS: [
    {
      id: 'session',
      label: 'outline.outlines'
    },
    {
      id: 'exercise',
      label: 'outline.exercises'
    },
  ],
  TYPE_STATS: [
    {
      id: 'attendance',
      label: 'club.list_attendance'
    },
    {
      id: 'dues',
      label: 'club.list_dues'
    },
  ],
  TYPE_BLOCK_GENERATOR: [
    {
      id: 'soccer',
      label: 'global.soccer'
    },
    {
      id: 'futsal',
      label: 'global.futsal'
    },
  ],
  STRIPE_PROMO : {
    price: 39,
    months: 1,
    link: 'https://buy.stripe.com/28obL94d3bOP1Bm28k'
  },
  STRIPE_REDIRECTS: [
    [{
      price: 59,
      months: 1,
      link: 'https://buy.stripe.com/28oeXl5h77yzgwgbII'
    },
    {
      price: 139,
      months: 3,
      link: 'https://buy.stripe.com/8wMeXlcJzg55gwgcMN'
    },
    {
      price: 249,
      months: 6,
      link: 'https://buy.stripe.com/28oaH5cJz6uv0xi5km'
    },
    {
      price: 399,
      months: 12,
      link: 'https://buy.stripe.com/cN2cPd5h7067fsc147'
    }],
    [{
      price: 14.99,
      months: 1,
      link: 'https://buy.stripe.com/6oEdTh24Vg5593O6os'
    },
    {
      price: 32.99,
      months: 3,
      link: 'https://buy.stripe.com/aEU3eD5h72ef2Fq7sx'
    },
    {
      price: 57.99,
      months: 6,
      link: 'https://buy.stripe.com/14k7uT9xn067eo8cMS'
    },
    {
      price: 93.99,
      months: 12,
      link: 'https://buy.stripe.com/dR616v24V6uv6VGcMT'
    }],
    [{
      price: 12.99,
      months: 1,
      link: 'https://buy.stripe.com/fZe6qP10Rg55dk414c'
    },
    {
      price: 29.99,
      months: 3,
      link: 'https://buy.stripe.com/00g4iH4d34mn4Ny14d'
    },
    {
      price: 54.99,
      months: 6,
      link: 'https://buy.stripe.com/aEU8yXfVLdWX1Bm9AK'
    },
    {
      price: 85.99,
      months: 12,
      link: 'https://buy.stripe.com/7sI8yX6lb6uva7S28j'
    }]
  ]

}
