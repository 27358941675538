<template>
  <f7-page class="pagePublic">
        <h2 class="text-center">{{calendar.group.name}}</h2>
        <div class="overflow-x-scroll">
            <calendar
                class="custom-calendar max-w-full"
                ref="calendar"
                is-expanded
                :attributes="attributes"
                @update:from-page="changeMonthPublic"
                >
                <template v-slot:day-content="{ day, attributes }">
                    <div class="d-flex flex-col h-100 z-10 hidden">
                    <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                    <div class="flex-grow overflow-y-auto overflow-x-auto">
                        <p
                        v-for="attr in attributes"
                        :key="attr.key"
                        class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                        :class="attr.customData.class"
                        >
                        {{ attr.customData.title }}
                        </p>
                    </div>
                    </div>
                </template>
            </calendar>
        </div>
        
  </f7-page>
</template>

<script>
import groupsServices from "@/services/GroupsServices.js"
import Calendar from 'v-calendar/lib/components/calendar.umd'
import CONSTANTS from "@/utils/constants.js"

export default {
  components: {
    Calendar,
  },
    data() {
        return {
            loader: false,
            attributes: [
                
            ],
            calendar: {
                group: {
                    name: ''
                },
            },
            paleteColorsDot: CONSTANTS.COLORS_DOT,
            group: '',
            public: '',
            actualYear: '',
            actualMonth: '',
        }
    },
    mounted() {
        if (this.$f7route.query.group !== undefined) {
            this.group = this.$f7route.query.group
            this.public = this.$f7route.query.public
            if (this.actualYear == '' && this.actualMonth == '') {
                const today = new Date();
                this.actualYear = today.getUTCFullYear()
                this.actualMonth = today.getUTCMonth() + 1
                this.fetchPublicGroupMonth(this.actualYear, this.actualMonth)
            }
        }
    },
    methods: {
        setClipboard(value) {
            const tempInput = document.createElement("input");
            tempInput.style = "position: absolute; left: -1000px; top: -1000px";
            tempInput.value = value;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
            this.$f7.toast.create({
                text: 'Code copied to clipboard',
                position: 'bottom',
                closeTimeout: 3000,
            }).open();
        },
        async fetchPublicGroupMonth(year, month) {
        try {
            const data = {
                year,
                month,
                group: this.group,
                public: this.public
            }
            const responseDataGroup = await groupsServices.itemPublicGroup(data);
            this.calendar = responseDataGroup.data
            this.calendar.trainings.forEach((training, index) => {
                let textDate = ''
                if (training.training_hours_start && training.training_hours_end) {
                    const hours_start = training.training_hours_start.split(/:/)
                    const hours_end = training.training_hours_end.split(/:/)
                    textDate = `${hours_start[0]}:${hours_start[1]} - ${hours_end[0]}:${hours_end[1]}`
                }
                let place = ''
                if (training.playing_field_object !== null) {
                    place = `- ${training.playing_field_object.name}`
                }
                
                const card = {
                    key: index,
                    customData: {
                        title: `${textDate} ${training.subject} ${place}`,
                        class: `bg-calendar bg-calendar-${this.calendar.group.color}`,
                    },
                    dates: new Date(training.training_date),
                }
                this.attributes.push(card)
            })
        } catch (error) {
            if (!error.response.data.access) {
                this.$f7router.navigate('/not-public-found/')
            } else {
            this.$f7.toast
                .create({
                text: "Something went wrong with the public group. Please try again or contact your administrator.",
                            position: "bottom",
            
                closeTimeout: 3000
                })
                .open();
            }
            console.log(error);
        }
        },
        changeMonthPublic(page) {
            if ((this.actualYear !== page.year || this.actualMonth !== page.month) && this.group ) {
                this.actualMonth = page.month;
                this.actualYear = page.year;
                this.fetchPublicGroupMonth(page.year, page.month)
            }
        }
    }
};
</script>
<style lang="scss">
@import "@/assets/scss/pages/calendarpublic.scss";
</style>