<template>
  <f7-page login-screen>
    <navbar></navbar>

    <f7-block class="text-center">
      <img style="height: 150px;" src="/static/icons/closed.svg" alt />
    </f7-block>
    <f7-block class="text-center text-uppercase ">
      <h4>{{ 'global.no_access_private' | translate}}!</h4>
    </f7-block>
    <f7-block class="text-center">{{ 'global.no_access_private_description' | translate}}</f7-block>
    <f7-block class="text-center">
      <f7-button href="/payments/" class="font-weight-bold" style="margin: 0 8px; color: white;" raised><img height="12px" src="/static/icons/shopping-cart.svg" alt />  {{ 'global.buy_subscriptions' | translate }}!</f7-button>
    </f7-block>

  </f7-page>
</template>
<script>
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>