import Api from './index'
import store from '@/store'

export default {
  async settingsTimetable() {
    return Api().get('timetable/', {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async createSettingsTimetable(data) {
    return Api().post(`timetable/`, data, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async settingTimetable(data) {
    return Api().put(`timetable/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async settingTimetable(data) {
    return Api().put(`timetable/${data.id}/`, data, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
}