<template>
  <f7-page login-screen>
    <navbarcalendar></navbarcalendar>

    <loader v-if="loader"></loader>

    <div v-else>
          <f7-block class="text-center text-uppercase">
            <h4>{{ 'club.new_attendance' | translate}} <f7-link class="p-1" :tooltip="'popover.new_attendance' | translate"><img height="11px" src="/static/icons/help.svg" alt /></f7-link></h4>
          </f7-block>
          <f7-list form no-hairlines-md @submit.prevent="createAttendance">
            <f7-list-item :title="'club.group' | translate" class="custom-select">
              <select name="group" v-model="attendance.group">
                <option v-if="groups.length == 0" value="" disabled>{{ 'club.lack_group' | translate }}</option>
                <option v-for="group in groups" :key="group.id" :value="group.id" :selected="group.id == attendance.group">
                    {{group.name}}
                </option>
              </select>
            </f7-list-item>
            <f7-list-item :title="'club.training' | translate" class="custom-select">
              <select name="training" v-model="attendance.training">
                <option v-for="training in trainings" :key="training.id" :value="training.id" :selected="training.id == attendance.training">
                    {{training.subject}} | {{training.training_date | formatDate}} | <span v-text="getTime(training)"></span>
                </option>
              </select>
            </f7-list-item>
            <f7-list-item v-if="players.length > 0" class="custom-item-inside-list-header">
              <h4 class="text-uppercase">{{ 'club.players_list' | translate }}:</h4>
            </f7-list-item>
            <f7-button v-else style="margin: 0px 16px;" href="/players/" raised>{{'club.need_add_player_to_group' | translate}}!</f7-button>
            <f7-list-item  class="custom-item-inside-list" v-for="player in players" :key="player.id">
              <span>
                <f7-link v-if="player.status_active" :tooltip="'club.status_active' | translate">
                  <div style="width: 10px; height: 10px; background-color: green; border-radius: 50%;"></div>
                </f7-link>
                <f7-link v-else :tooltip="'club.status_not_active' | translate">
                  <div style="width: 10px; height: 10px; background-color: red; border-radius: 50%;"></div>
                </f7-link>
                {{player.first_name}} {{player.last_name}}
                <template v-if="player.player_attendance !== null && getReported(player.player_attendance.data.reported)" class="ml-1">
                  <f7-link :tooltip="'club.reported_absence' | translate">!</f7-link>
                </template>
              </span>
            
              <input type="checkbox" class="custom-checkbox" v-model="player.presense">
            </f7-list-item>
            <f7-block class="text-center">
              <f7-button v-if="!loaderAttendance" type="submit" raised>
                  {{ 'club.check_attendance' | translate }}
              </f7-button>
              <f7-button v-else raised>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
            </f7-block>
          </f7-list>
    </div>

  </f7-page>
</template>

<script>
import Navbarcalendar from "@/components/Navbar/Navbarcalendar.vue";
import Loader from "@/components/Loader";
import attendanceListServices from "@/services/AttendanceListServices.js"
import groupsServices from "@/services/GroupsServices.js"
import trainingsServices from "@/services/TrainingsServices.js"
import mixinUser from "@/mixins/mixinUser"

export default {
  mixins: [mixinUser],
  components: {
    Navbarcalendar,
    Loader,
  },
  data: () => {
    return {
      loader: false,
      loaderAttendance: false,
      attendance: {
        group: '',
        training: '',
        was_presense: [],
      },
      groups: [],
      trainings: [],
      players: [],
    };
  },
  mounted() {
    this.fetchDataUser().then(() => {
      if (this.$f7route.query.group !== undefined && this.$f7route.query.training !== undefined && this.$f7route.query.day !== undefined) {
        this.attendance.group = this.$f7route.query.group
        this.attendance.training = this.$f7route.query.training
        this.fetchGroup(this.attendance.group)
        this.fetchTraining(this.attendance.training)
        this.fetchAllPlayers(this.attendance.group)
      } else {
        this.$f7router.navigate(`/home/`)
      }
    })
  },
  methods: {
    getTime(training) {
      if (training.training_hours_start && training.training_hours_end) {
        const hours_start = training.training_hours_start.split(/:/)
        const hours_end = training.training_hours_end.split(/:/)
        return `${hours_start[0]}:${hours_start[1]} - ${hours_end[0]}:${hours_end[1]}`
      } else {
        ''
      }
    },
    getReported(list) {
      const [year, month, day] = this.$f7route.query.day.split('-')
      const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      const date = new Date(formattedDate)
      return list.includes(date.toISOString().slice(0, 10))
    },
    async fetchGroup(id) {
      try {
        this.loader = true;

        const responseDataGroup = await groupsServices.itemGroup(id);
        this.groups.push(responseDataGroup.data)
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with the group. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    async fetchTraining(id) {
      try {
        this.loader = true;

        const responseDataTraining = await trainingsServices.itemTraining(id);
        this.trainings.push(responseDataTraining.data)
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with training. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    async fetchAllPlayers(group) {
      try {
        this.loader = true;

        const responseDataAllPlayers = await groupsServices.listAllPlayers(group);
        this.players = responseDataAllPlayers.data
        for (let player of this.players) {
            if (!player.status_active || (player.player_attendance !== null && this.getReported(player.player_attendance.data.reported))) {
                player.presense = false
            }
        }
        
      } catch (error) {
        if (!error.response.data.access) {
          this.$f7router.navigate('/not-found/')
        } else {
          this.$f7.toast
            .create({
              text: "Something went wrong with the list of players in the group. Please try again or contact your administrator.",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loader = false;
    },
    async createAttendance() {
      if (this.attendance.group == '') {
        return this.$f7.toast
          .create({
            text: "No group",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
      }
      if (this.players.length == 0) {
        return this.$f7.toast
          .create({
            text: "No players in the group",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
      }
      try {
        this.attendance.was_presense = []
        
        this.loaderAttendance = true;

        this.players.forEach((player) => {
          if (player.presense) {
            this.attendance.was_presense.push(player.id)
          }
        })

        const responseCreateAttendance = await attendanceListServices.createAttendance(this.attendance);
        if ('access' in responseCreateAttendance.data && !responseCreateAttendance.data.access) {
          if (responseCreateAttendance.data.type == 'exist') {
            this.$f7.toast
            .create({
              text: "The attendance list already exists!",
                          position: "bottom",
            
              closeTimeout: 3000
            })
            .open();
          }
          
        } else {
            if (responseCreateAttendance.data.id) {
              this.$f7router.navigate(`/calendar/?day=${this.$f7route.query.day}&group=${this.attendance.group}`)
            }
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the creation of the presence. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loaderAttendance = false;
    },
  }
};
</script>